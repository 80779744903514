/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { FormMode } from "cdes-vue/voc/gui/FormMode";
import { defineComponent, PropType, provide, InjectionKey, Ref, ref, toRefs } from "vue";

export type Breakpoint = "sx" | "sm" | "md" | "lg" | "xl" | "xxl";

export const formModeKey : InjectionKey<FormMode> = Symbol("formMode");
export const gutterKey: InjectionKey<Ref<number>> = Symbol("gutter");
export const columnsKey: InjectionKey<Ref<number>> = Symbol("columns");
export const singleColumnBreakpointKey: InjectionKey<Ref<Breakpoint>> = Symbol("singleColumnBreakpoint");
export const verticalBreakpointKey: InjectionKey<Ref<Breakpoint>> = Symbol("verticalBreakpoint");
export const labelWidthKey: InjectionKey<Ref<number>> = Symbol("labelWidth");

export default defineComponent({
    props: {
        columns: {
            type: Number as PropType<number>,
            default: () => 1,
        },
        singleColumnBreakpoint: {
            type: String as PropType<Breakpoint>,
            default: () => "lg",
        },
        verticalBreakpoint: {
            type: String as PropType<Breakpoint>,
            default: () => "md",
        },
        gutter: {
            type: Number as PropType<number>,
            default: () => 3,
        },
        labelWidth: {
            type: Number as PropType<number>,
            default: () => 6,
        },
    },

    setup(_props) {
        const props = toRefs(_props);
        provide(gutterKey, props.gutter);
        provide(columnsKey, props.columns);
        provide(singleColumnBreakpointKey, props.singleColumnBreakpoint);
        provide(verticalBreakpointKey, props.verticalBreakpoint);
        provide(labelWidthKey, props.labelWidth);
    },
});
