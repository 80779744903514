import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "cdes-page" }
const _hoisted_2 = { class: "input-group has-validation" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "invalid-feedback" }
const _hoisted_7 = ["id", "disabled"]
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row g-3" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_Select = _resolveComponent("Select")!
  const _component_FormGridMember = _resolveComponent("FormGridMember")!
  const _component_FormGrid = _resolveComponent("FormGrid")!
  const _directive_validation_form = _resolveDirective("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_FormGrid, {
      novalidate: "",
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.save()), ["prevent"])),
      labelWidth: 12
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormGridMember, null, {
          label: _withCtx(({ controlId, class: class_ }) => [
            _createVNode(_component_Label, {
              class: _normalizeClass(`col-form-label text-md-end ${class_}`),
              for: controlId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("general.subProject")), 1)
              ]),
              _: 2
            }, 1032, ["class", "for"])
          ]),
          control: _withCtx(({ id }) => [
            _createVNode(_component_Select, {
              mode: "flex",
              id: id,
              disabled: this.disabled || this.types == null	|| !this.isTypeEditable,
              selectClass: "form-select",
              options: this.typeOptions,
              modelValue: this.subProject.subProjectTypeId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.subProject.subProjectTypeId) = $event)),
              optional: "",
              sort: ""
            }, null, 8, ["id", "disabled", "options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_FormGridMember, { fieldset: "" }, {
          label: _withCtx(({ class: class_ }) => [
            _createElementVNode("legend", {
              class: _normalizeClass(`col-form-label text-md-end ${class_}`)
            }, _toDisplayString(_ctx.$t("subProject.edit.description")), 3)
          ]),
          control: _withCtx(({}) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(this.code), 1),
              _withDirectives(_createElementVNode("input", {
                class: "form-control w-6-em flex-grow-0",
                type: "number",
                min: "0",
                max: "99",
                disabled: this.disabled || _ctx.subProject == null || !this.isTypeEditable,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.subProject.number) = $event)),
                ref: "numberInput",
                onInvalid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateNumberValidity()))
              }, null, 40, _hoisted_4), [
                [_vModelText, this.subProject.number]
              ]),
              _withDirectives(_createElementVNode("input", {
                class: "form-control",
                type: "text",
                disabled: this.disabled || _ctx.subProject == null,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.subProject.name) = $event))
              }, null, 8, _hoisted_5), [
                [_vModelText, this.subProject.name]
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.numberValidity.rangeOverflow || _ctx.numberValidity.rangeUnderflow)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('subProject.edit.numberOutOfRange', { from: 0, to: 99 })), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('subProject.edit.numberInvalid')), 1)
                    ], 64))
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_FormGridMember, null, {
          label: _withCtx(({ controlId, class: class_ }) => [
            _createVNode(_component_Label, {
              class: _normalizeClass(`col-form-label text-md-end ${class_}`),
              for: controlId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("general.comment")), 1)
              ]),
              _: 2
            }, 1032, ["class", "for"])
          ]),
          control: _withCtx(({ id }) => [
            _withDirectives(_createElementVNode("input", {
              class: "form-control",
              type: "text",
              id: id,
              disabled: this.disabled || _ctx.subProject == null,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.subProject.comment) = $event))
            }, null, 8, _hoisted_7), [
              [_vModelText, this.subProject.comment]
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                type: "submit",
                disabled: this.disabled,
                class: "btn btn-primary"
              }, _toDisplayString(_ctx.$t("general.save")), 9, _hoisted_11),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary m-1",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
                disabled: _ctx.disabled
              }, _toDisplayString(_ctx.$t('general.cancel')), 9, _hoisted_12)
            ])
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_directive_validation_form]
    ])
  ]))
}