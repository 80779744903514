/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, onUnmounted, PropType, toRaw, watch, watchEffect, ref, Ref, UnwrapRef } from "vue";
import { DocumentReference } from "cdes-api/dto/DocumentReference";
import { LoadingPlaceholder, loadingPlaceholder, mapLoading, asyncEagerComputed, makePropWithValue, debounceWatcher } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import DateInput from "cdes-vue/util/form/DateInput.vue";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export class TemporaryDocumentReference extends DocumentReference {
    private _file: File;

    public set file(file: File) {
        this._file = file;
        this.attachmentOriginalFilename = file?.name;
    }

    public get file(): File {
        return this._file;
    }
}

export class LoadedDocumentReference extends TemporaryDocumentReference {
    public temporaryId: number;
    public temporaryDocumentVersionId: string;
}

function maybeArrayBuffer<T extends Blob>(blob: T | undefined | null): Promise<ArrayBuffer | undefined | null> {
    if (blob == null) {
        return Promise.resolve(blob as (null | undefined));
    } else {
        return blob.arrayBuffer();
    }
}

function removeExtension(filename: string): string {
    const split = filename.split(".");

    if (split.length === 0) {
        return "";
    } else if (split.length === 1) {
        return split[0];
    } else if (split.length === 2 && split[0].length === 0) {
        return split.join(".");
    } else {
        return split.slice(0, split.length - 1).join(".")
    }
}

// TODO: this component does not currently work with alread persisted references, they should be implemented once we need them.

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        DateInput,
        Dialog,
    },

    props: {
        reference: {
            type: [DocumentReference, TemporaryDocumentReference, LoadedDocumentReference] as PropType<DocumentReference | TemporaryDocumentReference | LoadedDocumentReference>,
        },
        temporaryDocumentVersionId: {
            type: [String, Symbol] as PropType<string | LoadingPlaceholder>,
        },
        temporary: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        onlyValidateFor: {
            type: String as PropType<string | undefined>,
        },
        disabled : {
            type : Boolean as PropType<boolean>,
            default : false            
        }
    },

    emits: ["delete", "add", "update:reference"],

    setup(props, context) {
        const ctx = useCtx();
        let prevIds = null as ([string, number] | null);
        let hasFileChanged = false;

        onUnmounted(() => {
            if (prevIds != null) {
                ctx.documentService.deleteDocumentReference(...prevIds);
            }
        });

        // typescript sees the emits type parameter of SetupContext as covariant instead of contravariant
        const ourReference = makePropWithValue(props, context as any, "reference" as const, (ref) => Object.assign(new (Object.getPrototypeOf(ref).constructor)(), ref), { deep: true });

        let loadedReference: Ref<UnwrapRef<LoadingPlaceholder | DocumentReference | TemporaryDocumentReference | LoadedDocumentReference>>;
        if (!props.temporary) {
            loadedReference = asyncEagerComputed(() => {
            const reference = ourReference.value;
            if (prevIds != null && !(reference instanceof LoadedDocumentReference && reference.temporaryId === prevIds[1] && reference.temporaryDocumentVersionId === prevIds[0] && reference.temporaryDocumentVersionId === props.temporaryDocumentVersionId)) {
                ctx.documentService.deleteDocumentReference(...prevIds);
            }

            if (reference instanceof LoadedDocumentReference && reference.temporaryDocumentVersionId === props.temporaryDocumentVersionId) {
                prevIds = [reference.temporaryDocumentVersionId, reference.temporaryId];
                return reference;
            } else if (reference instanceof TemporaryDocumentReference) {
                const temporaryDocumentVersionId = props.temporaryDocumentVersionId;

                if (temporaryDocumentVersionId === loadingPlaceholder || temporaryDocumentVersionId == null) {
                    return loadingPlaceholder;
                }

                return maybeArrayBuffer(reference.file)
                .then(arrayBuffer => ctx.documentService.uploadDocumentReference(temporaryDocumentVersionId, toRaw(reference), arrayBuffer != null ? new Uint8Array(arrayBuffer) : null))
                .then(temporaryId => {
                    prevIds = [temporaryDocumentVersionId, temporaryId];
                    return Object.assign(new LoadedDocumentReference(), reference, {
                        temporaryId,
                        temporaryDocumentVersionId,
                    });
                });
            } else if (reference instanceof DocumentReference) {
                prevIds = null;
                return reference;
            } else {
                throw new Error();
            }
        }, loadingPlaceholder);

        watchEffect(() => {
            if (loadedReference.value !== loadingPlaceholder) {
                ourReference.value = loadedReference.value;
            }
        });

        watch(
            loadedReference,
            debounceWatcher((newLoadedReference, oldLoadedReference) => {
                // only try to edit the uploaded document reference if it was not newly uploaded.
                if (newLoadedReference !== loadingPlaceholder && oldLoadedReference !== loadingPlaceholder && newLoadedReference instanceof LoadedDocumentReference && oldLoadedReference instanceof LoadedDocumentReference && newLoadedReference.temporaryId === oldLoadedReference.temporaryId && newLoadedReference.temporaryDocumentVersionId === oldLoadedReference.temporaryDocumentVersionId) {
                    if (newLoadedReference.file !== oldLoadedReference.file || hasFileChanged) {
                        hasFileChanged = false;
                        maybeArrayBuffer(newLoadedReference.file)
                        .then(arrayBuffer => ctx.documentService.editDocumentReferenceData(newLoadedReference.temporaryDocumentVersionId, newLoadedReference.temporaryId, newLoadedReference, new Uint8Array(arrayBuffer)), err => {
                            ErrorHelper.processErrorWithoutI18n(err);
                        });
                    } else {
                        ctx.documentService.editDocumentReference(newLoadedReference.temporaryDocumentVersionId, newLoadedReference.temporaryId, newLoadedReference);
                    }
                }
            }, 300),
            {
                deep: true,
            },
        );
        } else {
            loadedReference = ourReference;
        }

        const attachFile = () => {
            const reference = loadedReference.value;
            if (!(reference instanceof TemporaryDocumentReference)) {
                throw new Error("");
            }

            const uploadInput = document.createElement("input");
            uploadInput.setAttribute("type", "file");

            console.info("attachFile - init: " + (new Date().toISOString()));

            uploadInput.onchange = () => {
                console.info("attachFile - onchange: " + (new Date().toISOString()));
                if (uploadInput.files.length === 1) {
                    const file = uploadInput.files[0];
                    reference.file = file;
                    hasFileChanged = true;
                    console.info("attachFile - file: ", file);
                    // includes empty
                    if (!reference.reference) {
                        reference.reference = removeExtension(file.name);
                        reference.referencedVersionId = undefined;
                    }
                }
            };
            uploadInput.click();
        };

        const selectDocumentRef = ref<null | InstanceType<typeof Dialog>>(null);

        const referenceDocumentVersion = () => {
            const reference = loadedReference.value;
            if (!(reference instanceof TemporaryDocumentReference)) {
                throw new Error("");
            }
            selectDocumentRef.value.show().then(((value: { documentVersionId: number, title: string } | undefined) => {
                if (value != null) {
                    if (reference.file !== undefined) {
                        hasFileChanged = true;
                    }
                    reference.file = undefined;
                    reference.referencedVersionId = value.documentVersionId;
                    reference.reference = value.title;
                }
            }) as any, err => {
                ErrorHelper.processErrorWithoutI18n(err);
            });
        };

        return {
            selectDocumentRef,
            referenceDocumentVersion,
            TemporaryDocumentReference,
            loadedReference,
            attachFile,
        };
    },
});
