import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cdes-page" }
const _hoisted_2 = { class: "pageCaption ps-2" }
const _hoisted_3 = {
  class: "border m-2 ps-4 py-2 form-horizontal container-fluid",
  role: "form"
}
const _hoisted_4 = { class: "my-2 row" }
const _hoisted_5 = { class: "col-sm-2 form-group" }
const _hoisted_6 = ["name", "disabled", "id"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "ms-5 col form-group" }
const _hoisted_9 = ["name", "disabled", "id"]
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "my-2 form-group row" }
const _hoisted_12 = ["for"]
const _hoisted_13 = ["disabled", "id"]
const _hoisted_14 = { class: "my-2 form-group row" }
const _hoisted_15 = ["for"]
const _hoisted_16 = { class: "col-md-3" }
const _hoisted_17 = ["disabled", "id"]
const _hoisted_18 = { class: "mt-5 form-group" }
const _hoisted_19 = { class: "ms-5 me-2" }
const _hoisted_20 = ["for"]
const _hoisted_21 = ["disabled", "id"]
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProvideOriginalPageListWidget = _resolveComponent("ProvideOriginalPageListWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("original.provideOriginal.page.caption")), 1),
    _createVNode(_component_ProvideOriginalPageListWidget, {
      class: "m-2",
      infos: _ctx.infos
    }, null, 8, ["infos"]),
    _createElementVNode("form", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", this.radioCss]),
            name: _ctx.$id('provideOriginalResult'),
            disabled: this.disabled,
            type: "radio",
            id: this.$id('provideRequestRadioBtn'),
            value: "accepted",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.result) = $event))
          }, null, 10, _hoisted_6), [
            [_vModelRadio, this.result]
          ]),
          _createElementVNode("label", {
            class: "form-check-label",
            type: "radio",
            style: {'margin-left': '5px'},
            for: this.$id('provideRequestRadioBtn')
          }, _toDisplayString(_ctx.$t("original.provideOriginal.page.provideRequest")), 9, _hoisted_7)
        ]),
        _createElementVNode("span", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", this.radioCss]),
            name: _ctx.$id('provideOriginalResult'),
            disabled: this.disabled,
            value: "denied",
            type: "radio",
            id: this.$id('denyRequestRadioBtn'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.result) = $event))
          }, null, 10, _hoisted_9), [
            [_vModelRadio, this.result]
          ]),
          _createElementVNode("label", {
            class: "form-check-label",
            style: {'margin-left': '5px'},
            type: "radio",
            for: this.$id('denyRequestRadioBtn')
          }, _toDisplayString(_ctx.$t("original.provideOriginal.page.denyRequest")), 9, _hoisted_10)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", {
          class: "form-label col-lg-2",
          type: "text",
          for: this.$id('commentTextArea')
        }, _toDisplayString(_ctx.$t("general.comment")), 9, _hoisted_12),
        _withDirectives(_createElementVNode("textarea", {
          disabled: this.disabled,
          class: "ms-3 col-md-3",
          rows: "2",
          cols: "60",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.comment) = $event)),
          id: this.$id('commentTextArea')
        }, " ", 8, _hoisted_13), [
          [_vModelText, _ctx.comment]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", {
          class: "col-form-label col-lg-2",
          type: "file",
          for: this.$id('fileUploadInput')
        }, _toDisplayString(_ctx.$t("original.provideOriginal.page.selectOriginalFile")), 9, _hoisted_15),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("input", {
            type: "file",
            disabled: this.disabled,
            class: _normalizeClass({ 'form-control': true, 'is-invalid': _ctx.error !== _ctx.loadingPlaceholder }),
            onChange: _cache[3] || (_cache[3] = ($event: any) => {_ctx.file = ($event.target.files[0] ?? null);}),
            id: this.$id('fileUploadInput')
          }, null, 42, _hoisted_17),
          _createElementVNode("div", null, [
            _createElementVNode("i", null, _toDisplayString(_ctx.$t('original.provideOriginal.page.uploadHint')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, [
          _createElementVNode("label", {
            for: this.$id('passwordInput'),
            style: { 'margin-right' : '5px'}
          }, _toDisplayString(_ctx.$t('general.password')), 9, _hoisted_20),
          _withDirectives(_createElementVNode("input", {
            disabled: this.disabled,
            class: _normalizeClass(_ctx.passwordCss),
            type: "password",
            id: this.$id('passwordInput'),
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event))
          }, null, 10, _hoisted_21), [
            [_vModelText, _ctx.password]
          ])
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          style: { 'margin-right' : '1em'},
          disabled: this.signDisabled,
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (this.sign && this.sign(...args)))
        }, _toDisplayString(_ctx.$t('original.provideOriginal.page.sign')), 9, _hoisted_22),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-secondary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.abort()))
        }, _toDisplayString(_ctx.$t('general.cancel')), 1)
      ])
    ])
  ]))
}