/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent } from "vue";
import FormGrid from "cdes-vue/util/form/FormGrid.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import LabelTextFieldListWidget from "cdes-vue/review/label/LabelTextFieldListWidget.vue";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { LabelTextField } from "cdes-api/dto/review/LabelTextField";
import { LabelContentInfo } from "cdes-api/dto/review/LabelContentInfo";
import { ReviewCycle } from "generated/cdes-api/dto/ReviewCycle";
import { LabelContent } from "cdes-api/dto/LabelContent";
import { Label } from "cdes-api/dto/Label";
import { LabelTextFieldType } from "cdes-vue/voc/review/LabelTextFieldType";
import { LabelInfo } from "cdes-api/dto/review/LabelInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components : {
        CustomSelect,
        CheckBox,
        FormGrid,
        FormGridLocal,
        FormGridColumn,
        FormGridMember,
        LabelTextFieldListWidget,
        Select,
        TextInput
    },

    computed : {
        reviewCycleOptions() : SelectOption[] {
            let labelContentInfo : LabelContentInfo = this.labelContentInfo as LabelContentInfo;
            let reviewCycles : ReviewCycle[] = labelContentInfo.reviewCycles;
            let selectOptions : SelectOption[] = [];
            for (let reviewCycle of reviewCycles) {
                let name : string = this.$s(reviewCycle.name);
                selectOptions.push({
                    label : name,
                    value : reviewCycle.id
                });
            }
            return selectOptions;
        },

        reviewCycleName() : string {
            let reviewCycleId : number = this.label.reviewCycleId;
            for (let reviewCycle of this.labelContentInfo.reviewCycles) {
                if (reviewCycle.id == reviewCycleId) {
                    return this.$s(reviewCycle.name);
                }
            }
            return "";
        },
        
        rotationOptions() : SelectOption[] {
            return [{ value : 0, label : "0°" },
                    { value : 90, label : "270°" },
                    { value : 270, label : "90°" },
                    { value : 180, label : "180°" }
                   ];
        },

        signatureFields() : LabelTextField[] {
            return this.getTextFieldsByType(LabelTextFieldType.SIGNATURE);
        },
        documentVersionFields() : LabelTextField[] {
            return this.getTextFieldsByType(LabelTextFieldType.DOCUMENT_VERSION);
        },
        freeFields() : LabelTextField[] {
            return this.getTextFieldsByType(LabelTextFieldType.FREE);
        },
        imageLink() : string {
			let reviewCycleId : number = this.alternativeReviewCycleId != null ? this.alternativeReviewCycleId : this.label.reviewCycleId;
            let url = this.ctx.getTapestryRequestUrl("external/LabelPngPreview", [
                "" + this.labelContent.id,
                "-1",
                "320",
                "" + (reviewCycleId!=null?reviewCycleId:0)
            ]);
            return url;

            /*
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());
            let paramString : string = urlSearchParams.toString();
            */
            /*
            const parameters = {
                versionId: temporaryDocumentVersionId.value,
                destinationId: props.destinationId,
                pixelSize: 320,
                angle: seekLabelResult.value.nativeRotation,
                };*/
            /*
            return this.ctx.getTapestryRequestUrl("SecureDirectService/1/EditLabel/editLabel/editLabelContent", [
                "" + this.labelContent.id
            ]);*/
        }
    },

    mounted() {
        let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
        this.ctx.reviewService.getLabelContentInfo(organisationPersonId, this.labelContentId, this.labelId)
            .then((labelContentInfo : LabelContentInfo) => {
                this.labelContentInfo = labelContentInfo;
                this.label = labelContentInfo.label;
                this.labelContent = labelContentInfo.labelContent;
                this.selectedSourceReviewCycleId = null;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
    },

    data() {
        return {
            labelContentInfo : new LabelContentInfo(),
            labelContent : new LabelContent(),
            label : new Label(),
            alternativeReviewCycleId : null,
            pdfFile : null,
            originalFile : null,
            selectedSourceReviewCycleId : null
        };
    },

    props: {
        labelContentId : {
            type : Number
        },
        labelId : {
            type : Number
        }
    },

    methods : {
        openLabelContentPreviewWindow() : void {
            /*
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());
            urlSearchParams.append("sp", -1);
            urlSearchParams.append("sp", 320);
            urlSearchParams.append("sp", this.label.reviewCycleId);
            let paramString : string = urlSearchParams.toString();
            */

            window.open(this.imageLink);
        },
        constructDummyItems() {
            return [
                new LabelTextField()
            ];
        },
        getTextFieldsByType(fieldType : LabelTextFieldType) {
            let reviewCycleId : number = this.label.reviewCycleId;
            let alternativeReviewCycleId : number = this.alternativeReviewCycleId as number;
            let selectedSourceReviewCycleId : number = this.selectedSourceReviewCycleId as number;
            
            let fields : LabelTextField[] = [];
            if (this.labelContentInfo != null && this.labelContentInfo.textFields != null) {
                for (let labelTextField of this.labelContentInfo.textFields) {
                    if (labelTextField.fieldType == fieldType
                        && (labelTextField.reviewCycleId == reviewCycleId
                            || (alternativeReviewCycleId != null && (labelTextField.reviewCycleId == alternativeReviewCycleId || labelTextField.reviewCycleId == selectedSourceReviewCycleId)))) {
                        fields.push(labelTextField);
                    }
                }
            }
            return fields;
        },
        openPreview() {
            let labelPdfLink : string = this.getLabelPdfLink();
            window.open(labelPdfLink);
        },
        download() {
            let labelLink : string = this.getLabelLink();
            window.open(labelLink);
        },
        getLabelLink() : string {
            let reviewCycleId : number = this.alternativeReviewCycleId != null
                ? this.alternativeReviewCycleId : this.label.reviewCycleId;
            
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", this.labelContent.id.toString());
            urlSearchParams.append("sp", reviewCycleId.toString());
            
            let name : string = this.labelContent.name ?? "label";
            let filetype : string = this.labelContent.origFiletype ?? "";
            urlSearchParams.append("filename", name + (filetype.length > 0 ? "." + filetype : ""));
            return "/cdes/svc/label?" + urlSearchParams.toString();

        },
        getLabelPdfLink() : string {
            let reviewCycleId : number = this.alternativeReviewCycleId != null
                ? this.alternativeReviewCycleId : this.label.reviewCycleId;
            
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", this.labelContent.id.toString());
            urlSearchParams.append("sp", reviewCycleId.toString());
            return "/cdes/svc/labelPdf?" + urlSearchParams.toString();
        },
        addSignatureTextField() : void {
            let textField : LabelTextField = new LabelTextField();
            textField.reviewCycleId
                = (this.alternativeReviewCycleId != null ? this.alternativeReviewCycleId : this.label.reviewCycleId);
            textField.fieldType = LabelTextFieldType.SIGNATURE;
            this.labelContentInfo.textFields.push(textField);
        },
        addDocumentVersionTextField() : void {
            let textField : LabelTextField = new LabelTextField();
            textField.reviewCycleId
                = (this.alternativeReviewCycleId != null ? this.alternativeReviewCycleId : this.label.reviewCycleId);            
            textField.fieldType = LabelTextFieldType.DOCUMENT_VERSION;
            this.labelContentInfo.textFields.push(textField);            
        },
        addFreeTextField() : void {
            let textField : LabelTextField = new LabelTextField();
            textField.reviewCycleId
                = (this.alternativeReviewCycleId != null ? this.alternativeReviewCycleId : this.label.reviewCycleId);            
            textField.fieldType = LabelTextFieldType.FREE;
            this.labelContentInfo.textFields.push(textField);            
        },
        removeTextField(labelTextField : LabelTextField) : void {
            let idx : number = null;
            for (let n = 0; n < this.labelContentInfo.textFields.length; n++) {
                let textField : LabelTextField = this.labelContentInfo.textFields[n];
                if (textField == labelTextField) {
                    idx = n;
                }
            }
            this.labelContentInfo.textFields.splice(idx, 1);
        },

        save() : void {
            this.loadPdfFileAndSave(() => {
                //
            });
        },
        saveAndExit() : void {
            this.loadPdfFileAndSave(() => {
                this.$router.go(-1);
            });
        },

        loadPdfFileAndSave(callback : () => void) : void {
            if (this.pdfFile == null) {
                this.loadOrigFileAndSave(null, callback);
            } else {
                this.pdfFile.arrayBuffer().then(buffer => {
                    this.loadOrigFileAndSave(new Uint8Array(buffer), callback);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            }
        },
        loadOrigFileAndSave(pdfFile : Uint8Array, callback : () => void) : void {
            if (this.originalFile == null) {
                this.doSave(pdfFile, null, callback);
            } else {
                this.originalFile.arrayBuffer().then(buffer => {
                    this.doSave(pdfFile, new Uint8Array(buffer), callback);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            }
        },
        doSave(pdfFile : Uint8Array, origFile : Uint8Array, callback : () => void) : void {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            let labelContent : LabelContent = this.labelContent as LabelContent;
            this.ctx.reviewService.saveLabelContent(organisationPersonId, labelContent, this.labelContentInfo.textFields,
                                                    pdfFile, pdfFile != null ? this.pdfFile.name : null,
                                                    origFile, origFile != null ? this.originalFile.name : null)
                .then(() => {
                    callback();
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },
        copySelectedTextFields() : void {
            let newTextFields : LabelTextField[] = [];
            let sourceReviewCycleId = this.selectedSourceReviewCycleId;
            
            for (let labelTextField of this.labelContentInfo.textFields) {
                if (sourceReviewCycleId == labelTextField.reviewCycleId && labelTextField.selected) {
                    let newTextField = new LabelTextField();
                    newTextField.fieldType = labelTextField.fieldType;
    
                    newTextField.labelId = labelTextField.labelId;
                    newTextField.llx = labelTextField.llx;
                    newTextField.lly = labelTextField.lly;
                    newTextField.urx = labelTextField.urx;
                    newTextField.ury = labelTextField.ury;
                    newTextField.font = labelTextField.font;
                    newTextField.fontStyle = labelTextField.fontStyle;
                    newTextField.fontPtsize = labelTextField.fontPtsize;
                    newTextField.alignment = labelTextField.alignment;
                    newTextField.reviewCycleId = this.alternativeReviewCycleId;

                    newTextField.reviewCycleNodeId = null;
                    if (labelTextField.reviewCycleNodeId != null) {
                        let oldReviewCycleNodeName = null;
                        let oldReviewCycleNodes = this.labelContentInfo.reviewCycleIdToNodes.get(labelTextField.reviewCycleId);
                        for (let oldReviewCycleNode of oldReviewCycleNodes) {
                            if (oldReviewCycleNode.id == labelTextField.reviewCycleNodeId) {
                                oldReviewCycleNodeName = oldReviewCycleNode.name;
                            }
                        }
                        let newReviewCycleNodes = this.labelContentInfo.reviewCycleIdToNodes.get(this.alternativeReviewCycleId);
                        for (let newReviewCycleNode of newReviewCycleNodes) {
                            if (newReviewCycleNode.name == oldReviewCycleNodeName) {
                                newTextField.reviewCycleNodeId = newReviewCycleNode.id;
                                break;
                            }
                        }
                    }

                    newTextField.usedForRealm = labelTextField.usedForRealm;
                    newTextField.realmSequence = labelTextField.realmSequence;
    
                    newTextField.content = labelTextField.content;

                    newTextFields.push(newTextField);
                }
            }

            for (let newTextField of newTextFields) {
                this.labelContentInfo.textFields.push(newTextField);
            }
        }

        
        /*
        saveAndExit() : void {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            let labelContent : LabelContent = this.labelContent as LabelContent;
            this.ctx.reviewService.saveLabelContent(organisationPersonId, labelContent, this.labelContentInfo.textFields)
                .then(() => {
                    this.$router.go(-1);
                });
        },*/        
    }
});
