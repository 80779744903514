/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import { ReviewData } from "cdes-api/dto/review/ReviewData";
import { IReviewNodePositionJoin } from "cdes-api/joinDto/IReviewNodePositionJoin";
import { IReviewResultOptionJoin } from "cdes-api/joinDto/IReviewResultOptionJoin";
import ReviewNodeEditInfo from "cdes-api/dto/review/ReviewNodeEditInfo";
import ReviewAttachmentsWidget from "./ReviewAttachmentsWidget.vue";
import ReviewCommentTextArea from "./ReviewCommentTextArea.vue";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewAttachmentsWidget,
        ReviewCommentTextArea,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        documentVersionId: {
            type: Number as PropType<number>,
        },
        reviewData: {
            type: [Object, Symbol] as PropType<ReviewData | LoadingPlaceholder>,
        },
        position: {
            type: [Object, Symbol] as PropType<IReviewNodePositionJoin | LoadingPlaceholder>,
        },
        modelValue: {
            type: Object as PropType<ReviewNodeEditInfo>,
        }
    },

    computed: {
        resultOptions(): LoadingPlaceholder[] | IReviewResultOptionJoin[] {
            if (this.reviewData === loadingPlaceholder) {
                return new Array(2).fill(loadingPlaceholder);
            } else {
                return this.reviewData.resultOptionsByPositionTypeId.get(this.reviewData.reviewCycleNode.reviewCycleNodeReviewCyclePositionTypeId);
            }
        },
        redliningLink(): string {
            if (this.reviewData === loadingPlaceholder || this.position === loadingPlaceholder) {
                return 'javascript:void(0)';
            } else {
                return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionRedliningMimeSource", [
                    "" + this.documentVersionId,
                    "" + this.position.reviewCyclePositionResultId,
                    "" + (this.reviewData.executingParticipationJoin.overridingParticipantId ?? this.reviewData.executingParticipationJoin.projectParticipantId),
                    "l" + Date.now(),
                ]);
            }
        },
    },

    methods : {
        optionChosen(option : IReviewResultOptionJoin) {
            if (option.reviewCycleResultOptionFinishCellFlag) {
                window.alert(this.$t("review.review.entry.breakWarning"));
            }
        }
    },

    emits: ["update:modelValue"],

    setup(props, context) {
        const value = makePropWithValue(props, context, "modelValue", v => ({...v}), { deep: true });

        return {
            loadingPlaceholder,
            value,
        };
    },
});
