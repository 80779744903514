/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

export enum TaskType {
    ATTACHED_REVIEW_TASK = "ATTACHED_REVIEW_TASK",
    ORIGINAL_DOCUMENT_TASK = "ORIGINAL_DOCUMENT_TASK",
    PLOT_ORDER_ACCEPTANCE_TASK = "PLOT_ORDER_ACCEPTANCE_TASK",
    REVIEW_CYCLE_ENTRY_TASK = "REVIEW_CYCLE_ENTRY_TASK",
    REVIEW_CYCLE_PLOT_TASK = "REVIEW_CYCLE_PLOT_TASK",
    REVIEW_TASK = "REVIEW_TASK",
    SELECT_CELL_CONNECTION_TASK = "SELECT_CELL_CONNECTION_TASK",

    ATTACHED_REVIEW_TASK_DONE = "ATTACHED_REVIEW_TASK_DONE",
    ORIGINAL_DOCUMENT_TASK_DONE = "ORIGINAL_DOCUMENT_TASK_DONE",
    PLOT_ORDER_ACCEPTANCE_TASK_DONE = "PLOT_ORDER_ACCEPTANCE_TASK_DONE",
    REVIEW_CYCLE_ENTRY_TASK_DONE = "REVIEW_CYCLE_ENTRY_TASK_DONE",
    REVIEW_CYCLE_PLOT_TASK_DONE = "REVIEW_CYCLE_PLOT_TASK_DONE",
    REVIEW_TASK_DONE = "REVIEW_TASK_DONE",
    SELECT_CELL_CONNECTION_TASK_DONE = "SELECT_CELL_CONNECTION_TASK_DONE",

}
