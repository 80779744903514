/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import { Modal } from "bootstrap";
import Dialog from "./Dialog.vue";

export default defineComponent({
    components: {
        Dialog,
    },

    expose: ["getPassword"],

    props: {
        title: {
            type: String,
        },
        submitLabel: {
            type: String,
        },
        extraClasses : {
            type : String
        }
    },

    data() {
        return {
            password: "",
            wasValidated: false,
        };
    },

    methods: {
        onHide(): void {
            this.password = "";
        },
        getPassword(): Promise<string | undefined> {
            return (this.$refs.dialog as InstanceType<typeof Dialog>).show() as any;
        },
        submit(cont: (value: string | undefined) => void): void {
            console.info("PasswordDialog.submit called");
            const isValid = (this.$refs.passwordInput as HTMLInputElement).checkValidity();
            this.wasValidated = !isValid;

            if (isValid) {
                cont(this.password);
            }
        },
    },
});
