/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export function distinct<T>(array: T[], getKey: (val: T) => unknown = a => a): T[] {
    const ret: T[] = [];
    const seen: Set<unknown> = new Set();

    for (const i of array) {
        const key = getKey(i);
        if (!seen.has(key)) {
            ret.push(i);
            seen.add(key);
        }
    }

    return ret;
}

export function takeWhile<T>(array: T[], pred: (val: T) => boolean): T[] {
    let ret: T[] = [];

    for (const i of array) {
        if (!pred(i)) {
            break;
        }
        ret.push(i);
    }
    return ret;
}

export function groupBy<T, K>(array: T[], key: (val: T) => K): Map<K, T[]>;
export function groupBy<T, P extends keyof T>(array: T[], key: P): Map<T[P], T[]>;
export function groupBy<T>(array: T[], key: unknown): Map<unknown, T> {
    const ret = new Map();

    for (const i of array) {
        // @ts-ignore
        const computedKey = (typeof key === "function") ? key(i) : i[key];
        if (ret.has(computedKey)) {
            ret.get(computedKey).push(i);
        } else {
            ret.set(computedKey, [i]);
        }
    }

    return ret;
}
