/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import 'bootstrap';

import { computed, ComputedRef, defineComponent, getCurrentInstance, Ref, ref } from "vue";

import ContextLine from "cdes-vue/layout/main/ContextLine.vue";
import MainMenu from "cdes-vue/layout/main/MainMenu.vue";
import { PropType } from 'vue';
import { MainMenuSection } from 'cdes-vue/layout/main/MainMenu';
import { CdesContext } from 'cdes-vue/core/CdesContext';
import { timeZoneInjectionKey } from 'cdes-vue/util/form/DateInput.vue';
import { ContextLevel } from "cdes-vue/voc/ctx/ContextLevel";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import InfoMessageDisplayWidget from "cdes-vue/message/infoMessage/InfoMessageDisplayWidget.vue";
import NewPasswordWidget from "./NewPasswordWidget.vue";
import RequestUserPolicyWidget from "./RequestUserPolicyWidget.vue";
import { CLOSE } from "cdes-vue/util/layout/DialogButton";
import { ErrorHelper } from 'cdes-vue/util/ErrorHelper';
import { PostMessage } from './PostMessage';

export default defineComponent({
    components : {
        ContextLine,
        MainMenu,
        Dialog,
        InfoMessageDisplayWidget,
        NewPasswordWidget,
        RequestUserPolicyWidget,
    },
    computed : {
        actualContextLevel(): ContextLevel {
            if (this.ctx.activeNetworkId == null) {
                return ContextLevel.NONE;
            } else if (this.ctx.activeProjectId == null) {
                return ContextLevel.NETWORK;
            } else if (this.ctx.activeSubProjectId == null) {
                return ContextLevel.PROJECT;
            } else {
                return ContextLevel.SUB_PROJECT;
            }
        },
    },

    provide() {
        return {
            [timeZoneInjectionKey as symbol]: this.ctx.getTimeZone(),
        };
    },

    mounted() {
        window.addEventListener("message", (evt) => {
            if (evt.origin == window.origin) {
                this.processMessage(evt.data as PostMessage);
            } else {
                throw new Error("Illegal message to message event listener.");
            }
        });

        if (this.ctx.infoMessages?.length > 0) {
            this.openInfoMessages();
        }
        let prevComplete = null;
        if (this.ctx.passwordExpired) {
            prevComplete = (this.$refs.newPasswordDialog as InstanceType<typeof Dialog>).show()
        }
        if (this.ctx.userPolicyExpired) {
            Promise.resolve(prevComplete)
            .then(() => (this.$refs.requestUserPolicyDialog as InstanceType<typeof Dialog>).show(), err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        }
    },

    watch : {
        $route: {
            handler(route) {
                this.ctx.buekMode = route.meta.buek ?? this.ctx.buekMode ?? false;
                this.ctx.contextLevel = route.meta.contextLevel  ?? ContextLevel.NONE;
                this.section = route.meta.section ?? this.section ?? MainMenuSection.REVIEW;
            },
            immediate: true,
        },
    },

    data() {
        return {
            section: MainMenuSection.REVIEW,
            newPasswordInvalid: true,
            viewKey : 1
        };
    },

    setup() {
        return {
            ContextLevel,
            CLOSE,
        };
    },

    methods: {
        processMessage(message : PostMessage) : void {
            if (message.action == "routerPush") {
                if (message.routerObj != null) {
                    this.$router.push(message.routerObj);
                } else if (message.page == "uploadFile") {
                    this.$router.push({ name : "uploadFile", query : { documentId : message.documentId }});
                } else if (message.page == "teilnehmerBuek") {
                    let projectId : number = message.projectId;
                    this.ctx.activeProjectId = projectId;
                    this.$router.push({ name : "buek/teilnehmerBuek", query : { projectId : projectId } });
                } else if (message.page == "plotOrderAcceptanceTask") {
                    let plotOrderId : number = message.plotOrderId;
                    this.$router.push({ name : "plotOrderAcceptanceTask", query : { plotOrderId : plotOrderId } });
                }
            } else if (message.action == "routerBack") {
                this.$router.back();
            } else if (message.action == "relogin") {
                let loginUrl : string = message.loginUrl;
                let reloginSrc : string = message.reloginSrc;

                this.ctx.triggerRelogin(loginUrl, reloginSrc).then(() => {
                    this.processReloginFinished(loginUrl, reloginSrc);
                });
            }
        },

        processReloginFinished(loginUrl : string, reloginSrc : string) : void {
            if (this.$refs.componentRef != null && (this.$refs.componentRef as any).getContentWindow != null) {
                let msgObj = {
                    action : "reloginFinished",
                    loginUrl : loginUrl,
                    reloginSrc : reloginSrc
                };
                
                let wnd = (this.$refs.componentRef as any).getContentWindow() as Window;
                wnd.postMessage(msgObj, window.origin);
            }
        },
        
        openInfoMessages(): void {
            (this.$refs.infoDialog as InstanceType<typeof Dialog>).show();
        },
        
        openAbout(): void {
            (this.$refs.aboutDialog as InstanceType<typeof Dialog>).show();
        },
        contextChanged() : void {
            if (this.$refs.componentRef != null && (this.$refs.componentRef as any).reload != null) {
                (this.$refs.componentRef as any).reload();
            }
        },
        increaseViewKey() : void {
            this.viewKey = this.viewKey + 1;
        }
    },
});
/*
@Options({
    components : {
        MainMenu : MainMenu
    },
    props : {
        // Compare:
        // https://github.com/vuejs/vue-class-component/issues/456
        // https://github.com/vuejs/vue-class-component/issues/447#issuecomment-695685109
        // https://v3.vuejs.org/guide/typescript-support.html#annotating-props
        contentRef : Object as PropType<FrameworkRef>
    }
})
class CdesMainUi extends Vue {
    contentRef : FrameworkRef;

    get section() : MainMenuSection {
        return this.contentRef.getSection();
    }

    get src() : string {
        return this.contentRef.getSrc(this.ctx);
    }
}

export default CdesMainUi;
*/
