import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!

  return (!_ctx.hidden)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t("review.review.data.stateXCaption")), 1),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("table", _hoisted_2, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.reviewer')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.date')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.comment')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.result')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positions, ([position, resultOption]) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: position.nodePositionId
                }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_LoadedOrPlaceholder, {
                      value: position,
                      lines: 2
                    }, {
                      default: _withCtx(() => [
                        (position.reviewCyclePositionResultId != null)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("div", null, _toDisplayString(position.positionOrganisationName) + " (" + _toDisplayString(position.positionPersonGivenName) + " " + _toDisplayString(position.positionPersonSurName) + ") ", 1),
                              (position.positionOrganisationId !== position.positionMainOrganisationId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('review.review.data.deputyWithOrganisation', {
                    surName: position.positionMainPersonSurName,
                    givenName: position.positionMainPersonGivenName,
                    organisation: position.positionMainOrganisationName,
                    })), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('review.review.data.deputyWithoutOrganisation', {
                    surName: position.positionMainPersonSurName,
                    givenName: position.positionMainPersonGivenName,
                    })), 1))
                            ], 64))
                          : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(position.positionMainOrganisationName) + " (" + _toDisplayString(position.positionMainPersonGivenName) + " " + _toDisplayString(position.positionMainPersonSurName) + ") ", 1))
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  ]),
                  _createElementVNode("td", null, [
                    (position === _ctx.loadingPlaceholder || position.reviewCyclePositionResultId != null && position.reviewCyclePositionResultResultDate != null)
                      ? (_openBlock(), _createBlock(_component_LoadedOrPlaceholder, {
                          key: 0,
                          value: position
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$d(new Date(position.reviewCyclePositionResultResultDate * 1000), "long")), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (position === _ctx.loadingPlaceholder || position.reviewCyclePositionResultId != null && position.reviewCyclePositionResultComment != null)
                      ? (_openBlock(), _createBlock(_component_LoadedOrPlaceholder, {
                          key: 0,
                          value: position
                        }, {
                          default: _withCtx(() => [
                            (position.reviewCyclePositionResultComment != null)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  innerHTML: _ctx.sanitize(position.reviewCyclePositionResultComment)
                                }, null, 8, _hoisted_6))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (position === _ctx.loadingPlaceholder || (position.reviewCyclePositionResultId != null && position.reviewCyclePositionResultSignatureId != null && resultOption != null && resultOption.name != null))
                      ? (_openBlock(), _createBlock(_component_LoadedOrPlaceholder, {
                          key: 0,
                          value: position
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$s(resultOption.name)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ], 64))
    : _createCommentVNode("", true)
}