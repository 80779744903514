/* Copied to cdes-bs */
export class LocalStorageHelper {
    private static getContextKeyString(contextKeys : string[]) : string {
        let contextKeyString : string = "/cdes/";
        for (let n = 0; n < contextKeys.length; n++) {
            contextKeyString += contextKeys[n] != null ? contextKeys[n].toString() : "";
            contextKeyString += (n < contextKeys.length - 1 ? "/" : "");
        }
        if (contextKeyString == "") {
            // Global
            contextKeyString = "G";
        }
        return contextKeyString;
    }

    public static storeInLocalStorage(contextKeys : string[], key : string, obj : any) : boolean {
        let contextKeyString : string = LocalStorageHelper.getContextKeyString(contextKeys);
        if (window.localStorage != null) {
            let js : string = JSON.stringify(obj);
            try {
                if (!contextKeyString.endsWith("/")) {
                    contextKeyString += "/";
                }
                if (key != null && key.length > 0 && key[0] == "/") {
                    key = key.substring(1);
                }
                let fullKey = contextKeyString + key;
                window.localStorage[fullKey] = js;
                return true;
            } catch (e) {
                // Former js code: Check for                 if (e == QUOTA_EXCEEDED_ERR) {
                console.warn("Could not store object under key " + key + " in local storage.  Maybe quote exceeded.");
                return false;
            }
        } else {
            return false;
        }
    }

    public static getFromLocalStorage(contextKeys : string[], key : string) {
        if (window.localStorage != null) {
//            LocalStorageHelper.printLocalStorageToDebug();

            let contextKeyString = LocalStorageHelper.getContextKeyString(contextKeys);

            if (!contextKeyString.endsWith("/")) {
                contextKeyString += "/";
            }
            if (key != null && key.length > 0 && key[0] == "/") {
                key = key.substring(1);
            }

            let s = window.localStorage[contextKeyString + key];
            if (s == null) {
                return null;
            } else {
                return JSON.parse(s);
            }
        } else {
            return null;
        }
    }

    private static printLocalStorageToDebug() : void {
        /*
        if (storageLog.isDebugEnabled()) {
            storageLog.debug("========================");
            storageLog.debug("Contents of localStorage");
            storageLog.debug("========================");
            storageLog.debug("========================");
            for (var n = 0; n < window.localStorage.length; n++) {
                var key = window.localStorage.key(n);
                storageLog.debug("Key " + key + " = ", window.localStorage[key]);
            }
            storageLog.debug("(end of contents of localStorage)");
            }*/
        console.info("========================");
        console.info("Contents of localStorage");
        console.info("========================");
        console.info("========================");
        for (let n = 0; n < window.localStorage.length; n++) {
            let key = window.localStorage.key(n);
            console.info("Key " + key + " = ", window.localStorage[key]);
        }
        console.info("(end of contents of localStorage)");
    }
}
