import { LabelAlignment } from "cdes-api/voc/review/LabelAlignment";
import { LabelFontStyle } from "cdes-api/voc/review/LabelFontStyle";
import { LabelTextFieldType } from "cdes-api/voc/review/LabelTextFieldType";

export class LabelTextField {
    fieldType : LabelTextFieldType;
    
    id : number;
    labelId : number;
    llx : number;
    lly : number;
    urx : number;
    ury : number;
    font : string;
    fontStyle : LabelFontStyle;
    fontPtsize : number;
    alignment : LabelAlignment;
    reviewCycleId : number;
    
    reviewCycleNodeId : number;
    usedForRealm : boolean;
    realmSequence : number;
    
    content : string;

    // Client only
    selected : boolean;
}
