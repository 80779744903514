import { defineComponent } from "vue";
export default defineComponent({
    props: {
        addUpperCloseButton : {
            type : Boolean
        },
        src : {
            type : String
        }
    },

    expose : [ "reload", "getContentWindow" ],

    /*
    updated() {
        // When jumping from a Dojo page to another Dojo page in another main menu section,
        // the iframe receives the correct, new address, but isn't reloaded by default,
        // for some obscure reason.
        // Thus, explicitely call reload here.
        this.reload();
    },*/

    methods : {
        reload() {
            (this.$refs.iframe as HTMLIFrameElement).contentWindow.location.reload();
        },

        getContentWindow() {
            return (this.$refs.iframe as HTMLIFrameElement).contentWindow;
        },

        close() {
            this.$router.go(-1);           
        }
    }
});
