import { defineComponent, ref, onMounted, onBeforeUnmount, PropType } from "vue";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { ConsortiumPersonInfo } from "cdes-api/dto/person/ConsortiumPersonInfo";
import { OrganisationParticipationEditInfo } from "cdes-api/dto/project/OrganisationParticipationEditInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { Dropdown } from "bootstrap";

export default defineComponent({
    components : {
        CheckBox,        
        TextInput
    },

    name: "MainParticipantDropdown",

    emits: ['update:modelValue'],

    props : {
        id : String,
        disabled : Boolean,
        personInfos : Array as PropType<ConsortiumPersonInfo[]>,
        modelValue : Number,
        editInfo : Object as PropType<OrganisationParticipationEditInfo>
    },    

    data() {
        return {
            active: ref(false),
            dropdown: undefined as (undefined | Dropdown),            
            offerParticipationsOfAllOrganisations : false,
            resizeObserver: new ResizeObserver(this.resizeCallback.bind(this)),
            mainParticipantFilter : "",
            expanded : false,
            selectedIndex : null
        };
    },

    //       :aria-activedescendant="$id('item' + selectedIndex)"

    mounted() {
        document.addEventListener('click', this.close);

        this.dropdown = new Dropdown(this.$refs.select, {
            popperConfig: (popperConfig) => {
                popperConfig.modifiers.push({
                    name: 'flip',
                    options: {
                        // don't try to flip between start/end alignments
                        flipVariations: false,
                    },
                });
                return popperConfig;
            },
        });
    },

    beforeUnmount() {
        document.removeEventListener('click', this.close);
    },

    unmounted() {
        this.dropdown.dispose();
        this.dropdown = undefined;
//        this.form = undefined;
    },

    computed : {
        cssClass() : string {
            if (this.modelValue == null) {
                return "form-select customSelectSelect mustField";
            } else {
                return "form-select customSelectSelect";
            }
        },
        organisationPersonIdToPersonInfo() : Map<number, ConsortiumPersonInfo> {
            let organisationPersonIdToPersonInfo : Map<number, ConsortiumPersonInfo> = new Map<number, ConsortiumPersonInfo>();
            for (let personInfo of this.personInfos) {
                let organisationPersonId : number = personInfo.organisationPerson.id;
                organisationPersonIdToPersonInfo.set(organisationPersonId, personInfo);
            }
            return organisationPersonIdToPersonInfo;
        },
        selectId(): string {
            return this.id ?? this.$id("select");
        },
        labelId(): string | null {
            return document.querySelector(`label[for="${CSS.escape(this.selectId)}"]`)?.id;
        },
        options() : SelectOption[] {
            let optionsArray : SelectOption[] = [];

            let filterString = this.mainParticipantFilter != null ? this.mainParticipantFilter.trim() : null;

            let participationOrganisationId : number = this.editInfo.organisationId;
            /*
            let mainParticipantInfo : ParticipantInfo = this.mainParticipantInfo;
            let existingDeputyInfos : ParticipantInfo[] = this.deputyInfos;
            let existingDeputyOpIds : Set<number> = new Set<number>();
            if (mainParticipantInfo != null) {
                existingDeputyOpIds.add(mainParticipantInfo.organisationPerson.id);
            }
            for (let existingDeputyInfo of existingDeputyInfos) {
                existingDeputyOpIds.add(existingDeputyInfo.organisationPerson.id);
            }*/
            
            let rawPersonInfos : ConsortiumPersonInfo[] = this.personInfos;
            let personInfos : ConsortiumPersonInfo[] = [];

            for (let rawPersonInfo of rawPersonInfos) {
                let currOrganisationPersonId : number = rawPersonInfo.organisationPerson.id;
                let currOrganisationId : number = rawPersonInfo.organisation.id;
                let currConsortiumId : number = rawPersonInfo.consortium?.id;
                let candidateString = this.getPersonOrganisationString(rawPersonInfo);
                if ((this.offerParticipationsOfAllOrganisations
                        || participationOrganisationId == currOrganisationId
                        || participationOrganisationId == currConsortiumId)
                    && (   filterString == null || filterString.length == 0
                        || candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1)) {
                    personInfos.push(rawPersonInfo);
                }
            }
            
            for (let personInfo of personInfos) {
                let label : string = this.getPersonOrganisationString(personInfo);
                optionsArray.push({
                    label : label,
                    value : personInfo.organisationPerson.id
                });
            }
            return optionsArray;
        }
        

        /*
        deputyOptions() : ConsortiumPersonInfo[] {
            let filterString = this.deputyAddFilter != null ? this.deputyAddFilter.trim() : null;

            let participationInfo : ParticipationInfo = this.participationInfo;
            if (participationInfo == null) {
                return [];
            }
            
            let participationOrganisationId : number = participationInfo.projectParticipation.organisationId;
            let mainParticipantInfo : ParticipantInfo = this.mainParticipantInfo;
            let existingDeputyInfos : ParticipantInfo[] = this.deputyInfos;
            let existingDeputyOpIds : Set<number> = new Set<number>();
            if (mainParticipantInfo != null) {
                existingDeputyOpIds.add(mainParticipantInfo.organisationPerson.id);
            }
            for (let existingDeputyInfo of existingDeputyInfos) {
                existingDeputyOpIds.add(existingDeputyInfo.organisationPerson.id);
            }
            
            let rawPersonInfos : ConsortiumPersonInfo[] = this.personInfos;
            let personInfos : ConsortiumPersonInfo[] = [];

            for (let rawPersonInfo of rawPersonInfos) {
                let currOrganisationPersonId : number = rawPersonInfo.organisationPerson.id;
                let currOrganisationId : number = rawPersonInfo.organisation.id;
                let currConsortiumId : number = rawPersonInfo.consortium?.id;
                let candidateString = this.getPersonOrganisationString(rawPersonInfo);
                if (!existingDeputyOpIds.has(currOrganisationPersonId)
                    && (this.offerParticipationsOfAllOrganisations
                        || participationOrganisationId == currOrganisationId
                        || participationOrganisationId == currConsortiumId)
                    && (   filterString == null || filterString.length == 0
                        || candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1)) {
                    personInfos.push(rawPersonInfo);
                }
            }
            
            return personInfos;
        },
        mainParticipantInfo() : ParticipantInfo {
            return this.participationInfo.mainParticipantInfo;
        },
        deputyInfos() : ParticipantInfo[] {
            return this.participationInfo.deputyInfos;
        },        */
    },

    methods : {
        close() {
            this.active = false
        },
        hide(evt?): void {
            let mainParticipantFilterInput : (typeof TextInput) = (this.$refs.mainParticipantFilterTextInput) as (typeof TextInput);
            if (evt == null || !mainParticipantFilterInput.isInputNode(evt)) {
                this.expanded = false;
            }
        },
        show(): void {
            if (!this.disabled) {
                this.expanded = true;
            }
        },
        toggle(): void {
            if (!this.disabled) {
                this.expanded = !this.expanded;
            }
        },
        toggleActive() {
            this.active = !this.active;
        },
        getPersonOrganisationString(consortiumPersonInfo : ConsortiumPersonInfo) : string {
            return consortiumPersonInfo.person.surName + " " + consortiumPersonInfo.person.givenName
                + " (" + consortiumPersonInfo.organisation.name + ")";
        },
        getOptionLabel(organisationPersonId : number) : string {
            let personInfo : ConsortiumPersonInfo = this.organisationPersonIdToPersonInfo.get(organisationPersonId);
            return personInfo != null ? this.getPersonOrganisationString(personInfo) : "";
        },
        keydown() {
            //
        },
        resizeCallback(entries: ResizeObserverEntry[]): void {
            for (const entry of entries) {
                if (entry.target === this.$refs.select) {
                    (this.$refs.dropdown as HTMLElement).style.minWidth =
                        (this.$refs.select as HTMLElement).offsetWidth + "px";
                }
            }
        },
        itemClick(index: number, option : SelectOption): void {
            this.selectedIndex = index;
            this.$emit("update:modelValue", option.value);
            this.hide();
        },
        onMouseDown(evt : MouseEvent) {
            let mainParticipantFilterInput : (typeof TextInput) = (this.$refs.mainParticipantFilterTextInput) as (typeof TextInput);
            if (mainParticipantFilterInput.isInputNode(evt)) {
                mainParticipantFilterInput.focus();
                evt.preventDefault();
                evt.stopPropagation();
            }
        }
    },

    watch: {
        expanded(expanded: boolean): void {
            if (expanded) {
                this.resizeObserver.observe(this.$refs.select as HTMLElement);
                this.dropdown.show();
            } else {
                this.resizeObserver.disconnect();
                this.dropdown.hide();
            }
        },
        disabled(disabled: boolean): void {
            if (disabled) {
                this.hide();
            }
        }
    }
});
