/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

export class ParticipationPageSearchModel {
    // Essentially boolean, but vue-router doesn't support booleans.
    newForOrgMode : number;

    projectId : number;
    
    organisationId : number;
    cdesRoleId : number;
    subProjectId : number;
    mainParticipantPersonId : number;
    deputyParticipantPersonId : number;
}
