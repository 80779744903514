/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import CheckBox from 'cdes-vue/util/form/CheckBox.vue';
import Select from 'cdes-vue/util/form/Select.vue';
import TextInput from 'cdes-vue/util/form/TextInput.vue';
import LabelVue from "cdes-vue/util/form/Label.vue";
import { Label } from 'cdes-api/dto/Label';
import { defineComponent } from "vue";
import { ReviewCycle } from 'cdes-api/dto/ReviewCycle';
import { LabelInfo } from 'cdes-api/dto/review/LabelInfo';
import LabelContentListWidget from "./LabelContentListWidget.vue";
import { ErrorHelper } from 'cdes-vue/util/ErrorHelper';

export default defineComponent({

    components: {
        TextInput,
        LabelVue,
        Select,
        CheckBox,
        LabelContentListWidget
    },

    data() {
        return {
            labelInfo: Object.assign(new LabelInfo(), {
                label: {
                    name: "",
                    active: true, 
                    organisationId: this.ctx.activeNetwork.maintainerId
                }
            }) as LabelInfo,
            availableReviewCycles: [],
            menuSection : this.$route.meta.section
        }
    },

    props: {
        labelId : {
            type: Number
        }
    },
    computed: {
        activeChangedDateFormatted() {
            return this.labelInfo?.label?.activeModified ? this.$d(new Date(this.labelInfo.label.activeModified * 1000), {dateStyle: 'short'}) : undefined
        },
        reviewCycleOptions() {
            return this.availableReviewCycles.map((rc : ReviewCycle) => ({
                label: rc.name,
                value: rc
            }));
        }
    },
    mounted() {
        if (this.labelId) {
            this.ctx.reviewService.getLabelInfo(this.ctx.activeOrganisationPersonId, this.labelId).then((labelInfo : LabelInfo) => {
                this.labelInfo = labelInfo;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        } else {
            // Newly created label - review cycle needs to be set, so fetch options:
            this.ctx.reviewService.getReviewCyclesByNetwork(this.ctx.activeNetworkId).then((reviewCycles : ReviewCycle[]) => {
                this.availableReviewCycles = reviewCycles;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            }) 
        }
    },
    methods: {
        save() : void {
            this.doSave(false);
        },
        saveAndExit() : void {
            this.doSave(true);
        },
        doSave(back : boolean) : void {
            let label : Label = this.labelInfo.label as Label;
            this.ctx.reviewService.saveLabel(this.ctx.activeOrganisationPersonId, label).then(() => {
                if (back) {
                    this.$router.back();                    
                }
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        },        
        updateLabelReviewCycleId() : void {
            this.labelInfo.label.reviewCycleId = this.labelInfo.reviewCycle.id;
        },
        deleteLabelContent(labelToDeleteId : number) {
            this.labelInfo.labelContents = this.labelInfo.labelContents.filter((lc) => lc.id !== labelToDeleteId)
        },
        addLabelContent() : void {
            let targetName : string = (this.menuSection == "plan" ? "labelContentEdit" : "labelContentEditAdmin");
            this.$router.push({
                name: targetName,
                query: {
                    labelId : this.labelId
                }
            })
        },        
    }
});
