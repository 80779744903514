import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "d-flex flex-column w-100 reviewTaskSearchTopDiv" }
const _hoisted_3 = { class: "d-flex flex-row align-items-start flex-wrap leftWidgetBorderDistance" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_6 = { class: "d-flex flex-row" }
const _hoisted_7 = { class: "d-flex flex-column dateColumn" }
const _hoisted_8 = { class: "d-flex flex-row flex-wrap" }
const _hoisted_9 = { class: "d-flex flex-column" }
const _hoisted_10 = { class: "d-flex flex-column align-items-center fromDateInput" }
const _hoisted_11 = { class: "invalid-feedback" }
const _hoisted_12 = { class: "d-flex flex-column align-items-center toDateInput" }
const _hoisted_13 = { class: "invalid-feedback" }
const _hoisted_14 = { class: "d-flex flex-row align-items-center formLine leftWidgetBorderDistance" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "d-flex flex-row align-items-center ms-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_Label = _resolveComponent("Label")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _directive_validation_form = _resolveDirective("validation-form")!

  return (this.expanded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "collapse show",
        id: _ctx.$id('collapse')
      }, [
        _withDirectives(_createElementVNode("form", {
          class: "needs-validation",
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.doSearch && _ctx.doSearch(...args)), ["prevent"])),
          novalidate: ""
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Select, {
                    mode: "flex",
                    class: "col formLine",
                    label: _ctx.$t("general.filter"),
                    labelClass: "deputyLabel",
                    id: _ctx.$id('deputySelect'),
                    options: this.deputyOptions,
                    widthMode: "auto",
                    disabled: _ctx.filterByLate || this.asyncRunning,
                    modelValue: _ctx.deputyMode,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deputyMode) = $event))
                  }, null, 8, ["label", "id", "options", "disabled", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (this.ctx.getNumberOfOrganisations() > 1)
                    ? (_openBlock(), _createBlock(_component_Select, {
                        key: 0,
                        mode: "flex",
                        class: "col formLine flex-grow-1",
                        label: _ctx.$t("task.search.organisationLabel"),
                        labelClass: "modeColumnLabel",
                        id: _ctx.$id('organisationSelect'),
                        options: this.organisationPersonOptions,
                        disabled: this.asyncRunning,
                        modelValue: _ctx.organisationPersonId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.organisationPersonId) = $event))
                      }, null, 8, ["label", "id", "options", "disabled", "modelValue"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_CheckBox, {
                      mode: "flex",
                      class: "labelFormLine",
                      id: _ctx.$id('filterByDateCheckBox'),
                      label: _ctx.$t('task.search.dateLabel'),
                      modelValue: _ctx.filterByDate,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterByDate) = $event)),
                      disabled: _ctx.filterByDateOverride != undefined || this.asyncRunning
                    }, null, 8, ["id", "label", "modelValue", "disabled"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_DateInput, {
                      class: "formLine btn-sm",
                      utcSeconds: _ctx.fromTs,
                      "onUpdate:utcSeconds": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fromTs) = $event)),
                      disabled: !_ctx.filterByDate || this.asyncRunning,
                      required: ""
                    }, null, 8, ["utcSeconds", "disabled"]),
                    _createVNode(_component_Label, { class: "subItemSectionText" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.fromTsDifference != null ? _ctx.formatTimeDifference(_ctx.fromTsDifference, this.$i18n.locale) : ''), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('task.search.fromIntervalInvalid')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_DateInput, {
                      class: "formLine btn-sm",
                      utcSeconds: _ctx.toTs,
                      "onUpdate:utcSeconds": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.toTs) = $event)),
                      disabled: !_ctx.filterByDate || this.asyncRunning,
                      required: ""
                    }, null, 8, ["utcSeconds", "disabled"]),
                    _createVNode(_component_Label, { class: "subItemSectionText" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.toTsDifference != null ? _ctx.formatTimeDifference(_ctx.toTsDifference, this.$i18n.locale) : ''), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('task.search.toIntervalInvalid')), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-primary",
                  disabled: this.asyncRunning
                }, _toDisplayString(_ctx.$t("general.button.searchCaption")), 9, _hoisted_15)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_TextInput, {
                  mode: "flex",
                  class: "ms-4",
                  inputClass: "quickSearchInput",
                  type: "text",
                  ref: "quickSearchInput",
                  disabled: this.asyncRunning,
                  label: _ctx.$t("general.fields.quickSearchCaption"),
                  modelValue: this.quickFilter,
                  "onUpdate:modelValue": [
                    _cache[5] || (_cache[5] = ($event: any) => ((this.quickFilter) = $event)),
                    _cache[6] || (_cache[6] = ($event: any) => (this.$emit('update:quickFilter', $event || null)))
                  ]
                }, null, 8, ["disabled", "label", "modelValue"])
              ])
            ])
          ])
        ], 544), [
          [_directive_validation_form]
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}