/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { ObjectPlannerPositionDefinitionInfo } from "cdes-api/dto/project/ObjectPlannerPositionDefinitionInfo";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { RoleType } from "cdes-api/dto/RoleType";
import { SelectOption } from "cdes-vue/util/form/Select";

export default defineComponent({
    components: {
        CheckBox,
        ParempiGrid,
        Select,
        TextInput
    },

    props: {
        items: {
            type: Array as PropType<ObjectPlannerPositionDefinitionInfo[]>,
            default: () => [],
        },
        roleTypes : {
            type : Array as PropType<RoleType[]>,
            default : () => []
        }
    },
    emits: ['deleteItem'],
    computed : {
        columns() : ParempiColumn<ObjectPlannerPositionDefinitionInfo>[] {
            return [
                ParempiColumn.asRowSpanSlot("name", this.$t("general.name"), "name"),
                ParempiColumn.asRowSpanSlot("roleType", this.$t("general.roleType"), "roleType"),
                new ParempiColumn({
                    id: "notificationOptions",
                    slotName: "notificationOptions",
                    headerSlotName: "notificationOptionsHeader",
                    defaultWidth: 2,
                }),
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions")
            ];
        },
        roleTypeOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            for (let roleType of this.roleTypes) {
                options.push({
                    label : roleType.name,
                    value : roleType.id
                });
            }
            options = options.sort((a : SelectOption, b : SelectOption) => {
                return a.label.localeCompare(b.label);
            });

            return options;
        }
    },
    methods : {
        deleteItem(item : ObjectPlannerPositionDefinitionInfo) {
            let deleteIdx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                let currItem = this.items[n];
                if (currItem == item) {
                    deleteIdx = n;
                }
            }
            this.$emit("deleteItem", deleteIdx);
        }
    }
});
