/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { TaskDeputyMode } from "cdes-api/voc/task/TaskDeputyMode";
import { TaskSearchMode } from "cdes-api/voc/task/TaskSearchMode";

export class TaskSearchModel {
    organisationPersonId : number;
    
    searchMode : TaskSearchMode;
    deputyMode : TaskDeputyMode; 
    fromTs : number;
    toTs : number;

    // client-only
    filterByDate : boolean;
}
