/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, computed, watchEffect, watch, reactive } from "vue";
import { makePropWithValue, asyncEagerComputed, loadingPlaceholder } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import { groupBy } from "cdes-vue/util/Array";
import { IPlanUploadCommentAttachmentJoin } from "cdes-api/joinDto/IPlanUploadCommentAttachmentJoin";
import { IPlanUploadCommentResultJoin } from "cdes-api/joinDto/IPlanUploadCommentResultJoin";
import { ReviewCycleCommentOption } from "cdes-api/dto/ReviewCycleCommentOption";
import RadioButton from "cdes-vue/util/form/RadioButton.vue";
import DOMPurify from "dompurify";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { IPlanUploadCommentNodeJoin } from "cdes-api/joinDto/IPlanUploadCommentNodeJoin";
import PlanUploadPreviousCommentInfo from "cdes-api/dto/document/PlanUploadPreviousCommentInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ParempiRowPos } from "cdes-vue/util/grid/ParempiRowPos";

export default defineComponent({
    components : {
        RadioButton,
        LoadedOrPlaceholder,
        ParempiGrid
    },

    emits: ["update:modelValue", "update:hidden"],

    props: {
        taskId: {
            type: Number as PropType<number>,
        },
        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            base : null,
            nodeJoins : [],
            idToResultJoin : null,
            commentResultIdToAttachments : null,
            commentOptions : []
        }
    },

    computed: {
        columns() : ParempiColumn<IPlanUploadCommentNodeJoin>[] {
            return [
                ParempiColumn.asWidthRowSpanSlot(1, "node", this.$t("general.reviewCycleNode"), "node"),
                ParempiColumn.asWidthRowSpanSlot(1, "reviewer", this.$t("general.reviewer"), "reviewer"),
                ParempiColumn.asWidthRowSpanSlot(1, "realmShort", this.$t("general.realmShort"), "realmShort"),
                ParempiColumn.asWidthRowSpanSlot(1, "date", this.$t("general.date"), "date"),
                ParempiColumn.asWidthRowSpanSlot(1, "review", this.$t("general.review"), "review"),
                ParempiColumn.asWidthRowSpanSlot(1, "result", this.$t("general.result"), "result"),
                ParempiColumn.asWidthRowSpanSlot(6, "comment", this.$t("review.review.data.comment"), "comment"),
                ParempiColumn.asWidthRowSpanSlot(1, "reviewCycleCommentOption", this.$t("review.review.data.reviewCycleCommentOption"),
                                            "reviewCycleCommentOption"),
            ]
        },

        reviewCycleNodes() {
            if (this.nodeJoins == null) {
                return [];
            }
            return this.nodeJoins.map(reviewCycleNode => ({
                ...reviewCycleNode,
                reviewCyclePositionResults: this.idToResultJoin.get(reviewCycleNode.reviewCycleNodeId) ?? [],
            }));
        },

        comments() {
            if (this.base == null) {
                return [];
            }

            return this.reviewCycleNodes
            .flatMap(node => node.reviewCyclePositionResults)
            .map(result => ({
                reviewCyclePositionResultId: result.reviewCyclePositionResultId,
                comment: result.reviewCyclePositionResultComment,
                commentOptionId: result.reviewCyclePositionResultReviewCycleCommentOptionId
            }));
        }
    },

    watch : {
        comments : {
            handler(comments, oldComments) {
                this.$emit("update:modelValue", comments);
            },
            deep : true
        }
    },

    mounted() {
        this.ctx.reviewService.getPreviousCommentInfo(this.taskId, this.ctx.activeOrganisationPersonId)
            .then((previousCommentInfo : PlanUploadPreviousCommentInfo) => {
                this.base = previousCommentInfo.base;
                this.commentOptions = previousCommentInfo.reviewCycleCommentOptions;

                this.nodeJoins = previousCommentInfo.nodes;
                this.nodeJoins = this.nodeJoins.sort((nodeOne : IPlanUploadCommentNodeJoin, nodeTwo : IPlanUploadCommentNodeJoin) => {
                    let resultDateOne = nodeOne.commentedResultResultDate;
                    let resultDateTwo = nodeTwo.commentedResultResultDate;
                    if (resultDateOne < resultDateTwo) {
                        return -1;
                    } else if (resultDateOne > resultDateTwo) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                let commentResults = previousCommentInfo.commentResults;
                this.idToResultJoin = groupBy(commentResults, "reviewCycleNodeId");

                this.commentResultIdToAttachments = groupBy(previousCommentInfo.attachments, "commentedResultId");
            }, (err) => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
    },

    methods: {
        rowPosFactory(item : IPlanUploadCommentNodeJoin) {
            let resultJoins = this.idToResultJoin.get(item.reviewCycleNodeId);
            return new ParempiRowPos(resultJoins.length);
        },

        rowPosIncrementor(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : void {
            pos.increment(0);
        },

        nodeRowSpanFct(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : number {
            let subRow : number = pos.getSubRow();
            if (subRow == 0) {
                let resultJoins = this.idToResultJoin.get(item.reviewCycleNodeId);
                return resultJoins.length;
            } else {
                return null;
            }
        },

        getResultJoin(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : IPlanUploadCommentResultJoin {
            let resultJoins = this.idToResultJoin.get(item.reviewCycleNodeId);
            return resultJoins[pos.getSubRow()];
        },

        getAttachments(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : IPlanUploadCommentResultJoin {
            let resultJoin = this.getResultJoin(item, pos);
            let attachments = this.commentResultIdToAttachments.get(resultJoin.commentedResultId);
            return attachments != null ? attachments : [];
        },

        getReviewerString(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : string {
            let resultJoin : IPlanUploadCommentResultJoin = this.getResultJoin(item, pos);
            return resultJoin.organisationName + " (" + resultJoin.personGivenName + " " + resultJoin.personSurName + ")";
        },

        getRealmString(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : string {
            let resultJoin : IPlanUploadCommentResultJoin = this.getResultJoin(item, pos);
            // @ts-ignore
            let realmNameFormatted : string = this.$s(resultJoin.realmName);
            return resultJoin.realmCode + " " + realmNameFormatted;
        },

        getDateString(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : string {
            let resultJoin : IPlanUploadCommentResultJoin = this.getResultJoin(item, pos);
            return this.$d(new Date(resultJoin.commentedResultResultDate * 1000), "long")
        },

        getComment(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : string {

            let resultJoin : IPlanUploadCommentResultJoin = this.getResultJoin(item, pos);
            return DOMPurify.sanitize(resultJoin.commentedResultComment);
        },
        getResult(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos) : string {
            let resultJoin : IPlanUploadCommentResultJoin = this.getResultJoin(item, pos);
            // @ts-ignore
            return this.$s(resultJoin.reviewCycleResultOptionName)
        },
        commentChanged(item : IPlanUploadCommentNodeJoin, pos : ParempiRowPos, evt) : void {
            let resultJoin = this.getResultJoin(item, pos);
            this.getResultJoin(item, pos).reviewCyclePositionResultComment = evt.target.value;
        },

        getAttachmentLink(attachment: IPlanUploadCommentAttachmentJoin): string {
            if (attachment.attachmentIsRedliningDelta) {
                return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionReviewProtocolPdfMimeSource", [""+attachment.attachmentDocumentVersionId]);
            } else {
                return `/cdes-dojo-impl/repositoryDownload/attachments/${this.ctx.activeOrganisationPersonId}/${attachment.attachmentId}?${new URLSearchParams({fileName: attachment.attachmentOriginalName})}`;
            }
        },
        getRedliningLink(position: IPlanUploadCommentResultJoin): string {
            return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionRedliningMimeSource", [
                "" + this.base.documentVersionId,
                "" + position.reviewCyclePositionResultId,
                "" + this.base.projectParticipantId,
                "l" + Date.now(),
            ]);
        },
        getCommentOptions(reviewCycleCommentTypeId : number) : ReviewCycleCommentOption[] {
            let selectedOptions : ReviewCycleCommentOption[] = [];
            let commentOptions : ReviewCycleCommentOption[] = this.commentOptions;
//            let commentOptions : ReviewCycleCommentOption[] = (typeof this.base == "object" && "commentOptions" in this.base
//                ? this.base.commentOptions as ReviewCycleCommentOption[] : []);
            for (let option of commentOptions) {
                if (option.reviewCycleCommentTypeId == reviewCycleCommentTypeId) {
                    selectedOptions.push(option);
                }
            }
            return selectedOptions;
        },
        optionChosen(option : ReviewCycleCommentOption) {
            if (option.finishCellFlag) {
                window.alert(this.$t("review.review.entry.breakWarning"));
            }
        }
    }
});
