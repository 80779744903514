/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Label } from 'cdes-api/dto/Label';
import { LabelContent } from 'cdes-api/dto/LabelContent';
import { ReviewCycle } from 'cdes-api/dto/ReviewCycle';

export class LabelInfo {
    label? : Label;
    reviewCycle? : ReviewCycle;
    labelContents? : LabelContent[];
}
