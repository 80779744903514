import { defineComponent } from "vue";
import OriginalDocumentOrderSearchWidget from "./OriginalDocumentOrderSearchWidget.vue";
import OriginalDocumentOrderListWidget from "./OriginalDocumentOrderListWidget.vue";
import { OriginalDocumentOrderSearchModel } from "cdes-api/dto/original/OriginalDocumentOrderSearchModel";
import { OriginalDocumentOrderInfo } from "cdes-api/dto/original/OriginalDocumentOrderInfo";
import Collapse from "cdes-vue/util/layout/Collapse.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

export default defineComponent({
    components: {
        OriginalDocumentOrderListWidget,
        OriginalDocumentOrderSearchWidget,
        Collapse,
    },

    computed: {
        countLabelString() : string {
			let returnString = "";
			if (this.infos != null && !this.searchRunning){
				returnString = this.infos.length != null ? (this.infos.length + " " + this.$t("original.page.countLabel")) : "";
			} else if (this.searchRunning){
				returnString = this.$t("general.searchRuns");
			}
            return returnString;
        },

        disabled() : boolean {
            return this.searchRunning;
        }
    },

    mounted() {
        let searchWidget : (typeof OriginalDocumentOrderSearchWidget)
            = this.$refs.searchWidget as (typeof OriginalDocumentOrderSearchWidget);
        searchWidget.populateFromLocalStorage();

        this.search(OriginalDocumentOrderSearchWidget.searchModel);
    },

    data() {
        return {
            infos: [] as OriginalDocumentOrderInfo[],
            searchRunning : false
        };
    },

    methods: {
        search(searchModel: OriginalDocumentOrderSearchModel): void {
            this.searchRunning = true;

            if (searchModel != null) {
                let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
                LocalStorageHelper.storeInLocalStorage([ opString ], "/original/search", searchModel);            
            }

            
            this.ctx.documentService.getOriginalDocumentOrders(this.ctx.activeOrganisationPersonId, {
                ...searchModel,
                organisationPersonId: this.ctx.activeOrganisationPersonId,
                subProjectId: this.ctx.activeSubProjectId,
            })
                .then(infos => {
                    this.infos = infos;
                    this.searchRunning = false;
                },
                      err => {
                          ErrorHelper.processError(this.$t, this.$d, err);
                          this.searchRunning = false;                          
                      });
        },
        requestOriginal(item: OriginalDocumentOrderInfo): void {
            this.$router.push({
                path: "/plan/originale/requestOriginal",
                query: {
                    orderId: item.originalDocumentOrderId
                }
            })
        },
        removeOrder(originalDocumentOrderId : number) : void {
            let deleteIdx : number = null;
            for (let n = 0; n < this.infos.length; n++) {
                let info : OriginalDocumentOrderInfo = this.infos[n] as OriginalDocumentOrderInfo;
                if (info.originalDocumentOrderId == originalDocumentOrderId) {
                    deleteIdx = n;
                }
            }
            this.infos.splice(deleteIdx, 1);
        }
    },
});
