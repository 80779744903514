/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import DeliveryTargetOutput from "cdes-vue/plot/template/DeliveryTargetOutput.vue";
import { PlotOrderTemplateHelper } from "cdes-vue/plot/template/PlotOrderTemplateHelper";
import AddressOutput from "cdes-vue/util/output/AddressOutput.vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { PlotOrderItemTemplateInfo } from "cdes-api/dto/plot/PlotOrderItemTemplateInfo";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import NumberInput from "cdes-vue/util/form/NumberInput.vue";
import Select from "cdes-vue/util/form/Select.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { PlotPaperQuality } from "cdes-vue/voc/plot/PlotPaperQuality";
import { PlotHelper } from "../util/PlotHelper";
import { PlotTemplateResolver } from "cdes-vue/voc/plot/PlotTemplateResolver";
import { PlotOrderTemplateEditInfo } from "cdes-api/dto/plot/PlotOrderTemplateEditInfo";
import { ReviewCycleNode } from "cdes-api/dto/ReviewCycleNode";

export default defineComponent({
    components: {
        CheckBox,
        NumberInput,
        ParempiGrid,
        Select
    },

    props: {
        plotOrderItemTemplateInfos : {
            type: Array as PropType<PlotOrderItemTemplateInfo[]>,
            default: () => [],
        },

        templateResolver : {
            type : String as PropType<PlotTemplateResolver>
        },

        deliveryAddressId : {
            type : Number
        },

        editInfo : {
            type : Object as PropType<PlotOrderTemplateEditInfo>
        }
    },    

    computed: {
        columns(): ParempiColumn<PlotOrderItemTemplateInfo>[] {
            let columns = [
                ParempiColumn.asRowSpanSlot("paperQuality", this.$t("plot.template.item.list.paper"), "paperQuality"),
                ParempiColumn.asRowSpanSlot("isInColor", this.$t("plot.template.item.list.color"), "isInColor"),
                ParempiColumn.asRowSpanSlot("margin", this.$t("plot.template.item.list.margin"), "margin"),
                ParempiColumn.asRowSpanSlot("mirror", this.$t("plot.template.item.list.mirror"), "mirror"),
                ParempiColumn.asRowSpanSlot("scale", this.$t("plot.template.item.list.scale"), "scale"),
                ParempiColumn.asRowSpanSlot("count", this.$t("plot.template.item.list.count"), "count"),
            ];
            if (this.templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION
                || this.templateResolver == PlotTemplateResolver.REVIEW_CYCLE) {
                columns.push(ParempiColumn.asRowSpanSlot("deliveryTarget", this.deliveryTargetCaption, "deliveryTarget"));
            }
            columns.push(ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions"));
            return columns;
        },

        deliveryTargetCaption() : string {
            if (this.templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION) {
                return this.$t("plot.template.item.list.projectParticipationTarget");
            } else if (this.templateResolver == PlotTemplateResolver.REVIEW_CYCLE) {
                return this.$t("plot.template.item.list.reviewCycleNodeTarget");
            } else {
                return "Not implemented";
            }
        },

        deliveryTargetOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            if (this.templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION) {
                if (this.editInfo.deliveryPersonJoins != null) {
                    for (let personJoin of this.editInfo.deliveryPersonJoins) {
                        let organisationName : string = personJoin.organisationName;
                        let personGivenName : string = personJoin.personGivenName;
                        let personSurName : string = personJoin.personSurName;
                        let cdesRoleName : string = personJoin.cdesRoleName;
                        let projectParticipationId : number = personJoin.projectParticipationId;

                        options.push({
                            value : projectParticipationId,
                            label : organisationName + " (" + personGivenName + " " + personSurName + ") - " + cdesRoleName
                        });
                    }
                }
            } else if (this.templateResolver == PlotTemplateResolver.REVIEW_CYCLE) {
                if (this.editInfo.reviewCycleTreeInfo != null && this.editInfo.templateInfo != null
                   && this.editInfo.templateInfo.reviewCycleId != null) {
                    let idToCells = this.editInfo.reviewCycleTreeInfo.reviewCycleIdToCells;
                    let cellIdToNodes = this.editInfo.reviewCycleTreeInfo.reviewCycleCellIdToNodes;
                    if (idToCells != null && cellIdToNodes != null) {
                        let reviewCycleCells = idToCells.get(this.editInfo.templateInfo.reviewCycleId);
                        if (reviewCycleCells != null) {
                            for (let reviewCycleCell of reviewCycleCells) {
                                let children = [];
                                let reviewCycleNodes = cellIdToNodes.get(reviewCycleCell.id);
                                if (reviewCycleNodes != null) {
                                    for (let reviewCycleNode of reviewCycleNodes) {
                                        children.push({
                                            value : reviewCycleNode.id,
                                            // @ts-ignore
                                            label : this.$s(reviewCycleNode.name)
                                        });
                                    }
                                }

                                options.push({
                                    value : reviewCycleCell.id,
                                    // @ts-ignore
                                    label : this.$s(reviewCycleCell.name),
                                    children : children
                                });

                            }
                        }
                        
                    }                    
                }
            }

            options = options.sort((optionOne : SelectOption, optionTwo : SelectOption) => {
                let labelOne = optionOne.label;
                let labelTwo = optionTwo.label;
                return labelOne.localeCompare(labelTwo);
            });
            
            return options;
        }
    },

    emits: ["addItem", 'removeItem', "update:plotOrderItemTemplateInfos"],

    methods : {
        constructPaperQualityOptions() : SelectOption[] {
            return PlotHelper.getPaperQualitySelectOptions(this.$t);
        },

        constructColorOptions() : SelectOption[] {
            return PlotHelper.getColorSelectOptions(this.$t);
        },

        constructMarginOptions() : SelectOption[] {
            return PlotHelper.getMarginSelectOptions(this.$t);
        },

        constructScaleOptions() : SelectOption[] {
            return PlotHelper.getScaleSelectOptions(this.$t);
        },

        addPosition(item : PlotOrderItemTemplateInfo) : void {
            this.$emit("addItem", item.id);
        },

        removePosition(item : PlotOrderItemTemplateInfo) : void {
            this.$emit("removeItem", item.id);
        },
        getDeliveryTargetCss(item : PlotOrderItemTemplateInfo) : string {
            return item.deliveryAddressId != null ? "" : "mustField";
        }
    }
});
