import { defineComponent } from "vue";
export default defineComponent({
    props: {
        addUpperCloseButton : {
            type : Boolean
        },
        src : {
            type : String
        }
    },

    expose : [ "reload" ],

    mounted() {
        let iframe : HTMLIFrameElement = this.$refs.iframe as HTMLIFrameElement;
        iframe.onload = () => {
            let document = iframe.contentDocument;
            let redirectSpan : HTMLSpanElement = document.getElementById("redirectSpan") as HTMLSpanElement;
            let tabSessionIdSpan : HTMLSpanElement = document.getElementById("tabSessionIdSpan") as HTMLSpanElement;
            let cameBackSpan : HTMLSpanElement = document.getElementById("cameBackSpan") as HTMLSpanElement;
            let documentIdSpan : HTMLSpanElement = document.getElementById("documentIdSpan") as HTMLSpanElement;

            if (redirectSpan != null) {
                let target = redirectSpan.innerText;
                if (target == "UploadFilePage") {
                    let documentId : number = Number.parseInt(documentIdSpan.innerText);
                    this.$emit("iframeReloaded");
                    this.$router.push({ name : "uploadFile", query : { documentId : documentId } });
                }
            }            
        };
    },

    /*
    updated() {
        // When jumping from a Dojo page to another Dojo page in another main menu section,
        // the iframe receives the correct, new address, but isn't reloaded by default,
        // for some obscure reason.
        // Thus, explicitely call reload here.
        this.reload();
    },*/

    methods : {
        reload() {
            /* The problem here was that (only) Firefox presented a "Form resend" warning when you did:
             * 1. Use back button on Tapestry page
             * 2. Switch context, e.g. change network
             *
             * I tried several things until I found a solution for getting rid that warning.
             *
             *
             * https://stackoverflow.com/questions/570015/how-do-i-reload-a-page-without-a-postdata-warning-in-javascript
             * let iFrameElement : HTMLIFrameElement = this.$refs.iframe as HTMLIFrameElement;
             * let href = iFrameElement.contentWindow.location.href;
             * iFrameElement.contentWindow.history.replaceState( null, null, href );
             * ---> This just lead to a redirect to /cdes-ui.  Bad.
             *
             * (this.$refs.iframe as HTMLIFrameElement).contentWindow.location.reload();
             * ---> This was the original code causing the form resend error.
             *
             * let href = iFrameElement.contentWindow.location.href;
             * ---> This, used in the following code, again triggered the form resend warning.
             */

            let iFrameElement : HTMLIFrameElement = this.$refs.iframe as HTMLIFrameElement;
            let href = iFrameElement.src;
            iFrameElement.contentWindow.location.href = href;
        },

        close() {
            this.$router.go(-1);           
        }
    }
});
