/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import ReviewPositionTable from "./ReviewPositionTable.vue";
import ReviewStateXTable from "./ReviewStateXTable.vue";
import ReviewCommentTable from "./ReviewCommentTable.vue";

function isDocumentVersionInActionAndNotDeleted(status: number): boolean {
    return status === 1 /* INREVIEW */
        || status === 4 /* INVALIDATEDVERSION */
        || status === 3 /* RELEASEDNEGATIV */;
}

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewPositionTable,
        ReviewStateXTable,
        ReviewCommentTable,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        version: {
            type: [Object, Symbol] as PropType<ReviewProtocolVersionJoin | LoadingPlaceholder>,
        },
        /*showAll: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },*/
    },

    data() {
        return {
            commentsHidden: false,
            positionsHidden: false,
        };
    },

    computed: {
        nodeResults(): ReviewProtocolVersionJoin[] | LoadingPlaceholder[] {
            const { context, version } = this;
            if (context === loadingPlaceholder || version === loadingPlaceholder) {
                return [loadingPlaceholder];
            } else {
                return Array.from(context.documentVersionIdToCellResultIds.get(version.documentVersionId))
                .flatMap((cellResultId: number) => Array.from(context.cellResultIdToNodeResultIdToNodeResult.get(cellResultId).values()))
                    .sort((joinOne : ReviewProtocolVersionJoin, joinTwo : ReviewProtocolVersionJoin) => {
                        let reviewCycleNodeResultArrivalDateOne = joinOne.reviewCycleNodeResultArrivalDate;
                        let reviewCycleNodeResultArrivalDateTwo = joinTwo.reviewCycleNodeResultArrivalDate;
                        if (reviewCycleNodeResultArrivalDateOne != null && reviewCycleNodeResultArrivalDateTwo != null) {
                            return reviewCycleNodeResultArrivalDateOne - reviewCycleNodeResultArrivalDateTwo;
                        } else {
                            return 0;
                        }
                    })
                    .filter(nodeResult => {
                    const resultOption = context.idToResultOption.get(nodeResult.reviewCycleNodeResultReviewCycleResultOptionId);
                    return (resultOption != null && resultOption.value !== -8)
                        || (resultOption == null && version.reviewCycleNodeId === nodeResult.reviewCycleNodeId && isDocumentVersionInActionAndNotDeleted(version.documentVersionStatus) && context.isLastVersion(version.documentVersionId))
                        || (resultOption == null && !context.isLastVersion(version.documentVersionId))
                        || (nodeResult.reviewCycleNodeFree);
                        //|| this.showAll;
                });
            }
        },
    },

    setup() {
        return {
            loadingPlaceholder,
        };
    },
});
