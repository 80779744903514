import { BoundingBox } from "./BoundingBox";
import { LengthUnit } from "./LengthUnit";

export class LengthCalculator {

    public static getMetricFactor(u : LengthUnit) : number {
        switch (u) {
            case LengthUnit.METER:
                return 1.0;
            case LengthUnit.DECIMETER:
                return 0.1;
            case LengthUnit.CENTIMETER:
                return 0.01;
            case LengthUnit.MILLIMETER:
                return 0.001;
            case LengthUnit.INCH:
                return 0.0254;
            case LengthUnit.PT:
                return 0.0254 / 72.0;
            default:
                return 1.0;
        }
    }

    public static convertUnit(x : number, from : LengthUnit, to : LengthUnit) : number {
        return x * LengthCalculator.getMetricFactor(from) / LengthCalculator.getMetricFactor(to);
    }
    
    
    public static convertSquareUnit(x : number, from : LengthUnit, to : LengthUnit) : number {
        return x * Math.pow((LengthCalculator.getMetricFactor(from) / LengthCalculator.getMetricFactor(to)),2);
    }

    public static getUnitLabel(u : LengthUnit) : string {
        switch (u) {
            case LengthUnit.METER:
                return "m";
            case LengthUnit.DECIMETER:
                return "dm";
            case LengthUnit.CENTIMETER:
                return "cm";
            case LengthUnit.MILLIMETER:
                return "mm";
            case LengthUnit.INCH:
                return "inch";
            case LengthUnit.PT:
                return "pt";
            default:
                return "m";
        }
    }

    public static getSquareUnitLabel(u : LengthUnit) : string {
        // 00B2 SUPERSCRIPT TWO.
        return LengthCalculator.getUnitLabel(u) + "\u00B2";
    }

    public static getBBoxWidth(bbox : BoundingBox, u : LengthUnit) : number {
        if (bbox.urx == null || bbox.llx == null) {
            return null;
        }

        return LengthCalculator.convertUnit(bbox.urx - bbox.llx, LengthUnit.PT, u);
    }

    public static getBBoxHeight(bbox : BoundingBox, u : LengthUnit) : number {
        if (bbox.ury == null || bbox.lly == null) {
            return null;
        }

        return LengthCalculator.convertUnit(bbox.ury - bbox.lly, LengthUnit.PT, u);
    }

    public static getBBoxArea(bbox : BoundingBox, u : LengthUnit) : number {
        let w : number = LengthCalculator.getBBoxWidth(bbox, u);
        let h : number = LengthCalculator.getBBoxHeight(bbox, u);

        if (w == null || h == null) {
            return null;
        }

        return w * h;
    }
    
    
    public static getPaperFormatSquareSize(din : string, u : LengthUnit) {
        if (din == null) {
            return null;
        }
        
        if (din == "A0") {
            return LengthCalculator.convertSquareUnit(1.,LengthUnit.METER,u);
        } else if (din == "A1") {
            return LengthCalculator.convertSquareUnit(0.5,LengthUnit.METER,u);
        } else if (din == "A2") {
            return LengthCalculator.convertSquareUnit(0.25,LengthUnit.METER,u);
        } else if (din == "A3") {
            return LengthCalculator.convertSquareUnit(0.125,LengthUnit.METER,u);
        } else if (din == "A4") {
            return LengthCalculator.convertSquareUnit(0.0625,LengthUnit.METER,u);
        } else {
            return null;
        }
    }
    
    
    public static getPaperFormatHeigth(din : string, u : LengthUnit) : number {
        if(din == null) {
            return null;
        }
        
        if (din == "A0") {
            return LengthCalculator.convertUnit(1.189,LengthUnit.METER,u);
        } else if (din == "A1") {
            return LengthCalculator.convertUnit(0.841,LengthUnit.METER,u);
        } else if (din == "A2") {
            return LengthCalculator.convertUnit(0.594,LengthUnit.METER,u);
        } else if (din == "A3") {
            return LengthCalculator.convertUnit(0.420,LengthUnit.METER,u);
        } else if (din == "A4") {
            return LengthCalculator.convertUnit(0.297,LengthUnit.METER,u);
        } else {
            return null;
        }
    }
    
    public static getPaperFormatWidth(din : string, u : LengthUnit) : number {
        if (din == null) {
            return null;
        }
        
        if (din == "A0") {
            return LengthCalculator.convertUnit(0.841,LengthUnit.METER,u);
        } else if (din == "A1") {
            return LengthCalculator.convertUnit(0.594,LengthUnit.METER,u);
        } else if (din == "A2") {
            return LengthCalculator.convertUnit(0.420,LengthUnit.METER,u);
        } else if (din == "A3") {
            return LengthCalculator.convertUnit(0.297,LengthUnit.METER,u);
        } else if (din == "A4") {
            return LengthCalculator.convertUnit(0.210,LengthUnit.METER,u);
        } else {
            return null;
        }
    }
}
