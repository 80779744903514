import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class OrderAddress extends Persistent {

    // Properties: (placed here as documentation)
    //
    // commonName : (string | null | undefined);
    // givenName : (string | null | undefined);
    // surName : (string | null | undefined);
    // gender : (string | null | undefined);
    // title : (string | null | undefined);
    // postalAddress : string;
    // postalCode : string;
    // localityName : string;
    // countryId : number; --> Country
    // organisationName : string;
    // organisationalUnitName : (string | null | undefined);
    // clientCode : (string | null | undefined);
    // type : number;
    // networkId : (number | null | undefined); --> Network

    private _commonName : (string | null | undefined);
    get commonName() : (string | null | undefined) {
        this.__checkAttributeDefinition(15, "commonName");
        return this._commonName;
    }
    set commonName(commonName : (string | null | undefined)) {
        this._commonName = commonName;
    }

    private _givenName : (string | null | undefined);
    get givenName() : (string | null | undefined) {
        this.__checkAttributeDefinition(16, "givenName");
        return this._givenName;
    }
    set givenName(givenName : (string | null | undefined)) {
        this._givenName = givenName;
    }

    private _surName : (string | null | undefined);
    get surName() : (string | null | undefined) {
        this.__checkAttributeDefinition(17, "surName");
        return this._surName;
    }
    set surName(surName : (string | null | undefined)) {
        this._surName = surName;
    }

    private _gender : (string | null | undefined);
    get gender() : (string | null | undefined) {
        this.__checkAttributeDefinition(18, "gender");
        return this._gender;
    }
    set gender(gender : (string | null | undefined)) {
        this._gender = gender;
    }

    private _title : (string | null | undefined);
    get title() : (string | null | undefined) {
        this.__checkAttributeDefinition(19, "title");
        return this._title;
    }
    set title(title : (string | null | undefined)) {
        this._title = title;
    }

    private _postalAddress : string;
    get postalAddress() : string {
        this.__checkAttributeDefinition(20, "postalAddress");
        return this._postalAddress;
    }
    set postalAddress(postalAddress : string) {
        this._postalAddress = postalAddress;
    }

    private _postalCode : string;
    get postalCode() : string {
        this.__checkAttributeDefinition(21, "postalCode");
        return this._postalCode;
    }
    set postalCode(postalCode : string) {
        this._postalCode = postalCode;
    }

    private _localityName : string;
    get localityName() : string {
        this.__checkAttributeDefinition(22, "localityName");
        return this._localityName;
    }
    set localityName(localityName : string) {
        this._localityName = localityName;
    }

    private _countryId : number;
    get countryId() : number {
        this.__checkAttributeDefinition(23, "countryId");
        return this._countryId;
    }
    set countryId(countryId : number) {
        this._countryId = countryId;
    }

    private _organisationName : string;
    get organisationName() : string {
        this.__checkAttributeDefinition(24, "organisationName");
        return this._organisationName;
    }
    set organisationName(organisationName : string) {
        this._organisationName = organisationName;
    }

    private _organisationalUnitName : (string | null | undefined);
    get organisationalUnitName() : (string | null | undefined) {
        this.__checkAttributeDefinition(25, "organisationalUnitName");
        return this._organisationalUnitName;
    }
    set organisationalUnitName(organisationalUnitName : (string | null | undefined)) {
        this._organisationalUnitName = organisationalUnitName;
    }

    private _clientCode : (string | null | undefined);
    get clientCode() : (string | null | undefined) {
        this.__checkAttributeDefinition(26, "clientCode");
        return this._clientCode;
    }
    set clientCode(clientCode : (string | null | undefined)) {
        this._clientCode = clientCode;
    }

    private _type : number;
    get type() : number {
        this.__checkAttributeDefinition(27, "type");
        return this._type;
    }
    set type(type : number) {
        this._type = type;
    }

    private _networkId : (number | null | undefined);
    get networkId() : (number | null | undefined) {
        this.__checkAttributeDefinition(28, "networkId");
        return this._networkId;
    }
    set networkId(networkId : (number | null | undefined)) {
        this._networkId = networkId;
    }


    public static constructPartialInstance() : OrderAddress {
        let instance = new OrderAddress();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : OrderAddress {
        let instance = new OrderAddress();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : OrderAddress) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.commonName, other.commonName, "String")
            && DtoHelper.areMembersEqual(this.givenName, other.givenName, "String")
            && DtoHelper.areMembersEqual(this.surName, other.surName, "String")
            && DtoHelper.areMembersEqual(this.gender, other.gender, "String")
            && DtoHelper.areMembersEqual(this.title, other.title, "String")
            && DtoHelper.areMembersEqual(this.postalAddress, other.postalAddress, "String")
            && DtoHelper.areMembersEqual(this.postalCode, other.postalCode, "String")
            && DtoHelper.areMembersEqual(this.localityName, other.localityName, "String")
            && DtoHelper.areMembersEqual(this.countryId, other.countryId, "Long")
            && DtoHelper.areMembersEqual(this.organisationName, other.organisationName, "String")
            && DtoHelper.areMembersEqual(this.organisationalUnitName, other.organisationalUnitName, "String")
            && DtoHelper.areMembersEqual(this.clientCode, other.clientCode, "String")
            && DtoHelper.areMembersEqual(this.type, other.type, "Integer")
            && DtoHelper.areMembersEqual(this.networkId, other.networkId, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            commonName: this._commonName,
            givenName: this._givenName,
            surName: this._surName,
            gender: this._gender,
            title: this._title,
            postalAddress: this._postalAddress,
            postalCode: this._postalCode,
            localityName: this._localityName,
            countryId: this._countryId,
            organisationName: this._organisationName,
            organisationalUnitName: this._organisationalUnitName,
            clientCode: this._clientCode,
            type: this._type,
            networkId: this._networkId,
        };
    }
}
