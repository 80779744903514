import { defineComponent, PropType, reactive } from "vue";
import { makePropWithValue } from "cdes-vue/util/Prop";
import { Dropdown } from "bootstrap";
import { Comparator, collation, keyToCmp } from "cdes-vue/util/Sort";
import ValidationError from "./ValidationError";

export const selectDivider: unique symbol = Symbol("select divider");

export interface OptGroup<T> {
    label: string;
    children: (T | typeof selectDivider)[];
}

export function isOptGroup<T>(obj: unknown): obj is OptGroup<T> {
    return typeof obj === "object"
        && obj != null
        && "label" in obj
        && "children" in obj
    // @ts-ignore
        && typeof obj.label === "string"
    // @ts-ignore
        && Array.isArray(obj.children);
}

type NonGroupRenderOption<T> = ["option", T, number] | typeof selectDivider;

type RenderOption<T> = ["group", string, NonGroupRenderOption<T>[]] | NonGroupRenderOption<T>;

export type Options<T> = (T | OptGroup<T> | typeof selectDivider)[];

function parsePx(str: string): number {
    if (!str.endsWith("px")) {
        throw new Error("value is not a pixel value.");
    }
    return Number.parseInt(str.substring(0, str.length - 2));
}

export default defineComponent({
    expose: ["reset"],

    props : {
        id : {
            type : String
        },

        defaultValue: {
            type: undefined as PropType<unknown>,
            default: () => null,
        },

        modelValue : {
            type: undefined as PropType<unknown>,
            default(this: void, props: unknown): unknown {
                // @ts-ignore
                return props.defaultValue;
            },
        },

        optionLabel: {
            type: Function as PropType<(a: unknown) => string>,
            default(this: void, a: unknown) {
                if (typeof a !== "object" || !("label" in a)) {
                    throw new Error("option does not have a label property, Did you want to specify optionLabel?");
                }
                // @ts-ignore
                return a.label;
            },
        },

        optionValue: {
            type: Function as PropType<(a: unknown) => unknown>,
            default(this: void, a: unknown) {
                if (typeof a !== "object" || !("value" in a)) {
                    throw new Error("option does not have a value property, Did you want to specify optionValue?");
                }
                // @ts-ignore
                return a.value;
            },
        },

        equals: {
            type: Function as PropType<((valueA: unknown, valueB: unknown) => boolean)>,
            // writing this as an arrow function breaks type inference.
            default(this: void, valueA: unknown, valueB: unknown): boolean {
                return valueA === valueB;
            },
        },

        options: {
            type: Array as PropType<Options<unknown>>,
            default: () => [],
        },

        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        searchTimeout: {
            type: Number as PropType<number>,
            default: () => 300,
        },

        sort: {
            type: [Boolean, Function] as PropType<Comparator<unknown> | boolean | null | undefined>,
        },

        optional: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        optionalLabel: {
            type: String as PropType<string>,
            default: () => "",
        },

        required: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        wasValidated: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            expanded: false,
            dropdown: undefined as (undefined | Dropdown),
            selectDivider,
            resizeObserver: new ResizeObserver(this.resizeCallback.bind(this)),
            searchString: undefined as (string | undefined),
            searchTimeoutId: undefined as (ReturnType<typeof setTimeout> | undefined),
            form: undefined as (HTMLFormElement | undefined),
            // @ts-ignore
            formResetListener: () => this.reset(),
        };
    },

    setup(props, context) {
        return {
            value: makePropWithValue(props, context, "modelValue"),
            ValidationError,
        };
    },

    watch: {
        expanded(expanded: boolean): void {
            if (expanded) {
                this.resizeObserver.observe(this.$refs.select as HTMLElement);
                this.dropdown.show();
            } else {
                this.resizeObserver.disconnect();
                this.dropdown.hide();
            }
        },
        disabled(disabled: boolean): void {
            if (disabled) {
                this.hide();
            }
        },
        selectedIndex(selectedIndex: number): void {
            // scroll all the way to the end if the end is reached.
            if (selectedIndex === 0) {
                if (this.$refs.dropdown != null) {
                    (this.$refs.dropdown as Element).scrollTop = 0;
                }
            } else if (selectedIndex === (this.flattenedOptions.length - 1)) {
                if (this.$refs.dropdown != null) {
                    (this.$refs.dropdown as Element).scrollTop = 10e10;
                }
            } else {
                document.getElementById(this.$id("item" + this.selectedIndex))?.scrollIntoView?.({
                    block: "nearest",
                    inline: "nearest",
                });
            }
        },
        searchString(searchString: string, oldSearchString: string): void {
            if (searchString == null || searchString === "") {
                return;
            }

            // it would be nice if we could implement this using a collation
            // but the javascript collation does not have startsWith.
            const optionMatcher = option =>
                this.trueOptionLabel(option).toLowerCase().startsWith(searchString.toLowerCase());

            let foundIndex = -1;
            if (
                oldSearchString != null
                && oldSearchString !== ""
                && searchString.length > oldSearchString.length
                && searchString.startsWith(oldSearchString)
            ) {
                // if we have already found something and the user adds a character to the searchString
                // we don't want to continue to the next match.
                if (optionMatcher(this.flattenedOptions[this.selectedIndex])) {
                    foundIndex = this.selectedIndex;
                }
            }
            // we generally try to find the next match.
            if (foundIndex < 0 && this.selectedIndex != null) {
                foundIndex = this.flattenedOptions.findIndex((option, index) =>
                    index > this.selectedIndex && optionMatcher(option));
            }
            // wrap around if we have not found anything after the selectedIndex.
            if (foundIndex < 0) {
                foundIndex = this.flattenedOptions.findIndex(optionMatcher);
            }

            if (foundIndex >= 0) {
                this.selectedIndex = foundIndex;
            }
        },
        form(newForm, oldForm): void {
            if (oldForm) {
                oldForm.removeEventListener("reset", this.formResetListener);
            }
            if (newForm) {
                newForm.addEventListener("reset", this.formResetListener);
            }
        },
        validationError(validationError: ValidationError): void {
            (this.$refs.validationInput as HTMLInputElement).setCustomValidity(validationError);
        },
    },

    mounted() {
        this.dropdown = new Dropdown(this.$refs.select, {
            popperConfig: (popperConfig) => {
                popperConfig.modifiers.push({
                    name: 'flip',
                    options: {
                        // don't try to flip between start/end alignments
                        flipVariations: false,
                    },
                });
                return popperConfig;
            },
        });

        let el = this.$refs.select as (HTMLElement | undefined);
        do {
            el = el.parentElement;
        } while (el && !(el instanceof HTMLFormElement));
        this.form = el as (HTMLFormElement | undefined);
    },

    unmounted() {
        this.dropdown.dispose();
        this.dropdown = undefined;
        this.form = undefined;
    },

    deactivated() {
        this.expanded = false;
    },

    computed: {
        labelId(): string | null {
            return document.querySelector(`label[for="${CSS.escape(this.selectId)}"]`)?.id;
        },
        hasGroups(): boolean {
            return this.options.some(isOptGroup);
        },
        trueOptions(): unknown[] {
            let options = reactive(this.options.map(a => a));
            if (this.sort === true || typeof this.sort === "function") {
                if (this.hasGroups) {
                    throw new Error("cannot sort select with groups.");
                }
                options.sort(this.sort === true
                           ? keyToCmp(a => this.trueOptionLabel(a), collation(this.$i18n.locale))
                           : this.sort);
            }

            if (this.optional) {
                // insert null at the start
                options.splice(0,0,null);
            }

            return options;
        },
        flattenedOptions(): unknown[] {
            return this.trueOptions.flatMap(option => {
                if (isOptGroup(option)) {
                    return option.children;
                } else {
                    return [option];
                }
            }).filter(a => a !== selectDivider);
        },
        renderOptions(): RenderOption<unknown>[] {
            const ret: RenderOption<unknown>[] = [];
            let index = 0;

            for (const option of this.trueOptions) {
                if (isOptGroup(option)) {
                    const children: NonGroupRenderOption<unknown>[] = [];
                    for (const child of option.children) {
                        if (child === selectDivider) {
                            children.push(selectDivider);
                        } else {
                            children.push(["option", child, index++]);
                        }
                    }

                    ret.push(["group", option.label, children]);
                } else if (option === selectDivider) {
                    ret.push(selectDivider);
                } else {
                    ret.push(["option", option, index++]);
                }

            }

            return ret;
        },

        selectedIndex: {
            get(): number | undefined {
                const index = this.flattenedOptions.findIndex(a => this.equals(this.trueOptionValue(a), this.value));
                return index === -1 ? undefined : index;
            },
            set(selectedIndex: number | undefined) {
                if (selectedIndex >= 0 && selectedIndex < this.flattenedOptions.length) {
                    this.value = selectedIndex == null ? undefined : this.trueOptionValue(this.flattenedOptions[selectedIndex]);
                }
            },
        },
        selectId(): string {
            return this.id ?? this.$id("select");
        },
        validationError(): ValidationError {
            if (this.required && this.selectedIndex == null) {
                return ValidationError.MISSING;
            } else {
                return ValidationError.NONE;
            }
        },
    },

    methods: {
        toggle(): void {
            if (!this.disabled) {
                this.expanded = !this.expanded;
            }
        },
        hide(): void {
            this.expanded = false;
        },
        show(): void {
            if (!this.disabled) {
                this.expanded = true;
            }
        },
        keydown(event): void {
            if (this.disabled) {
                return;
            }
            if (event.altKey) {
                if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                    this.toggle();
                    event.preventDefault();
                }
            } else if (event.key === "ArrowDown") {
                this.moveDown();
                event.preventDefault();
            } else if (event.key === "ArrowUp") {
                this.moveUp();
                event.preventDefault();
            } else if (event.key === " ") {
                this.show();
                event.preventDefault();
            } else if (event.key === "Escape") {
                this.hide();
                event.preventDefault();
            } else if (
                //  detect text keys.
                !event.isComposing
                && !event.altKey
                && !event.ctrlKey
                && !event.metaKey
                // if the key only consists of english alphanumerics and is longer than one character
                // it is probably a special key.
                // This should handle emoji because they aren't alphanumeric.
                && (!/^[A-Za-z0-9]+$/.test(event.key) || event.key.length <= 1)
            ) {
                if (this.searchTimeoutId != null) {
                    clearTimeout(this.searchTimeoutId);
                    this.searchTimeoutId = undefined;
                }
                if (this.searchString === undefined) {
                    this.searchString = "";
                }
                this.searchString += event.key;
                this.searchTimeoutId = setTimeout(() => {
                    this.searchString = undefined;
                    this.searchTimeoutId = undefined;
                }, this.searchTimeout);
                event.preventDefault();
            }
        },
        moveUp(): void {
            const index = this.selectedIndex;
            this.selectedIndex = index == null ? this.flattenedOptions.length - 1 : index - 1;
        },
        moveDown(): void {
            const index = this.selectedIndex;
            this.selectedIndex = index == null ? 0 : index + 1;
        },
        itemClick(index: number): void {
            this.selectedIndex = index;
            this.hide();
        },
        resizeCallback(entries: ResizeObserverEntry[]): void {
            for (const entry of entries) {
                if (entry.target === this.$refs.select) {
                    (this.$refs.dropdown as HTMLElement).style.minWidth =
                        (this.$refs.select as HTMLElement).offsetWidth + "px";
                }
            }
        },
        trueOptionLabel(option: unknown): string {
            if (this.optional && option == null) {
                return this.optionalLabel;
            } else {
                return this.optionLabel(option);
            }
        },

        trueOptionValue(option: unknown): unknown {
            if (this.optional && option == null) {
                return option;
            } else {
                return this.optionValue(option);
            }
        },

        reset(): void {
            this.value = this.defaultValue;
        }
    },
});
