import { FormMode } from "cdes-vue/voc/gui/FormMode";
import { defineComponent, inject, PropType, ref } from "vue";
import { formModeKey } from "cdes-vue/util/form/FormGrid.vue";
import { FormHelper } from "./FormHelper";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import Label from "cdes-vue/util/form/Label.vue";

export default defineComponent({
    props : {
        mode : {
            type : String as PropType<FormMode>,
        },

        value : {
            type : String
        },

        selected : {
            type : String
        },

        disabled : {
            type : Boolean
        },

        id : {
            type : String
        },

        name : {
            type : String
        },

        label : {
            type : String
        },

        title : {
            type : String
        }
    },

    emits: ['update:selected'],

    components : {
        Label,
        FormGridMember
    },

    data() {
        return {
            injectedFormMode : ref(inject(formModeKey, undefined))
        }
    },

    computed : {
        effectiveMode() : FormMode {
            if (this.mode != null) {
                return this.mode;
            } else {
                return this.injectedFormMode;
            }
        },

        isFlexMode() : boolean {
            return this.effectiveMode == FormMode.FLEX;
        },

        isFormGridMode() : boolean {
            return this.effectiveMode == FormMode.FORM_GRID;
        },
    },

    methods : {
        changed(evt : Event) : void {
            // @ts-ignore
            let value = evt.target.checked;
            if (value) {
                this.$emit("update:selected", this.value);
            }
        }
    }
});
