/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { OrganisationPersonJoin } from "cdes-api/joinDto/OrganisationPersonJoin";
import { SelectOption } from "cdes-vue/util/form/Select";

export class OrganisationPersonHelper {
    public static getOrganisationPersonOptionsByJoins(organisationPersonJoins : OrganisationPersonJoin[]) {
            organisationPersonJoins = organisationPersonJoins.sort((a : OrganisationPersonJoin, b : OrganisationPersonJoin) => {
                let organisationNameOne : string = a.organisationName;
                let organisationNameTwo : string = b.organisationName;
                return organisationNameOne == null ? -1 : organisationNameOne.localeCompare(organisationNameTwo);
            });

            let organisationPersonOptions : SelectOption[] = [];
            for (let organisationPersonJoin of organisationPersonJoins) {
                organisationPersonOptions.push({
                    label : organisationPersonJoin.organisationName,
                    value : organisationPersonJoin.organisationPersonId
                });
            }
            return organisationPersonOptions;        
    }
}
