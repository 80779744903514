/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { LabelContent } from 'cdes-api/dto/LabelContent';
import CheckBox from 'cdes-vue/util/form/CheckBox.vue';
import { LabelInfo } from "cdes-api/dto/review/LabelInfo";
import { LabelContentDocTypeJoin } from "cdes-api/joinDto/LabelContentDocTypeJoin";
import { LabelContentDocTypeEntry } from "cdes-api/dto/review/LabelContentDocTypeEntry";

export default defineComponent({
    components: {
        ParempiGrid,
        CheckBox
    },

    data() {
        return {
            columns : this.constructColumns(),
        }
    },

    props: {
        docTypeEntries : {
            type: Array as PropType<LabelContentDocTypeEntry[]>,
            default: () => [],
        }
    },

    methods: {
        constructColumns() : ParempiColumn<LabelContent>[] {
            let columns : ParempiColumn<LabelContent>[] = [
                ParempiColumn.asRowSpanSlot("code", this.$t("review.labelContent.documentType.codeColumnCaption"), "code"),
                ParempiColumn.asRowSpanSlot("name", this.$t("review.labelContent.documentType.nameColumnCaption"), "name"),
                ParempiColumn.asRowSpanSlot("description", this.$t("general.description"), "description"),
                ParempiColumn.asRowSpanSlot("documentTypeLabelContent",
                                            this.$t("review.labelContent.documentType.chooseColumnCaption"),
                                            "documentTypeLabelContent")
            ];

            return columns;
        },
    }
});        
