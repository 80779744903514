export class PageCall {
    name : string;
    params : Map<string, unknown> = new Map<string, unknown>();

    constructor(name : string) {
        this.name = name;
    }

    getName() : string {
        return this.name;
    }

    setParam(name : string, value : unknown) : void {
        this.params.set(name, value);
    }

    getParam(name : string) : unknown {
        return this.params.get(name);
    }

    getNumberParam(name : string) : number {
        let value : unknown = this.params.get(name);
        if (typeof value != "number") {
            throw new Error("Fetched param [" + name + "] of page [" + this.name + "] is not of type number.");
        }
        return value as number;
    }
}
