import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { OriginalDocumentOrderInfo } from "cdes-api/dto/original/OriginalDocumentOrderInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components: {
        ParempiGrid,
    },

    emits: ["requestOriginal", "removeOrder"],

    methods: {
        requestOriginal(item : OriginalDocumentOrderInfo): void {
            this.$emit('requestOriginal', item);
        },
        askDeleteOriginal(item : OriginalDocumentOrderInfo) : void {
            if (window.confirm(this.$t("original.list.askDeleteRequest"))) {
                let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                let originalDocumentOrderId : number = item.originalDocumentOrderId;
                this.ctx.documentService.deleteOriginalOrder(organisationPersonId, originalDocumentOrderId)
                    .then(() => {
                        this.$emit("removeOrder", originalDocumentOrderId);
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        },
        download(item : OriginalDocumentOrderInfo) : void {
            let originalLink : string = this.getOriginalLink(item);
            window.open(originalLink);
        },
        getOriginalLink(item : OriginalDocumentOrderInfo) : string {
            return this.ctx.getTapestryRequestUrl("CDESMimeService/1/originalDocumentMimeSource", [
                item.originalDocumentOrderId.toString()
            ]);
            
            // http://localhost:8885/cdes/app?service=CDESMimeService/1/originalDocumentMimeSource&sp=5757008&ts=Admin
            /*
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());
            return "/cdes/svc/label?" + urlSearchParams.toString();
            */
        }
    },

    props: {
        infos: {
            type: Array as PropType<OriginalDocumentOrderInfo[]>,
            default: () => [],
        },
    },

    watch : {
        infos(newOriginalDocumentOrderInfos : OriginalDocumentOrderInfo[]) {
            window.setTimeout(() => {
                if (this.ctx.editedOriginalDocumentOrderId != null) {
                    let grid : typeof ParempiGrid = this.$refs.grid as (typeof ParempiGrid);
                    grid.scrollIntoView(this.ctx.editedOriginalDocumentOrderId);
                    this.ctx.editedOriginalDocumentOrderId = null;
                }
            }, 500);
        }
    },    

    computed: {
        columns(): ParempiColumn<OriginalDocumentOrderInfo>[] {
            return [
                ParempiColumn.asRowSpanSlot("type", this.$t("general.type"), "type"),
                ParempiColumn.asRowSpanSlot("planNumber", this.$t("general.planNumber"), "planNumber"),
                ParempiColumn.asRowSpanSlot("planner", this.$t("general.planner"), "planner"),
                ParempiColumn.asRowSpanSlot("requestedBy", this.$t("general.requestedBy"), "requestedBy"),
                ParempiColumn.asRowSpanSlot("requestedFor", this.$t("general.requestedFor"), "requestedFor"),
                ParempiColumn.asRowSpanSlot("date", this.$t("general.date"), "date"),
                ParempiColumn.asRowSpanSlot("request", this.$t("general.request"), "request"),
                ParempiColumn.asRowSpanSlot("acceptance", this.$t("general.acceptance"), "acceptance"),
                ParempiColumn.asRowSpanSlot("status", this.$t("general.status"), "status"),
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions"),
            ];
        }
    }
});
