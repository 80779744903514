import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class ObjectPlannerPositionDefinition extends Persistent {

    // Properties: (placed here as documentation)
    //
    // name : string;
    // definitionId : number; --> ObjectPlannerDefinition
    // roleTypeId : number; --> RoleType
    // statusNotification : number;

    private _name : string;
    get name() : string {
        this.__checkAttributeDefinition(5, "name");
        return this._name;
    }
    set name(name : string) {
        this._name = name;
    }

    private _definitionId : number;
    get definitionId() : number {
        this.__checkAttributeDefinition(6, "definitionId");
        return this._definitionId;
    }
    set definitionId(definitionId : number) {
        this._definitionId = definitionId;
    }

    private _roleTypeId : number;
    get roleTypeId() : number {
        this.__checkAttributeDefinition(7, "roleTypeId");
        return this._roleTypeId;
    }
    set roleTypeId(roleTypeId : number) {
        this._roleTypeId = roleTypeId;
    }

    private _statusNotification : number;
    get statusNotification() : number {
        this.__checkAttributeDefinition(8, "statusNotification");
        return this._statusNotification;
    }
    set statusNotification(statusNotification : number) {
        this._statusNotification = statusNotification;
    }


    public static constructPartialInstance() : ObjectPlannerPositionDefinition {
        let instance = new ObjectPlannerPositionDefinition();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : ObjectPlannerPositionDefinition {
        let instance = new ObjectPlannerPositionDefinition();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : ObjectPlannerPositionDefinition) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.definitionId, other.definitionId, "Long")
            && DtoHelper.areMembersEqual(this.roleTypeId, other.roleTypeId, "Long")
            && DtoHelper.areMembersEqual(this.statusNotification, other.statusNotification, "Integer")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            name: this._name,
            definitionId: this._definitionId,
            roleTypeId: this._roleTypeId,
            statusNotification: this._statusNotification,
        };
    }
}
