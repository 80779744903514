export class ErrorHelper {
    public static processErrorWithoutI18n(err : any) : void {
        window.alert("Some technical error occurred.  Please contact the support, and specify the current time.");
    }

    public static processError($t, $d, err : any, msgCode? : string) : void {
        if (msgCode != null) {
            window.alert($t(msgCode, { timestamp : $d(new Date(), "long") }));
        } else {
            window.alert($t("general.error.message", { timestamp : $d(new Date(), "long") }));
        }
    }

    public static processPasswordError($t, $d, err : any) : void {
        window.alert($t("general.error.passwordMessage", { timestamp : $d(new Date(), "long") }));
    }    
}
