/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import ProjectParticipationSearchWidgetVue from "cdes-vue/project/participation/ProjectParticipationSearchWidget.vue";
import ProjectParticipationListWidgetVue from "cdes-vue/project/participation/ProjectParticipationListWidget.vue";

import { defineComponent } from "vue";
import { ParticipationPageSearchModel } from "cdes-api/dto/project/ParticipationPageSearchModel";
import { ParticipationInfo } from "cdes-api/dto/project/ParticipationInfo";
import { ProjectParticipationJoin } from "cdes-api/joinDto/ProjectParticipationJoin";
import { OrganisationParticipationInfo } from "cdes-api/dto/project/OrganisationParticipationInfo";
import { TinyLog } from "clazzes-core/log/TinyLog";
import TextInputVue from "cdes-vue/util/form/TextInput.vue";

import { JobHelper } from "clazzes-core/util/JobHelper";
import { IJobStatusService } from "clazzes-core/svc/IJobStatusService";
import { ParticipationPageInfo } from "cdes-api/dto/project/ParticipationPageInfo";
import { ParticipationPageEditInfo } from "cdes-api/dto/project/ParticipationPageEditInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { keyToCmp } from "cdes-vue/util/Sort";
import { SubProject } from "cdes-api/dto/SubProject";
import JobStatusDTO from "cdes-api/dto/job/JobStatusDTO";
import { ParticipationSecurityInfo } from "cdes-api/dto/project/ParticipationSecurityInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

const log = new TinyLog("cdes.project.participation");

export default defineComponent({
    name: "ProjectParticipationPage",

    components : {
        ProjectParticipationSearchWidget : ProjectParticipationSearchWidgetVue,
        ProjectParticipationListWidget : ProjectParticipationListWidgetVue,
        TextInput : TextInputVue
    },

    computed : {
        participationCount() {
            let count : number = 0;
            let organisationParticipationInfos : OrganisationParticipationInfo[]
                = this.organisationParticipationInfos as OrganisationParticipationInfo[];
            if (organisationParticipationInfos != null) {
                for (let organisationParticipationInfo of organisationParticipationInfos) {
                    count = count + organisationParticipationInfo.participationInfos.length;
                }
            }
            return count;
        },
        disabled() : boolean {
            return this.querySecurityInfoRunning || this.searchRunning;
        },
        buek() : boolean {
            return this.ctx.buekMode;
//            return this.menuSection == "buek";
        },

        countLabelString() : string {
			let returnString = "";
			if (this.organisationParticipationInfos != null && !this.searchRunning){
				returnString = this.participationCount != null ? (this.participationCount + " " + this.$t("general.entries")) : "";
			} else if (this.searchRunning){
				returnString = this.$t("general.searchRuns");
			}
            return returnString;
        },

        participantMessages() : string[] {
            if (this.organisationParticipationInfos == null) {
                return [];
            }
            let messages : string[] = [];
            for (let organisationParticipationInfo of this.organisationParticipationInfos) {
                for (let participationInfo of organisationParticipationInfo.participationInfos) {
                    if (participationInfo.mainParticipantInfo == null) {
                        messages.push(this.$t("project.participation.noMainParticipantError", {
                            role : participationInfo.cdesRole.name,
                            organisation : organisationParticipationInfo.organisation.name
                        }));
                    }
                }
            }
            return messages;
        }
    },

    activated() {
        if (this.dirty && !this.ctx.participationAborted) {
            this.organisationParticipationInfos = [];
            (this.$refs.searchWidget as InstanceType<typeof ProjectParticipationSearchWidgetVue>).fetchSearchInfo();
        }
        this.ctx.participationAborted = false;
    },

    data() {
        return {
            organisationParticipationInfos : [],
            editInfo : new ParticipationPageEditInfo(),
            searchModel: undefined as (ParticipationPageSearchModel | undefined),
            quickFilter: null as (null | string),
            dirty: true,
            loadingPdf : false,
            securityInfo : new ParticipationSecurityInfo(),
            searchRunning : false,
            querySecurityInfoRunning : false,
            menuSection : this.$route.meta.section
        };
    },

    beforeRouteEnter(to) {
        if ("dirty" in to.query) {
            return {
                ...to,
                query: Object.fromEntries(Object.entries(to.query).filter(([k, v]) => k !== "dirty")),
                replace: true,
                force: true,
            };
        } else {
            return undefined;
        }
    },

    beforeRouteUpdate(to) {
        if ("dirty" in to.query) {
            this.dirty = true;
            return {
                ...to,
                query: Object.fromEntries(Object.entries(to.query).filter(([k, v]) => k !== "dirty")),
                replace: true,
                force: true,
            };
        } else {
            return undefined;
        }
    },

    watch: {
        'ctx.activeProjectId'(newProjectId : number) {
            this.dirty = true;
            this.querySecurityInfo(newProjectId);
        },
    },

    mounted() {
        let searchWidget : (typeof ProjectParticipationSearchWidgetVue)
            = this.$refs.searchWidget as (typeof ProjectParticipationSearchWidgetVue);
        searchWidget.populateFromLocalStorage();

        this.querySecurityInfo(this.ctx.activeProjectId);
    },    

    methods : {
        querySecurityInfo(newProjectId : number) : void {
            this.querySecurityInfoRunning = true;
            this.ctx.projectService.getParticipationSecurityInfo(this.ctx.activeOrganisationPersonId, newProjectId)
                .then((securityInfo : ParticipationSecurityInfo) => {
                    this.securityInfo = securityInfo;
                    this.querySecurityInfoRunning = false;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                    this.querySecurityInfoRunning = false;
                });
        },
        doSearch(searchModel : ParticipationPageSearchModel) : void {
            searchModel.projectId = this.ctx.activeProjectId;
            this.searchRunning = true;

            if (searchModel != null) {
                let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
                LocalStorageHelper.storeInLocalStorage([ opString ], "/participation/search", searchModel);            
            }

            this.ctx.projectService.getParticipationPageInfo(this.ctx.activeOrganisationPersonId, searchModel)
                .then((participationPageInfo : ParticipationPageInfo) => {
                    this.searchModel = searchModel;
                    this.organisationParticipationInfos = participationPageInfo.organisationParticipationInfos;
                    this.editInfo = participationPageInfo.editInfo;

                    for (let organisationParticipationInfo of this.organisationParticipationInfos) {
                        for (let participationInfo of organisationParticipationInfo.participationInfos) {
                            for (let deputyInfo of participationInfo.deputyInfos) {
                                deputyInfo.participationInfo = participationInfo;
                            }
                        }
                    }
                    this.searchRunning = false;
                }, (err) => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                    this.searchRunning = false;
                });
        },
        doEdit(item: OrganisationParticipationInfo): void {
            let targetName : string = (!this.buek ? "projectParticipationEdit" : "projectParticipationEditBuek");
            const searchModel = {
                ...this.searchModel,
                organisationId: item.organisation.id,
            };
            this.$router.push({
                name: targetName,
                query: {
                    ...searchModel,
                }
            });
        },

        openEmailPage() : void {
            let targetName : string = (!this.buek ? "sendEmail" : "sendEmailBuek");
            this.$router.push({name: targetName, query: {
                targetProjectId: this.ctx.activeProjectId
            }});
        },

        exportToPdf() : void {
            this.loadingPdf = true;

            let columnToWidth : Map<string, number> = new Map<string, number>();
            columnToWidth.set("organisation", 1.0);
            columnToWidth.set("roles", 1.0);
            columnToWidth.set("mainParticipant", 1.0);
            columnToWidth.set("deputies", 1.0);
            columnToWidth.set("allowedSubProjects", 1.0);
            columnToWidth.set("comment", 1.0);

            this.ctx.projectService.triggerExportParticipationPdf(this.ctx.activeOrganisationPersonId, this.searchModel,
                                                                  columnToWidth, [])
                .then((jobId : string) => {
                    JobHelper.registerJobStatusQueryForDownload({
                        jobStatusService : this.ctx.jobStatusService as unknown as IJobStatusService,
                        jobId : jobId,
                        doneCallback : (status : JobStatusDTO) => {
                            this.loadingPdf = false;
                        },
                        downloadUrlGetter : (jobId : string) => {
                            let params = new URLSearchParams({
                                jobId : jobId
                            });
                            this.loadingPdf = false;
                            return "/cdes-dojo-impl/download?" + params.toString();
                        }
                    });
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        removeParticipation(organisationId : number, participationId : number) : void {
            let organisationIdx : number = null;
            for (let n = 0; n < this.organisationParticipationInfos.length; n++) {
                if (this.organisationParticipationInfos[n].organisation.id == organisationId) {
                    organisationIdx = n;
                }
            }
            if (organisationIdx != null) {
                if (participationId == null) {
                    // Case: We delete for a whole organisation, i.e. need to remove the whole OrganisationParticipationInfo
                    this.organisationParticipationInfos.splice(organisationIdx, 1);
                } else {
                    let participationInfos : ParticipationInfo[]
                        = this.organisationParticipationInfos[organisationIdx].participationInfos;
                    let participationIdx : number = null;
                    for (let n = 0; n < participationInfos.length; n++) {
                        if (participationInfos[n].projectParticipation.id == participationId) {
                            participationIdx = n;
                        }
                    }
                    if (participationIdx != null) {
                        participationInfos.splice(participationIdx, 1);
                    }

                    if (participationInfos.length == 0) {
                        this.organisationParticipationInfos.splice(organisationIdx, 1);
                    }
                }
            }

        },

        addParticipation() : void {
            let targetName : string = (!this.buek ? "projectParticipationNew" : "projectParticipationNewBuek");
            this.$router.push({ name: targetName, query: { projectId : this.ctx.activeProjectId } });
        }
    },
});
