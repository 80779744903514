import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-contents" }
const _hoisted_2 = { class: "d-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ReviewCommentEditor = _resolveComponent("ReviewCommentEditor")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_ctx.nodes.length > 0)
    ? (_openBlock(), _createBlock(_component_Collapse, { key: 0 }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('review.review.entry.comments')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodes, ([node, results], index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: node.commentedNodeId,
              class: "reviewCommentGrid2",
              style: _normalizeStyle({ 'grid-template-rows': _ctx.gridTemplateRows })
            }, [
              _createElementVNode("section", _hoisted_1, [
                _createElementVNode("h4", {
                  class: "reviewEntryCommentHeading",
                  style: _normalizeStyle({ '--review-entry-headings-before': index + 1 })
                }, [
                  _createVNode(_component_i18n_t, { keypath: "review.review.entry.commentNodeHeading" }, {
                    node: _withCtx(() => [
                      _createVNode(_component_LoadedOrPlaceholder, { value: node }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$s(node.commentedNodeName)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    version: _withCtx(() => [
                      _createVNode(_component_LoadedOrPlaceholder, { value: node }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(node.commentedVersionVersionParta + node.commentedVersionVersionPartSeperator + node.commentedVersionVersionPartb), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 2
                  }, 1024)
                ], 4),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(results, ([result, resultIndex]) => {
                    return (_openBlock(), _createElementBlock("section", {
                      key: result.commentedResultId,
                      class: "d-contents"
                    }, [
                      _createVNode(_component_ReviewCommentEditor, {
                        disabled: _ctx.reviewData === _ctx.loadingPlaceholder,
                        node: node,
                        result: result,
                        reviewData: _ctx.reviewData,
                        documentVersionId: _ctx.documentVersionId,
                        modelValue: _ctx.value[resultIndex],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.value[resultIndex]) = $event),
                        style: _normalizeStyle({ '--review-entry-comments-before': resultIndex + 1 })
                      }, null, 8, ["disabled", "node", "result", "reviewData", "documentVersionId", "modelValue", "onUpdate:modelValue", "style"])
                    ]))
                  }), 128))
                ])
              ])
            ], 4))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}