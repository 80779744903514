/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { IReviewResultOptionJoin } from "cdes-api/joinDto/IReviewResultOptionJoin";
import { IReviewNodePositionJoin } from "cdes-api/joinDto/IReviewNodePositionJoin";
import { IReviewNodePositionAttachmentJoin } from "cdes-api/joinDto/IReviewNodePositionAttachmentJoin";
import { IReviewNodeJoin } from "cdes-api/joinDto/IReviewNodeJoin";
import { IReviewCommentNodeJoin } from "cdes-api/joinDto/IReviewCommentNodeJoin";
import { IReviewCommentResultJoin } from "cdes-api/joinDto/IReviewCommentResultJoin";
import { IReviewCommentOptionJoin } from "cdes-api/joinDto/IReviewCommentOptionJoin";
import { ExecutingParticipationJoin } from "cdes-api/joinDto/ExecutingParticipationJoin";
import { ReviewData } from "./ReviewData";
import { ContextJoin } from "cdes-api/joinDto/ContextJoin";

interface ReviewNodePositionInfo extends IReviewNodePositionJoin {
    readonly attachments: IReviewNodePositionAttachmentJoin[],
}

export class ReviewContext implements ReviewData {
    contextJoin : ContextJoin;

    reviewCycleNode: IReviewNodeJoin;
    positions: ReviewNodePositionInfo[];
    resultOptionsByPositionTypeId: Map<number, IReviewResultOptionJoin[]>;
    executingParticipationJoin: ExecutingParticipationJoin;
    commentOptionsByCommentTypeId: Map<number, IReviewCommentOptionJoin[]>;
    commentedNodes: IReviewCommentNodeJoin[];
    commentResultsByCommentedNodeResultId: Map<number, IReviewCommentResultJoin[]>;
    attachments: IReviewNodePositionAttachmentJoin[];
    attachmentsByPositionResultId: Map<number, IReviewNodePositionAttachmentJoin[]>;

    constructor(data: ReviewData) {
        Object.assign(this, data);

        this.attachments = this.attachments.map(attachment => Object.assign(new IReviewNodePositionAttachmentJoin(), attachment));

        this.attachmentsByPositionResultId = this.groupBy(this.attachments, "positionResultAttachmentMapResultId");
        this.positions = this.positions.map(position => ({
            ...position,
            attachments: this.attachmentsByPositionResultId.get(position.reviewCyclePositionResultId),
        } as ReviewNodePositionInfo));
    }

    // Copied from cdes-vue/util/Array; here for making the above constructor call run in cdes-ts-api
    private groupBy<T>(array: T[], key: string): Map<number, T[]> {
        const ret = new Map();

        for (const i of array) {
            // @ts-ignore
            const computedKey = (typeof key === "function") ? key(i) : i[key];
            if (ret.has(computedKey)) {
                ret.get(computedKey).push(i);
            } else {
                ret.set(computedKey, [i]);
            }
        }

        return ret;
    }    
}
