import { Persistent } from "cdes-api/dto/Persistent";

export function containsPersistentById(persistents : Persistent[], candidateId : number) : boolean {
    if (persistents == null) {
        return false;
    }
    for (let persistent of persistents) {
        if (persistent.id == candidateId) {
            return true;
        }
    }
    return false;
}

export function shallowCopyPersistent<T extends Persistent>(obj: T): T {
	return {...obj};
}
