import { normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.fieldset)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label", {
            id: _ctx.labelId,
            controlId: _ctx.controlId,
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}-${_ctx.labelWidth}-em`)
          }),
          _createElementVNode("div", {
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}`)
          }, [
            _renderSlot(_ctx.$slots, "control", {
              id: _ctx.controlId,
              labelId: _ctx.labelId
            })
          ], 2),
          _renderSlot(_ctx.$slots, "labelSecond", {
            id: _ctx.labelId,
            controlId: _ctx.controlId,
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}-${_ctx.labelWidth}-em`)
          }),
          _renderSlot(_ctx.$slots, "additional")
        ]))
      : _createCommentVNode("", true),
    (_ctx.fieldset)
      ? (_openBlock(), _createElementBlock("fieldset", _hoisted_2, [
          _renderSlot(_ctx.$slots, "label", {
            id: _ctx.labelId,
            controlId: _ctx.controlId,
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}-${_ctx.labelWidth}-em`)
          }),
          _createElementVNode("div", {
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}`)
          }, [
            _renderSlot(_ctx.$slots, "control", {
              id: _ctx.controlId,
              labelId: _ctx.labelId
            })
          ], 2),
          _renderSlot(_ctx.$slots, "labelSecond", {
            id: _ctx.labelId,
            controlId: _ctx.controlId,
            class: _normalizeClass(`col-${_ctx.verticalBreakpoint}-${_ctx.labelWidth}-em`)
          }),
          _renderSlot(_ctx.$slots, "additional")
        ]))
      : _createCommentVNode("", true)
  ]))
}