import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeItem = _resolveComponent("TreeItem")!

  return (_openBlock(), _createElementBlock("ul", {
    class: "cdesTreeView",
    onKeydown: [
      _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.selectFirst()), ["stop","prevent"]), ["home"])),
      _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.selectLast()), ["stop","prevent"]), ["end"]))
    ],
    onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocusOut && _ctx.onFocusOut(...args))),
    role: "tree"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_TreeItem, {
        item: item,
        key: index,
        ref: ((ref) => (ref != null && (_ctx.items[index].ref = ref)))
      }, {
        default: _withCtx((props) => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(props)))
        ]),
        _: 2
      }, 1032, ["item"]))
    }), 128))
  ], 32))
}