export class ParempiRowPos {
    private currIndices : number[];
    private maxIndices : number[];

    constructor(topLevelRowCount : number) {
        this.currIndices = [ 0 ];
        this.maxIndices = [ topLevelRowCount ];
    }

    public increment(depth : number) : void {
        this.currIndices[depth]++;
    }

    public isFinished() : boolean {
        return this.currIndices[0] >= this.maxIndices[0];
    }

    public getSubRow(depth? : number) {
        return this.currIndices[depth != null ? depth : 0];
    }
}

export type RowPosFactory<T> = (item : T) => ParempiRowPos;
export type RowPosIncrementor<T> = (item : T, pos : ParempiRowPos) => void;

