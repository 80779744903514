import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row g-3" }
const _hoisted_3 = { class: "col-sm-auto mr-auto" }
const _hoisted_4 = { class: "row g-3" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "col-sm-auto ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_Select = _resolveComponent("Select")!
  const _component_FormGridMember = _resolveComponent("FormGridMember")!
  const _component_FormGridColumn = _resolveComponent("FormGridColumn")!
  const _component_FormGrid = _resolveComponent("FormGrid")!

  return (_openBlock(), _createBlock(_component_FormGrid, {
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (this.$emit('search', _ctx.searchModel)), ["prevent"])),
    columns: 2,
    labelWidth: 8
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGridColumn, null, {
        default: _withCtx(() => [
          _createVNode(_component_FormGridMember, null, {
            label: _withCtx(({ controlId, class: class_ }) => [
              _createVNode(_component_Label, {
                class: _normalizeClass(`col-form-label text-end ${class_}`),
                for: controlId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.network")), 1)
                ]),
                _: 2
              }, 1032, ["class", "for"])
            ]),
            control: _withCtx(({ id }) => [
              _createVNode(_component_Select, {
                mode: "flex",
                id: id,
                disabled: this.disabled,
                defaultLabel: _ctx.$t('general.all'),
                selectClass: "form-select",
                options: this.networkOptions,
                modelValue: this.selectedNetworkId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.selectedNetworkId) = $event)),
                optional: "",
                sort: ""
              }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_FormGridMember, null, {
            label: _withCtx(({ controlId, class: class_ }) => [
              _createVNode(_component_Label, {
                class: _normalizeClass(`col-form-label text-end ${class_}`),
                for: controlId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.project")), 1)
                ]),
                _: 2
              }, 1032, ["class", "for"])
            ]),
            control: _withCtx(({ id }) => [
              _createVNode(_component_Select, {
                mode: "flex",
                id: id,
                disabled: this.disabled,
                defaultLabel: _ctx.$t('general.all'),
                selectClass: "form-select",
                options: this.projectOptions,
                modelValue: this.selectedProjectId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.selectedProjectId) = $event)),
                optional: "",
                sort: ""
              }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_FormGridMember, null, {
            label: _withCtx(({ controlId, class: class_ }) => [
              _createVNode(_component_Label, {
                class: _normalizeClass(`col-form-label text-end ${class_}`),
                for: controlId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.subProject")), 1)
                ]),
                _: 2
              }, 1032, ["class", "for"])
            ]),
            control: _withCtx(({ id }) => [
              _createVNode(_component_Select, {
                mode: "flex",
                id: id,
                disabled: this.disabled,
                defaultLabel: _ctx.$t('general.all'),
                selectClass: "form-select",
                options: this.subProjectOptions,
                modelValue: this.selectedSubProjectId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.selectedSubProjectId) = $event)),
                optional: "",
                sort: ""
              }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_FormGridColumn, null, {
        default: _withCtx(() => [
          _createVNode(_component_FormGridMember, null, {
            label: _withCtx(({ controlId, class: class_ }) => [
              _createVNode(_component_Label, {
                class: _normalizeClass(`col-form-label text-end ${class_}`),
                for: controlId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.projectLeader")), 1)
                ]),
                _: 2
              }, 1032, ["class", "for"])
            ]),
            control: _withCtx(({ id }) => [
              _createVNode(_component_Select, {
                mode: "flex",
                id: id,
                disabled: this.disabled,
                defaultLabel: _ctx.$t('general.all'),
                selectClass: "form-select",
                options: this.projectLeaderOptions,
                modelValue: this.selectedProjectLeaderId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.selectedProjectLeaderId) = $event)),
                optional: "",
                sort: ""
              }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_FormGridMember, null, {
            label: _withCtx(({ controlId, class: class_ }) => [
              _createVNode(_component_Label, {
                class: _normalizeClass(`col-form-label text-end ${class_}`),
                for: controlId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.attribute")), 1)
                ]),
                _: 2
              }, 1032, ["class", "for"])
            ]),
            control: _withCtx(({ id }) => [
              _createVNode(_component_Select, {
                mode: "flex",
                id: id,
                disabled: this.disabled,
                defaultLabel: _ctx.$t('general.all'),
                selectClass: "form-select",
                options: this.projectStatusOptions,
                modelValue: this.selectedProjectStatus,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.selectedProjectStatus) = $event)),
                optional: "",
                sort: ""
              }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  type: "reset",
                  class: "btn btn-secondary",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (this.clearSearchFields && this.clearSearchFields(...args)))
                }, _toDisplayString(_ctx.$t("general.button.resetSearchCaption")), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  type: "submit",
                  disabled: this.disabled,
                  class: "btn btn-primary"
                }, _toDisplayString(_ctx.$t("general.button.searchCaption")), 9, _hoisted_7)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "extraButtons")
          ])
        ])
      ])
    ]),
    _: 3
  }))
}