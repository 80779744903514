import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1 flex-min-height-0" }
const _hoisted_2 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_3 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_4 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_5 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_8 = {
  key: 1,
  class: "d-flex justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParempiGrid = _resolveComponent("ParempiGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.items && this.items.length > 0)
      ? (_openBlock(), _createBlock(_component_ParempiGrid, {
          key: 0,
          class: "flex-grow-1 flex-min-height-1",
          columns: this.columns,
          items: this.items,
          storageKey: "projectParticipantHistoryPartListWidget"
        }, {
          affectedPerson: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(item.affectedPersonSurName + " " + item.affectedPersonGivenName + " (" + item.affectedOrganisationName + ")" + (item.projectParticipantMainParticipantFlag?' '+_ctx.$t('general.mainParticipantShort'):'')), 1)
            ])
          ]),
          projectParticipantHistoryPartStartDate: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(item.projectParticipantHistoryPartStartDate ?
              this.$d(new Date(item.projectParticipantHistoryPartStartDate * 1000), "long" )
              : ""), 1)
            ])
          ]),
          projectParticipantHistoryPartEndDate: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(item.projectParticipantHistoryPartEndDate ?
              this.$d(new Date(item.projectParticipantHistoryPartEndDate * 1000), "long" )
              : ""), 1)
            ])
          ]),
          modifyingPersonGivenName: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, [
              (item.projectParticipantHistoryPartEditDate)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.modifyingPersonSurName + " " + item.modifyingPersonGivenName) + " " + _toDisplayString(" (" +  this.$d(new Date(item.projectParticipantHistoryPartEditDate * 1000), {dateStyle: "full"} ) + ")"), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          projectParticipantInactiveComment: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(item.projectParticipantInactiveComment), 1)
            ])
          ]),
          _: 1
        }, 8, ["columns", "items"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("i", null, _toDisplayString(this.$t("project.participation.list.participantHistory.noHistory")), 1)
        ]))
  ]))
}