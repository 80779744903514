import { ParticipationInfo } from "cdes-api/dto/project/ParticipationInfo";
import { ParticipationPageEditInfo } from "cdes-api/dto/project/ParticipationPageEditInfo";
import { ParticipationSecurityInfo } from "cdes-api/dto/project/ParticipationSecurityInfo";
import { defineComponent, PropType } from "vue";
import MultiSelect from "cdes-vue/util/form/MultiSelect.vue";
import { eagerComputed } from "cdes-vue/util/Prop";
import { SubProject } from "cdes-api/dto/SubProject";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components : {
        MultiSelect
    },
    props : {
        participationInfo : {
            type : Object as PropType<ParticipationInfo>
        },
        editInfo : {
            type : Object as PropType<ParticipationPageEditInfo>
        },
        securityInfo : {
            type : Object as PropType<ParticipationSecurityInfo>
        },
        disabled : {
            type : Boolean
        }
    },
    setup(props) {
        return {
            allowedSubProjectIds: eagerComputed(() => props.participationInfo?.allowedSubProjects?.map(subProject => subProject.id) ?? []),
        };
    },

    methods : {
        saveChange() : void {            
            this.ctx.projectService.saveSubProjectRestrictions(this.ctx.activeOrganisationPersonId, this.participationInfo.projectParticipation.id, this.allowedSubProjectIds);
        },
        getRemovalError(subProjectId : number) : Promise<string> {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            let participationId : number = this.participationInfo.projectParticipation.id;
            
            return this.ctx.projectService.mayAddSubProjectRestriction(organisationPersonId, participationId, subProjectId)
                .then((allowed : boolean) => {
                    if (allowed) {
                        return null;
                    } else {
                        return this.$t("project.participation.list.allowedStatuses.restrictNotAllowed");
                    }
                });
        },
        compareSubProjects(subProjectOne : SubProject, subProjectTwo : SubProject) : number {
            let labelOne : string = (subProjectOne.code ?? "") + (subProjectOne.number ?? "");
            let labelTwo : string = (subProjectTwo.code ?? "") + (subProjectTwo.number ?? "");
            return labelOne.localeCompare(labelTwo);
        }
    },
});
