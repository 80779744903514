import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex overflow-y-auto flex-grow-1 flex-min-height-0 flex-column" }
const _hoisted_2 = { class: "d-sm-flex flex-row gap-3 align-items-center mb-3 flex-wrap" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "form-check" }
const _hoisted_5 = ["disabled", "id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!
  const _component_ReviewFileWidget = _resolveComponent("ReviewFileWidget")!
  const _component_ReviewNodeResultList = _resolveComponent("ReviewNodeResultList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('review.review.data.heading')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        disabled: this.disabled,
        type: "button",
        class: "btn btn-secondary me-auto",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showNav')))
      }, _toDisplayString(_ctx.$t('review.review.data.showNav')), 9, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          disabled: this.disabled,
          id: _ctx.$id('showAll'),
          class: "form-check-input",
          type: "checkbox",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ourShowAll) = $event))
        }, null, 8, _hoisted_5), [
          [_vModelCheckbox, _ctx.ourShowAll]
        ]),
        _createElementVNode("label", {
          for: _ctx.$id('showAll'),
          class: "form-check-label"
        }, _toDisplayString(_ctx.$t('review.review.data.showAll')), 9, _hoisted_6)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedVersions, (version) => {
      return (_openBlock(), _createElementBlock("section", {
        key: version.documentVersionId,
        class: "mb-4"
      }, [
        _createElementVNode("h4", null, [
          _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('review.review.cellHeading', {
              cellName: _ctx.$s(version.reviewCycleCellName),
              versionPart: version.documentVersionVersionParta + version.documentVersionVersionPartSeperator + version.documentVersionVersionPartb,
          })), 1)
            ]),
            _: 2
          }, 1032, ["value"])
        ]),
        _createElementVNode("dl", _hoisted_7, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('general.planNumber')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(version.documentVersionName), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('general.alternativePlanNumber')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
              default: _withCtx(() => [
                _createTextVNode(" ​" + _toDisplayString(version.documentVersionAltName), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('review.review.data.uploaded')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$d(new Date(version.documentVersionUploaded * 1000), "long")), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('review.review.data.by')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(version.entryResultPersonSurName ?? version.connectionResultPersonSurName) + " " + _toDisplayString(version.entryResultPersonGivenName ?? version.connectionResultPersonGivenName) + " (" + _toDisplayString(version.entryResultOrganisationName ?? version.connectionResultOrganisationName) + ") ", 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('general.comment')), 1),
          _createElementVNode("dd", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: version }, {
              default: _withCtx(() => [
                _createTextVNode(" ​" + _toDisplayString(version.reviewCycleEntryResultComment ?? version.destVersionCellConnectionResultComment), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ])
        ]),
        _createVNode(_component_ReviewFileWidget, {
          disabled: this.disabled,
          context: _ctx.context,
          documentVersionId: version === _ctx.loadingPlaceholder ? _ctx.loadingPlaceholder : version.documentVersionId,
          class: "mb-3"
        }, null, 8, ["disabled", "context", "documentVersionId"]),
        _createVNode(_component_ReviewNodeResultList, {
          context: _ctx.context,
          version: version
        }, null, 8, ["context", "version"])
      ]))
    }), 128))
  ]))
}