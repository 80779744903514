/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, computed, watchEffect } from "vue";

import { asyncEagerComputed, asyncEagerComputedWithError, loadingPlaceholder } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import { ReviewMatrixInfo } from "cdes-api/dto/document/ReviewMatrixInfo";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";

export default defineComponent({
    components : {
        CheckBox
    },

    expose : ["getAttachedMatrix"],

    props: {
        documentId: {
            type: Number as PropType<number>,
        },
        documentVersionId: {
            type: Number as PropType<number>,
        },        
        reviewCycleCellId: {
            type: Number as PropType<number>,
        },
        disabled : {
            type : Boolean
        }
    },

    emits: ["update:hidden"],

    setup(props, { emit }) {
        const ctx = useCtx();

        const {
            result: info,
            error,
            retry,
        } = asyncEagerComputedWithError(() => ctx.reviewService.getReviewMatrixInfo(ctx.activeOrganisationPersonId,
                                                                                    props.documentId, props.documentVersionId,
                                                                                    props.reviewCycleCellId), loadingPlaceholder, null);

        const selectedMap = computed(() => {
            if (info.value === loadingPlaceholder) {
                return info.value;
            }

            const ret: Map<number, Map<number, true>> = new Map();

            for (const item of info.value.selectedItems) {
                if (!ret.has(item.realmId)) {
                    ret.set(item.realmId, new Map());
                }
                ret.get(item.realmId).set(item.projectParticipationId, true);
            }

            return ret;
        });

        watchEffect(() => {
            if (info.value === loadingPlaceholder) {
                emit("update:hidden", false);
            } else if (info.value.selectedItems.length > 0) {
                emit("update:hidden", false);
            } else {
                emit("update:hidden", true);
            }
        });

        return { info, error, retry, selectedMap, loadingPlaceholder };
    },

    methods : {
        setSelected(realmId : number, participationId : number, selected : boolean) {
            let info : ReviewMatrixInfo = this.info as ReviewMatrixInfo;
            info.realmIdToAttachedParticipationIdToSelected.get(realmId).set(participationId, selected);
        },
        getAttachedMatrix() : Map<number, Map<number, boolean>> {
            let info : ReviewMatrixInfo = this.info as ReviewMatrixInfo;
            return info.realmIdToAttachedParticipationIdToSelected;
        }
    }
});
