/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import ReviewCommentEditor from "./ReviewCommentEditor.vue";
import { ReviewData } from "cdes-api/dto/review/ReviewData";
import { IReviewCommentNodeJoin } from "cdes-api/joinDto/IReviewCommentNodeJoin";
import { IReviewCommentResultJoin } from "cdes-api/joinDto/IReviewCommentResultJoin";
import { IReviewCommentOptionJoin } from "cdes-api/joinDto/IReviewCommentOptionJoin";
import ReviewCommentSaveInfo from "cdes-api/dto/review/ReviewCommentSaveInfo";
import Collapse from "cdes-vue/util/layout/Collapse.vue";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewCommentEditor,
        Collapse,
    },

    props: {
        documentVersionId: {
            type: Number as PropType<number>,
        },
        reviewData: {
            type: [Object, Symbol] as PropType<ReviewData | LoadingPlaceholder>,
        },
        modelValue: {
            type: Object as PropType<ReviewCommentSaveInfo[]>,
        }
    },

    computed: {
        nodes(): [LoadingPlaceholder, [LoadingPlaceholder, number][]][] | [IReviewCommentNodeJoin, [IReviewCommentResultJoin, number][]][] {
            const { reviewData } = this;
            if (reviewData === loadingPlaceholder) {
                return [
                    [loadingPlaceholder, [[loadingPlaceholder, 0], [loadingPlaceholder, 1]]],
                    [loadingPlaceholder, [[loadingPlaceholder, 2], [loadingPlaceholder, 3]]],
                ];
            } else {
                let index = 0;
                return reviewData.commentedNodes.flatMap(node => {
                    const results = reviewData.commentResultsByCommentedNodeResultId.get(node.commentedNodeResultId)
                    ?.map(result => [result, index++] as [IReviewCommentResultJoin, number]);

                    if (results == null || results.length <= 0) {
                        return [];
                    } else {
                        return [[node, results]];
                    }
                });
            }
        },
        gridTemplateRows(): string {
            return this.nodes.flatMap(([node, results]) => {
                return [
                    "var(--review-entry-heading-rows)",
                    ...results.map(([result, index]) => {
                        return "var(--review-entry-comment-rows)";
                    }),
                ];
            }).join(" ");
        },
    },

    watch: {
        nodes: {
            handler(nodes: [LoadingPlaceholder, [LoadingPlaceholder, number][]][] | [IReviewCommentNodeJoin, [IReviewCommentResultJoin, number][]][]): void {
                this.value = nodes.flatMap(node => node[1].map(([result]) => ({
                    reviewCyclePositionResultId: result.commentResultId,
                    comment: result.commentResultComment,
                    reviewCycleCommentOptionId: result.commentResultReviewCycleCommentOptionId,
                })));
            },
            immediate: true,
        },
    },

    emits: ["update:modelValue"],

    setup(props, context) {
        const value = makePropWithValue(props, context, "modelValue", v => ([...v]), { deep: true });

        return {
            loadingPlaceholder,
            value,
        };
    },
});
