/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export enum PasswordValidationError {
    OK = "OK",
    TOO_SHORT = "TOO_SHORT",
    LACKING_DIVERSITY = "LACKING_DIVERSITY",
    INVALID_CHARS = "INVALID_CHARS",
    OLD_PW = "OLD_PW",
}

export default PasswordValidationError;
