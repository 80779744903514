import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParempiGrid = _resolveComponent("ParempiGrid")!

  return (_openBlock(), _createBlock(_component_ParempiGrid, {
    class: "flex-grow-1 flex-min-height-0",
    columns: this.columns,
    items: _ctx.infos,
    storageKey: "provideOriginalPageListWidget"
  }, {
    planNumber: _withCtx(({ item }) => [
      _createElementVNode("div", null, [
        _createElementVNode("b", null, _toDisplayString(item.documentVersionName), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$s(item.documentContent)), 1)
      ])
    ]),
    requestedBy: _withCtx(({ item }) => [
      _createElementVNode("b", null, _toDisplayString(item.requestPersonGivenName) + " " + _toDisplayString(item.requestPersonSurName), 1),
      _createTextVNode(" (" + _toDisplayString(item.requestOrganisationName) + ") ", 1)
    ]),
    requestedFor: _withCtx(({ item }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (orderItem) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: orderItem.itemId
        }, [
          (orderItem.receiverPersonId != null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("b", null, _toDisplayString(orderItem.receiverPersonGivenName) + " " + _toDisplayString(orderItem.receiverPersonSurName), 1),
                _createTextVNode(" (" + _toDisplayString(orderItem.receiverOrganisationName) + ") ", 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(orderItem.receiverOrganisationName), 1)
              ], 64))
        ], 64))
      }), 128))
    ]),
    date: _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.requestFinished != null ?  _ctx.$d(new Date(item.requestFinished * 1000), "long") : ""), 1)
    ]),
    request: _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.requestComment ?? ""), 1)
    ]),
    _: 1
  }, 8, ["columns", "items"]))
}