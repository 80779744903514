import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class LabelContent extends Persistent {

    // Properties: (placed here as documentation)
    //
    // labelId : number; --> Label
    // digestCalculatorId : number; --> DigestCalculator
    // name : (string | null | undefined);
    // filename : (string | null | undefined);
    // filetype : (string | null | undefined);
    // filedigest : (string | null | undefined);
    // origFilename : (string | null | undefined);
    // origFiletype : (string | null | undefined);
    // origFiledigest : (string | null | undefined);
    // comment : (string | null | undefined);
    // rotationAngle : number;
    // isDefault : boolean;
    // isAsBuiltUnchangedDestination : boolean;
    // isRenderableOutside : boolean;

    private _labelId : number;
    get labelId() : number {
        this.__checkAttributeDefinition(15, "labelId");
        return this._labelId;
    }
    set labelId(labelId : number) {
        this._labelId = labelId;
    }

    private _digestCalculatorId : number;
    get digestCalculatorId() : number {
        this.__checkAttributeDefinition(16, "digestCalculatorId");
        return this._digestCalculatorId;
    }
    set digestCalculatorId(digestCalculatorId : number) {
        this._digestCalculatorId = digestCalculatorId;
    }

    private _name : (string | null | undefined);
    get name() : (string | null | undefined) {
        this.__checkAttributeDefinition(17, "name");
        return this._name;
    }
    set name(name : (string | null | undefined)) {
        this._name = name;
    }

    private _filename : (string | null | undefined);
    get filename() : (string | null | undefined) {
        this.__checkAttributeDefinition(18, "filename");
        return this._filename;
    }
    set filename(filename : (string | null | undefined)) {
        this._filename = filename;
    }

    private _filetype : (string | null | undefined);
    get filetype() : (string | null | undefined) {
        this.__checkAttributeDefinition(19, "filetype");
        return this._filetype;
    }
    set filetype(filetype : (string | null | undefined)) {
        this._filetype = filetype;
    }

    private _filedigest : (string | null | undefined);
    get filedigest() : (string | null | undefined) {
        this.__checkAttributeDefinition(20, "filedigest");
        return this._filedigest;
    }
    set filedigest(filedigest : (string | null | undefined)) {
        this._filedigest = filedigest;
    }

    private _origFilename : (string | null | undefined);
    get origFilename() : (string | null | undefined) {
        this.__checkAttributeDefinition(21, "origFilename");
        return this._origFilename;
    }
    set origFilename(origFilename : (string | null | undefined)) {
        this._origFilename = origFilename;
    }

    private _origFiletype : (string | null | undefined);
    get origFiletype() : (string | null | undefined) {
        this.__checkAttributeDefinition(22, "origFiletype");
        return this._origFiletype;
    }
    set origFiletype(origFiletype : (string | null | undefined)) {
        this._origFiletype = origFiletype;
    }

    private _origFiledigest : (string | null | undefined);
    get origFiledigest() : (string | null | undefined) {
        this.__checkAttributeDefinition(23, "origFiledigest");
        return this._origFiledigest;
    }
    set origFiledigest(origFiledigest : (string | null | undefined)) {
        this._origFiledigest = origFiledigest;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(24, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _rotationAngle : number;
    get rotationAngle() : number {
        this.__checkAttributeDefinition(25, "rotationAngle");
        return this._rotationAngle;
    }
    set rotationAngle(rotationAngle : number) {
        this._rotationAngle = rotationAngle;
    }

    private _isDefault : boolean;
    get isDefault() : boolean {
        this.__checkAttributeDefinition(26, "isDefault");
        return this._isDefault;
    }
    set isDefault(isDefault : boolean) {
        this._isDefault = isDefault;
    }

    private _isAsBuiltUnchangedDestination : boolean;
    get isAsBuiltUnchangedDestination() : boolean {
        this.__checkAttributeDefinition(27, "isAsBuiltUnchangedDestination");
        return this._isAsBuiltUnchangedDestination;
    }
    set isAsBuiltUnchangedDestination(isAsBuiltUnchangedDestination : boolean) {
        this._isAsBuiltUnchangedDestination = isAsBuiltUnchangedDestination;
    }

    private _isRenderableOutside : boolean;
    get isRenderableOutside() : boolean {
        this.__checkAttributeDefinition(28, "isRenderableOutside");
        return this._isRenderableOutside;
    }
    set isRenderableOutside(isRenderableOutside : boolean) {
        this._isRenderableOutside = isRenderableOutside;
    }


    public static constructPartialInstance() : LabelContent {
        let instance = new LabelContent();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : LabelContent {
        let instance = new LabelContent();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : LabelContent) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.labelId, other.labelId, "Long")
            && DtoHelper.areMembersEqual(this.digestCalculatorId, other.digestCalculatorId, "Long")
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.filename, other.filename, "String")
            && DtoHelper.areMembersEqual(this.filetype, other.filetype, "String")
            && DtoHelper.areMembersEqual(this.filedigest, other.filedigest, "String")
            && DtoHelper.areMembersEqual(this.origFilename, other.origFilename, "String")
            && DtoHelper.areMembersEqual(this.origFiletype, other.origFiletype, "String")
            && DtoHelper.areMembersEqual(this.origFiledigest, other.origFiledigest, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.rotationAngle, other.rotationAngle, "Integer")
            && DtoHelper.areMembersEqual(this.isDefault, other.isDefault, "Boolean")
            && DtoHelper.areMembersEqual(this.isAsBuiltUnchangedDestination, other.isAsBuiltUnchangedDestination, "Boolean")
            && DtoHelper.areMembersEqual(this.isRenderableOutside, other.isRenderableOutside, "Boolean")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            labelId: this._labelId,
            digestCalculatorId: this._digestCalculatorId,
            name: this._name,
            filename: this._filename,
            filetype: this._filetype,
            filedigest: this._filedigest,
            origFilename: this._origFilename,
            origFiletype: this._origFiletype,
            origFiledigest: this._origFiledigest,
            comment: this._comment,
            rotationAngle: this._rotationAngle,
            isDefault: this._isDefault,
            isAsBuiltUnchangedDestination: this._isAsBuiltUnchangedDestination,
            isRenderableOutside: this._isRenderableOutside,
        };
    }
}
