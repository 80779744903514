import { FormMode } from "cdes-vue/voc/gui/FormMode";
import { defineComponent, inject, PropType, ref } from "vue";
import { formModeKey } from "cdes-vue/util/form/FormGrid.vue";
import { FormHelper } from "./FormHelper";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import Label from "cdes-vue/util/form/Label.vue";

export enum TextInputTag {
    INPUT = "input",
    TEXTAREA = "textarea"
}

export default defineComponent({
    props : {
        required : {
            type : Boolean
        },

        mode : {
            type : String as PropType<FormMode>,
        },

        tag : {
            type : String as PropType<TextInputTag>,
            default : TextInputTag.INPUT
        },

        rows : {
            type : Number,
            default : 3
        },

        id : {
            type : String
        },

        wasValidated : {
            type : Boolean
        },

        inputClass : {
            type : String
        },

        label : {
            type : String
        },

        labelClass : {
            type : String
        },

        type : {
            type : String
        },

        title : {
            type : String
        },

        modelValue: {
            type: String,
        },

        disabled: {
            type: Boolean,
        },
        maxlength : {
            type : Number
        }
    },

    emits: ["update:modelValue", "textInputBlur"],

    expose : ["focus", "isInputNode", "getValue"],

    components : {
        Label,
        FormGridMember
    },

    data() {
        return {
            injectedFormMode : ref(inject(formModeKey, undefined)),
            stringValue : ""
        }
    },

    computed : {
        effectiveMode() : FormMode {
            if (this.mode != null) {
                return this.mode;
            } else {
                return this.injectedFormMode;
            }
        },

        isFlexMode() : boolean {
            return this.effectiveMode == FormMode.FLEX;
        },

        isFormGridMode() : boolean {
            return this.effectiveMode == FormMode.FORM_GRID;
        },

        isInput() : boolean {
            return this.tag == TextInputTag.INPUT;
        },

        isTextArea() : boolean {
            return this.tag == TextInputTag.TEXTAREA;
        },

        computedLabelCss() : string {
            let cssClasses = "text-end textInputLabel ";
            if (this.labelClass != null) {
                cssClasses += this.labelClass;
            }
            return cssClasses;
        },

        computedInputCss() : string {
            let cssClasses = "form-control ";
            if (this.inputClass != null) {
                cssClasses += this.inputClass;
            }
            return cssClasses;
        },

        computedTextAreaCss() : string {
            let cssClasses = "form-control ";
            return cssClasses;
        }
    },

    methods : {
        focus() : void {
            (this.$refs.flexInput as HTMLInputElement).focus();
        },
        isInputNode(evt : MouseEvent) : boolean {
            return evt.target === this.$refs.flexInput || evt.relatedTarget === this.$refs.flexInput;
        },
        textBoxBlur1() : void {
            this.$emit("textInputBlur");
        },
        setValue(s : string) : void {
            this.stringValue = s;
        },
        getValue() : string {
            return this.stringValue;
        }
    }
});
