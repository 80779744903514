import { defineComponent, InjectionKey, PropType, inject, Ref, ref } from "vue";
import { FancyDate } from "clazzes-core/dateTime/FancyDate";
import { DateHelper } from "clazzes-core/dateTime/DateHelper";

export const timeZoneInjectionKey: InjectionKey<string | Ref<string> | undefined> = Symbol("timeZone");

export default defineComponent({
    props : {
        id : {
            type : String
        },

        inputClass : {
            type : String
        },

        label : {
            type : String
        },

        labelClass : {
            type : String
        },

        title : {
            type : String
        },

        timeZone: {
            type: String as PropType<string | undefined>,
        },

        date: {
            type: Date as PropType<Date | undefined>,
        },
        utcMillis: {
            type: Number as PropType<number | undefined>,
        },
        utcSeconds: {
            type: Number as PropType<number | undefined>,
        },
        fancyDate: {
            type: FancyDate as PropType<FancyDate | undefined>,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["update:date", "update:utcMillis", "update:utcSeconds", "update:fancyDate"],

    components : {
    },

    data() {
        return {
            dateString: "",
        };
    },

    created() {
        if (this.date != null) {
            this.internalDate = DateHelper.fromJsDate(this.date, this.trueTimeZone);
        } else if (this.fancyDate != null) {
            this.internalDate = this.fancyDate;
        } else if (this.utcMillis != null) {
            this.internalDate = DateHelper.fromUtcMillis(this.utcMillis, this.trueTimeZone);
        } else if (this.utcSeconds != null) {
            this.internalDate = DateHelper.fromUtcSeconds(this.utcSeconds, this.trueTimeZone);
        }
    },

    setup(_, { expose }) {
        const input = ref<HTMLInputElement>(null);

        expose({
            input,
        });

        return {
            injectedTimeZone: ref(inject(timeZoneInjectionKey, undefined)),
            input,
        };
    },

    watch: {
        date(date: Date) {
            this.internalDate = DateHelper.fromJsDate(date, this.trueTimeZone);
        },
        utcMillis(millis: number) {
            this.internalDate = millis != null ? DateHelper.fromUtcMillis(millis, this.trueTimeZone) : undefined;
        },
        utcSeconds(seconds: number) {
            this.internalDate = seconds != null ? DateHelper.fromUtcSeconds(seconds, this.trueTimeZone) : undefined;
        },
        fancyDate(fancyDate: FancyDate | undefined) {
            this.internalDate = fancyDate;
        },
        dateString(str) {
            this.$emit("update:date", this.internalDate != null ? new Date(this.internalDate.getUtcMillis()) : undefined);
            this.$emit("update:utcMillis", this.internalDate?.getUtcMillis?.());
            this.$emit("update:utcSeconds", this.internalDate?.getUtcSeconds?.());
            this.$emit("update:fancyDate", this.internalDate);
        },
    },

    computed : {
        computedLabelCss() : string {
            let cssClasses = "text-end dateInputLabel ";
            if (this.labelClass != null) {
                cssClasses += this.labelClass;
            }
            return cssClasses;
        },

        computedInputCss() : string {
            let cssClasses = "form-control ";
            if (this.inputClass != null) {
                cssClasses += this.inputClass;
            }
            return cssClasses;
        },

        trueTimeZone(): string {
            return this.timeZone ?? this.injectedTimeZone ?? DateHelper.getTimeZone();
        },

        internalDate: {
            get(): FancyDate {
                return this.dateString != "" ? this.parse(this.dateString) : undefined;
            },
            set(date: FancyDate) {
                this.dateString = date != null ? `${date.getYear().toString().padStart(4, '0')}-${(date.getMonth()).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : "";
            },
        },
    },

    methods : {
        parse(str: string): FancyDate {
            const parts = str.split("-").map(a => Number.parseInt(a));
            if (parts.length != 3) {
                throw new Error("");
            }
            return new FancyDate({
                year: parts[0],
                month: parts[1],
                day: parts[2],
                timeZone: this.trueTimeZone,
            });
        }
    }
});
