import { PlotTaskInfo } from "cdes-api/dto/plot/PlotTaskInfo";
import { PlotTaskItemInfo } from "cdes-api/dto/plot/PlotTaskItemInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { PlotColor } from "cdes-vue/voc/plot/PlotColor";
import { PlotKindOfDelivery } from "cdes-vue/voc/plot/PlotKindOfDelivery";
import { PlotMargin } from "cdes-vue/voc/plot/PlotMargin";
import { PlotPaperQuality } from "cdes-vue/voc/plot/PlotPaperQuality";
import { PlotScale } from "cdes-vue/voc/plot/PlotScale";
import { PlotTemplateResolver } from "cdes-vue/voc/plot/PlotTemplateResolver";
import { PlottProperties } from "cdes-api/dto/PlottProperties";
import { LengthCalculator } from "./LengthCalculator";
import { LengthUnit } from "./LengthUnit";

export class PlotHelper {
    public static getKindOfDeliveryOptions($t) : SelectOption[] {
        return [
            new SelectOption(PlotKindOfDelivery.DELIVERY_SERVICE_CONTRACTOR, $t("plot.template.kindOfDelivery.deliveryServiceContractor")),
            new SelectOption(PlotKindOfDelivery.POST, $t("plot.template.kindOfDelivery.post")),
            new SelectOption(PlotKindOfDelivery.UPS_PARCEL_SERVICE, $t("plot.template.kindOfDelivery.upsParcelService")),
            new SelectOption(PlotKindOfDelivery.VELO_EXPRESS, $t("plot.template.kindOfDelivery.veloExpress")),
            new SelectOption(PlotKindOfDelivery.BY_CUSTOMER, $t("plot.template.kindOfDelivery.byCustomer"))
        ];
    }

    public static getTemplateResolverOptions($t) : SelectOption[] {
        return [
            new SelectOption(PlotTemplateResolver.PROJECT_PARTICIPATION, $t("plot.template.resolver.projectParticipation")),
            new SelectOption(PlotTemplateResolver.REVIEW_CYCLE, $t("plot.template.resolver.reviewCycle")),
            new SelectOption(PlotTemplateResolver.MANUAL, $t("plot.template.resolver.manual"))
        ];
    }

    public static getTemplateResolverLabel(templateResolver : string, $t) : string {
        if (templateResolver == "ReviewCyclePlotProjectTemplate") {
            return $t("plot.template.resolver.reviewCycle");
        } else if (templateResolver == "ProjectParticipationPlotTemplate") {
            return $t("plot.template.resolver.projectParticipation");
        } else if (templateResolver == "ManualPlotTemplate") {
            return $t("plot.template.resolver.manual");
        } else {
            throw new Error("Unsupported templateResolver [" + templateResolver + "]");
        }
    }

    public static getPaperQualitySelectOptions($t) : SelectOption[] {
        return [
            new SelectOption('LASER_80', $t("plot.template.paperQuality.laser80")),
            new SelectOption('LASER_90', $t("plot.template.paperQuality.laser90")),
            new SelectOption('INKJET_80', $t("plot.template.paperQuality.inkjet80")),
            new SelectOption('INKJET_90', $t("plot.template.paperQuality.inkjet90"))
        ];
    }

    public static getColorSelectOptions($t) : SelectOption[] {
        return [
            new SelectOption(PlotColor.MONOCHROME, $t("plot.template.color.monochrome")),
            new SelectOption(PlotColor.IN_COLOR, $t("plot.template.color.color")),
            new SelectOption(PlotColor.LIKE_TEMPLATE, $t("plot.template.color.likeTemplate")),
        ];
    }

    public static getPlotColorLabel(colorAsNumber, $t) : string {
        if (colorAsNumber == 0) {
            return $t("plot.template.color.monochrome");
        } else if (colorAsNumber == 1) {
            return $t("plot.template.color.color");
        } else if (colorAsNumber == 2) {
            return $t("plot.template.color.likeTemplate");
        } else {
            throw new Error("Illegal plotColor [" + colorAsNumber + "]");
        }            
    }

    public static getMarginSelectOptions($t) : SelectOption[] {
        return [
            new SelectOption('NO_FOLD', $t("plot.template.margin.noFold")),
            new SelectOption('FOLD_20MM_EDGE', $t("plot.template.margin.fold20mmEdge")),
            new SelectOption('FOLD_WITHOUT_EDGE', $t("plot.template.margin.foldWithoutEdge")),
        ];
    }

    public static getScaleSelectOptions($t) : SelectOption[] {
        return [
            new SelectOption(PlotScale.NONE, $t("plot.template.scale.none")),
            new SelectOption(PlotScale.A0, $t("plot.template.scale.a0")),
            new SelectOption(PlotScale.A1, $t("plot.template.scale.a1")),
            new SelectOption(PlotScale.A2, $t("plot.template.scale.a2")),
            new SelectOption(PlotScale.A3, $t("plot.template.scale.a3")),
            new SelectOption(PlotScale.A4, $t("plot.template.scale.a4")),
        ];
    }

    public static getPlotMirrorLabel(mirror, $t) : string {
        if (mirror == 0) {
            return $t("general.no");
        } else {
            return $t("general.yes");
        }
    }

    public static getPlotPropertiesArea(plotProperties) : number {
        let area : number = LengthCalculator.getBBoxArea(plotProperties, LengthUnit.METER);
        if (area != null) {
            let paperFormatSquareSize = LengthCalculator.getPaperFormatSquareSize(plotProperties.scale, LengthUnit.METER);
            if (paperFormatSquareSize != null) {
                area = paperFormatSquareSize;
            }
            let count : number = plotProperties.count;
            if (count != null) {
                area *= count;
            }
            return area;
        } else {
            return null;
        }
    }

    private static isInvalidated(itemInfo : PlotTaskItemInfo) : boolean {
        let status : number = itemInfo.documentVersion.status;
        // INVALIDATED || INVALIDATED_ALL || DELETED          
        return status == 4 || status == 5 || status == 7;
    }

    public static getPlotAreaString(area : number) : string {
        if (area != null) {
            return area.toFixed(2) + " " + LengthCalculator.getSquareUnitLabel(LengthUnit.METER);
        } else {
            return "";
        }        
    }

    public static getSumForColorValue(taskInfo : PlotTaskInfo, isInColorValue : number) : number {
        let sum = 0;
        if (taskInfo != null) {
            for (let itemInfo of taskInfo.itemInfos) {
                let plotProperties : PlottProperties = itemInfo.plotProperties;
                if (!PlotHelper.isInvalidated(itemInfo) && plotProperties.isInColor == isInColorValue) {
                    let area = PlotHelper.getPlotPropertiesArea(plotProperties);
                    if (area != null) {
                        sum += area;
                    }
                }
            }
        }

        return sum;
    }    

    public static getSumForPaperQuality(taskInfo : PlotTaskInfo) : number[] {
        let paperQualitySums: number[] = new Array(Object.keys(PlotPaperQuality).length) ;
        if (taskInfo != null) {
            for (let itemInfo of taskInfo.itemInfos) {
                let plotProperties : PlottProperties = itemInfo.plotProperties;
                if (!PlotHelper.isInvalidated(itemInfo) && plotProperties.paperQuality != null) {
                    Object.keys(PlotPaperQuality).forEach((val, i) =>
                    {
                        if (PlotPaperQuality[val].match(plotProperties.paperQuality)) {
                            let area = this.getPlotPropertiesArea(plotProperties);
                            if (area != null) 
                                paperQualitySums[i] = (paperQualitySums[i]?paperQualitySums[i]:0) + area;
                        }
                    });
                }
            }
        }
        return paperQualitySums;
    }    

    public static getSumForPaperQualityString(taskInfo : PlotTaskInfo) : string {
	
		let paperQualitySum = this.getSumForPaperQuality(taskInfo);
		let paperQualityString : string = '';
		Object.keys(PlotPaperQuality).forEach((val, i) =>
        {
            if (paperQualitySum[i])	
                paperQualityString += PlotPaperQuality[val] + " " + this.getPlotAreaString(paperQualitySum[i]) + " ";
        });
        return paperQualityString;
    }    

    public static getSumForPlotMargin(taskInfo : PlotTaskInfo) : number[] {
        let plotMarginSums: number[] = new Array(Object.keys(PlotMargin).length) ;
        if (taskInfo != null) {
            for (let itemInfo of taskInfo.itemInfos) {
                let plotProperties : PlottProperties = itemInfo.plotProperties;
                if (!PlotHelper.isInvalidated(itemInfo) && plotProperties.margin != null) {
                    Object.keys(PlotMargin).forEach((val, i) =>
                    {
                        if (PlotMargin[val].match(plotProperties.margin)) {
                            let area = this.getPlotPropertiesArea(plotProperties);
                            if (area != null)
                                //plotMarginSums[i] += area;
                                plotMarginSums[i] = (plotMarginSums[i]?plotMarginSums[i]:0) + area;
                        }
                    });
                }
            }
        }
        return plotMarginSums;
    }    

    public static getSumForPlotMarginString(taskInfo : PlotTaskInfo) : string {
	
		let plotMarginSum = this.getSumForPlotMargin(taskInfo);
		let plotMarginString : string = '';
		Object.keys(PlotMargin).forEach((val, i) =>
        {
            if (plotMarginSum[i])	
                plotMarginString += PlotMargin[val] + " " + this.getPlotAreaString(plotMarginSum[i]) + " ";
        });
        return plotMarginString;
    }    
}
