/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, watch, onUnmounted, SetupContext, computed } from "vue";
import { Attachment } from "cdes-api/dto/Attachment";
import { IAttachmentJoin } from "cdes-api/joinDto/IAttachmentJoin";
import TemporaryAttachment from "cdes-api/dto/document/TemporaryAttachment";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder, eagerComputed } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export enum AttachmentState {
    TO_UPLOAD,
    UPLOADING,
    TEMPORARY,
    PERSISTENT,
}

export type UploadedAttachment = {
    state: AttachmentState.TO_UPLOAD,
    id: string,
    file: File,
} | {
    state: AttachmentState.UPLOADING,
    id: string,
    file: File,
} | ({
    state: AttachmentState.TEMPORARY,
    id: string,
    file: File,
    filename: string,
}) | ({
    state: AttachmentState.PERSISTENT,
} & IAttachmentJoin);



export default defineComponent({
    props: {
        modelValue: {
            type: Object as PropType<UploadedAttachment>,
            required: true,
        },
        disabled : {
            type : Boolean
        }
    },

    emits: ["update:modelValue", "delete"],

    setup(props, context) {
        const attachment = makePropWithValue(props, context as SetupContext<"update:modelValue"[]>, "modelValue", obj => (obj instanceof IAttachmentJoin ? Object.assign(new IAttachmentJoin(), obj) : {...obj}), { deep: true });
        const ctx = useCtx();

        onUnmounted(() => {
            if (attachment.value.state === AttachmentState.TEMPORARY) {
                ctx.documentService.deleteTemporaryAttachment(attachment.value.id);
            }
        });

        watch(
            attachment,
            (attachmentValue, prevAttachment) => {
                if (prevAttachment?.state === AttachmentState.TEMPORARY) {
                    ctx.documentService.deleteTemporaryAttachment(prevAttachment.id);
                }
                if (attachmentValue.state === AttachmentState.TO_UPLOAD) {
                    attachment.value = {
                        ...attachmentValue,
                        state: AttachmentState.UPLOADING,
                    };
                    attachmentValue.file.arrayBuffer()
                    .then(buffer =>
                        ctx.documentService.uploadTemporaryAttachment(attachmentValue.id, attachmentValue.file.name, new Uint8Array(buffer)))
                    .then((filename) => attachment.value = {
                        ...attachmentValue,
                        state: AttachmentState.TEMPORARY,
                        filename,
                    }, err => {
                        ErrorHelper.processErrorWithoutI18n(err);
                    });
                }
            },
            {
                immediate: true,
            },
        );

        const fileName = computed(() => {
            if (attachment.value.state === AttachmentState.PERSISTENT) {
                return attachment.value.attachmentOriginalName;
            } else {
                return attachment.value.file.name;
            }
        });

        const persistent = computed(() => {
            return attachment.value.state == AttachmentState.PERSISTENT;
        });

        const link = eagerComputed((onCleanup) => {
            if (attachment.value.state === AttachmentState.TO_UPLOAD
            || attachment.value.state === AttachmentState.UPLOADING) {
                const url = URL.createObjectURL(attachment.value.file);
                onCleanup(() => URL.revokeObjectURL(url));
                return url;
            } else if (attachment.value.state === AttachmentState.TEMPORARY) {
                return `/cdes-dojo-impl/repositoryDownload/temp/${ctx.activeOrganisationPersonId}/${attachment.value.filename}?` + new URLSearchParams({
                    fileName: fileName.value,
                });
            } else {
                return `/cdes-dojo-impl/repositoryDownload/attachments/${ctx.activeOrganisationPersonId}/${attachment.value.attachmentId}?` + new URLSearchParams({
                    fileName: fileName.value,
                });
            }
        });

        return {
            attachment,
            link,
            fileName,
            persistent,
        };
    },
});
