import { Address } from "cdes-api/dto/util/Address";
import { Country } from "cdes-api/dto/Country";
import { OrderAddress } from "cdes-api/dto/OrderAddress";
import { Organisation } from "cdes-api/dto/Organisation";

export class AddressHelper {
    public static getAddressFromOrderAddress(orderAddress : OrderAddress, country : Country,
                                             outputPerson : boolean, outputOrganisation : boolean) : Address {
        let address : Address = new Address();
        if (outputPerson) {
            address.personGivenName = orderAddress.givenName;
            address.personSurName = orderAddress.surName;
        }
        if (outputOrganisation) {
            address.organisationName = orderAddress.organisationName;
        }
        address.postalAddress = orderAddress.postalAddress;
        address.postalCode = orderAddress.postalCode;
        address.localityName = orderAddress.localityName;
        address.country = country.descDe;
        return address;
    }

    public static getAddressFromOrganisation(organisation : Organisation, country : Country) : Address {
        let address : Address = new Address();
        address.organisationName = organisation.name;
        address.postalAddress = organisation.postalAddress;
        address.postalCode = organisation.postalCode;
        address.localityName = organisation.localityName;
        address.country = country.descDe;
        return address;
    }
}
