/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { createI18n, I18n, LocaleMessages, LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import { TinyLog } from "clazzes-core/log/TinyLog";

const log = new TinyLog("cdes.i18n.I18nHelper");

export class I18nHelper {

/*
vue-i18n.d.ts:
export declare function createI18n<Options extends I18nOptions = {}, Messages extends Record<keyof Options['messages'], LocaleMessageDictionary<VueMessageType>> = Record<keyof Options['messages'], LocaleMessageDictionary<VueMessageType>>, DateTimeFormats extends Record<keyof Options['datetimeFormats'], IntlDateTimeFormat> = Record<keyof Options['datetimeFormats'], IntlDateTimeFormat>, NumberFormats extends Record<keyof Options['numberFormats'], IntlNumberFormat> = Record<keyof Options['numberFormats'], IntlNumberFormat>>(options?: Options): I18n<Options['messages'], Options['datetimeFormats'], Options['numberFormats'], Options['legacy'] extends boolean ? Options['legacy'] : true>;

https://vue-i18n-next.intlify.dev/guide/advanced/typescript.html#type-safe-resources-in-createi18n

createI18n<Options extends I18nOptions = {},
           Messages extends Record<keyof Options['messages'], LocaleMessageDictionary<VueMessageType>>
               = Record<keyof Options['messages'], LocaleMessageDictionary<VueMessageType>>,
           DateTimeFormats extends Record<keyof Options['datetimeFormats'], DateTimeFormat>
               = Record<keyof Options['datetimeFormats'], DateTimeFormat>,
           NumberFormats extends Record<keyof Options['numberFormats'], NumberFormat>
               = Record<keyof Options['numberFormats'], NumberFormat>>
  (options?: Options)
: I18n<Options['messages'], Options['datetimeFormats'], Options['numberFormats'],
       Options['legacy'] extends boolean ? Options['legacy'] : true>;
*/


    public static setupI18n(defaultLocale : string, mainMessages : LocaleMessageDictionary<VueMessageType>,
                            messages : LocaleMessages<VueMessageType>) : I18n {
        const locale = I18nHelper.getLocale(null, defaultLocale, messages);

        log.info("Initializing with locale [", locale, "].");

        type MessageSchema = typeof mainMessages;

        // TODO: The following code follows the example at
        // https://github.com/intlify/vue-i18n-next/blob/master/examples/type-safe/type-annotation/src/main.ts
        // and should, in theory, allow for type safe i18n resources.  I.e. hopefully enforce strings being
        // present in all languages, and such things.
        // We currently have vue-i18n 9.1.7 in package.json, and it gives a type error.
        // At the time of writing this, vue-i18n has version 9.2.0-beta.3, so hopefully we can use the
        // following code once we are at vue-i18n 9.2.0.
        // See also https://vue-i18n-next.intlify.dev/guide/advanced/typescript.html#type-safe-resources-in-usei18n
        //
        // Create VueI18n instance with options
        // Record<string, unknown> instead of {} to make the linter happy.
        /*
        const i18n = createI18n< { message : MessageSchema }, "de", false>({
            locale : "de",
            legacy : false,
            // https://vue-i18n-next.intlify.dev/guide/advanced/composition.html#global-scope
            // https://stackoverflow.com/questions/66732739/internationalization-for-vue-3-vite-with-i18n
            // This flag triggers setting up $t (and some other global variables) in context.
            // Unfortunately, this is missing in most examples.
            globalInjection: true,
            messages : messages
            });*/

        const datetimeFormats = {
            'de': {
                short: {
                    year: 'numeric', month: 'numeric', day: 'numeric'
                },
                long: {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second : "numeric"
                }
            },
            'en': {
                short: {
                    year: 'numeric', month: 'numeric', day: 'numeric'
                },
                long: {
                    year: 'numeric', month: 'numeric', day: 'numeric',
                    hour: 'numeric', minute: 'numeric', second : "numeric"
                }
            }            
        };

        const i18n = createI18n<Record<string, unknown>, MessageSchema>({
            datetimeFormats : datetimeFormats,
            locale,
            fallbackLocale: [...navigator.languages, defaultLocale],
            legacy : false,
            // https://vue-i18n-next.intlify.dev/guide/advanced/composition.html#global-scope
            // https://stackoverflow.com/questions/66732739/internationalization-for-vue-3-vite-with-i18n
            // This flag triggers setting up $t (and some other global variables) in context.
            // Unfortunately, this is missing in most examples.
            globalInjection: true,

            messages : messages
        });
        return i18n;
    }

    private static removeLatestTag(locale: string): string {
        return locale.replace(/^(.*)-[^-]*$/, "$1");
    }

    private static getLessSpecificLocales(locale: string): string[] {
        // includes empty.
        if (!locale) {
            return [];
        }
        let prevLocale;
        let ret = [];
        do {
            ret.push(locale);
            prevLocale = locale;
            locale = I18nHelper.removeLatestTag(locale);
        } while (prevLocale !== locale);
        return ret;
    }

    public static getLocale(userDefaultLocale: string | null, globalDefaultLocale: string, messages: LocaleMessages<VueMessageType>): string {
        let urlParams = new URLSearchParams(window.location.search);
        let urlLocale = urlParams.get('locale');

        const localeOrder = I18nHelper.getLessSpecificLocales(urlLocale)
        .concat(I18nHelper.getLessSpecificLocales(userDefaultLocale))
        .concat(I18nHelper.getLessSpecificLocales(navigator.language))
        .concat(navigator.languages.flatMap(I18nHelper.getLessSpecificLocales));

        log.info("Trying locales in the order " + localeOrder);

        for (const locale of localeOrder) {
            if (locale in messages) {
                log.info("Using locale " + locale);
                return locale;
            }
        }
        log.warn("Falling back to default locale " + globalDefaultLocale);
        return globalDefaultLocale;
    }
}
