/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { TaskSearchModel } from "cdes-api/dto/task/TaskSearchModel";
import CheckBoxVue from "cdes-vue/util/form/CheckBox.vue";
import DateInputVue from "cdes-vue/util/form/DateInput.vue";
import SelectVue from "cdes-vue/util/form/Select.vue";
import TextInputVue from "cdes-vue/util/form/TextInput.vue";
import { SelectOption } from "cdes-vue/util/form/Select";

import { defineComponent, PropType } from "vue";
import { OrganisationPersonJoin } from "cdes-api/joinDto/OrganisationPersonJoin";
import { OrganisationPersonHelper } from "cdes-vue/person/util/OrganisationPersonHelper";
import { TaskDeputyMode } from "cdes-vue/voc/task/TaskDeputyMode";
import { TaskSearchMode } from "cdes-vue/voc/task/TaskSearchMode";
import Label from "cdes-vue/util/form/Label.vue";

import { DateHelper, TimeDifference } from "clazzes-core/dateTime/DateHelper";
import { TaskCounts } from "cdes-api/dto/task/TaskCounts";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

export default defineComponent({
    components : {
        CheckBox : CheckBoxVue,
        DateInput : DateInputVue,
        Select : SelectVue,
        TextInput : TextInputVue,
        Label,
    },

    props: {
        asyncRunning : Boolean,
        searchModelProp: Object as PropType<TaskSearchModel>,
        taskCounts : Object as PropType<TaskCounts>,
        initialized : Boolean
    },

    emits: ["update:searchModelProp", "doSearch", "update:quickFilter"],

    expose : [ "activateFilterByDate", "getFilterByDate", "setSearchModelFromLocalStorage" ],

    mounted() {
        let storedQuickSearch : string = LocalStorageHelper.getFromLocalStorage([ this.ctx.principal ], "/task/quickSearch");
        if (storedQuickSearch != null) {
            this.quickFilter = storedQuickSearch;
            this.$emit("update:quickFilter", this.quickFilter);
        }        
    },

   computed : {
        showSearchFieldsText() : string {
            let searchMode : TaskSearchMode = this.searchModel.searchMode;
            let deputyMode : TaskDeputyMode = this.searchModel.deputyMode;

            let organisationPersonJoins : OrganisationPersonJoin[] = this.ctx.getOwnOrganisationPersonJoins();
            let organisationName : string = null;
            if (this.searchModel.organisationPersonId != null) {
                for (let organisationPersonJoin of organisationPersonJoins) {
                    if (organisationPersonJoin.organisationPersonId == this.searchModel.organisationPersonId) {
                        organisationName = organisationPersonJoin.organisationName;
                    }
                }
            }

            let fromTs : number = this.searchModel.fromTs;
            let toTs : number = this.searchModel.toTs;

            let text = this.$t("task.search.toggleButton.showSearchFields") + this.getSearchModeLabel(searchMode);
            if (deputyMode == TaskDeputyMode.DEPUTY) {
                text = text + " " + this.$t("task.search.toggleButton.asDeputy");
            } else if (deputyMode == TaskDeputyMode.MAIN) {
                text = text + " " + this.$t("task.search.toggleButton.asMainParticipant");                
            }
            if (organisationName != null) {
                text = text + " " + this.$t("task.search.toggleButton.inOrganisation", {
                    organisationName : organisationName
                });
            }

            if (fromTs != null) {
                text = text + " " + this.$t("task.search.toggleButton.fromTs", {
                    fromTs : this.$d(new Date(fromTs * 1000), { dateStyle: 'short' })
                });
            }
            if (toTs != null) {
                text = text + " " + this.$t("task.search.toggleButton.toTs", {
                    toTs : this.$d(new Date(toTs * 1000), { dateStyle: 'short' })
                });
            }            

            return text;
        },

        fromTsDifference(): TimeDifference {
            return this.getDifferenceFromNow(this.fromTs);
        },

        toTsDifference(): TimeDifference {
            return this.getDifferenceFromNow(this.toTs);
        },

        deputyOptions() : SelectOption[] {
            return [
                { label : this.$t("general.all")
                    + (this.deputyAllCount != null ? " " + this.deputyAllCount : ""), value : null },
                { label : (this.searchMode == TaskSearchMode.DONE ? this.$t("task.search.deputyDONEOptions.main") : this.$t("task.search.deputyOptions.main"))
                    + (this.searchMode != TaskSearchMode.DONE && this.deputyMainCount != null ? " " + this.deputyMainCount : ""), value : TaskDeputyMode.MAIN },
                { label : (this.searchMode == TaskSearchMode.DONE ? this.$t("task.search.deputyDONEOptions.deputy") : this.$t("task.search.deputyOptions.deputy"))
                    + (this.searchMode != TaskSearchMode.DONE && this.deputyDeputyCount != null ? " " + this.deputyDeputyCount : ""), value : TaskDeputyMode.DEPUTY },
            ];
        },

        deputyAllCount() : number {
            if (this.taskCounts == null || this.searchMode == null) {
                return null;
            } else if (this.searchMode == TaskSearchMode.OWN) {
                return this.taskCounts.ownTaskCount;
            } else if (this.searchMode == TaskSearchMode.BATCH) {
                return this.taskCounts.batchTaskCount;
            } else if (this.searchMode == TaskSearchMode.DONE) {
                return this.taskCounts.doneTaskCount;
            } else if (this.searchMode == TaskSearchMode.LATE) {
                return this.taskCounts.lateTaskCount;
            } else {
                throw new Error("Illegal searchMode [" + this.searchMode + "]");
            }
        },

        deputyMainCount() : number {
            if (this.taskCounts == null || this.searchMode == null) {
                return null;
            } else if (this.searchMode == TaskSearchMode.OWN) {
                return this.taskCounts.ownTaskMainCount;
            } else if (this.searchMode == TaskSearchMode.BATCH) {
                return this.taskCounts.batchTaskMainCount;
            } else if (this.searchMode == TaskSearchMode.DONE) {
                return this.taskCounts.doneTaskMainCount;
            } else if (this.searchMode == TaskSearchMode.LATE) {
                return this.taskCounts.lateTaskMainCount;
            } else {
                throw new Error("Illegal searchMode [" + this.searchMode + "]");
            }
        },

        deputyDeputyCount() : number {
            if (this.taskCounts == null || this.searchMode == null) {
                return null;
            } else if (this.searchMode == TaskSearchMode.OWN) {
                return this.taskCounts.ownTaskDeputyCount;
            } else if (this.searchMode == TaskSearchMode.BATCH) {
                return this.taskCounts.batchTaskDeputyCount;
            } else if (this.searchMode == TaskSearchMode.DONE) {
                return this.taskCounts.doneTaskDeputyCount;
            } else if (this.searchMode == TaskSearchMode.LATE) {
                return this.taskCounts.lateTaskDeputyCount;
            } else {
                throw new Error("Illegal searchMode [" + this.searchMode + "]");
            }
        },       

        modeOptions() : SelectOption[] {
            let options : SelectOption[] = [];

            options.push({ label : this.$t("task.search.myTasksItemLabel")
                + (this.taskCounts != null ? " " + this.taskCounts.ownTaskCount : ""), value : TaskSearchMode.OWN });
            options.push({ label : this.$t("task.search.signatureFolderItemLabel")
                + (this.taskCounts != null ? " " + this.taskCounts.batchTaskCount : ""), value : TaskSearchMode.BATCH });
            options.push({ label : this.$t("task.search.doneTasksItemLabel")
                + (this.taskCounts != null ? " " + this.taskCounts.doneTaskCount : ""), value : TaskSearchMode.DONE });
            options.push({ label : this.$t("task.search.belatedTasksItemLabel")
                + (this.taskCounts != null ? " " + this.taskCounts.lateTaskCount : ""), value : TaskSearchMode.LATE });

            return options;
        },

        organisationPersonOptions() : SelectOption[] {
            let organisationPersonJoins : OrganisationPersonJoin[] = this.ctx.getOwnOrganisationPersonJoins();
            return [{
                label: this.$t("general.all"),
                value: null,
            }].concat(OrganisationPersonHelper.getOrganisationPersonOptionsByJoins(organisationPersonJoins));
        },

        searchModel(): TaskSearchModel {
            const ret = new TaskSearchModel();
            ret.organisationPersonId = this.organisationPersonId;
            if (this.filterByDate) {
                ret.fromTs = this.fromTs;
                ret.toTs = this.toTs;
            }
            ret.searchMode = this.searchMode;
            ret.deputyMode = this.deputyMode;
            return ret;
        },

        filterByDateOverride(): boolean | undefined {
            switch (this.searchMode) {
                case "DONE":
                    return true;
                case "LATE":
                    return false;
                default:
                    return undefined;
            }
        },

        filterByLate(): boolean | undefined {
            switch (this.searchMode) {
                case "LATE":
                    return true;
                default:
                    return false;
            }
        },

        filterByDate: {
            get(): boolean {
                return this.filterByDateOverride ?? this.filterByDateCheckBox;
            },
            set(value: boolean) {
                this.filterByDateCheckBox = value;
            },
        },
    },

    watch: {
        searchModelProp: {
            handler(searchModel: TaskSearchModel) {
                if (searchModel != null) {
                    if (searchModel.fromTs != null) {
                        this.fromTs = searchModel.fromTs;
                    }
                    if (searchModel.toTs) {
                        this.toTs = searchModel.toTs;
                    }
                    this.searchMode = searchModel.searchMode;
                    this.deputyMode = searchModel.deputyMode;
                    this.organisationPersonId = searchModel.organisationPersonId;
                }
            },
            immediate: true,
        },
        searchModel: {
            handler(searchModel: TaskSearchModel) {
                this.$emit("update:searchModelProp", searchModel);
            },
            immediate: true,
        },
        searchMode() {
            if (this.initialized) {
                console.info("Watcher for searchMode triggered.");
                this.doSearch();
            }
        },
        deputyMode() {
            if (this.initialized) {
                console.info("Watcher for deputyMode triggered");
                this.doSearch();
            }
        },
    },

    data() {
        const now = new Date();

        const fromDate = new Date(now.getTime());
        fromDate.setMonth(fromDate.getMonth() - 1)

        const toDate = new Date(now.getTime());
        toDate.setDate(toDate.getDate() + 3);

        return {
            fromTs: fromDate.getTime() / 1000,
            toTs: toDate.getTime() / 1000,
            filterByDateCheckBox: false,
            searchMode: TaskSearchMode.OWN,
            deputyMode: null,
            organisationPersonId: null,
            expanded : true,
            quickFilter : ""
        };
    },

    methods : {
        getSearchModeLabel(taskSearchMode : TaskSearchMode) : string {
            if (taskSearchMode == TaskSearchMode.OWN) {
                return this.$t("task.search.myTasksItemLabel");
            } else if (taskSearchMode == TaskSearchMode.BATCH) {
                return this.$t("task.search.signatureFolderItemLabel");
            } else if (taskSearchMode == TaskSearchMode.DONE) {
                return this.$t("task.search.doneTasksItemLabel");
            } else if (taskSearchMode == TaskSearchMode.LATE) {
                return this.$t("task.search.belatedTasksItemLabel");
            } else {
                return "---"
            }
        },

        toggleCollapsed() : void {
            this.expanded = !this.expanded;
        },

        formatTimeDifference: DateHelper.formatMostSignificantPartOfTimeDifference,
        reduceDatePrecision(date: Date): Date {
            const ret = new Date(date);
            ret.setHours(0);
            ret.setMinutes(0);
            ret.setSeconds(0);
            ret.setMilliseconds(0);

            return ret;
        },
        getDifferenceFromNow(ts: number): TimeDifference {
            if (ts == null) {
                return undefined;
            }
            return DateHelper.getUtcSecondsDifference(this.reduceDatePrecision(new Date()).getTime() / 1000, ts, DateHelper.getTimeZone());
        },
        doSearch() : void {
            this.$emit("doSearch", this.searchModel);
        },
        setSearchModelFromLocalStorage() : void {
            let storedSearchModel : TaskSearchModel = LocalStorageHelper.getFromLocalStorage([ this.ctx.principal ], "/task/search");
            if (storedSearchModel != null) {
                this.deputyMode = storedSearchModel.deputyMode;
                if (storedSearchModel.fromTs != null) {
                    this.fromTs = storedSearchModel.fromTs;
                }
                if (storedSearchModel.toTs != null) {
                    this.toTs = storedSearchModel.toTs;
                }
                this.searchMode = this.getTaskSearchModeByString(storedSearchModel.searchMode);
                if (this.searchMode == null) {
                    this.searchMode = TaskSearchMode.OWN;
                }
                
                if (storedSearchModel.filterByDate) {
                    this.filterByDate = true;
                }
            }
            this.doSearch();
        },
        getTaskSearchModeByString(s : string) : TaskSearchMode {
            if (s == "OWN") {
                return TaskSearchMode.OWN;
            } else if (s == "OPEN") {
                return TaskSearchMode.OPEN;
            } else if (s == "DONE") {
                return TaskSearchMode.DONE;
            } else if (s == "LATE") {
                return TaskSearchMode.LATE;
            } else if (s == "BATCH") {
                return TaskSearchMode.BATCH;
            } else {
                return null;
            }
        },        
        activateFilterByDate() : void {
            this.filterByDate = true;
        },
        getFilterByDate() : boolean {
            return this.filterByDate;
        }
    },
});
