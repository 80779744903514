/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import 'bootstrap';

import { defineComponent, watchEffect, ref } from "vue";
import { useI18n } from "vue-i18n";
import { I18nHelper } from "cdes-vue/i18n/I18nHelper";
import { contextKey } from "cdes-vue/util/Ctx";

import LoginDialogVue from "cdes-vue/service/LoginDialog.vue";
import CdesMainUi from "cdes-vue/layout/main/CdesMainUi.vue";
import { ErrorHelper } from 'cdes-vue/util/ErrorHelper';

// See e.g. https://stackoverflow.com/questions/58488865/is-there-a-way-to-get-the-type-of-a-component-in-vuejs
type LoginDialog = InstanceType<typeof LoginDialogVue>;

export default defineComponent({
    components : {
        LoginDialog : LoginDialogVue,
        CdesMainUi,
    },

    provide() {
        return {
            // @ts-ignore
            [contextKey]: this.ctx,
        };
    },

    setup() {
        const composer = useI18n();
        const { locale } = composer;

        watchEffect(() => {
            document.querySelector("html").lang = locale.value;
        });

        return {
            // deep unwrap ref.
            composer: ref(composer),
        };
    },

    mounted() : void {
        const loginDialog : LoginDialog = this.$refs.loginDialog as LoginDialog
        this.ctx.setReloginHandler(loginDialog.reloginHandler);

        // Register services, open tab session, start Tapestry session.
        // Tasks based on services, login, tab session, routing may not
        // be done before this asynchronous call has completed.
        this.ctx.initialize().then(() => {
            this.$i18n.locale = I18nHelper.getLocale(this.ctx.getUserLocale(), "de", this.composer.messages as any);
            this.composer.fallbackLocale = [this.ctx.getUserLocale(), ...navigator.languages, "de"];
        }, err => {
            ErrorHelper.processError(this.$t, this.$d, err);
        });
    }
});

/*
@Options({
    components : {
        LoginDialog : LoginDialog
    }
})
class App extends Vue {

    public $refs : {
        loginDialog : LoginDialog
    }

    public mounted() : void {
        this.ctx.setReloginHandler(this.$refs.loginDialog.reloginHandler);

        // Register services, open tab session, start Tapestry session.
        // Tasks based on services, login, tab session, routing may not
        // be done before this asynchronous call has completed.
        this.ctx.initialize().then(() => this.handleInitialized());
    }

    private handleInitialized() : void {
        let userLocale = this.ctx.getUserLocale();
        this.$root.$i18n.locale = userLocale;
    }
}

export default App;
*/
