/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { Project } from "cdes-api/dto/Project";

export default defineComponent({
    components: {
        ParempiGrid,
    },

    props: {
        items: {
            type: Array as PropType<Project[]>,
            default: () => [],
        },
    },
    computed : {
        columns() : ParempiColumn<Project>[] {
            return [
                ParempiColumn.asRowSpanSlot("code", this.$t("general.code"), "code"),
                ParempiColumn.asRowSpanSlot("name", this.$t("general.name"), "name"),
                ParempiColumn.asRowSpanSlot("comment", this.$t("general.comment"), "comment"),                
            ];
        }
    }
});
