import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "invalid-feedback" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "minlength"]
const _hoisted_6 = { class: "invalid-feedback" }
const _hoisted_7 = ["for"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "invalid-feedback" }
const _hoisted_10 = ["for"]
const _hoisted_11 = ["id"]
const _hoisted_12 = { class: "invalid-feedback" }
const _hoisted_13 = ["for"]
const _hoisted_14 = ["id", "minlength"]
const _hoisted_15 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Transform = _resolveComponent("Transform")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-column gap-3", { 'was-validated': _ctx.oldPassword?.length > 0 || _ctx.newPassword1?.length > 0 || _ctx.newPassword2?.length > 0 || _ctx.question?.length > 0 || _ctx.answer?.length > 0 }])
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_i18n_t, { keypath: "newPassword.description" }, {
        maxAge: _withCtx(() => [
          _createVNode(_component_LoadedOrPlaceholder, { value: _ctx.info }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.info.passwordMaxAge), 1)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        remainingDays: _withCtx(() => [
          _createVNode(_component_LoadedOrPlaceholder, { value: _ctx.info }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.info.passwordRemainingDays), 1)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        for: _ctx.$id('oldPassword'),
        class: "form-label"
      }, _toDisplayString(_ctx.$t('newPassword.oldPasswordLabel')), 9, _hoisted_1),
      _createVNode(_component_Transform, { transformer: _ctx.oldPasswordTransformer }, {
        default: _withCtx(() => [
          _createElementVNode("input", {
            id: _ctx.$id('oldPassword'),
            class: "form-control",
            type: "password",
            autocomplete: "password",
            required: "",
            "data-autofocus": ""
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["transformer"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.oldPasswordValidity.valueMissing)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('newPassword.oldPasswordMissing')), 1)
            ], 64))
          : (_ctx.oldPasswordValidity.WRONG_PW)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('newPassword.oldPasswordWrong')), 1)
              ], 64))
            : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        for: _ctx.$id('newPassword1'),
        class: "form-label"
      }, _toDisplayString(_ctx.$t('newPassword.newPassword1Label')), 9, _hoisted_4),
      _createVNode(_component_Transform, { transformer: _ctx.newPassword1Transformer }, {
        default: _withCtx(() => [
          _createElementVNode("input", {
            id: _ctx.$id('newPassword1'),
            class: "form-control",
            type: "password",
            autocomplete: "new-password",
            required: "",
            minlength: _ctx.minLength
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      }, 8, ["transformer"]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.newPassword1Validity.valueMissing)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPassword1Missing')), 1)
            ], 64))
          : (_ctx.newPassword1Validity[_ctx.ValidationError.INVALID])
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPasswordInvalid')), 1)
              ], 64))
            : (_ctx.newPassword1Validity.tooShort)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPasswordTooShort', { minLength: _ctx.minLength })), 1)
                ], 64))
              : (_ctx.newPassword1Validity[_ctx.ValidationError.LACKING_DIVERSITY])
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPasswordLackingDiversity')), 1)
                  ], 64))
                : (_ctx.newPassword1Validity[_ctx.ValidationError.OLD_PW])
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPasswordOldPw')), 1)
                    ], 64))
                  : (_ctx.newPassword1Validity[_ctx.ValidationError.INVALID_CHARS])
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('newPassword.passwordIllegalCharsWarning')), 1)
                      ], 64))
                    : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        for: _ctx.$id('newPassword2'),
        class: "form-label"
      }, _toDisplayString(_ctx.$t('newPassword.newPassword2Label')), 9, _hoisted_7),
      _createVNode(_component_Transform, { transformer: _ctx.newPassword2Transformer }, {
        default: _withCtx(() => [
          _createElementVNode("input", {
            id: _ctx.$id('newPassword2'),
            class: "form-control",
            type: "password",
            autocomplete: "new-password",
            required: ""
          }, null, 8, _hoisted_8)
        ]),
        _: 1
      }, 8, ["transformer"]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.newPassword2Validity.valueMissing)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPassword2Missing')), 1)
            ], 64))
          : (_ctx.newPassword2Validity[_ctx.ValidationError.REPETITION_NOT_IDENTICAL])
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('newPassword.newPassword2NotIdentical')), 1)
              ], 64))
            : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.info === _ctx.loadingPlaceholder || _ctx.info.needsSecurityQuestion)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", null, [
            _createElementVNode("label", {
              for: _ctx.$id('question'),
              class: "form-label"
            }, _toDisplayString(_ctx.$t('newPassword.questionLabel')), 9, _hoisted_10),
            _createVNode(_component_Transform, { transformer: _ctx.questionTransformer }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  id: _ctx.$id('question'),
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question) = $event)),
                  required: ""
                }, null, 8, _hoisted_11), [
                  [_vModelText, _ctx.question]
                ])
              ]),
              _: 1
            }, 8, ["transformer"]),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('newPassword.questionMissing')), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", {
              for: _ctx.$id('answer'),
              class: "form-label"
            }, _toDisplayString(_ctx.$t('newPassword.answerLabel')), 9, _hoisted_13),
            _createVNode(_component_Transform, { transformer: _ctx.answerTransformer }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  id: _ctx.$id('answer'),
                  class: "form-control",
                  type: "password",
                  autocomplete: "new-password",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.answer) = $event)),
                  required: "",
                  minlength: _ctx.answerMinLength,
                  pattern: "^([a-z]|[A-Z]|ö|Ö|ä|Ä|ü|Ü|ß| |[0-9]|_|-|\\\\.)*$"
                }, null, 8, _hoisted_14), [
                  [_vModelText, _ctx.answer]
                ])
              ]),
              _: 1
            }, 8, ["transformer"]),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.answerValidity.valueMissing)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('newPassword.answerMissing')), 1)
                  ], 64))
                : (_ctx.answerValidity.patternMismatch)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('newPassword.answerInvalidCharacters')), 1)
                    ], 64))
                  : (_ctx.answerValidity.tooShort)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('newPassword.answerTooShort', { minLength: _ctx.answerMinLength })), 1)
                      ], 64))
                    : _createCommentVNode("", true)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}