import { defineComponent } from "vue";
export default defineComponent({
    props: {
        addUpperCloseButton : {
            type : Boolean
        },
        src : {
            type : String
        }
    },

    emits : [ "iframeReloaded" ],

    expose : [ "reload" ],

    mounted() {
        let iframe : HTMLIFrameElement = this.$refs.iframe as HTMLIFrameElement;
        iframe.onload = () => {
            let document = iframe.contentDocument;
            let redirectSpan : HTMLSpanElement = document.getElementById("redirectSpan") as HTMLSpanElement;
            let tabSessionIdSpan : HTMLSpanElement = document.getElementById("tabSessionIdSpan") as HTMLSpanElement;
            let cameBackSpan : HTMLSpanElement = document.getElementById("cameBackSpan") as HTMLSpanElement;

            if (redirectSpan != null) {
                let target = redirectSpan.innerText;
                if (target == "planDeliverCatalogue") {
                    this.$emit("iframeReloaded");
                    this.$router.push("liefern");
                }
            }            
        };
    },

    /*
    updated() {
        // When jumping from a Dojo page to another Dojo page in another main menu section,
        // the iframe receives the correct, new address, but isn't reloaded by default,
        // for some obscure reason.
        // Thus, explicitely call reload here.
        this.reload();
    },*/

    methods : {
        reload() {
            (this.$refs.iframe as HTMLIFrameElement).contentWindow.location.reload();
        },

        close() {
            this.$router.go(-1);           
        }
    }
});
