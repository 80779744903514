/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { h, FunctionalComponent, VNode } from "vue";

export type Transformer = (vnode: VNode) => VNode;

export default (((props, { slots }) => {
    const transformers = props.transformer ?? props.transformers;
    const slotVNode = slots.default();

    if (slotVNode.length > 1) {
        throw new Error("slot for Transform can only have one root node.");
    }

    if (Array.isArray(transformers)) {
        return transformers.reduce((vnode, transformer) => transformer(vnode), slotVNode[0]);
    } else {
        return transformers(slotVNode[0]);
    }
}) as FunctionalComponent<{
    transformers: Transformer | Transformer[],
    transformer: Transformer,
}>);
