import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_2 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_3 = { class: "d-flex flex-row flex-wrap gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_ParempiGrid = _resolveComponent("ParempiGrid")!

  return (_openBlock(), _createBlock(_component_ParempiGrid, {
    class: "flex-grow-1 flex-min-height-1",
    columns: this.columns,
    items: this.docTypeEntries
  }, {
    code: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(item.code), 1)
    ]),
    name: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1)
    ]),
    description: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_3, _toDisplayString(item.description), 1)
    ]),
    documentTypeLabelContent: _withCtx(({ item }) => [
      _createVNode(_component_CheckBox, {
        mode: "flex",
        modelValue: item.chosen,
        "onUpdate:modelValue": ($event: any) => ((item.chosen) = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _: 1
  }, 8, ["columns", "items"]))
}