import { defineComponent, Ref, computed } from "vue";
import { asyncEagerComputed, eagerComputed, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import Label from "cdes-vue/util/form/Label.vue";
import { OriginalDocumentOrderPageInfo } from "cdes-api/dto/original/OriginalDocumentOrderPageInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { OriginalType } from "cdes-vue/voc/original/OriginalType";
import { OriginalStatus } from "cdes-vue/voc/original/OriginalStatus";
import { OrganisationTreeModel } from "cdes-api/dto/organisation/OrganisationTreeModel";
import { Organisation } from "cdes-api/dto/Organisation";
import { OrganisationPersonSelectionJoin } from "cdes-api/joinDto/OrganisationPersonSelectionJoin";
import TreeSelect, { TreeSelectModel } from "cdes-vue/util/form/TreeSelect.vue";
import { useI18n } from "vue-i18n";
import { OriginalDocumentOrderSearchModel } from "cdes-api/dto/original/OriginalDocumentOrderSearchModel";
import { OriginalDocumentOrder } from "cdes-api/dto/OriginalDocumentOrder";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

export type OrganisationOrOrganisationPersonId = ["organisation", number] | ["organisationPerson", number];

class OrganisationTreeModelImpl implements TreeSelectModel<null | OrganisationPersonSelectionJoin | Organisation, OrganisationOrOrganisationPersonId | null> {
    t: (key: string) => string;
    public constructor(
        private data: Ref<OrganisationTreeModel | LoadingPlaceholder>,
    ) {
        this.t = useI18n().t;
    }

    public getChildren(parent?: Organisation | OrganisationPersonSelectionJoin | null) {
        if (this.data.value === loadingPlaceholder) {
            return loadingPlaceholder;
        } else if (arguments.length === 0) {
            return [null].concat(this.data.value.organisations);
        } else if (parent == null) {
            return [];
        } else if ("id" in parent) {
            return this.data.value.organisationIdToJoins.get(parent.id);
        } else {
            return [];
        }
    }

    public isLeaf(value: Organisation | OrganisationPersonSelectionJoin | null): boolean {
        return value == null || !("id" in value);
    }

    public getKey(value: Organisation | OrganisationPersonSelectionJoin | null): OrganisationOrOrganisationPersonId | null {
        if (value == null) {
            return value as null | undefined;
        } else if ("id" in value) {
            return ["organisation", value.id];
        } else {
            return ["organisationPerson", value.organisationPersonId];
        }
    }

    public keyHash(value: OrganisationOrOrganisationPersonId | null): number {
        return value == null ? value as null : value[1];
    }

    public keyEquals(a: OrganisationOrOrganisationPersonId, b: OrganisationOrOrganisationPersonId): boolean {
        return (a == null && b == null) || (a != null && b != null && a[0] === b[0] && a[1] === b[1]);
    }

    public getLabel(value: Organisation | OrganisationPersonSelectionJoin | null): string {
        if (value == null) {
            return this.t("general.all");
        } else if ("id" in value) {
            return value.name
        } else {
            return `${value.personSurName} ${value.personGivenName}`;
        }

    }
}

export default defineComponent({
    components: {
        CustomSelect,
        Label,
        TreeSelect,
    },

    emits: ["search"],

    expose : [ "populateFromLocalStorage" ],

    setup() {
        const ctx = useCtx();

        const originalDocumentOrderPageInfo = asyncEagerComputed(() =>
            ctx.documentService.getOriginalDocumentOrderPageInfo(ctx.activeOrganisationPersonId, ctx.activeSubProjectId), loadingPlaceholder);

        const requestOptions = new OrganisationTreeModelImpl(computed(() =>
            originalDocumentOrderPageInfo.value === loadingPlaceholder ? loadingPlaceholder
                                                                        : originalDocumentOrderPageInfo.value.requestOrganisationTreeModel));
        const receiverOptions = new OrganisationTreeModelImpl(computed(() =>
            originalDocumentOrderPageInfo.value === loadingPlaceholder ? loadingPlaceholder
                                                                         : originalDocumentOrderPageInfo.value.receiverOrganisationTreeModel));


        return {
            originalDocumentOrderPageInfo,
            requestOptions,
            receiverOptions,
        };
    },

    computed : {
        documentVersionNameOptions() : SelectOption[] {
            if (this.originalDocumentOrderPageInfo === loadingPlaceholder) {
                return [];
            }
            let options : SelectOption[] = [];
            for (let documentVersion of this.originalDocumentOrderPageInfo.documentVersions) {
                options.push({ value : documentVersion.id, label : documentVersion.name });
            }
            return options;
        },

        plannerOptions() : SelectOption[] {
            if (this.originalDocumentOrderPageInfo === loadingPlaceholder) {
                return [];
            }
            let options : SelectOption[] = [];
            for (let documentOwnerJoin of this.originalDocumentOrderPageInfo.documentOwnerJoins) {
                let label : string = documentOwnerJoin.personSurName + " " + documentOwnerJoin.personGivenName
                    + " (" + documentOwnerJoin.organisationName + ")";
                options.push({ value : documentOwnerJoin.organisationPersonId, label : label });
            }
            return options;
        },
        searchModel() : OriginalDocumentOrderSearchModel {

            let searchModel : OriginalDocumentOrderSearchModel = {
                originalType : this.originalTypeValue,
                documentVersionId : this.documentVersionNameValue,
                plannerOrganisationPersonId : this.plannerOrganisationPersonIdValue,
                acceptedMode : this.acceptedModeValue,
                requestedByOrganisationId : this.requestedByValue != null && this.requestedByValue[0] == "organisation"
                    ? this.requestedByValue[1] : null,
                requestedByOrganisationPersonId : this.requestedByValue != null && this.requestedByValue[0] == "organisationPerson"
                    ? this.requestedByValue[1] : null,
                requestedForOrganisationId : this.requestedForValue != null && this.requestedForValue[0] == "organisation"
                    ? this.requestedForValue[1] : null,
                requestedForOrganisationPersonId : this.requestedForValue != null && this.requestedForValue[0] == "organisationPerson"
                    ? this.requestedForValue[1] : null,
            };
            return searchModel;
        }
    },

    props : {
        disabled : {
            type : Boolean
        },
    },

    data() {
        return {
            originalTypeValue : null,
            documentVersionNameValue : null,
            plannerOrganisationPersonIdValue : null,
            acceptedModeValue : null,
            requestedByValue : null,
            requestedForValue : null
        };
    },

    watch : {
        requestedByValue() {
            console.info(this.requestedByValue);
        },
        requestedForValue() {
            console.info(this.requestedForValue);
        }
    },

    methods : {
        getTypeOptions() : SelectOption[] {
            return [
                { label : this.$t("original.search.fromMe"), value : OriginalType.FROM_ME },
                { label : this.$t("original.search.toMe"), value : OriginalType.TO_ME }
            ];
        },

        getStatusOptions() : SelectOption[] {
            return [
                { label : this.$t("original.search.accepted"), value : OriginalStatus.ACCEPTED },
                { label : this.$t("original.search.rejected"), value : OriginalStatus.REJECTED }
            ];
        },
        populateFromLocalStorage() : void {
            let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
            let searchModel : OriginalDocumentOrderSearchModel = LocalStorageHelper.getFromLocalStorage([ opString ], "/original/search");

            console.info("Loaded searchmodel, will now populate fields");
            console.info(searchModel);

            if (searchModel == null) {
                return;
            }

            this.originalTypeValue = searchModel.originalType;
            this.documentVersionNameValue = searchModel.documentVersionId;
            this.plannerOrganisationPersonIdValue = searchModel.plannerOrganisationPersonId;
            this.acceptedModeValue = searchModel.acceptedMode;

            if (searchModel.requestedByOrganisationId != null) {
                this.requestedByValue = [ "organisation", searchModel.requestedByOrganisationId ];
            } else if (searchModel.requestedByOrganisationPersonId != null) {
                this.requestedByValue = [ "organisationPerson", searchModel.requestedByOrganisationPersonId ];
            }
            if (searchModel.requestedForOrganisationId != null) {
                this.requestedForValue = [ "organisation", searchModel.requestedForOrganisationId ];
            } else if (searchModel.requestedForOrganisationPersonId != null) {
                this.requestedForValue = [ "organisationPerson", searchModel.requestedForOrganisationPersonId ];
            }
        }        
    }
});
