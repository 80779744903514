import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "d-flex flex-row align-items-center" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = { class: "text-muted mb-2" }
const _hoisted_6 = { class: "card-text" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(this.infoMessage.headline), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.recordDateString), 1)
      ]),
      _createElementVNode("p", _hoisted_6, [
        _createVNode(_component_LoadedOrPlaceholder, {
          value: this.infoMessage
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: this.infoMessage.content
            }, null, 8, _hoisted_7)
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ])
  ]))
}