/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType } from "vue";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { LoadingPlaceholder, loadingPlaceholder } from "cdes-vue/util/Promise";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import { Dropdown, Popover } from "bootstrap";
import ReviewProtocolContext from "./ReviewProtocolContext";

export default defineComponent({
    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        documentVersionId: {
            type: [Number, Symbol] as PropType<number | LoadingPlaceholder>,
        },
        disabled : {
            type : Boolean
        }
    },

    computed: {
        pdfLink(): string {
            if (this.documentVersionId === loadingPlaceholder) {
                return "javascript:void(0)";
            }
            return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionPdfMimeSource", [
                "" + this.documentVersionId,
            ]);
        },
        pngLink(): string {
            if (this.documentVersionId === loadingPlaceholder) {
                return "javascript:void(0)";
            }
            return this.ctx.getTapestryRequestUrl("CDESMimeService/4/documentVersionPngMimeSource", [
                "" + this.documentVersionId,
                // pixel-size
                "" + 320,
                // anti-alias
                "T",
            ]);
        },
        pngPopover(): HTMLElement {
            const e = document.createElement("img");
            e.src = this.pngLink;
            return e;
        },
        cmpLink(): string {
            if (this.documentVersionId === loadingPlaceholder) {
                return "javascript:void(0)";
            }
            return this.ctx.getTapestryRequestUrl("external/CmpPngPreview", [
                "SdownloadDocumentVersion",
                "" + this.documentVersionId,
            ]);
        },
        documentVersionLink(): string {
            if (this.documentVersionId === loadingPlaceholder) {
                return "javascript:void(0)";
            }
            return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionMimeSource", [
                "" + this.documentVersionId,
            ]);
        },
        documentVersionFileName(): string {
            if (this.context === loadingPlaceholder || this.documentVersionId === loadingPlaceholder) {
                return "";
            }
            const join = this.context.documentVersionsById.get(this.documentVersionId);
            return `${join.documentVersionName}.${join.documentVersionFiletype}`;
        },
        hasCmp(): boolean {
            if (this.context === loadingPlaceholder) {
                return true;
            }
            return this.context.versionJoins[0].documentVersionId !== this.documentVersionId;
        },
    },

    methods: {
        openCmp(): void {
            window.open(this.cmpLink, "_blank", "popup");
        },
        getDropdown(): Dropdown {
            return Dropdown.getInstance(this.$refs.dropdown);
        },
        getPngPopover(): Popover {
            return Popover.getInstance(this.$refs.pngPopover);
        },
    },
});
