import { DtoHelper } from "dmgen-cli/util/DtoHelper";

export abstract class Persistent {

    // Properties: (placed here as documentation)
    //
    // id : number;

    private _id : number;
    get id() : number {
        this.__checkAttributeDefinition(1, "id");
        return this._id;
    }
    set id(id : number) {
        this._id = id;
    }


    private __partialInstance : boolean;
    protected __setPartialInstance(__partialInstance : boolean) : void {
        this.__partialInstance = __partialInstance;
    }
    protected __isPartialInstance() : boolean {
        return this.__partialInstance;
    }

    private __definedAttributes : boolean[];
    protected __setDefinedAttributes(__definedAttributes : boolean[]) : void {
        this.__definedAttributes = __definedAttributes;
    }
    protected __getDefinedAttributes() : boolean[] {
        return this.__definedAttributes;
    }
    protected __checkAttributeDefinition(attributeIndex : number, attributeCodeName : string) : void {
        if (this.__definedAttributes != null && !this.__definedAttributes[attributeIndex]) {
            throw new Error("Attribute [" + attributeCodeName + "] is not defined in this dto.  Most probably, you want to check the JoinDto this Dto was extracted from in datamodel.");
        }
    }

    public equals(other : Persistent) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && DtoHelper.areMembersEqual(this.id, other.id, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            __partialInstance: this.__isPartialInstance(),
            __definedAttributes: this.__getDefinedAttributes(),
            id: this._id,
        };
    }
}
