/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Attachment } from "cdes-api/dto/Attachment";
import { ReviewCycleResultOption } from "cdes-api/dto/ReviewCycleResultOption";
import { ReviewProtocolBaseJoin } from "cdes-api/joinDto/ReviewProtocolBaseJoin";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import { ContextJoin } from "cdes-api/joinDto/ContextJoin";

export class ReviewProtocolData {
    contextJoin : ContextJoin;

    globalActions : Set<string>;
    /** Actions as returned by at.cdes.impl.dao.ActionDAO.getObjectPlannerActions(Long, Long, String...)
     */
    objectPlannerIdToActions : Map<number,Map<string,boolean>>;
    baseJoin : ReviewProtocolBaseJoin;
    versionJoins : ReviewProtocolVersionJoin[];
    referenceJoins : ReviewProtocolVersionJoin[];
    documentVersionIdToCellResultIds : Map<number, Set<number>>;
    cellResultIdToResultJoin : Map<number, ReviewProtocolVersionJoin>;
    cellResultIdToNodeResultIdToNodeResult : Map<number,Map<number, ReviewProtocolVersionJoin>>;
    nodeResultIdToPositionResultIdToPositionResult : Map<number, Map<number, ReviewProtocolVersionJoin>>;
    idToPositionResultJoin : Map<number,ReviewProtocolVersionJoin>;
    idToResultOption : Map<number,ReviewCycleResultOption>;
    documentVersionIdToNodePositionJoins : Map<number,ReviewProtocolVersionJoin[]>;
    documentVersionIdToAttachedNodePositionJoins : Map<number,ReviewProtocolVersionJoin[]>;
    positionResultIdToAttachments : Map<number,Attachment[]>;
    documentVersionIdToCellConnectionResultJoins : Map<number,ReviewProtocolVersionJoin[]>;
    documentVersionIdToEntryResultJoins : Map<number,ReviewProtocolVersionJoin[]>;
    nodeResultIdToOebbCommentJoins : Map<number,ReviewProtocolVersionJoin[]>;
    svgImage : string;
    svgImageHeight : number;
}
