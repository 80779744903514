/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, reactive, ref, computed } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder, eagerComputed } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import ReviewPositionEditor from "./ReviewPositionEditor.vue";
import ReviewDocumentReferences from "./ReviewDocumentReferences.vue";
import ReviewEntryComments from "./ReviewEntryComments.vue";
import { ReviewContext } from "cdes-api/dto/review/ReviewContext";
import { IReviewNodePositionJoin } from "cdes-api/joinDto/IReviewNodePositionJoin";
import { IReviewResultOptionJoin } from "cdes-api/joinDto/IReviewResultOptionJoin";
import { IAttachmentJoin } from "cdes-api/joinDto/IAttachmentJoin";
import ReviewSaveInfo from "cdes-api/dto/review/ReviewSaveInfo";
import ReviewNodeEditInfo from "cdes-api/dto/review/ReviewNodeEditInfo";
import ReviewCommentSaveInfo from "cdes-api/dto/review/ReviewCommentSaveInfo";
import { AttachmentState } from "./ReviewAttachmentsWidget";
import { useValidationForm } from "cdes-vue/util/directives/ValidationForm";
import { ReviewData } from "cdes-api/dto/review/ReviewData";
import Transform from "cdes-vue/util/Transform";
import PasswordDialog from "cdes-vue/util/layout/PasswordDialog.vue";
import { useElementBreakpoints } from "cdes-vue/util/Size";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewPositionEditor,
        Transform,
        ReviewDocumentReferences,
        ReviewEntryComments,
        PasswordDialog,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        documentVersionId: {
            type: Number as PropType<number>,
        },
        taskId: {
            type: Number as PropType<number>,
        },
        reviewData: {
            type: [ReviewContext, Symbol] as PropType<ReviewContext | LoadingPlaceholder>,
        },
        overriding: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        disabled : {
            type : Boolean
        },
        taskStatus: {
            type: Number as PropType<number>,
        }
    },

    emits: ["update:reviewData"],

    setup(props, context) {
        const positions = computed(() => {
            if (props.reviewData === loadingPlaceholder) {
                return new Array(2).fill(loadingPlaceholder);
            } else {
                return props.reviewData.positions;
            }
        });

        const saveInfo = reactive({
            comments: [],
            nodes: eagerComputed(() => {
                return reactive(positions.value.map(position => ({
                    reviewCyclePositionResultId: position.reviewCyclePositionResultId,
                    comment: position.reviewCyclePositionResultComment ?? "",
                    isAuthorized: position.reviewCyclePositionResultIsAuthorized ?? false,
                    reviewCycleResultOptionId: position.reviewCyclePositionResultReviewCycleResultOptionId,
                    attachments: position.attachments?.map(attachment => Object.assign(new IAttachmentJoin(), attachment, {
                        state: AttachmentState.PERSISTENT,
                    })) ?? [],
                    deletedAttachmentIds: [],
                } as ReviewNodeEditInfo)));
            }),
        });

        const {
            transformer: formTransformer,
            onSubmit: validationOnSubmit,
            wasValidated,
        } = useValidationForm();

        const root = ref<HTMLElement | null>(null);

        useElementBreakpoints(root, {
            sm: 460,
            md: 580,
            lg: 780,
        }, "review-entry-bp");

        return {
            root,
            formTransformer,
            wasValidated,
            validationOnSubmit,
            loadingPlaceholder,
            positions,
            saveInfo,
        };
    },

    methods: {
        cancel() {
            this.ctx.taskAborted = true;
            this.ctx.editedTaskId = this.taskId;            
            this.$router.go(-1);
        },
        onSubmit(event: SubmitEvent) {
            event.preventDefault();
            const action = event.submitter?.dataset?.action ?? (event.target as HTMLElement).dataset.action;

            const saveInfo = {
                ...this.saveInfo,
                nodes: this.saveInfo.nodes.map(nodeEditInfo => ({
                    ...nodeEditInfo,
                    attachments: undefined,
                    temporaryAttachmentIds: nodeEditInfo.attachments
                    .flatMap(attachment => {
                        if (attachment.state === AttachmentState.TEMPORARY) {
                            return [attachment.id];
                        } else {
                            return [];
                        }
                    }),
                })),
            };

            if (action === "save" || action === "implicit" || action == "saveAndExit") {
                this.ctx.reviewService.saveReview(saveInfo, this.taskId, this.ctx.activeOrganisationPersonId, this.overriding)
                    .then(reviewData => {
                        this.updateReviewData(reviewData);
                        if (action == "saveAndExit") {
                            this.$router.go(-1);
                        }
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            } else if (action === "save-to-signature-folder") {
                this.ctx.reviewService.saveReviewToSignatureFolder(saveInfo, this.taskId, this.ctx.activeOrganisationPersonId, this.overriding)
                .then((reviewData) => {
                    this.updateReviewData(reviewData);
                    this.ctx.editedTaskId = this.taskId;
                    this.$router.go(-1);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            } else if (action === "sign") {
                (this.$refs.passwordDialog as InstanceType<typeof PasswordDialog>).getPassword()
                .then(password => {
                    if (password != null) {
                        return this.ctx.reviewService.saveAndSignReview(saveInfo, this.taskId, this.ctx.activeOrganisationPersonId, password, this.overriding)
                        .then((reviewData) => {
                            this.updateReviewData(reviewData);
                            this.ctx.editedTaskId = this.taskId;
                            this.$router.go(-1);
                        }, err => {
                            ErrorHelper.processError(this.$t, this.$d, err, this.$t("review.review.entry.signPasswordError"));
                        });
                    } else {
                        return undefined;
                    }
                }, err => {
                    ErrorHelper.processPasswordError(this.$t, this.$d, err);
                });
            } else {
                throw new Error("Unknown action " + action);
            }
        },
        updateReviewData(data: ReviewData): void {
            this.$emit("update:reviewData", new ReviewContext(data));
        },
    },
});
