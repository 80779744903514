/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent } from "vue";
import LabelListWidget from "./LabelListWidget.vue";
import { LabelInfo } from 'cdes-api/dto/review/LabelInfo';
import { Organisation } from 'cdes-api/dto/Organisation';
import CheckBox from 'cdes-vue/util/form/CheckBox.vue';
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { LabelPageInfo } from "cdes-api/dto/review/LabelPageInfo";

export default defineComponent({

    components: {
        LabelListWidget,
        CheckBox
    },

    data() {
        return {
            organisationId : undefined,
            organisation : {name: ""},
            items: [] as LabelInfo[],
            hideInactive: false,
            menuSection : this.$route.meta.section,
            labelPageInfo : null
        };
    },
    mounted() {
        this.organisationId = this.ctx.activeNetwork.maintainerId;

        this.ctx.reviewService.getLabelPageInfo(this.ctx.activeOrganisationPersonId, this.ctx.activeNetworkId)
            .then((labelPageInfo : LabelPageInfo) => {
                this.labelPageInfo = labelPageInfo;
                this.organisation = labelPageInfo.organisation;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });

/*        this.ctx.organisationService.getOrganisation(this.organisationId).then((organisation : Organisation) => {
            if (organisation?.name) {
                this.organisation = organisation;
            }
        }, err => {
            ErrorHelper.processError(this.$t, this.$d, err);
        });*/

        this.doReload();
    },
    methods: {
        create() : void {
            let targetName : string = (this.menuSection == "plan" ? "labelEdit" : "labelEditAdmin");
            this.$router.push({
                name: targetName
            })
        },
        doReload() : void {
            this.ctx.reviewService.getLabelInfos(this.ctx.activeOrganisationPersonId, this.organisationId).then((infos : LabelInfo[]) => {
                this.items = infos;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        },
        removeLabel(labelId : number) : void {
            let idx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                let item : LabelInfo = this.items[n] as LabelInfo;
                if (item.label.id == labelId) {
                    idx = n;
                }
            }
            if (idx != null) {
                this.items.splice(idx, 1);
            }
        }
    }
});
