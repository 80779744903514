export type Comparator<T> = (a: T, b: T) => number;

export function defaultCmp<T>(a: T, b: T): number {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

export function keyToCmp<T, K>(key: (val: T) => K, prevCmp: Comparator<K> = defaultCmp): Comparator<T> {
    return (a,b) => prevCmp(key(a), key(b));
}

export function invertCmp<T>(cmp: Comparator<T>): Comparator<T> {
    return (a,b) => -cmp(a,b);
}

// Sorts in the order T, null, undefined
export function nullsLast<T>(cmp: Comparator<T>): Comparator<T | null | undefined> {
    return (a, b) => {
        if (a === undefined && b === undefined) {
            return 0;
        }
        if (a === undefined && b === null) {
            return -1;
        }
        if (a === null && b === undefined) {
            return 1;
        }
        if (a === null && b === null) {
            return 0;
        }
        // b has to be non null
        if (a === null || a === undefined) {
            return -1;
        }
        // a has to be non null
        if (b === null || b === undefined) {
            return 1;
        }
        return cmp(a, b);
    };
}

// Sorts in the order undefined, null, T
export function nullsFirst<T>(cmp: Comparator<T>): Comparator<T | null | undefined> {
    return (a, b) => {
        if (a === undefined && b === undefined) {
            return 0;
        }
        if (a === undefined && b === null) {
            return 1;
        }
        if (a === null && b === undefined) {
            return -1;
        }
        if (a === null && b === null) {
            return 0;
        }
        // b has to be non null
        if (a === null || a === undefined) {
            return 1;
        }
        // a has to be non null
        if (b === null || b === undefined) {
            return -1;
        }
        return cmp(a, b);
    };
}

export function collation(locale: string): Comparator<string> {
    return new Intl.Collator(locale).compare;
}
