/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { OrderAddress } from "cdes-api/dto/OrderAddress";
import { ReviewCycle } from "cdes-api/dto/ReviewCycle";
import { ReviewCycleCell } from "cdes-api/dto/ReviewCycleCell";
import { ReviewCycleNode } from "cdes-api/dto/ReviewCycleNode";
import { PersonJoin } from "cdes-api/joinDto/PersonJoin";
import { ReviewCycleTreeInfo } from "../review/ReviewCycleTreeInfo";
import { PlotOrderTemplateInfo } from "./PlotOrderTemplateInfo";

export class PlotOrderTemplateEditInfo {
    templateInfo : PlotOrderTemplateInfo;

    orderAddresses : OrderAddress[];
    plotterJoins : PersonJoin[];
    deliveryPersonJoins : PersonJoin[];
    reviewCycleTreeInfo : ReviewCycleTreeInfo;
}
