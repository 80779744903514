import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class Attachment extends Persistent {

    // Properties: (placed here as documentation)
    //
    // filename : (string | null | undefined);
    // filetype : (string | null | undefined);
    // comment : (string | null | undefined);
    // digest : (string | null | undefined);
    // isRedliningDelta : boolean;
    // digestCalculatorId : (number | null | undefined); --> DigestCalculator
    // documentVersionId : (number | null | undefined); --> DocumentVersion
    // reviewCycleNodeId : (number | null | undefined); --> ReviewCycleNode
    // projectParticipantId : (number | null | undefined); --> ProjectParticipant
    // originalName : (string | null | undefined);
    // created : (number | null | undefined);
    // tempKey : (number | null | undefined);

    private _filename : (string | null | undefined);
    get filename() : (string | null | undefined) {
        this.__checkAttributeDefinition(13, "filename");
        return this._filename;
    }
    set filename(filename : (string | null | undefined)) {
        this._filename = filename;
    }

    private _filetype : (string | null | undefined);
    get filetype() : (string | null | undefined) {
        this.__checkAttributeDefinition(14, "filetype");
        return this._filetype;
    }
    set filetype(filetype : (string | null | undefined)) {
        this._filetype = filetype;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(15, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _digest : (string | null | undefined);
    get digest() : (string | null | undefined) {
        this.__checkAttributeDefinition(16, "digest");
        return this._digest;
    }
    set digest(digest : (string | null | undefined)) {
        this._digest = digest;
    }

    private _isRedliningDelta : boolean;
    get isRedliningDelta() : boolean {
        this.__checkAttributeDefinition(17, "isRedliningDelta");
        return this._isRedliningDelta;
    }
    set isRedliningDelta(isRedliningDelta : boolean) {
        this._isRedliningDelta = isRedliningDelta;
    }

    private _digestCalculatorId : (number | null | undefined);
    get digestCalculatorId() : (number | null | undefined) {
        this.__checkAttributeDefinition(18, "digestCalculatorId");
        return this._digestCalculatorId;
    }
    set digestCalculatorId(digestCalculatorId : (number | null | undefined)) {
        this._digestCalculatorId = digestCalculatorId;
    }

    private _documentVersionId : (number | null | undefined);
    get documentVersionId() : (number | null | undefined) {
        this.__checkAttributeDefinition(19, "documentVersionId");
        return this._documentVersionId;
    }
    set documentVersionId(documentVersionId : (number | null | undefined)) {
        this._documentVersionId = documentVersionId;
    }

    private _reviewCycleNodeId : (number | null | undefined);
    get reviewCycleNodeId() : (number | null | undefined) {
        this.__checkAttributeDefinition(20, "reviewCycleNodeId");
        return this._reviewCycleNodeId;
    }
    set reviewCycleNodeId(reviewCycleNodeId : (number | null | undefined)) {
        this._reviewCycleNodeId = reviewCycleNodeId;
    }

    private _projectParticipantId : (number | null | undefined);
    get projectParticipantId() : (number | null | undefined) {
        this.__checkAttributeDefinition(21, "projectParticipantId");
        return this._projectParticipantId;
    }
    set projectParticipantId(projectParticipantId : (number | null | undefined)) {
        this._projectParticipantId = projectParticipantId;
    }

    private _originalName : (string | null | undefined);
    get originalName() : (string | null | undefined) {
        this.__checkAttributeDefinition(22, "originalName");
        return this._originalName;
    }
    set originalName(originalName : (string | null | undefined)) {
        this._originalName = originalName;
    }

    private _created : (number | null | undefined);
    get created() : (number | null | undefined) {
        this.__checkAttributeDefinition(23, "created");
        return this._created;
    }
    set created(created : (number | null | undefined)) {
        this._created = created;
    }

    private _tempKey : (number | null | undefined);
    get tempKey() : (number | null | undefined) {
        this.__checkAttributeDefinition(24, "tempKey");
        return this._tempKey;
    }
    set tempKey(tempKey : (number | null | undefined)) {
        this._tempKey = tempKey;
    }


    public static constructPartialInstance() : Attachment {
        let instance = new Attachment();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : Attachment {
        let instance = new Attachment();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : Attachment) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.filename, other.filename, "String")
            && DtoHelper.areMembersEqual(this.filetype, other.filetype, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.digest, other.digest, "String")
            && DtoHelper.areMembersEqual(this.isRedliningDelta, other.isRedliningDelta, "Boolean")
            && DtoHelper.areMembersEqual(this.digestCalculatorId, other.digestCalculatorId, "Long")
            && DtoHelper.areMembersEqual(this.documentVersionId, other.documentVersionId, "Long")
            && DtoHelper.areMembersEqual(this.reviewCycleNodeId, other.reviewCycleNodeId, "Long")
            && DtoHelper.areMembersEqual(this.projectParticipantId, other.projectParticipantId, "Long")
            && DtoHelper.areMembersEqual(this.originalName, other.originalName, "String")
            && DtoHelper.areMembersEqual(this.created, other.created, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.tempKey, other.tempKey, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            filename: this._filename,
            filetype: this._filetype,
            comment: this._comment,
            digest: this._digest,
            isRedliningDelta: this._isRedliningDelta,
            digestCalculatorId: this._digestCalculatorId,
            documentVersionId: this._documentVersionId,
            reviewCycleNodeId: this._reviewCycleNodeId,
            projectParticipantId: this._projectParticipantId,
            originalName: this._originalName,
            created: this._created,
            tempKey: this._tempKey,
        };
    }
}
