/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, SetupContext } from "vue";
import { Attachment } from "cdes-api/dto/Attachment";
import TemporaryAttachment from "cdes-api/dto/document/TemporaryAttachment";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import ReviewAttachmentRow, { UploadedAttachment, AttachmentState } from "./ReviewAttachmentRow.vue";
export type { UploadedAttachment } from "./ReviewAttachmentRow.vue";
export { AttachmentState } from "./ReviewAttachmentRow.vue";
import { randomUuid } from "cdes-vue/util/Uuid";

export default defineComponent({
    components: {
        ReviewAttachmentRow,
    },

    props: {
        modelValue: {
            type: Array as PropType<UploadedAttachment[]>,
            default: () => [],
        },
        deletedAttachmentIds: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        disabled : {
            type : Boolean
        }
    },

    emits: ["update:modelValue", "update:deletedAttachmentIds"],

    setup(props, context) {
        const attachments = makePropWithValue(props, context as SetupContext<"update:modelValue"[]>, "modelValue", arr => [...arr], { deep: true });
        const ourDeletedAttachmentIds = makePropWithValue(props, context as SetupContext<"update:deletedAttachmentIds"[]>, "deletedAttachmentIds", arr => [...arr], { deep: true });

        return {
            attachments,
            ourDeletedAttachmentIds,
        };
    },

    methods: {
        addFile(file: File): void {
            this.attachments.push({
                state: AttachmentState.TO_UPLOAD,
                id: randomUuid(),
                file,
            });
        },
        removeAttachment(index: number): void {
            const [deletedAttachment] = this.attachments.splice(index, 1);
            if (deletedAttachment.state === AttachmentState.PERSISTENT) {
                this.ourDeletedAttachmentIds.push(deletedAttachment.attachmentId);
            }
        },
    },
});
