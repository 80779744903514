/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";

export default class ReviewProtocolContext extends ReviewProtocolData {
    public documentVersionsById: Map<number, ReviewProtocolVersionJoin>;

    public constructor(private data: ReviewProtocolData) {
        super();
        Object.assign(this, data);
        this.documentVersionsById = new Map(data.versionJoins.map(versionJoin => [versionJoin.documentVersionId, versionJoin]));
    }

    public isLastVersion(documentVersionId: number): boolean {
        let lastVersionJoin = this.data.versionJoins[this.data.versionJoins.length - 1];
        if (lastVersionJoin.documentVersionReactivatedDocumentVersionId != null) {
            lastVersionJoin = this.documentVersionsById.get(lastVersionJoin.documentVersionReactivatedDocumentVersionId);
        }
        return lastVersionJoin.documentVersionId === documentVersionId;
    }

    public hasPositionResultAttachments(positionResultId: number): boolean {
        return this.positionResultIdToAttachments.get(positionResultId)?.length > 0;
    }
}
