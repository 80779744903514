/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, ref, reactive, toRef, provide, computed, watchEffect } from "vue";
import { LoadingPlaceholder, loadingPlaceholder, frozenRef, eagerComputed } from "cdes-vue/util/Prop";
import TreeItem, { TreeItemData, TreeModel, useCommon, State, startLoading, commonOptionsKey, RenderInfo, CommonOptions, getLoadingChildren } from "./TreeItem.vue";
import ClassType from "../Class";
import HashMap from "clazzes-core/util/HashMap";

export type { TreeModel } from "./TreeItem.vue";

const missingSentinel = Symbol();

export default defineComponent({
    components: {
        TreeItem,
    },

    props: {
        model: {
            type: Object as PropType<TreeModel<unknown>>,
        },
        /*selectedPath: {
            type: Array as PropType<TreePath | null>,
            default: () => null,
        },*/
        defaultKey: {
            type: undefined as PropType<unknown | null | typeof missingSentinel>,
            default: missingSentinel,
        },
        selectedData: {
            type: undefined as PropType<unknown | null | typeof missingSentinel>,
            default: missingSentinel,
        },
        selectedKey: {
            type: undefined as PropType<unknown | null | typeof missingSentinel>,
            default: missingSentinel,
        },
        focusable: {
            type: Boolean as PropType<boolean>,
            default: () => true,
        },
        customItemClass: {
            type: [Function, String, Object, Array] as PropType<((info: RenderInfo<unknown>) => ClassType) | ClassType>,
        },
        customItemLabelClass: {
            type: [Function, String, Object, Array] as PropType<((info: RenderInfo<unknown>) => ClassType) | ClassType>,
        },
        selectionFollowsFocus: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        action: {
            type: Function as PropType<((data: unknown) => void) | undefined | null>,
            default: null,
        },
    },

    emits: ["update:selectedKey", "update:selectedData", "update:focusedId", "activate"],

    setup(props, { emit }) {
        const focusedData = ref<TreeItemData<unknown> | null | undefined>(null);

        const ourSelectedData = (() => {
            if (props.selectionFollowsFocus) {
                return frozenRef(focusedData, computed(() => focusedData.value.disabled));
            } else {
                const ret = ref<TreeItemData<unknown> | null | undefined>(null);

                watchEffect(() => {
                    if (ret.value != null) {
                        focusedData.value = ret.value;
                    }
                });

                return ret;
            }
        })();

        // @ts-ignore
        const commonOptions: CommonOptions<unknown, unknown> = reactive({
            model: toRef(props, "model"),
            focusable: toRef(props, "focusable"),
            customItemClass: toRef(props, "customItemClass"),
            customItemLabelClass: toRef(props, "customItemLabelClass"),
            selectionFollowsFocus: toRef(props, "selectionFollowsFocus"),
            action: toRef(props, "action"),
            allNodes: new Set(),
            allNodesByKey: new HashMap(props.model.keyEquals?.bind(props.model), props.model.keyHash?.bind(props.model)),
            selectedData: ourSelectedData,
            focusedData,
        } as unknown as CommonOptions<unknown, unknown>) as CommonOptions<unknown, unknown>;

        provide(commonOptionsKey, commonOptions);

        const loadingItems = startLoading(commonOptions, []);

        const items = computed(() => loadingItems.value === loadingPlaceholder ? getLoadingChildren([]) : loadingItems.value);

        const common = useCommon<unknown, unknown>({
                getChildren: () => items.value as TreeItemData<unknown>[],
                parent: null,
                commonOptions,
            });

        const getKey = (data: unknown) => {
            if (props.model.getKey != null) {
                return props.model.getKey(data);
            } else {
                return data;
            }
        };

        const keyEquals = (keyA: unknown, keyB: unknown): boolean => {
            if (props.model.keyEquals) {
                return props.model.keyEquals(keyA, keyB);
            } else {
                return keyA === keyB;
            }
        };

        const findItem = (key: unknown) => {
            return commonOptions.allNodesByKey.get(key);
        };

        const findFirstEnabledItem = (children = items.value) => {
            for (const child of children) {
                if (!child.disabled) {
                    return child;
                }

                if ("children" in child) {
                    const foundItem = findFirstEnabledItem(child.children);
                    if (foundItem != null) {
                        return foundItem;
                    }
                }
            }
            return null;
        };

        const foundSelectedKey = ref(false);

        const trueSelectedKey = eagerComputed(() => {
            foundSelectedKey.value = false;
            if (props.selectedData !== missingSentinel) {
                return getKey(props.selectedData);
            } else if (props.selectedKey !== missingSentinel) {
                return props.selectedKey;
            } else {
                return props.defaultKey;
            }
        });

        watchEffect(() => {
            if (focusedData.value == null || !commonOptions.allNodes.has(focusedData.value as TreeItemData<unknown>)) {
                if (ourSelectedData.value == null || !commonOptions.allNodes.has(ourSelectedData.value as TreeItemData<unknown>)) {
                    const defaultItem = props.defaultKey === missingSentinel ? null : findItem(getKey(props.defaultKey));
                    if (defaultItem != null) {
                        focusedData.value = defaultItem;
                    } else {
                        focusedData.value = items.value?.[0] ?? null;
                    }
                } else {
                    focusedData.value = ourSelectedData.value;
                }
            }
        });

        watchEffect(() => {
            if (foundSelectedKey.value && commonOptions.allNodes.has(ourSelectedData.value as TreeItemData<unknown>)) {
                return;
            }

            if (ourSelectedData.value?.state === State.LOADING) {
                throw new Error("selected loading item even though it should be disabled.");
            }

            let found = false;
            if (ourSelectedData.value == null
                || !commonOptions.allNodes.has(ourSelectedData.value as TreeItemData<unknown>)
                || (trueSelectedKey.value !== missingSentinel && !keyEquals(trueSelectedKey.value, getKey(ourSelectedData.value.data)))) {

                let foundItem: TreeItemData<unknown> | null;

                if (trueSelectedKey.value === missingSentinel) {
                    foundItem = findFirstEnabledItem();
                } else {
                    foundItem = findItem(trueSelectedKey.value);
                    if (foundItem == null && props.defaultKey !== missingSentinel) {
                        foundItem = findItem(props.defaultKey);
                    }
                    if (foundItem == null) {
                        foundItem = findFirstEnabledItem();
                    }
                }

                found = foundItem != null;

                ourSelectedData.value = foundItem;
            } else {
                found = true;
            }

            foundSelectedKey.value = found;
        });

        // The only time where we should have nothing selected is if we are currently loading the root.
        // In that case we want to stop that intermediate state from going outside of this component.
        watchEffect(() => {
            const id = focusedData.value?.ref?.id;
            if (id != null) {
                emit("update:focusedId", id);
            }
        });


        return {
            ...common,
            commonOptions,
            focusedData,
            items,
            ourSelectedData,
            findItem,
            getKey,
            keyEquals,
        };
    },

    watch: {
        ourSelectedData: {
            handler(ourSelectedData: TreeItemData<unknown> | null): void {
                if (ourSelectedData == null) {
                    return;
                }
                if (ourSelectedData.disabled
                || ourSelectedData.state === State.LOADING) {
                    throw new Error("selected disabled element.");
                }
                const data = ourSelectedData?.data;
                const key = this.getKey(data);
                if ((key != null || this.selectedKey != null) && !this.keyEquals(key, this.selectedKey)) {
                    this.$emit("update:selectedKey", key);
                }
                if ((data != null || this.selectedData != null) && data !== this.selectedData) {
                    this.$emit("update:selectedData", data);
                }
            }
        },
    },

    methods: {
        up(): void {
            this.focusedData?.ref?.up?.();
        },
        down(): void {
            this.focusedData?.ref?.down?.();
        },
        prev(): void {
            this.focusedData?.ref?.prev?.();
        },
        next(): void {
            this.focusedData?.ref?.next?.();
        },
        activate(): void {
            this.focusedData?.ref?.activate?.();
        },
        expandSiblings(): void {
            this.focusedData?.ref?.expandSiblings?.();
        },
        toggle(): void {
            this.focusedData?.ref?.toggle();
        },
        onFocusOut(): void {
            if (this.ourSelectedData != null) {
                this.focusedData = this.ourSelectedData;
            } else {
                this.focusedData = this.items[0] ?? null;
            }
        },
    },
});
