/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { PlotOrderItemTemplateInfo } from "cdes-api/dto/plot/PlotOrderItemTemplateInfo";
import { PlotDeliveryTarget } from "cdes-vue/voc/plot/PlotDeliveryTarget";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        items : {
            type : Array as PropType<PlotOrderItemTemplateInfo[]>,
        }
    },

    methods : {
        isOrganisationTarget(item : PlotOrderItemTemplateInfo) : boolean {
            return item.target == PlotDeliveryTarget.PROJECT_PARTICIPATION;
        },

        isReviewCycleTarget(item : PlotOrderItemTemplateInfo) : boolean {
            return item.target == PlotDeliveryTarget.REVIEW_CYCLE_NODE;
        }
    }
});
