/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, computed, ref, watch, watchEffect } from "vue";
import { asyncEagerComputed, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import UserPolicyInfo from "cdes-api/dto/person/UserPolicyInfo";



export default defineComponent({
    components: {
        LoadedOrPlaceholder,
    },

    setup() {
        const ctx = useCtx();

        const info = asyncEagerComputed(() => {
            return ctx.resetPasswordService.getUserPolicyInfo(ctx.person.id);
        }, loadingPlaceholder);

        const orgPersonIds = computed(() => {
            return info.value === loadingPlaceholder ? ctx.getOwnOrganisationPersonJoins().map(join => join.organisationPersonId) : info.value.orgPersonIds;
        });

        const link = computed(() => {
            return '/cdes/svc/userPolicy?'+ (new URLSearchParams(orgPersonIds.value.map(id => ["sp", "L" + id])));
        });

        return {
            info,
            link,
            orgPersonIds,
        };
    },
});
