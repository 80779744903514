/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import { ReviewData } from "cdes-api/dto/review/ReviewData";
import { IReviewCommentNodeJoin } from "cdes-api/joinDto/IReviewCommentNodeJoin";
import { IReviewCommentResultJoin } from "cdes-api/joinDto/IReviewCommentResultJoin";
import { IReviewCommentOptionJoin } from "cdes-api/joinDto/IReviewCommentOptionJoin";
import ReviewCommentSaveInfo from "cdes-api/dto/review/ReviewCommentSaveInfo";
import ReviewCommentTextArea from "./ReviewCommentTextArea.vue";
import DOMPurify from "dompurify";
import { ReviewCycleCommentOption } from "cdes-api/dto/ReviewCycleCommentOption";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewCommentTextArea,
    },

    props: {
        documentVersionId: {
            type: Number as PropType<number>,
        },
        reviewData: {
            type: [Object, Symbol] as PropType<ReviewData | LoadingPlaceholder>,
        },
        node: {
            type: [Object, Symbol] as PropType<IReviewCommentNodeJoin | LoadingPlaceholder>,
        },
        result: {
            type: [Object, Symbol] as PropType<IReviewCommentResultJoin | LoadingPlaceholder>,
        },
        modelValue: {
            type: Object as PropType<ReviewCommentSaveInfo>,
        },
        disabled : {
            type : Boolean
        }
    },

    computed: {
        options(): LoadingPlaceholder[] | IReviewCommentOptionJoin[] {
            if (this.reviewData === loadingPlaceholder || this.node === loadingPlaceholder) {
                return new Array(2).fill(loadingPlaceholder);
            } else {
                return this.reviewData.commentOptionsByCommentTypeId.get(this.reviewData.reviewCycleNode.reviewCycleNodeReviewCycleCommentTypeId);
            }
        },
        redliningLink(): string {
            if (this.reviewData === loadingPlaceholder || this.result === loadingPlaceholder || this.node === loadingPlaceholder) {
                return 'javascript:void(0)';
            } else {
                return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionRedliningMimeSource", [
                    "" + this.node.commentedVersionId,
                    "" + this.result.commentResultId,
                    "" + (this.reviewData.executingParticipationJoin.overridingParticipantId ?? this.reviewData.executingParticipationJoin.projectParticipantId),
                    "l" + Date.now(),
                ]);
            }
        },
    },

    methods: {
        sanitize: DOMPurify.sanitize,
        optionChosen(option : IReviewCommentOptionJoin) {
            if (option.reviewCycleCommentOptionFinishCellFlag) {
                window.alert(this.$t("review.review.entry.breakWarning"));
            }
        }        
    },

    emits: ["update:modelValue"],

    setup(props, context) {
        const value = makePropWithValue(props, context, "modelValue", v => ({...v}), { deep: true });

        return {
            loadingPlaceholder,
            value,
        };
    },
});
