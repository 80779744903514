/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { LoadingPlaceholder, loadingPlaceholder, mapLoading, loadingArray } from "cdes-vue/util/Promise";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import { ReviewCycleCell } from "cdes-api/dto/ReviewCycleCell";
import { makePropWithValue } from "cdes-vue/util/Prop";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
    },

    props: {
        data: [Object, Symbol] as PropType<ReviewProtocolData | LoadingPlaceholder>,
        selectedVersionId: {
            type: Number as PropType<number>,
            defaultValue: () => null,
        },
    },

    emits: ["update:selectedVersionId", "versionClicked"],

    setup(props, context) {
        return {
            // @ts-ignore
            ourSelectedVersionId: makePropWithValue(props, context, "selectedVersionId"),
            loadingPlaceholder,
        };
    },

    computed: {
        cells(): ReviewProtocolVersionJoin[] | LoadingPlaceholder[] {
            if (this.data === loadingPlaceholder) {
                return new Array(4).fill(loadingPlaceholder);
            } else {
                return this.data.versionJoins;
            }
        },
    },
});
