/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
    },

    computed: {
        references(): ReviewProtocolVersionJoin[] | LoadingPlaceholder[] {
            if (this.context === loadingPlaceholder) {
                return new Array(1).fill(loadingPlaceholder);
            } else {
                return this.context.referenceJoins;
            }
        },
    }
});
