import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "onKeydown"]
const _hoisted_4 = { class: "invalid-feedback" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.title,
    ref: "dialog",
    lazy: "",
    onHide: _ctx.onHide,
    extraClasses: _ctx.extraClasses
  }, {
    default: _withCtx(({ submit }) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "upperContent"),
        _createElementVNode("form", {
          ref: "classElement",
          class: _normalizeClass({ 'was-validated': _ctx.wasValidated })
        }, [
          _createElementVNode("label", {
            for: _ctx.$id('password'),
            class: "form-label"
          }, _toDisplayString(_ctx.$t('general.password')), 9, _hoisted_2),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: _ctx.$id('password'),
            type: "password",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
            onKeydown: _withKeys(($event: any) => (this.submit(submit)), ["enter"]),
            required: "",
            ref: "passwordInput",
            "data-autofocus": ""
          }, null, 40, _hoisted_3), [
            [_vModelText, _ctx.password]
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('passwordDialog.missingPassword')), 1)
        ], 2)
      ])
    ]),
    buttons: _withCtx(({ submit }) => [
      _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.$t('general.abort')), 1),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        ref: "submitButton",
        onClick: _withModifiers(($event: any) => (this.submit(submit)), ["prevent"])
      }, _toDisplayString(_ctx.submitLabel), 9, _hoisted_6)
    ]),
    _: 3
  }, 8, ["title", "onHide", "extraClasses"]))
}