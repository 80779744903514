/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

export class Address {
    personGivenName : string;
    personSurName : string;
    organisationName : string;
    postalAddress : string;
    postalCode : string;
    localityName : string;
    country : string;
}
