import { computed, ref, shallowRef, watch, watchEffect, Ref, WritableComputedRef, ComputedRef, UnwrapRef, SetupContext, WatchOptionsBase, WatchOptions, WatchCallback, onUnmounted, WatchEffect, unref, customRef } from "vue";
import { resolvedOrDefault, resolvedOrDefaultOrError, loadingPlaceholder, loadedOrDefault, mapLoading } from "cdes-vue/util/Promise";
export { loadingPlaceholder, mapLoading, loadedOrDefault } from "cdes-vue/util/Promise";
import debounce from "lodash-es/debounce";

export type LoadingPlaceholder = typeof loadingPlaceholder;


export function makePropWithValue<P extends (keyof T & string), T, U extends UnwrapRef<T[P]> | T[P] = UnwrapRef<T[P]>>(options: {
    props: T,
    context: SetupContext<`update:${P}`[]>,
    name: P,
    copier?: ((value: T[P]) => T[P]),
    watchOptions?: WatchOptions<false>
    ref?: Ref<U>
}): Ref<U>;
export function makePropWithValue<P extends (keyof T & string), T>(props: T, context: SetupContext<`update:${P}`[]>, name: P, copier?: ((value: T[P]) => T[P]), watchOptions?: WatchOptions<false>): Ref<UnwrapRef<T[P]>>;
export function makePropWithValue(...args: any[]): unknown {
    if (args.length === 1) {
        return makePropWithValueOptions(args[0]);
    } else {
        return makePropWithValueOptions({
            props: args[0],
            context: args[1],
            name: args[2],
            copier: args[3],
            watchOptions: args[4],
        });
    }
}

export function makePropWithValueOptions<P extends (keyof T & string), T, U extends UnwrapRef<T[P]> | T[P] = UnwrapRef<T[P]>>(options: {
    props: T,
    context: SetupContext<`update:${P}`[]>,
    name: P,
    copier?: ((value: T[P]) => T[P]),
    watchOptions?: WatchOptions<false>
    ref?: Ref<U>
}): Ref<U> {
    const {
        props,
        context,
        name,
        copier = (v => v),
        watchOptions,
    } = options;
    //console.log(`initial ${name} =`, props[name]);
    const value = options.ref == null ? ref(props[name]) : options.ref;
    if (options.ref != null) {
        value.value = props[name] as UnwrapRef<T[P]>;
    }
    let suppressInside = false;
    let suppressOutside = false;

    watch(
        () => props[name],
        (newValue) => {
            //console.log(`attempted outside ${name} =`, newValue);
            if (suppressOutside) {
                suppressOutside = false;
                return;
            }
            //console.log(`outside ${name} =`, newValue);
            newValue = copier(newValue);
            suppressInside = true;
            value.value = newValue as UnwrapRef<T[P]>;
        },
        watchOptions,
    );

    watch(
        () => value.value as T[P],
        (newValue) => {
            //console.log(`attempted inside ${name} =`, newValue);
            if (suppressInside) {
                suppressInside = false;
                return;
            }
            //console.log(`inside ${name} =`, newValue);
            suppressOutside = true;
            context.emit(`update:${name}`, copier(newValue));
        },
        watchOptions,
    );

    return value as Ref<U>;
}

export function eagerComputed<T>(get: (...args: Parameters<WatchEffect>) => T, options?: WatchOptionsBase): Ref<T> {
    // the value gets will get overwritten by watchEffect so initializing it with undefined is fine.
    const value = shallowRef<T>(undefined as T);

    watchEffect((...args) => value.value = get(...args), options);

    return value;
}

export function asyncEagerComputed<T, D>(get: () => Promise<T | D> | T | D, def: D): WritableComputedRef<T | D> {
    const computedRef: Ref<Ref<D | T> | D | T> = eagerComputed(() => resolvedOrDefault(get(), def));

    return computed({
        get() {
            return unref(computedRef.value);
        },
        set(newValue: T | D) {
            computedRef.value = newValue;
        },
    });
}

export function asyncEagerComputedWithError<T, D, ED>(get: () => Promise<T | D> | T | D, def: D, errDef: ED): { result: ComputedRef<T | D>, error: ComputedRef<ED | unknown>, retry: () => void } {
    const trueGetter = () => resolvedOrDefaultOrError(get(), def, errDef);

    const computedRef = eagerComputed(trueGetter);

    return {
        result: computed(() => computedRef.value.result.value),
        error:  computed(() => computedRef.value.error.value),
        retry: () => computedRef.value = trueGetter(),
    };
}

export function toLoadingRefArray<T extends object, P extends keyof T>(value: Ref<T | LoadingPlaceholder>, prop: P, idx : number): Ref<LoadingPlaceholder | T[P]> {
    return computed({
        get: () => {
            return mapLoading(a => a[prop][idx], value.value);
        },
        set: newValue => {
            if (newValue === loadingPlaceholder && value.value !== loadingPlaceholder) {
                console.warn("Ignoring attempt to mark property as loading.");
            }
            if (value.value === loadingPlaceholder && newValue !== loadingPlaceholder) {
                console.warn("Ignoring attempt to set value on loading object.");
            }

            if (value.value !== loadingPlaceholder && newValue !== loadingPlaceholder) {
                value.value[prop][idx] = newValue;
            }
        },
    });    
}

export function toLoadingRef<T extends object, P extends keyof T>(value: Ref<T | LoadingPlaceholder>, prop: P): Ref<LoadingPlaceholder | T[P]> {
    return computed({
        get: () => {
            return mapLoading(a => a[prop], value.value);
        },
        set: newValue => {
            if (newValue === loadingPlaceholder && value.value !== loadingPlaceholder) {
                console.warn("Ignoring attempt to mark property as loading.");
            }
            if (value.value === loadingPlaceholder && newValue !== loadingPlaceholder) {
                console.warn("Ignoring attempt to set value on loading object.");
            }

            if (value.value !== loadingPlaceholder && newValue !== loadingPlaceholder) {
                value.value[prop] = newValue;
            }
        },
    });
}

export function writableLoadedOrDefault<T, D>(ref: Ref<T | D | LoadingPlaceholder>, def: D): WritableComputedRef<T | D> {
    return computed({
        get: () => {
            return loadedOrDefault(ref.value, () => def);
        },
        set: newValue => {
            if (ref.value === loadingPlaceholder && newValue !== def) {
                console.warn("Ignoring attempt to set value on loading object.");
                return;
            }
            ref.value = newValue;
        },
    });
}

const missingSentinel = Symbol("missing");

export function debounceWatcher<V, OV>(callback: WatchCallback<V, OV>, ...debounceArgs: any): WatchCallback<V, OV> {
    let oldValue = missingSentinel as (typeof missingSentinel | OV);

    const debounced = (debounce((((newValue, newOldValue, ...rest) => {
        if (oldValue === missingSentinel) {
            oldValue = newOldValue;
        }
        const oldValueCopy = oldValue;
        oldValue = missingSentinel;
        callback(newValue, oldValueCopy, ...rest);
    }) as WatchCallback<V, OV>), ...debounceArgs));

    return (newValue, newOldValue, ...rest) => {
        if (oldValue === missingSentinel) {
            oldValue = newOldValue;
        }
        debounced(newValue, oldValue, ...rest);
    };
}

export function frozenRef<T>(value: Ref<T>, frozen: Ref<boolean>): Ref<T> {
    return customRef((track, trigger) => {
        let latest: typeof missingSentinel | T = missingSentinel;
        watchEffect(() => {
            if (!frozen.value || latest == missingSentinel) {
                const newValue = value.value;
                if (!Object.is(newValue, latest)) {
                    latest = value.value;
                    trigger();
                }
            }
        });

        return {
            get() {
                track();
                return latest as T;
            },
            set(newValue) {
                value.value = newValue;
                if (!Object.is(latest, newValue)) {
                    latest = newValue;
                    trigger();
                }
            },
        };
    });
}

interface PersistentOptionsBase<T> {
    key: string,
    serializer?: (val: T) => string,
    deserializer?: (str: string) => T,
    session?: boolean,
}

export type PersistentOptions<T> = (PersistentOptionsBase<T> & { ref: Ref<UnwrapRef<T> | T> }) | (PersistentOptionsBase<T> & { set: (val: T) => void, get: () => T});

const persistentValueSavers = new Set<() => void>();

document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
        for (const saver of persistentValueSavers) {
            saver();
        }
    }
});

export function usePersistentValue<T>(options: PersistentOptions<T>) {
    const { key, serializer = JSON.stringify, deserializer = JSON.parse, session = false } = options;

    const set = "ref" in options ? ((val: T) => options.ref.value = val) : options.set;
    const get = "ref" in options ? (() => options.ref.value) : options.get;

    const storage = session ? window.sessionStorage : window.localStorage;
    let initialStorageValue = storage.getItem(key)

    const def = get();

    if (initialStorageValue != null) {
        try {
            set(deserializer(initialStorageValue));
        } catch (e) {
            console.warn("Error when setting persistent value " + key + " from local/session storage.", e);
            storage.removeItem(key);
            set(def as T);
        }
    }

    const saver = () => {
        const v = get();
        if (v !== undefined) {
            storage.setItem(key, serializer(v));
        } else {
            storage.removeItem(key);
        }
    };

    persistentValueSavers.add(saver);

    onUnmounted(() => {
        saver();
        persistentValueSavers.delete(saver);
    });
}
