import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewStateXTable = _resolveComponent("ReviewStateXTable")!
  const _component_ReviewPositionTable = _resolveComponent("ReviewPositionTable")!
  const _component_ReviewCommentTable = _resolveComponent("ReviewCommentTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodeResults, (nodeResult) => {
      return (_openBlock(), _createElementBlock("section", {
        key: nodeResult.reviewCycleNodeResultId
      }, [
        _createVNode(_component_ReviewStateXTable, {
          context: _ctx.context,
          version: _ctx.version,
          nodeResult: nodeResult,
          "onUpdate:hidden": _cache[0] || (_cache[0] = ($event: any) => (_ctx.positionsHidden = $event))
        }, null, 8, ["context", "version", "nodeResult"])
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodeResults, (nodeResult) => {
      return (_openBlock(), _createElementBlock("section", {
        key: nodeResult.reviewCycleNodeResultId
      }, [
        _createVNode(_component_ReviewPositionTable, {
          context: _ctx.context,
          version: _ctx.version,
          nodeResult: nodeResult,
          "onUpdate:hidden": _cache[1] || (_cache[1] = ($event: any) => (_ctx.positionsHidden = $event))
        }, null, 8, ["context", "version", "nodeResult"])
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodeResults, (nodeResult) => {
      return (_openBlock(), _createElementBlock("section", {
        key: nodeResult.reviewCycleNodeResultId
      }, [
        _createVNode(_component_ReviewCommentTable, {
          context: _ctx.context,
          version: _ctx.version,
          nodeResult: nodeResult,
          "onUpdate:hidden": _cache[2] || (_cache[2] = ($event: any) => (_ctx.commentsHidden = $event))
        }, null, 8, ["context", "version", "nodeResult"])
      ]))
    }), 128)),
    (_ctx.positionsHidden && _ctx.commentsHidden)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('review.review.data.noEntries')), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}