export class ParticipationSecurityInfo {
    mayExportPdf : boolean;
    mayInsertParticipation : boolean;
    mayEmailAllParticipations : boolean;
    mayShowPerson : boolean;
    mayEmailParticipation : boolean;
    mayDeleteDeputy : boolean;
    mayInsertMeAsDeputy : boolean;
    mayShowHistory : boolean;
    mayDeleteParticipation : boolean;
    mayEditParticipation : boolean;
    mayShowParticipation : boolean;
}
