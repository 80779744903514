/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { PlotOrderTemplateInfo } from "cdes-api/dto/plot/PlotOrderTemplateInfo";
import { PlotOrderTemplatePageInfo } from "cdes-api/dto/plot/PlotOrderTemplatePageInfo";
import { PlotOrderTemplateSearchModel } from "cdes-api/dto/plot/PlotOrderTemplateSearchModel";
import PlotOrderTemplateListWidgetVue from "cdes-vue/plot/template/PlotOrderTemplateListWidget.vue"
import { useCtx } from "cdes-vue/util/Ctx";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { asyncEagerComputed, loadingPlaceholder } from "cdes-vue/util/Prop";
import { defineComponent } from "vue";

export default defineComponent({
    components : {
        PlotOrderTemplateListWidget : PlotOrderTemplateListWidgetVue
    },

    computed : {
        mayEditTemplate() : boolean {
            let plotOrderTemplatePageInfo : PlotOrderTemplatePageInfo = this.plotOrderTemplatePageInfo as PlotOrderTemplatePageInfo;
            return plotOrderTemplatePageInfo != null && plotOrderTemplatePageInfo.mayEditTemplate;
        },
        
        countLabelString() : string {
			let returnString = "";
			if (this.plotOrderTemplateInfos != null && !this.searchRunning){
                let count = this.plotOrderTemplateInfos.length;
				returnString = count != null ? (count + " " + this.$t("general.entries")) : "";
			} else if (this.searchRunning){
				returnString = this.$t("general.searchRuns");
			}
            return returnString;
        }

    },

    data() {
        return {
            plotOrderTemplatePageInfo : null,
            plotOrderTemplateInfos : [],
            prevQueriedProjectId : null,
            searchRunning : false
        };
    },

    mounted() {
        this.reload();
    },

    methods: {
        reload() {
            if (this.prevQueriedProjectId == null || this.ctx.activeProjectId != this.prevQueriedProjectId) {
                this.prevQueriedProjectId = this.ctx.activeProjectId;
                this.ctx.plotService.getPlotOrderTemplatePageInfo(this.ctx.activeOrganisationPersonId, this.ctx.activeProjectId)
                    .then((plotOrderTemplatePageInfo : PlotOrderTemplatePageInfo) => {
                        this.plotOrderTemplatePageInfo = plotOrderTemplatePageInfo;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });        

                let searchModel : PlotOrderTemplateSearchModel = new PlotOrderTemplateSearchModel();
                searchModel.projectId = this.ctx.activeProjectId;

                this.searchRunning = true;
                this.ctx.plotService.getPlotOrderTemplateInfos(this.ctx.activeOrganisationPersonId, searchModel)
                    .then((plotOrderTemplateInfos : PlotOrderTemplateInfo[]) => {
                        this.plotOrderTemplateInfos = plotOrderTemplateInfos;
                        this.searchRunning = false;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                        this.searchRunning = false;
                    });
            }
        },

        search(searchModel: PlotOrderTemplateSearchModel): void {
            this.ctx.plotService.getPlotOrderTemplateInfos(this.ctx.activeOrganisationPersonId, {
                ...searchModel,
                projectId: this.ctx.activeProjectId,
            })
                .then(infos => this.plotOrderTemplateInfos = infos, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        addTemplate(): void {
            this.$router.push({ name: "plotOrderTemplateNew", query: { projectId: this.ctx.activeProjectId } });
        },

        removePlotOrderTemplate(plotOrderTemplateId : number) : void {
            let rmIdx : number = null;
            for (let n = 0; n < this.plotOrderTemplateInfos.length; n++) {
                let templateInfo : PlotOrderTemplateInfo = this.plotOrderTemplateInfos[n];
                if (templateInfo.id == plotOrderTemplateId) {
                    rmIdx = n;
                }
            }
            if (rmIdx != null) {
                this.plotOrderTemplateInfos.splice(rmIdx, 1);
            }
        }
    }
});
