/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Address } from "cdes-api/dto/util/Address";
import { PlotOrderItemTemplateInfo } from "cdes-api/dto/plot/PlotOrderItemTemplateInfo";
import { PlotKindOfDelivery } from "cdes-api/voc/plot/PlotKindOfDelivery";
import { PlotTemplateResolver } from "cdes-api/voc/plot/PlotTemplateResolver";

export class PlotOrderTemplateInfo {
    id : number;
    projectId : number;
    name : string;
    description : string;
    comment : string;
    templateResolver : PlotTemplateResolver;
    invalidated : boolean;
    kindOfDelivery : PlotKindOfDelivery;
    plotterProjectParticipationId : number;
    reviewCycleId : number;
    plotterOrganisationAddress : Address;
    billingAddressId : number;
    billingAddress : Address;
    items : PlotOrderItemTemplateInfo[];
}
