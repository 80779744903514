<template>
  <div class="dropdown">
    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
            :disabled="this.disabled" 
            id="$id('deputyAddButton-' + item.organisation.id + '-' + rowPos)" data-bs-toggle="dropdown"
            @click.stop="this.toggleActive"
            aria-expanded="false" :title="$t('project.participation.list.deputy.add')" >
      <i class="bi bi-plus-lg"/>
    </button>
    <div class="dropdown-menu" aria-labelledby="$id('deputyAddButton-' + item.organisation.id + '-' + rowPos)">
      <li class="">
        <div class="d-flex flex-row align-items-center">
          <TextInput class="ms-3 deputyAddFilter" v-model="deputyAddFilter" mode="flex"
                     :label="this.$t('general.filter')"/>
          <CheckBox class="ms-4 me-2" :disabled="this.disabled" mode="flex"
                    v-model="offerParticipationsOfAllOrganisations"
                    :label="this.$t('project.participation.list.deputy.offerParticipationsOfAllOrganisations')"/>
        </div>
      </li>
      <li class="addDeputyList">
        <template v-for="consortiumPersonInfo in this.deputyOptions"
                  :key="consortiumPersonInfo.organisationPerson.id">
          <div class="d-flex flex-column addDeputyList">
            <a class="dropdown-item" @click="(() => {
                                             return () => this.addDeputy(this.participationInfo, consortiumPersonInfo);
                                             })()">
              <div v-if="this.offerParticipationsOfAllOrganisations">
                {{ this.getPersonOrganisationString(consortiumPersonInfo) }}
              </div>
              <div v-else>
                {{ this.getPersonOrganisationString(consortiumPersonInfo) }}
              </div>
            </a>
          </div>
        </template>
      </li>
    </div>
  </div>

<!--
  <div class="dropdown" :class="{'is-active': this.active.value}">
    <div class="dropdown-trigger">
      <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
              :disabled="this.disabled"
              aria-expanded="false" :title="$t('project.participation.list.deputy.add')"
              @click.stop="this.toggleActive">
        <i class="bi bi-plus-lg"/>        
      </button>
    </div>
    <div class="dropdown-menu" role="filter">
      <div class="dropdown-content" @click.stop>
        <li class="">
          <div class="d-flex flex-row align-items-center">
            <TextInput class="ms-3 deputyAddFilter" v-model="this.deputyAddFilter" mode="flex"
                       :label="this.$t('general.filter')"/>
            <CheckBox class="ms-4 me-2" :disabled="this.disabled" mode="flex"
                      v-model="this.offerParticipationsOfAllOrganisations"
                      :label="this.$t('project.participation.list.deputy.offerParticipationsOfAllOrganisations')"/>
          </div>
        </li>
      </div>
    </div>
  </div>-->
</template>

<style lang="scss">
 .addDeputyList {
 max-height : 10em;
 overflow : auto;
 }
 .deputyLoopParentDiv {
 padding-bottom : 3px;
 border-bottom : 1px solid rgb(211,211,211);
 }
 .deputyAddFilter {
 width : 15em;
 }
</style>

<script src="./DeputyAddDropdown.ts"/>
