import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-row flex-wrap align-items-center" }
const _hoisted_2 = { class: "pageCaption ps-2" }
const _hoisted_3 = { class: "countLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlotOrderTemplateListWidget = _resolveComponent("PlotOrderTemplateListWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("plot.template.page.caption")), 1),
      (this.mayEditTemplate)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "ms-auto me-3 btn btn-secondary",
            form: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.addTemplate()))
          }, _toDisplayString(_ctx.$t("general.add")), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PlotOrderTemplateListWidget, {
      items: this.plotOrderTemplateInfos,
      mayEditTemplate: this.mayEditTemplate,
      onRemovePlotOrderTemplate: _ctx.removePlotOrderTemplate
    }, null, 8, ["items", "mayEditTemplate", "onRemovePlotOrderTemplate"]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(this.countLabelString), 1)
  ], 64))
}