/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import { ProjectInfo } from "cdes-api/dto/project/ProjectInfo";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { ProjectStatus } from "cdes-vue/voc/project/ProjectStatus";
import { SubProject } from "cdes-api/dto/SubProject";
import { SubProjectInfo } from "cdes-api/dto/project/SubProjectInfo";
import { PlotOrderDeleteJoin } from "cdes-api/joinDto/PlotOrderDeleteJoin";
import { ProjectAccess } from "cdes-vue/voc/project/ProjectAccess";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ProjectListPageInfo } from "cdes-api/dto/project/ProjectListPageInfo";

export default defineComponent({
    components: {
        ParempiGrid,
    },

    props: {
        items: {
            type: Array as PropType<ProjectInfo[]>,
            default: () => [],
        },
        disabled : {
            type : Boolean
        },
        menuSection : {
            type : String
        },
        projectListPageInfo : {
            type : Object as PropType<ProjectListPageInfo>
        }
    },

    emits: ["changeProjectAccess", "makeProjectAdmin", "removeProject", "removeSubProject"],

    expose: ["addProject", "addProjectNew"],

    setup() {
        return {
            ProjectStatus,
        };
    },

    watch : {
        items(newProjectInfos : ProjectInfo[]) {
            window.setTimeout(() => {
                if (this.ctx.editedProjectId != null) {
                    let grid : typeof ParempiGrid = this.$refs.grid as (typeof ParempiGrid);
                    grid.scrollIntoView(this.ctx.editedProjectId);
                    this.ctx.editedProjectId = null;
                }
            }, 500);
        }
    },

    computed: {
        columns(): (ParempiColumn<ProjectInfo> | ColumnGroup<ProjectInfo>)[] {
            return [
                {
                    label : this.$t("general.network"),
                    children : [
                        ParempiColumn.asRowSpanSlot("network", "", undefined, this.rowSpanFct),
                    ],
                },
                {
                    label: this.$t("general.project"),
                    sort : (infoOne : ProjectInfo, infoTwo : ProjectInfo) => {
                        let codeOne = infoOne.projectCode;
                        let codeTwo = infoTwo.projectCode;
                        return codeOne != null && codeTwo != null ? codeOne.localeCompare(codeTwo) : 0;
                    },
                    children: [
                        ParempiColumn.asRowSpanSlot("projectDesignation", this.$t("general.designation"), undefined, this.rowSpanFct),
                        ParempiColumn.asRowSpanSlot("projectLeader", this.$t("general.projectLeader"), undefined, this.rowSpanFct),
                        ParempiColumn.asRowSpanSlot("created", this.$t("general.created"), undefined, this.rowSpanFct),
                        ParempiColumn.asRowSpanSlot("route", this.$t("general.route"), undefined, this.rowSpanFct),
                        ParempiColumn.asRowSpanSlot("status", this.$t("general.attribute"), undefined, this.rowSpanFct),
                    ],
                },
                {
                    label: this.$t("general.subProject"),
                    children: [
                        ParempiColumn.asRowSpanSlot("subProjectDesignation", this.$t("general.designation")),
                        ParempiColumn.asRowSpanSlot("comment", this.$t("general.column.actions")),
                    ],
                },
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), undefined, this.rowSpanFct),
            ];
        }
    },

    methods: {
        getSubProjectAccessQuestion(subProjectInfo : SubProjectInfo) : string {
            let subProjectString = (subProjectInfo.code ?? "") + " " + (subProjectInfo.name ?? "");
            let rawQuestion = subProjectInfo.readOnly ? "project.list.subProject.readWriteQuestion"
                : "project.list.subProject.readOnlyQuestion";
            return this.$t(rawQuestion, { subProject : subProjectString });
        },

        openParticipationPage(item : ProjectInfo) : void {
            this.$router.push({
                path: '/plan/teilnehmer',
                query: { contextNetworkId: item.networkId, contextProjectId: item.projectId }
            });
        },

        rowSpanFct(item: ProjectInfo, pos: ParempiRowPos): number {
            if (pos.getSubRow(0) !== 0) {
                return null;
            } else {
                return item.subProjects.length;
            }
        },
        
        getSubprojectForRowPos(item : ProjectInfo, pos : ParempiRowPos) : SubProjectInfo {
            let subRow : number = pos.getSubRow();
            let subs : SubProjectInfo[] = item.subProjects;
            subs.sort((sp0 : SubProjectInfo, sp1 : SubProjectInfo) => {
                let res = sp0.code != null && sp1.code != null ? sp0.code.localeCompare(sp1.code) : 0;
                //now sort according to number
                if (res == 0) {
					if (sp0.number != null && sp1.number != null)
						res = sp0.number<sp1.number?-1:sp0.number>sp1.number?1:0;
					else if (sp0.number == null)
						res = -1;
					else
						res = 1;
				}
                return res;
            });
            return subs[subRow];
        },
        
        rowPosFactory(item: ProjectInfo): ParempiRowPos {
            return new ParempiRowPos(Math.max(item.subProjects.length, 1));
        },
        rowPosIncrementor(item: ProjectInfo, rowPos: ParempiRowPos): void {
            rowPos.increment(0);
        },
        editSubProject(item: SubProjectInfo): void {
            this.$router.push({ name: "editSubProject", params: { id: "" + item.id } });
        },
        addSubProject(item: ProjectInfo): void {
            this.$router.push({ name: "newSubProject", query: { projectId: "" + item.projectId } });
        },
        editProject(item: ProjectInfo): void {
            let targetName : string = (this.menuSection == "plan" ? "editProject" : "editProjectAdmin");
            this.$router.push({ name: targetName, params: { id: item.projectId } });
        },
        deleteProject(item: ProjectInfo): void {
            let projectCode : string = item.projectCode;
            let projectName : string = item.projectName;
            let projectId : number = item.projectId;
            if (window.confirm(this.$t("project.list.reallyDeleteQuestion", {
                code : projectCode,
                name : projectName
            }))) {
                this.ctx.plotService.getPlotOrderDeleteJoinsPreventingProjectInvalidate(this.ctx.activeOrganisationPersonId,
                                                                                        projectId)
                    .then((plotOrderDeleteJoins : PlotOrderDeleteJoin[]) => {
                        if (plotOrderDeleteJoins.length > 0) {
                            let somePlotOrderDeleteJoin = plotOrderDeleteJoins[0];
                            let subProjectCode = somePlotOrderDeleteJoin.subProjectCode;
                            let subProjectName = somePlotOrderDeleteJoin.subProjectName;
                            window.alert(this.$t("project.list.cannotDeletePlotOrders", {
                                subProject : subProjectCode + " " + subProjectName
                            }));
                        } else {
                            this.ctx.projectService.deleteProject(this.ctx.activeOrganisationPersonId, projectId)
                                .then(() => {
                                    console.info("Successfully deleted project [" + projectId + ":"
                                        + projectCode + ":" + projectName + "]");
                                    this.$emit("removeProject", projectId);
                                }, err => {
                                    ErrorHelper.processError(this.$t, this.$d, err);
                                });
                        }
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        },        
        makeProjectAdmin(item : ProjectInfo) : void {
            let projectCode : string = item.projectCode;
            let projectName : string = item.projectName;
            let projectId : number = item.projectId;
            if (window.confirm(this.$t("project.list.reallyMakeProjectAdminQuestion", {
                code : projectCode,
                name : projectName
            }))) {
                this.ctx.projectService.makeProjectAdmin(this.ctx.activeOrganisationPersonId, projectId)
                    .then(() => {
                        console.info("Successfully made orgPerson [" + this.ctx.activeOrganisationPersonId
                                     + "] project admin of project [" + projectId + ":"
                            + projectCode + ":" + projectName + "]");
                        this.$emit("makeProjectAdmin", projectId);
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });                
            }

        },

        addProject(): void {
            this.$router.push({ name: "newProject", query: { networkId: this.ctx.activeNetworkId } });
        },
        addProjectNew() : void {
            this.$router.push({ name: "newProjectBs" });
        },
        getSubProjectReadWriteToolTip(subProject : SubProject) : string {
            if (!subProject.readOnly) {
                return this.$t("project.list.subProject.read-only-tool-tip");
            } else {
                return this.$t("project.list.subProject.read-write-tool-tip");
            }
        },
        getSubProjectReadWriteIconClass(subProject : SubProject) : string {
            if (!subProject.readOnly) {
                return "ci ci-project-read-only";
            } else {
                return "ci ci-project-read-write";
            }
        },
        toggleSubProjectReadWrite(subProjectInfo : SubProjectInfo) : void {
            if (window.confirm(this.getSubProjectAccessQuestion(subProjectInfo))) {
                this.ctx.projectService.setSubProjectAccess(this.ctx.activeOrganisationPersonId, subProjectInfo.id,
                                                            !subProjectInfo.readOnly).then((subProject : SubProject) => {
                                                                subProjectInfo.readOnly = subProject.readOnly;
                                                            }, err => {
                                                                ErrorHelper.processError(this.$t, this.$d, err);
                                                            });
            }
        },

        deleteSubProject(subProjectInfo : SubProjectInfo) : void {
            if (window.confirm(this.$t("project.list.subProject.reallyDeleteQuestion", {
                subProject : subProjectInfo.code + " " + subProjectInfo.name
            }))) {
                this.ctx.plotService.getPlotOrderDeleteJoinsPreventingSubProjectInvalidate(this.ctx.activeOrganisationPersonId, subProjectInfo.id)
                    .then((plotOrderDeleteJoins : PlotOrderDeleteJoin[]) => {
                        if (plotOrderDeleteJoins.length > 0) {
                            window.alert(this.$t("project.list.subProject.cannotDeletePlotOrders", {
                                subProject : subProjectInfo.code + " " + subProjectInfo.name
                            }));
                        } else {
                            this.ctx.projectService.deleteSubProject(this.ctx.activeOrganisationPersonId, subProjectInfo.id).then(() => {
                                    console.info("Successfully deleted subProject [" + subProjectInfo.id + ":"
                                        + subProjectInfo.code + ":" + subProjectInfo.name + "]");
                                    this.$emit("removeSubProject", subProjectInfo.projectId, subProjectInfo.id);
                                });
                        }
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        },

        isActive(projectInfo : ProjectInfo) : boolean {
            return projectInfo.projectStatus == ProjectStatus.ACTIVE;
        },

        isInactive(projectInfo : ProjectInfo) : boolean {
            return projectInfo.projectStatus == ProjectStatus.INACTIVE;
        },

        isInvisibleAccessIcon(projectInfo : ProjectInfo) : boolean {
            let access : ProjectAccess = projectInfo.projectAccess;
            let maxAllowedAccess : ProjectAccess = this.getMaxAllowedAccess(projectInfo.projectStatus);
            return ((access == ProjectAccess.READ_ONLY && maxAllowedAccess == ProjectAccess.READ_ONLY)
                || (access == ProjectAccess.READ_WRITE));
        },

        isReadWriteAccessIcon(projectInfo : ProjectInfo) : boolean {
            let access : ProjectAccess = projectInfo.projectAccess;
            let maxAllowedAccess : ProjectAccess = this.getMaxAllowedAccess(projectInfo.projectStatus);
            return maxAllowedAccess == ProjectAccess.READ_WRITE && access == ProjectAccess.READ_ONLY;
        },

        isReadOnlyAccessIcon(projectInfo : ProjectInfo) : boolean {
            let access : ProjectAccess = projectInfo.projectAccess;
            let maxAllowedAccess : ProjectAccess = this.getMaxAllowedAccess(projectInfo.projectStatus);
            return access == ProjectAccess.INVISIBLE
                && (maxAllowedAccess == ProjectAccess.READ_ONLY || maxAllowedAccess == ProjectAccess.READ_WRITE);
        },

        getAccessTitle(projectInfo : ProjectInfo) : string {
            if (this.isInvisibleAccessIcon(projectInfo)) {
                return this.$t("project.list.invisibleAccessTitle");
            } else if (this.isReadWriteAccessIcon(projectInfo)) {
                return this.$t("project.list.readWriteAccessTitle");
            } else if (this.isReadOnlyAccessIcon(projectInfo)) {
                return this.$t("project.list.readOnlyAccessTitle");
            } else {
                return "---";
            }
        },        

        getAccessIcon(projectInfo : ProjectInfo) : string {
            if (this.isInvisibleAccessIcon(projectInfo)) {
                return "ci ci-project-invisible";
            } else if (this.isReadWriteAccessIcon(projectInfo)) {
                return "ci ci-project-read-write";
            } else if (this.isReadOnlyAccessIcon(projectInfo)) {
                return "ci ci-project-read-only";
            } else {
                return "ci ci-project-invisible";
            }
        },

        getMaxAllowedAccess(status : ProjectStatus) {
            switch(status) {
                case ProjectStatus.ACTIVE:
                    return ProjectAccess.READ_WRITE;
                    return ProjectAccess.READ_ONLY;
                default:
                    return ProjectAccess.INVISIBLE;
            }
        },

        getProjectAccessQuestion(projectInfo : ProjectInfo) : string {
            let projectCode : string = projectInfo.projectCode;
            let projectName : string = projectInfo.projectName;
            if (this.isInvisibleAccessIcon(projectInfo)) {
                return this.$t("project.list.invisibleAccessQuestion", {
                    code : projectCode,
                    name : projectName
                });
            } else if (this.isReadWriteAccessIcon(projectInfo)) {
                return this.$t("project.list.readWriteAccessQuestion", {
                    code : projectCode,
                    name : projectName
                });                    
            } else if (this.isReadOnlyAccessIcon(projectInfo)) {
                return this.$t("project.list.readOnlyAccessQuestion", {
                    code : projectCode,
                    name : projectName
                });                    
            } else {
                return "---";
            }
        },

        getProjectAccessTargetAccess(projectInfo : ProjectInfo) : ProjectAccess {
            if (this.isInvisibleAccessIcon(projectInfo)) {
                return ProjectAccess.INVISIBLE;
            } else if (this.isReadWriteAccessIcon(projectInfo)) {
                return ProjectAccess.READ_WRITE;
            } else if (this.isReadOnlyAccessIcon(projectInfo)) {
                return ProjectAccess.READ_ONLY;
            } else {
                return null;
            }
        },        

        changeProjectAccess(projectInfo : ProjectInfo) : void {
            let projectId : number = projectInfo.projectId;
            let targetAccess : ProjectAccess = this.getProjectAccessTargetAccess(projectInfo);
            if (window.confirm(this.getProjectAccessQuestion(projectInfo))) {
                this.ctx.projectService.setProjectAccess(this.ctx.activeOrganisationPersonId, projectId, targetAccess)
                    .then(() => {
                        console.info("Successfully changed project access of project [" + projectId
                            + "] to [" + targetAccess + "]");
                        this.$emit("changeProjectAccess", projectId, targetAccess);
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });                                
            }
        },

        jumpToObjectList(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push("/plan/objekte/liste");
        },
        jumpToReviewCycle(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push("/plan/objekte/pruefblaetter/liste");
        },
        jumpToPlanDeliverCatalogue(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push("/plan/liefern");
        },
        jumpToDocumentList(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push("/plan/verzeichnis");
        },
        jumpToBPObjList(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push({ name: 'bestandObj', params: { id : subProjectInfo.id } });
        },
        jumpToBPDocList(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.setContext(projectInfo, subProjectInfo);
            this.$router.push({ name : "bestandDoc", params: { id : subProjectInfo.id } });
        },
        jumpToPruefoptionen(projectInfo: ProjectInfo): void {
            this.setContext(projectInfo, null);
            this.$router.push("/plan/prjpruefoptionen");
        },
        setContext(projectInfo : ProjectInfo, subProjectInfo : SubProjectInfo) : void {
            this.ctx.activeNetworkId = projectInfo.networkId;
            this.ctx.activeProjectId = projectInfo.projectId;
            if (subProjectInfo != null)
                this.ctx.activeSubProjectId = subProjectInfo.id;
        },
        showAsBuiltSubProjects(projectInfo : ProjectInfo, rowPos : ParempiRowPos) : boolean {
            let subProjectInfo = projectInfo.subProjects[rowPos.getSubRow()];
            return projectInfo.hasAsBuiltSubProject && false && !subProjectInfo.readOnly; //functionality moved to another page
        },
        showAsBuiltDocuments(projectInfo : ProjectInfo, rowPos : ParempiRowPos) : boolean {
            let subProjectInfo = projectInfo.subProjects[rowPos.getSubRow()];
            return projectInfo.hasAsBuiltDocuments && false && !subProjectInfo.readOnly; //functionality moved to another page
        }
    }        
});
