/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Task } from "cdes-api/dto/task/Task";
import { ParempiColumn } from "cdes-vue/util/grid/ParempiColumn";
import ParempiGridVue from "cdes-vue/util/grid/ParempiGrid.vue";
import { Person } from "cdes-api/dto/Person";
import { TaskType } from "cdes-vue/voc/task/TaskType";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import { TaskSearchMode } from "../../../cdes-vue/voc/task/TaskSearchMode";
import Iframe from "cdes-vue/util/Iframe.vue";
import { ButtonType, CLOSE, DialogButton } from "cdes-vue/util/layout/DialogButton";

import { defineComponent, h, VNode, PropType } from "vue";

import { keyToCmp, nullsLast, defaultCmp, nullsFirst } from "cdes-vue/util/Sort";
import { OriginalDocumentTask } from 'cdes-api/dto/OriginalDocumentTask';
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import ParempiGrid from "cdes-vue/util/grid/ParempiGrid";

export default defineComponent({
    components : {
        CheckBox,
        Dialog,
        Iframe,
        ParempiGrid : ParempiGridVue
    },

    emits: ["removeTask", "toSignatureFolder"],

    expose : ["getTaskIdsAfterQuickFilter", "resetSelectionAll"],

    data() {
        return {
            TaskType,
            TaskSearchMode,
            selectionAll : null,
            reviewDetailsSrc : null,
            reviewProtocolSrc : null
        };
    },

    computed: {
        specificColumns(): ParempiColumn<Task>[] {
            const uploaded = new ParempiColumn<Task>({
		id : "uploaded",
                label : this.$t("task.list.uploadedCaption"),
                slotName : "uploaded",
                // nullsFirst seems to behave like: 1, 2, 3, null, i.e. rather like nullsLast...
                sort : keyToCmp(a => a.uploadedDate, nullsFirst(defaultCmp)) 
/*                sort : (taskOne : Task, taskTwo : Task) => {
                    let uploadedOne  taskOne.uploadedDate
                }});*/
            });

            //const uploaded = ParempiColumn.asRowSpanSlot("uploaded", this.$t("task.list.uploadedCaption"), "uploaded");
            const taskStatus = new ParempiColumn<Task>({
                id: "taskStatus",
                label: this.$t("task.list.statusCaption"),
                slotName: "taskStatus",
                sort: keyToCmp(a => a.taskDelay, nullsLast(defaultCmp)),
            });

            switch (this.mode) {
                case TaskSearchMode.OWN:
                    return [
                        uploaded,
                        taskStatus,
                    ];
                case TaskSearchMode.DONE:
                    return [
                        new ParempiColumn<Task>({
                            id: "doneDate",
                            label: this.$t("task.list.doneDateCaption"),
                            slotName: "doneDate",
                            sort: keyToCmp(a => a.doneDate, nullsLast(defaultCmp)),
                        }),
//                        ParempiColumn.asRowSpanSlot("doneDate", this.$t("task.list.doneDateCaption"), "doneDate"),
                        ParempiColumn.asRowSpanSlot("doneResult", this.$t("task.list.doneResultCaption"), "doneResult"),
                    ];
                case TaskSearchMode.LATE:
                    return [
                        uploaded,
                        taskStatus,
                        ParempiColumn.asRowSpanSlot("reviewStatus", this.$t("task.list.reviewStatusCaption"), "reviewStatus"),
                    ];
                case TaskSearchMode.BATCH:
                    return [
                        ParempiColumn.asRowSpanSlot("reviewDate", this.$t("task.list.reviewDateCaption"), "reviewDate"),
                        ParempiColumn.asRowSpanSlot("reviewResult", this.$t("task.list.reviewResultCaption"), "reviewResult"),
                        ParempiColumn.asRowSpanSlot("taskStatus", this.$t("task.list.statusCaption"), "taskStatus"),
                    ];
               default:
                    throw new Error("unreachable");
            }
        },

        columns(): ParempiColumn<Task>[] {
            let columns : ParempiColumn<Task>[] = [];
            columns.push(ParempiColumn.asRowSpanSlot("name", this.$t("task.list.taskCaption")) as ParempiColumn<Task>);
            columns.push(ParempiColumn.asRowSpanSlot("document", this.$t("task.list.documentCaption")) as ParempiColumn<Task>);
            if (!this.restrictToSignatureSignColumns) {
                columns.push(ParempiColumn.asRowSpanSlot("responsible", this.mode == TaskSearchMode.DONE ? this.$t("task.list.responsibleDONECaption") : this.$t("task.list.responsibleCaption")));
            }
            columns.push(ParempiColumn.asStrings("object", [ "objectReleaseCode", "objectReleaseName" ], this.$t("task.list.objectCaption")));
            columns.push(ParempiColumn.asRowSpanSlot("projectDesignation", this.$t("task.list.projectCaption")));
            columns.push(ParempiColumn.asRowSpanSlot("networkDesignation", this.$t("task.list.networkCaption")));

            if (!this.restrictToSignatureSignColumns) {
                for (let specificColumn of this.specificColumns) {
                    columns.push(specificColumn);
                }
                columns.push(ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions"));

                if (this.mode == TaskSearchMode.BATCH) {
                    columns.push(
                        new ParempiColumn({
                            id: "selection",
                            slotName: "selection",
                            headerSlotName: "selectionHeader",
                            sort: false,
                        }));
                }
            }

            return columns;
        },

        isBatch(): boolean {
            return this.mode === TaskSearchMode.BATCH;
        },

        isLate(): boolean {
            return this.mode === TaskSearchMode.LATE;
        },

        asyncRunning() : boolean {
            return this.asyncRunningPage;
        }
    },

// https://adamlynch.com/flexible-data-tables-with-css-grid/

    methods : {
        selectAllClicked() {
            let selectionAll : boolean = this.selectionAll;
            let tasks : Task[] = this.tasks as Task[];
            for (let task of tasks) {
                task.selected = selectionAll;
            }
        },
        selectClicked() : void {
            let selectionAll : boolean = (this.tasks.length > 0);
            let tasks : Task[] = this.tasks as Task[];
            for (let task of tasks) {
                selectionAll = selectionAll && task.selected;
            }
            if (selectionAll != this.selectionAll) {
                this.selectionAll = selectionAll;
            }
        },
        resetSelectionAll() : void {
			this.selectionAll = false;
        },
        
        getStatusClass(delay: number): string {
            if (delay > 0) {
                return "bg-danger";
            } else if (delay >= -3) {
                return "bg-warning";
            } else {
                return "bg-success";
            }
        },

        isInSignatureFolder(task: Task): boolean {
            return task.taskType === TaskType.REVIEW_TASK && task.taskStatus === 1;
        },
        getRowClass(task: Task): string {
            if (this.isInSignatureFolder(task)) {
                if (task.positionResultOrgPersonId == this.ctx.activeOrganisationPersonId) {
                    return "text-purple";
                } else {
                    return "text-black";
                }
            } else {
                return "";
            }
        },

        formatDelay(days: number): string {
            if (days < 0) {
                return this.$t("general.delayFormat.upcoming", Math.abs(days));
            } else {
                return this.$t("general.delayFormat.delayed", Math.abs(days));
            }
        },

        // this mostly useless function is here because templates cannot use imported things.
        isReviewTaskDone(task: Task): boolean {
            return task.taskType == TaskType.REVIEW_TASK_DONE;
        },

        toSignatureFolder(task: Task): void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.$emit("toSignatureFolder", task);
        },

        isLateTask(task: Task): boolean {
            return task.taskDelay > -1;
        },

        uploadDocument(task: Task): void {
            this.$router.push({ name: "uploadFile", query: { documentId: task.unreleasedDocumentId, taskId: task.taskId } });
        },

        openReviewProtocol(task: Task): void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.reviewProtocolSrc = this.getReviewProtocolSrc(task);
            (this.$refs.reviewProtocolDialog as InstanceType<typeof Dialog>).show();
//            this.$router.push({ name: "reviewProtocol", query: { documentVersionId: task.documentVersionId }});
        },

        getTaskIdsAfterQuickFilter() : Set<number> {
            let taskIds : Set<number> = new Set<number>();
            
            let grid : (typeof ParempiGridVue) = this.$refs.grid as (typeof ParempiGridVue);
            let trueItems : Task[] = grid.trueItems as Task[];
            for (let task of trueItems) {
                taskIds.add(task.taskId);
            }
            return taskIds;
        },

        getReviewProtocolSrc(task : Task) : string {
            return this.ctx.getDojoPageUrl("reviewProtocol", {
                documentVersionId : task.documentVersionId
            });
        },

        openReviewDetails(task: Task): void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.reviewDetailsSrc = this.getReviewDetailsUrl(task);
            (this.$refs.reviewDetailsDialog as InstanceType<typeof Dialog>).show();
            /*
            this.$router.push({ name: "reviewDetails", query: { taskId : task.taskId,
                                                                projectId : task.projectId,
                                                                isSurveillanceTab : "" + this.isBatch
                                                              }});
            */
        },

        getReviewDetailsUrl(task : Task) : string {
            return this.ctx.getTapestryRequestUrl("TaskDetailsService", [
                "" + task.taskId,
                "" + task.projectId,
                this.isBatch ? "T" : "F"
            ]);
        },

        review(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.$router.push({ name: "review", query: { taskId : task.taskId,
                                                         documentVersionId : task.documentVersionId,
                                                         taskStatus : task.taskStatus
                                                       }});
        },

        reviewOverride(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.$router.push({ name: "reviewOverride", query: { taskId : task.taskId,
                                                         documentVersionId : task.documentVersionId
                                                       }});
        },

        upload(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.$router.push({ name: "uploadFile", query: { documentId : task.unreleasedDocumentId,
                                                             taskId: task.taskId
                                                           }});
        },

        reUpload(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);
            this.$router.push({ name: "uploadFile", query: { documentId : task.unreleasedDocumentId,
                                                             documentVersionId : task.documentVersionId,
                                                             taskId : task.taskId
                                                           }});
        },

        requestOriginal(task: Task): void {
            this.switchToTaskOrgPersonIfAppropriate(task);

            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            this.ctx.taskService.getOriginalDocumentTaskByTaskId(organisationPersonId, task.taskId)
                .then((origTask : OriginalDocumentTask) => {
                    this.$router.push({
                        name: "requestOriginal",
                        query: {
                            orderId: origTask.orderId,
                            taskId : origTask.taskId
                        }
                    })
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                })
        },

        processReviewCyclePlotTask(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);

            this.$router.push({
                name: "reviewCyclePlotTask",
                query: {
                    taskId : task.taskId
                }
            })
        },

        processPlotOrderAcceptanceTask(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);

            this.$router.push({
                name: "plotOrderAcceptanceTask",
                query: {
                    taskId : task.taskId
                }
            })
        },

        deleteTask(task : Task) : void {
            this.switchToTaskOrgPersonIfAppropriate(task);

            if (window.confirm(this.$t("task.list.reallyDeleteTaskQuestion", {
                taskName : task.name,
                documentName : task.documentOrReleaseName
            }))) {
                this.ctx.taskService.deleteTask(this.ctx.activeOrganisationPersonId, task.taskId)
                    .then(() => {
                        this.$emit("removeTask", task.taskId);
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        },

        switchToTaskOrgPersonIfAppropriate(task : Task) : void {
            if (task.ownTaskOrgPersonId != null && task.ownTaskOrgPersonId != this.ctx.activeOrganisationPersonId) {
                this.ctx.activeOrganisationPersonId = task.ownTaskOrgPersonId;

                this.ctx.activeOrgPersonHint = true;
                setTimeout(() => {
                    this.ctx.activeOrgPersonHint = false;
                }, 5000);
            }
        },

        getPositionResultPersonText(task : Task) : string {
            return task.positionResultPersonGivenName + " " + task.positionResultPersonSurName + " ("
                + task.positionResultOrganisationName + ")";
        }
    },

    watch : {
        tasks(newTasks : Task[]) {
            window.setTimeout(() => {
                if (this.ctx.editedTaskId != null) {
                    let grid : typeof ParempiGrid = this.$refs.grid as (typeof ParempiGrid);
                    grid.scrollIntoView(this.ctx.editedTaskId);
                    this.ctx.editedTaskId = null;
                }
            }, 3500);
        }
    },

    props : {
        tasks : {
            type : Array
        },
        mode: String as PropType<TaskSearchMode>,
        quickFilter: {
            type: String as PropType<string | null>,
        },
        asyncRunningPage : Boolean,
        sizeClass : {
            type : String,
            default : "flex-min-height-0"
        },
        restrictToSignatureSignColumns : {
            type : Boolean,
            default : false
        }
    },
});
