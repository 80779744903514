/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export enum ValidationError {
    NONE = "",
    MISSING = "MISSING",
    DUPLICATE = "DUPLICATE",
    LACKING_DIVERSITY = "LACKING_DIVERSITY",
    REPETITION_NOT_IDENTICAL = "REPETITION_NOT_IDENTICAL",
    TOO_SHORT = "tooShort",
    OLD_PW = "OLD_PW",
    INVALID = "INVALID",
    WRONG_PW = "WRONG_PW",
    INVALID_CHARS = "INVALID_CHARS"
}

export default ValidationError;
