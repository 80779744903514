/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Address } from "cdes-api/dto/util/Address";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        address : {
            type : Object as PropType<Address>,
        },

        outputCountry : {
            type : Boolean,
            default : true
        },

        personal : {
            type : Boolean
        }
    },

    computed : {
        personalString() : string {
            return this.$t("addressOutput.personal", {
                givenName : this.address.personGivenName,
                surName : this.address.personSurName
            });
        }
    }
});
