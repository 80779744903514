export enum ButtonType {
    SAVE = "Save",
    CLOSE = "Close"
}

interface DialogButtonParams {
    handler? : () => void,
    buttonType : ButtonType,
    classes? : string,
    label? : string
}

export class DialogButton {
    handler : () => void;
    buttonType : ButtonType;
    classes : string;
    label : string;

    constructor(params : DialogButtonParams) {
        this.handler = params.handler;
        this.buttonType = params.buttonType;
        this.classes = params.classes;
        this.label = params.label;
    }

    public getClass() : string {
        let classes : string = "btn";
        if (this.classes != null) {
            classes += " " + this.classes;
        } else {
            if (this.buttonType == ButtonType.CLOSE || this.buttonType == ButtonType.SAVE) {
                classes = classes + " btn-primary";
            } 
        }

        return classes;
    }

    public getLabel($t : (key : string) => string) : string {
        if (this.label != null) {
            return this.label;
        } else {
            switch (this.buttonType) {
                case ButtonType.CLOSE:
                    return $t("general.close");
                case ButtonType.SAVE:
                    return $t("general.save");
                default:
                    return "Unnamed.";
            }
        }

    }

    public getKey() : string {
        return this.buttonType;
    }

    public isCloseButton() : boolean {
        return this.buttonType == ButtonType.SAVE || this.buttonType == ButtonType.CLOSE;
    }
}

export const CLOSE = [ new DialogButton({ buttonType : ButtonType.CLOSE }) ];
