import { Label } from "cdes-api/dto/Label";
import { LabelContent } from "cdes-api/dto/LabelContent";
import { ReviewCycle } from "cdes-api/dto/ReviewCycle";
import { ReviewCycleCell } from "cdes-api/dto/ReviewCycleCell";
import { ReviewCycleNode } from "cdes-api/dto/ReviewCycleNode";
import { LabelTextField } from "./LabelTextField";

export class LabelContentInfo {
    label : Label;
    labelContent : LabelContent;
    textFields : LabelTextField[];
    reviewCycles : ReviewCycle[] = [];
    idToReviewCycleCell : Map<number, ReviewCycleCell> = new Map<number, ReviewCycleCell>();
    reviewCycleIdToNodes : Map<number, ReviewCycleNode[]> = new Map<number, ReviewCycleNode[]>();
}
