import { Attachment } from "../../cdes-api/dto/Attachment";
import { Person } from "../../cdes-api/dto/Person";

export class IAttachmentJoin {

    // attachment : Attachment
    attachmentId : number;
    attachmentOriginalName : (string | null | undefined);
    attachmentCreated : (number | null | undefined);

    // attachmentPerson : Person
    attachmentPersonId : number;
    attachmentPersonSurName : string;
    attachmentPersonGivenName : string;


    // attachment : Attachment
    public getAttachment() : Attachment {
        const attachment : Attachment = Attachment.constructTrackedPartialInstance([true, false, false, false, false, false, false, false, false, false, true, true, false]);
        attachment.id = this.attachmentId;
        attachment.originalName = this.attachmentOriginalName;
        attachment.created = this.attachmentCreated;
        return attachment;
    }
    public setAttachment(attachment : Attachment) : void {
        this.attachmentId = attachment.id;
        this.attachmentOriginalName = attachment.originalName;
        this.attachmentCreated = attachment.created;
    }

    // attachmentPerson : Person
    public getAttachmentPerson() : Person {
        const person : Person = Person.constructTrackedPartialInstance([true, false, true, true, false, false, false, false, false, false, false, false, false, false]);
        person.id = this.attachmentPersonId;
        person.surName = this.attachmentPersonSurName;
        person.givenName = this.attachmentPersonGivenName;
        return person;
    }
    public setAttachmentPerson(attachmentPerson : Person) : void {
        this.attachmentPersonId = attachmentPerson.id;
        this.attachmentPersonSurName = attachmentPerson.surName;
        this.attachmentPersonGivenName = attachmentPerson.givenName;
    }

}
