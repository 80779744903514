/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, inject, ref } from "vue";
import FormGrid, { Breakpoint, verticalBreakpointKey, labelWidthKey } from "cdes-vue/util/form/FormGrid.vue";

export default defineComponent({
    props: {
        fieldset: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        }
    },

    setup(props) {
        return {
            labelWidth: inject(labelWidthKey, ref(3)),
            verticalBreakpoint: inject(verticalBreakpointKey, ref("md")),
        };
    },

    computed: {
        controlId(): string {
            return this.$id("control");
        },
        labelId(): string {
            return this.$id("label");
        },
    },
});
