import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "list-group overflow-y-auto flex-grow-1 flex-min-height-0" }
const _hoisted_3 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('review.review.nav.heading')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["list-group-item list-group-item-action", { 'active': _ctx.ourSelectedVersionId === cell.documentVersionId }]),
          key: cell.documentVersionId,
          onClick: ($event: any) => {_ctx.ourSelectedVersionId = cell.documentVersionId; this.$emit('versionClicked')},
          disabled: cell === _ctx.loadingPlaceholder
        }, [
          _createElementVNode("h5", null, [
            _createVNode(_component_LoadedOrPlaceholder, { value: cell }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('review.review.cellHeading', {
            cellName: _ctx.$s(cell.reviewCycleCellId),
            versionPart: cell.documentVersionVersionParta + cell.documentVersionVersionPartSeperator + cell.documentVersionVersionPartb,
        })), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _createElementVNode("dl", null, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('general.uploaded')), 1),
            _createElementVNode("dd", null, [
              _createVNode(_component_LoadedOrPlaceholder, { value: cell }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$d(new Date(cell.documentVersionUploaded * 1000), "long")), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            ]),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('general.planner')), 1),
            _createElementVNode("dd", null, [
              _createVNode(_component_LoadedOrPlaceholder, { value: cell }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(cell.entryResultPersonGivenName ?? cell.connectionResultPersonGivenName) + " " + _toDisplayString(cell.entryResultPersonSurName ?? cell.connectionResultPersonSurName), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            ])
          ])
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}