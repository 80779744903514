/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, computed, SetupContext, watchEffect, reactive } from "vue";
import { useCtx } from "cdes-vue/util/Ctx";
import { asyncEagerComputed, loadingPlaceholder, LoadingPlaceholder, makePropWithValue, eagerComputed } from "cdes-vue/util/Prop";
import { ReviewCycleStatementSaveInfo } from "cdes-api/dto/document/ReviewCycleStatementSaveInfo";
import { ReviewCycleStatementInfo } from "cdes-api/dto/document/ReviewCycleStatementInfo";

type DestinationType = "cell" | "connection" | undefined | null;

export default defineComponent({
    props: {
        taskId: {
            type: Number as PropType<number>,
        },
        destinationId: {
            type: Number as PropType<number>,
        },
        destinationType: {
            type: String as PropType<DestinationType>,
        },
        modelValue: {
            type: Array as PropType<ReviewCycleStatementSaveInfo[]>,
        },
        disabled : {
            type : Boolean
        }
    },

    emits: ["update:modelValue", "update:hidden"],

    setup(props, context) {
        const { emit } = context;
        const ctx = useCtx();

        const statementInfo = asyncEagerComputed<ReviewCycleStatementInfo, LoadingPlaceholder>(() => {
                if (props.destinationId == null) {
                    return props.destinationId as null;
                } else if (props.destinationType === "cell") {
                    return ctx.reviewService.getStatementInfosForEntry(props.taskId, props.destinationId);
                } else {
                    return ctx.reviewService.getStatementInfosForConnection(props.taskId, props.destinationId);
                }
            }, loadingPlaceholder);

        const value = makePropWithValue({
            props,
            context: context as SetupContext<"update:modelValue"[]>,
            name: "modelValue",
            ref: eagerComputed(() => {
                if (statementInfo.value === loadingPlaceholder) {
                    return reactive([]);
                }
                return reactive(statementInfo.value?.realms?.map(statementRealm => ({
                    realmId: statementRealm.id,
                    comment: null,
                    reviewCycleResultOptionId: null,
                })) ?? []);
            }),
            copier: (a) => a.map(b => ({...b})),
            watchOptions: {
                deep: true,
            },
        });

        const hidden = computed(() => {
            return statementInfo.value == null || (statementInfo.value !== loadingPlaceholder && statementInfo.value.realms.length <= 0);
        });

        watchEffect(() => {
            emit("update:hidden", hidden.value);
        });

        return {
            value,
            statementInfo,
            hidden,
            loadingPlaceholder,
        };
    },
});
