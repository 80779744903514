/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType } from "vue";
import { LoadingPlaceholder, loadingPlaceholder } from "cdes-vue/util/Promise";

export default defineComponent({
    setup() {
        return {
            loadingPlaceholder,
        };
    },

    props: {
        value: {
            type: undefined as PropType<unknown | LoadingPlaceholder>,
        },
        // em
        width: {
            type: Number as PropType<number>,
            default: () => 7,
        },
        lines: {
            type: Number as PropType<number>,
            default: () => 1,
        },
        input: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        select: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        noDefault: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },
});
