/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, Ref, toRaw } from "vue";
import { OrganisationParticipationInfo } from "cdes-api/dto/project/OrganisationParticipationInfo";
import { OrganisationParticipationEditInfo } from "cdes-api/dto/project/OrganisationParticipationEditInfo";
import { ParticipationEditInfo } from "cdes-api/dto/project/ParticipationEditInfo";
import { shallowCopyPersistent } from "cdes-vue/util/container/PersistentHelper";
import Select, { SelectOption } from "cdes-vue/util/form/Select.vue";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import MultiSelect from "cdes-vue/util/form/MultiSelect.vue";
import { ParticipationPageSearchModel } from "cdes-api/dto/project/ParticipationPageSearchModel";
import { ParticipationPageEditInfo } from "cdes-api/dto/project/ParticipationPageEditInfo";
import MainParticipantDropDown from "cdes-vue/project/participation/MainParticipantDropDown.vue";
import RoleConflictPage from "cdes-vue/project/participation/RoleConflictPage.vue";
import { ConsortiumPersonInfo } from "cdes-api/dto/person/ConsortiumPersonInfo";
import { ParticipationInfo } from "cdes-api/dto/project/ParticipationInfo";
import { resolvedOrDefault } from "cdes-vue/util/Promise";
import { keyToCmp } from "cdes-vue/util/Sort";
import Label from "cdes-vue/util/form/Label.vue";
import { CdesRole } from "cdes-api/dto/CdesRole";
import { SubProject } from "generated/cdes-api/dto/SubProject";
import { RoleConflictInfo } from "cdes-api/dto/project/RoleConflictInfo";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { ButtonType, CLOSE, DialogButton } from "cdes-vue/util/layout/DialogButton";
import { Project } from "cdes-api/dto/Project";
import { PersonInfo } from "cdes-api/dto/person/PersonInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { Organisation } from "cdes-api/dto/Organisation";

function copyInfo(info: OrganisationParticipationInfo): OrganisationParticipationInfo {
	return {
		...info,
		participationInfos: info.participationInfos.map(partInfo => ({
			...partInfo,
			allowedSubProjects: partInfo.allowedSubProjects.slice(),
			projectParticipation: shallowCopyPersistent(partInfo.projectParticipation),
		})),
	};
}

export default defineComponent({
    components: {
        CheckBox,        
        Select,
        TextInput,
        MultiSelect,
        Dialog,
        Label,
        RoleConflictPage,
        MainParticipantDropDown
    },

    props: {
        searchModel: Object as PropType<ParticipationPageSearchModel>,
    },

    beforeRouteLeave(to, from) {
        if (this.saved && to.path == "/plan/teilnehmer") {
            // prevent infinite recursion.
            this.saved = false;
            return {
                ...to,
                query: {
                    ...to.query,
                    dirty: "true",
                },
                replace: true,
            };
        } else {
            return undefined;
        }
    },

    computed: {
        participationInfos(): ParticipationInfo[] {
            return (this.value?.participationInfos as ParticipationInfo[] | undefined) ?? [];
        },
        selectInfo(): Ref<ParticipationPageEditInfo> {
            return resolvedOrDefault(
                this.ctx.projectService.getParticipationPageEditInfo(this.ctx.activeProjectId),
                {
                    organisations: [],
                    project : new Project(),
                    cdesRoles: [],
                    subProjects: [],
                    personInfos: new Map(),
                    consortiumIdToOrganisations : new Map()
                },
            );
        },
        organisationOptions(): SelectOption[] {
            return this.selectInfo.value.organisations.map(organisation => ({
                label: organisation.name,
                value: organisation.id,
            }));
        },
        idToRole() : Map<number, CdesRole> {
            let idToRole = new Map<number, CdesRole>();
            for (let role of this.selectInfo.value.cdesRoles) {
                idToRole.set(role.id, role);
            }
            return idToRole;
        },
        roleOptions(): SelectOption[] {
            return this.selectInfo.value.cdesRoles.map(role => ({
                label: role.name,
                value: role.id,
            }));
        },
        personInfos(): ConsortiumPersonInfo[] {
            // Copied to ProjectParticipationListWidget
            let personInfos : ConsortiumPersonInfo[] = [];
            let selectedOrganisationPersonIds : Set<number> = new Set<number>();

            // Two steps: First add all personInfos with a set consortiumId, then all without.
            // Reason: We need to suppress duplicates using selectedOrganisationPersonIds,
            //         but on the other hand, in case of consortiums, one copy with and one
            //         copy without consortiumId is around.  Ensure that the copy with
            //         consortiumId is chosen in such cases.
            for (let organisationId of this.selectInfo.value.personInfos.keys()) {
                let currPersonInfos : ConsortiumPersonInfo[] = this.selectInfo.value.personInfos.get(organisationId);
                for (let currPersonInfo of currPersonInfos) {
                    let organisationPersonId : number = currPersonInfo.organisationPerson?.id;
                    let consortiumId : number = currPersonInfo.consortium?.id;
                    if (consortiumId != null) {
                        if (!selectedOrganisationPersonIds.has(organisationPersonId)) {
                            personInfos.push(currPersonInfo);
                            selectedOrganisationPersonIds.add(organisationPersonId);
                        }
                    }
                }
            }

            for (let organisationId of this.selectInfo.value.personInfos.keys()) {
                let currPersonInfos : ConsortiumPersonInfo[] = this.selectInfo.value.personInfos.get(organisationId);
                for (let currPersonInfo of currPersonInfos) {
                    let organisationPersonId : number = currPersonInfo.organisationPerson?.id;
                    let consortiumId : number = currPersonInfo.consortium?.id;
                    if (consortiumId == null) {
                        if (!selectedOrganisationPersonIds.has(organisationPersonId)) {
                            personInfos.push(currPersonInfo);
                            selectedOrganisationPersonIds.add(organisationPersonId);
                        }
                    }
                }
            }
            personInfos = personInfos.sort((a : ConsortiumPersonInfo, b : ConsortiumPersonInfo) => {
                let nameOne = a.person?.surName + a.person?.givenName;
                let nameTwo = b.person?.surName + b.person?.givenName;
                nameOne = nameOne.trim().toLocaleLowerCase();
                nameTwo = nameTwo.trim().toLocaleLowerCase();
                return nameOne.localeCompare(nameTwo);
            });
            
            return personInfos;
            /*
            if (this.editInfo.organisationId != null) {
                return this.selectInfo.value.personInfos.get(this.editInfo.organisationId);
            } else {
                return [];
            }*/
        },
        orgPersonIdToOrganisation() : Map<number, Organisation> {
            let orgPersonIdToOrganisation : Map<number, Organisation> = new Map<number, Organisation>();
            for (let personInfo of this.personInfos) {
                let orgPersonId : number = personInfo.organisationPerson.id;
                let organisation : Organisation = personInfo.organisation;
                orgPersonIdToOrganisation.set(orgPersonId, organisation);
            }
            return orgPersonIdToOrganisation;
        },

        personOptions(): SelectOption[] {
            return this.personInfos?.map?.(info => ({
                label: this.renderPerson(info),
                value: info.organisationPerson.id,
            }))?.sort?.(keyToCmp(a => a.label)) ?? [];
        },
        subProjectOptions(): SelectOption[] {
            return this.selectInfo.value.subProjects.map(subProject => ({
                label: subProject.name,
                value: subProject.id,
            })).sort(keyToCmp(a => a.label));
        },
        cdesRoles(): Map<number, CdesRole> {
            return new Map(this.selectInfo.value.cdesRoles.map(a => [a.id, a]));
        },
        people(): Map<number, ConsortiumPersonInfo> {
            if (this.editInfo.organisationId != null) {
                let organisationId : number = this.editInfo.organisationId;
                let consortiumPersonInfos : ConsortiumPersonInfo[] = this.selectInfo.value.personInfos.get(organisationId);
                return new Map(consortiumPersonInfos?.map?.(a => [a.organisationPerson.id, a]));
            } else {
                return new Map();
            }
        },
        subProjects(): Map<number, SubProject> {
            return new Map(this.selectInfo.value.subProjects.map(a => [a.id, a]));
        },
        disabled(): boolean {
            return this.loading != 0;
        },
        withRestrictions(): boolean {
            let editInfo = this.participationPageEditInfo;
            return editInfo != null && editInfo.project != null
            ? editInfo.project.withParticipantsSubProjectRestriction : false;


            
            //return this.value?.withSubProjectRestrictions ?? false;
        },
        saveDisabled() : boolean {
            let result = this.disabled || this.editInfo.organisationId == null;
            for (let participationInfo of this.editInfo.participations) {
                if (participationInfo.cdesRoleId == null || participationInfo.mainParticipantOrganisationPersonId == null) {
                    result = true;
                }
            }
            return result;
        }
    },

    data() {
        return {
            editInfo: undefined as OrganisationParticipationEditInfo,
            value: undefined as (OrganisationParticipationInfo | undefined),
            participationPageEditInfo : undefined as (ParticipationPageEditInfo | undefined),
            loading: 0,
            participationFieldsets: [] as HTMLElement[],
            SaveButton : new DialogButton({
                buttonType : ButtonType.SAVE,
                handler : () => this.save()
            }),
            roleConflictInfo : new RoleConflictInfo(),
            CLOSE : CLOSE,
            saved: false,
            offerParticipationsOfAllOrganisations : false,
            deputyAddFilter : ""
        };
    },

    beforeUpdate(): void {
        this.participationFieldsets = [];
    },

    watch: {
        searchModel: {
            handler(searchModel) {
                this.value = undefined;
                this.editInfo = undefined;
                this.saved = false;
                this.withLoading(() => this.ctx.projectService.getParticipationPageInfo(this.ctx.activeOrganisationPersonId,
                                                                                        searchModel)
                    .then(a => {
                        this.value = a.organisationParticipationInfos[0];
                        this.participationPageEditInfo = a.editInfo;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    }));
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler() {
                this.editInfo = {
                    organisationId: this.value?.organisation?.id,
                    participations: this.value?.participationInfos?.map?.(info => {
                        return ({
                            participationId: info.projectParticipation.id,
                            cdesRoleId: info.cdesRole.id,
                            mainParticipantOrganisationPersonId: info.mainParticipantInfo?.participant?.organisationPersonId,
                            deputyOrganisationPersonIds: info.deputyInfos.map(deputyInfo => deputyInfo.participant.organisationPersonId),
                            comment: info.projectParticipation.comment,
                            attachmentEmailAddress: info.projectParticipation.attachmentEmailAddress,
                            customerId: info.projectParticipation.customerId,
                            mailFlag: info.projectParticipation.mailFlag,
                            allowedSubProjectIds: info.allowedSubProjects?.map?.(a => a.id) ?? [],
                        });
                    }) ?? [],
                    deletedParticipationIds: [],
                };
            },
            immediate: true,
        },
    },

    methods: {
        sortPerson(o1 : SelectOption, o2 : SelectOption) {
            let s1 = o1.label;
            let s2 = o2.label;
            s1 = s1 != null ? s1.trim().toLocaleLowerCase() : "";
            s2 = s2 != null ? s2.trim().toLocaleLowerCase() : "";
            return s1.localeCompare(s2);
        },

        withLoading<T>(start: () => Promise<T>): Promise<T> {
            this.loading += 1;
            return start()
            .then(a => {
                this.loading -= 1;
                return a;
            }, a => {
                this.loading -= 1;
                ErrorHelper.processError(this.$t, this.$d, a);
                throw a;
            });
        },
        renderPerson(person: ConsortiumPersonInfo): string {
            if (person == null) {
                return "";
            }
            if (person.consortium != null) {
                return `${person.person.surName} ${person.person.givenName} (${person.organisation.name})`;
            } else {
                return `${person.person.surName} ${person.person.givenName} (${person.organisation.name})`;
            }
        },
        save(): void {
            let baseOrganisationId : number = this.editInfo.organisationId;
            let roleIdToOrganisationIds = new Map<number, Set<number>>();
            let idToRole = new Map<number, CdesRole>();
            let idToOrganisation = new Map<number, Organisation>();
            for (let participation of this.editInfo.participations) {
                let desiredOrgPersonId : number = participation.mainParticipantOrganisationPersonId;
                let desiredOrganisation : Organisation = desiredOrgPersonId != null
                    ? this.orgPersonIdToOrganisation.get(desiredOrgPersonId) : null;
                if (desiredOrganisation != null && baseOrganisationId != desiredOrganisation.id
                    && !this.isBaseOrganisationConsortiumOfDesiredOrganisation(baseOrganisationId, desiredOrganisation)) {
                    let roleId : number = participation.cdesRoleId;
                    if (!roleIdToOrganisationIds.has(roleId)) {
                        roleIdToOrganisationIds.set(roleId, new Set<number>());
                    }
                    roleIdToOrganisationIds.get(roleId).add(desiredOrganisation.id);
                    idToOrganisation.set(desiredOrganisation.id, desiredOrganisation);
                }
            }

            if (roleIdToOrganisationIds.size > 0) {
                let msg : string = this.$t("project.participation.edit.switchOrg.prefix");
                let firstRole = true;
                for (let roleId of roleIdToOrganisationIds.keys()) {
                    let roleName : string = this.idToRole.get(roleId).name;
                    let orgIds : Set<number> = roleIdToOrganisationIds.get(roleId);
                    if (!firstRole) {
                        msg += ", ";
                    }

                    for (let orgId of orgIds) {
                        let org : Organisation = idToOrganisation.get(orgId);
                        msg += this.$t("project.participation.edit.switchOrg.oneOrg", {
                            role : roleName,
                            organisation : org.name
                        });
                    }
                    firstRole = false;
                }

                msg += this.$t("project.participation.edit.switchOrg.postfix");

                if (window.confirm(msg)) {
                    this.evaluteRoleConflict();
                }
            } else {
                this.evaluteRoleConflict();
            }
        },

        isBaseOrganisationConsortiumOfDesiredOrganisation(baseOrganisationId : number,
                                                          desiredOrganisation : Organisation) : boolean {
            if (baseOrganisationId != null && desiredOrganisation != null
                && this.selectInfo.value.consortiumIdToOrganisations.has(baseOrganisationId)) {
                let organisations : Organisation[] = this.selectInfo.value.consortiumIdToOrganisations.get(baseOrganisationId);
                for (let organisation of organisations) {
                    if (organisation.id == desiredOrganisation.id) {
                        return true;
                    }
                }
            }
            return false;
        },

        evaluteRoleConflict() : void {
            let organisationPersonId = this.ctx.activeOrganisationPersonId;
            this.ctx.projectService.getRoleConflictInfo(organisationPersonId, this.editInfo, this.editInfo.organisationId, this.ctx.activeProjectId)
                .then((roleConflictInfo : RoleConflictInfo) => {
                    if (roleConflictInfo != null) {
                        this.roleConflictInfo = roleConflictInfo;
                        (this.$refs.roleConflictPage as InstanceType<typeof Dialog>).show();
                    } else {
                        this.doSave();
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        doSave() : void {
            const searchModel = this.searchModel as ParticipationPageSearchModel;
            const ctx = this.ctx;
            this.withLoading(() => this.ctx.projectService.saveParticipationPageInfo(this.ctx.activeProjectId, this.ctx.activeOrganisationPersonId, this.editInfo)
            .then(() => {
                this.saved = true;
                this.ctx.editedParticipationOrganisationId = this.editInfo.organisationId;
                this.$router.back();
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            }));
        },
        cancel(): void {
            this.ctx.participationAborted = true;
            this.ctx.editedParticipationOrganisationId = this.editInfo.organisationId;
            this.$router.back();
        },
        newRole(): void {
            this.editInfo.participations.push({
                participationId : null,
                cdesRoleId : null,
                mainParticipantOrganisationPersonId : null,
                deputyOrganisationPersonIds : [],
                comment : null,
                attachmentEmailAddress : null,
                customerId : null,
                mailFlag : false,
                allowedSubProjectIds : this.subProjectOptions.map(a => a.value) as number[],
            });
            this.$nextTick(() => {
                this.participationFieldsets[this.participationFieldsets.length - 1]
                .scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            });
        },
        setRoleRef(ref: HTMLElement): void {
            if (ref) {
                this.participationFieldsets.push(ref);
            }
        },
        getDeputyOptions(participationEditInfo : ParticipationEditInfo): ConsortiumPersonInfo[] {
            let filterString = this.deputyAddFilter != null ? this.deputyAddFilter.trim() : null;

            let participationOrganisationId : number = this.editInfo.organisationId;
            
            let existingDeputyOpIds : Set<number> = new Set<number>();
            if (participationEditInfo.mainParticipantOrganisationPersonId != null) {
                existingDeputyOpIds.add(participationEditInfo.mainParticipantOrganisationPersonId);
            }
            for (let deputyOpId of participationEditInfo.deputyOrganisationPersonIds) {
                existingDeputyOpIds.add(deputyOpId);
            }

            let rawPersonInfos : ConsortiumPersonInfo[] = this.personInfos;
            let personInfos : ConsortiumPersonInfo[] = [];
            for (let rawPersonInfo of rawPersonInfos) {
                let currOrganisationPersonId : number = rawPersonInfo.organisationPerson.id;
                let currOrganisationId : number = rawPersonInfo.organisation.id;
                let currConsortiumId : number = rawPersonInfo.consortium?.id;
                let candidateString = this.getPersonOrganisationString(rawPersonInfo);
                if (!existingDeputyOpIds.has(currOrganisationPersonId) &&
                    (this.offerParticipationsOfAllOrganisations
                        || participationOrganisationId == currOrganisationId
                        || participationOrganisationId == currConsortiumId)
                    && (   filterString == null || filterString.length == 0
                        || candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1)) {
                    personInfos.push(rawPersonInfo);
                }
            }
            
            return personInfos;            
            /*
            let consortiumPersonInfos : ConsortiumPersonInfo[] = this.personInfos
                ?.filter(a => a.organisationPerson.id !== participationEditInfo.mainParticipantOrganisationPersonId)
                ?.sort(keyToCmp(a => this.renderPerson(a))) ?? [];
            return consortiumPersonInfos;
            */
        },
        deputies(participationEditInfo : ParticipationEditInfo): ConsortiumPersonInfo[] {
            let consortiumPersonInfos : ConsortiumPersonInfo[] = this.personInfos
                ?.filter(a => a.organisationPerson.id !== participationEditInfo.mainParticipantOrganisationPersonId)
                ?.sort(keyToCmp(a => this.renderPerson(a))) ?? [];
            return consortiumPersonInfos;
        },
        mainParticipantPersonOptions(participation: ParticipationEditInfo) : SelectOption[] {
            const deputies = new Set(participation.deputyOrganisationPersonIds);
            /*
            return this.personOptions
                .filter(a => !deputies.has(a.value as number));
            */
            
            let filteredPersonInfos = [];
            for (let personInfo of this.personInfos) {
                console.info("Checking personInfo: orgPersonId [" + personInfo.organisationPerson?.id + "], organisationId ["
                    + personInfo.organisation?.id + "], consortiumId [" + personInfo.consortium?.id + "]");
                if (personInfo.organisation != null
                    && (personInfo.organisation.id == this.editInfo.organisationId
                        || (personInfo.consortium != null && personInfo.consortium.id == this.editInfo.organisationId))
                    && !deputies.has(personInfo.organisationPerson.id)) {
                    filteredPersonInfos.push(personInfo);
                }
            }
            return filteredPersonInfos?.map?.(info => ({
                label: this.renderPerson(info),
                value: info.organisationPerson.id,
            }))?.sort?.(keyToCmp(a => a.label)) ?? [];            
        },        
        mainParticipantOptions(participation: ParticipationEditInfo): SelectOption[] {
            const deputies = new Set(participation.deputyOrganisationPersonIds);

            return this.personOptions
                .filter(a => !deputies.has(a.value as number));

        },

        getRoleConflictButtons() : DialogButton[] {
            return [
                new DialogButton({
                    buttonType : ButtonType.SAVE,
                    handler : () => {
                        this.doSave();
                    },
                    classes : "btn-primary"
                }),
                new DialogButton({
                    buttonType : ButtonType.CLOSE,
                    classes : "btn-secondary me-auto",
                    label : this.$t("general.cancel")
                })
            ];
        },

        getPersonOrganisationString(consortiumPersonInfo : ConsortiumPersonInfo) : string {
            return consortiumPersonInfo.person.surName + " " + consortiumPersonInfo.person.givenName
                + " (" + consortiumPersonInfo.organisation.name + ")";
        },

        getRemovalError(subProjectId : number, participationId : number) : Promise<string> {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            
            return this.ctx.projectService.mayAddSubProjectRestriction(organisationPersonId, participationId, subProjectId)
                .then((allowed : boolean) => {
                    if (allowed) {
                        return null;
                    } else {
                        return this.$t("project.participation.list.allowedStatuses.restrictNotAllowed");
                    }
                });
        },

        getOrganisationCss() : string {
            return this.editInfo.organisationId == null && !this.disabled ? "mustField" : "";
        },

        getRoleCss(participationEditInfo : ParticipationEditInfo) : string {
            return participationEditInfo.cdesRoleId == null && !this.disabled ? "mustField" : "";
        },

        getMainParticipantCss(participationEditInfo : ParticipationEditInfo) : string {
            return participationEditInfo.mainParticipantOrganisationPersonId == null && !this.disabled ? "mustField" : "";
        },

        compareSubProjects(subProjectOne : SubProject, subProjectTwo : SubProject) : number {
            let labelOne : string = (subProjectOne.code ?? "") + (subProjectOne.number ?? "");
            let labelTwo : string = (subProjectTwo.code ?? "") + (subProjectTwo.number ?? "");
            return labelOne.localeCompare(labelTwo);
        }        
    },
});
