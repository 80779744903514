/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, ref, computed } from "vue";
import { makePropWithValue } from "cdes-vue/util/Prop";
import { Editor, EditorContent, useEditor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';

export default defineComponent({
    name: "ReviewCommentTextArea",

    components: {
        EditorContent
    },

    props: {
        modelValue: {
            type: String as PropType<string>,
        },
        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            editor: null,
        }
    },    

    emits: ["update:modelValue"],

    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            
            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        },

        disabled(value) {
            this.editor.setOptions({ editable : !value });
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Underline,
                BulletList,
                OrderedList
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())
                
                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        });
        this.editor.setOptions({ editable : !this.disabled });        
    },
    
    beforeUnmount() {
        this.editor.destroy()
    }
});
