/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { CdesRole } from "cdes-api/dto/CdesRole";
import { Organisation } from "cdes-api/dto/Organisation";
import { SubProject } from "cdes-api/dto/SubProject";
import { OrganisationPersonInfo } from "../person/OrganisationPersonInfo";

export class ParticipationPageSearchInfo {
    organisations : Organisation[] = [];
    cdesRoles : CdesRole[] = [];
    subProjects : SubProject[] = [];
    mainParticipantInfos : OrganisationPersonInfo[] = [];
    deputyInfos : OrganisationPersonInfo[] = [];
}



