import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pageCaption ps-2" }
const _hoisted_2 = { class: "text-expanded" }
const _hoisted_3 = { class: "text-collapsed" }
const _hoisted_4 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "countLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectSearchWidget = _resolveComponent("ProjectSearchWidget")!
  const _component_Collapse = _resolveComponent("Collapse")!
  const _component_ProjectListWidget = _resolveComponent("ProjectListWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("project.page.caption")), 1),
    _createVNode(_component_Collapse, null, {
      label: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('general.button.collapseSearch')), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.button.openSearch')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ProjectSearchWidget, {
          onSearch: _ctx.onSearch,
          disabled: this.disabled
        }, {
          extraButtons: _withCtx(() => [
            (this.projectListPageInfo != null && this.projectListPageInfo.mayAddProject)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    disabled: this.disabled,
                    class: "btn btn-secondary",
                    form: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$refs.listWidget.addProjectNew()))
                  }, _toDisplayString(_ctx.$t("project.page.addProject")), 9, _hoisted_5)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onSearch", "disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_ProjectListWidget, {
      items: _ctx.items,
      "onUpdate:items": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.items) = $event)),
      ref: "listWidget",
      disabled: this.disabled,
      menuSection: this.menuSection,
      projectListPageInfo: this.projectListPageInfo,
      onMakeProjectAdmin: _ctx.makeProjectAdmin,
      onChangeProjectAccess: _ctx.changeProjectAccess,
      onRemoveSubProject: _ctx.removeSubProject,
      onRemoveProject: _ctx.removeProject
    }, null, 8, ["items", "disabled", "menuSection", "projectListPageInfo", "onMakeProjectAdmin", "onChangeProjectAccess", "onRemoveSubProject", "onRemoveProject"]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(this.countLabelString), 1)
  ], 64))
}