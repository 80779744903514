/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { ComponentPublicInstance } from "vue";

const END_TOKEN = "]";
const START_TOKEN = "[";

function removeLocaleWrapper(str: string, locale: string): string {
    const prefix = locale + START_TOKEN;
    const suffix = END_TOKEN + locale;
    if (str.startsWith(prefix) && str.endsWith(suffix)) {
        return str.substring(prefix.length, str.length - suffix.length)
    } else {
        return str;
    }
}

// convert json with unquoted names to standards compliant json.
function normalizeJson(str){
    let ret = "";
    let prev = 0;
    let inQuotedString = false;
    let inEscape = false;
    let inUnquotedString = false;
    let justAfterComma = false;
    let justAfterBrace = false;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === "," && !inQuotedString) {
            justAfterComma = true;
            continue;
        }

        if (str[i] === "{" && !inQuotedString) {
            justAfterBrace = true;
            continue;
        }

        if ((justAfterComma || justAfterBrace) && /^\p{L}$/u.test(str[i]) && !inQuotedString) {
            ret += str.substring(prev, i);
            prev = i;
            inUnquotedString = true;
        } else if (inUnquotedString && !inQuotedString) {
            // eslint-disable-next-line
            if (/^([{}\[\],:]|\s)$/.test(str[i])) {
                inUnquotedString = false;

                const content = str.substring(prev, i);
                prev = i;
                if (["null", "false", "true"].includes(content)) {
                    ret += content;
                } else {
                    ret += `"${content}"`;
                }
            }
        }

        if (str[i] === '"' && !inEscape) {
            inQuotedString = !inQuotedString;
        }

        if (inEscape) {
            inEscape = false;
        }

        if (inQuotedString && str[i] === "\\") {
            inEscape = true;
        }

        if (justAfterComma && !inQuotedString) {
            justAfterComma = /^\s$/.test(str[i]);
        }

        if (justAfterBrace && !inQuotedString) {
            justAfterBrace = /^\s$/.test(str[i]);
        }

    }
    if (prev === 0) {
        return str;
    } else {
        ret += str.substring(prev, str.length);
    }
    return ret;
}

export function getCurrentLocaleString(this: ComponentPublicInstance, i18nString: string, locale?: string): string {
    if (typeof i18nString !== "string") {
        return i18nString;
    }

    if (i18nString.startsWith("{")) {
        try {
            const trueLocale = this.$i18n.locale ?? locale;

            const localeToTranslation = JSON.parse(normalizeJson(i18nString));
            return removeLocaleWrapper(localeToTranslation[trueLocale] ?? Object.values(localeToTranslation)[0], trueLocale);
        } catch (e) {
            return i18nString;
        }
    } else {
        return i18nString;
    }
}
