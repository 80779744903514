import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-row" }
const _hoisted_2 = {
  class: "pageCaption ps-2",
  style: {"margin-bottom":"0px"}
}
const _hoisted_3 = { class: "d-flex flex-column px-3" }
const _hoisted_4 = { class: "d-flex flex-row justify-content-between align-items-center g-3 my-2" }
const _hoisted_5 = { class: "d-flex flex-row" }
const _hoisted_6 = {
  key: 0,
  class: "countLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_LabelListWidget = _resolveComponent("LabelListWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("review.label.list.pageCaption", {
                organisation: this.organisation.name,
              })), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CheckBox, {
            mode: "flex",
            label: _ctx.$t('review.label.list.hideInactive'),
            modelValue: _ctx.hideInactive,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hideInactive) = $event))
          }, null, 8, ["label", "modelValue"])
        ]),
        (this.labelPageInfo != null && this.labelPageInfo.mayEditLabel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.create()))
            }, _toDisplayString(_ctx.$t("review.label.list.addLabel")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_LabelListWidget, {
      items: _ctx.items,
      hideInactive: this.hideInactive,
      ref: "listWidget",
      onRemoveLabel: _ctx.removeLabel,
      menuSection: this.menuSection,
      labelPageInfo: this.labelPageInfo,
      onDoReload: _ctx.doReload
    }, null, 8, ["items", "hideInactive", "onRemoveLabel", "menuSection", "labelPageInfo", "onDoReload"]),
    (this.items != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('review.label.page.countLabel', this.items.length)), 1))
      : _createCommentVNode("", true)
  ], 64))
}