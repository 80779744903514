/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, ref, PropType, watchEffect, computed } from "vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import Select from "cdes-vue/util/form/Select.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { useCtx } from "cdes-vue/util/Ctx";
import { asyncEagerComputed, asyncEagerComputedWithError, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { DocumentUploadInfo } from "cdes-api/dto/document/DocumentUploadInfo";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import ShowLabelStatus from "./ShowLabelStatus.vue";
import NotFoundResolutionDialog from "./NotFoundResolutionDialog.vue";
import ManualNotFoundResolution from "cdes-api/dto/document/ManualNotFoundResolution";
import { randomUuid } from "cdes-vue/util/Uuid";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { SeekLabelResult } from "cdes-api/dto/document/SeekLabelResult";
import { LabelStatus } from "cdes-api/dto/document/LabelStatus";

export default defineComponent({
    components: {
        FormGridMember,
        FormGridLocal,
        FormGridColumn,
        Select,
        LoadedOrPlaceholder,
        ShowLabelStatus,
        NotFoundResolutionDialog,
    },

    expose: ["sign"],

    emits: ["update:temporaryDocumentVersionId", "update:notFoundResolution", "update:labelStatus", "fileUploaded", "update:selectedRotation"],

    props: {
        planNumber: {
            type: [String, Symbol] as PropType<string | LoadingPlaceholder>,
        },
        unreleasedDocumentId: {
            type: [Number, Symbol] as PropType<number | LoadingPlaceholder>,
        },
        taskId: {
            type: Number as PropType<number>,
        },
        uploadInfo: {
            type: [Object, Symbol] as PropType<DocumentUploadInfo | LoadingPlaceholder>,
        },
        destinationId: {
            type: [Number, Symbol] as PropType<number | LoadingPlaceholder | null>,
        },
        disabled : {
            type : Boolean
        }
    },

    setup(props, { emit }) {
        /*
        const imageLink = computed(() => {
            if (typeof props.destinationId !== "number") {
                return props.destinationId;
            } else if (typeof temporaryDocumentVersionId.value !== "string") {
                return temporaryDocumentVersionId.value;
                // we have a dependency to this through the server side temporary document version.
            } else if (typeof seekLabelResult.value !== "object" || seekLabelResult == null) {
                return seekLabelResult.value;
            }

            const parameters = {
                versionId: temporaryDocumentVersionId.value,
                destinationId: props.destinationId,
                pixelSize: Math.round(window.screen.width/2), //320
                angle: seekLabelResult.value.nativeRotation,
            };

            // The stringified json does not end up being absurdly big.
            return "/cdes/svc/temporaryDocumentPngPreview?"+(new URLSearchParams({ q: JSON.stringify(parameters) }));
        });

        const error = computed(() => {
            if (uploadError.value !== loadingPlaceholder) {
                return uploadError.value;
            } else if (unresolvedSeekLabelError.value !== loadingPlaceholder) {
                return unresolvedSeekLabelError.value;
            } else {
                return seekLabelError.value;
            }
        });

        function retry() {
            if (uploadError.value !== loadingPlaceholder) {
                retryUpload();
            } else if (unresolvedSeekLabelError.value !== loadingPlaceholder) {
                return retryUnresolvedSeekLabel();
            } else {
                retrySeekLabel();
            }
        }

        watchEffect(() => {
            emit("update:temporaryDocumentVersionId", temporaryDocumentVersionId.value);
        });

        return {
            notFoundDialog,
            temporaryDocumentVersionId,
            seekLabelResult,
            imageLink,
            file,
            error,
            retry,
            loadingPlaceholder,
        };
        */

        return {
            prevTemporaryDocumentVersionId : null,
            temporaryDocumentVersionId : ref(null),
            seekLabelResult : ref(null),
            labelStatus : null,
            file : ref(null),
            seekLabelCallCount : 0,
            seekLabelMaxCallCount : 100000,
            showRetryButton : ref(false),
            loadingPlaceholder,
            uploadProgresses: null,
            currentUpload: -1
        };
    },

    computed : {
        imageLink() {
/*            if (this.destinationId == null || typeof this.destinationId !== "number") {
                console.warn("Showing no image since destinationId is null");
                return null;
                } else*/
            if (this.temporaryDocumentVersionId == null || typeof this.temporaryDocumentVersionId !== "string") {
                console.warn("Showing no image since no temporaryDocumentVersionId exists");
                console.warn("temporaryDocVersionId [" + this.temporaryDocumentVersionId + "]");
                console.warn("typeof temporaryDocVersionId [" + (typeof this.temporaryDocumentVersionId) + "]");
                return null;
                // we have a dependency to this through the server side temporary document version.
            } else if (this.seekLabelResult == null) {
                console.warn("Showing no image since seekLabelResult is null");
                return null;
            }
            this.$emit("update:selectedRotation", this.seekLabelResult.previewRotation);

            const parameters = {
                versionId: this.temporaryDocumentVersionId,
                destinationId: (this.destinationId != loadingPlaceholder && this.destinationId != null ? this.destinationId : -1),
                pixelSize: Math.round(window.screen.width/2), //320
                angle: this.seekLabelResult.previewRotation,
            };

            // The stringified json does not end up being absurdly big.
            return "/cdes/svc/temporaryDocumentPngPreview?"+(new URLSearchParams({ q: JSON.stringify(parameters) }));
        },
        
        selectRotationPossible() : boolean {
			let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
			let labelStatus = this.seekLabelResult ? this.seekLabelResult.labelStatus : null;
			let resultOK = false;
            if (labelStatus!=null && [LabelStatus.GOOD, LabelStatus.PERFECT, LabelStatus.DOUBTFUL, LabelStatus.BAD].includes(labelStatus)) 
				resultOK = true;

			return resultOK ? false : !resultOK || (uploadInfo.labelDisablingPossible || uploadInfo.labelOutsidePossible);
		},

        validRotations() : SelectOption[] {
			return [
                { label : "0°", value : 0 },
                { label : "90°", value : 270 },
                { label : "180°", value : 180 },
                { label : "270°", value : 90 }
            ];
        }
    },


    methods: {
        
        doShowRetryButton() : boolean {
            return this.showRetryButton;
        },

        retry() : void {
            if (this.file != null) {
                this.processFileUpload(this.file);
            }
        },

        processFileUpload(file : File) : void {
            this.file = file;
            this.$emit("fileUploaded");
            this.temporaryDocumentVersionId = null;
            this.seekLabelResult = null;
            if (this.file == null || this.planNumber === loadingPlaceholder || this.unreleasedDocumentId === loadingPlaceholder) {
                // No file provided, or not yet initialized
                return;
            }

            // https://gitlab.intra.iteg.at/iteg-erp/infex-main/-/blob/main/infex.web/src/main/webapp/infex/src/components/Folder.vue
            // https://gitlab.intra.iteg.at/iteg-erp/infex-main/-/blob/main/infex.web/src/main/java/at/iteg/infex/web/InfexFileServlet.java
            
            this.file.arrayBuffer().then(buffer => {
                this.processUploadedFile(buffer);
            }, (err) => {
                this.showRetryButton = true;
                console.error("uploadTemporaryDocumentVersion Promise failed");
            });
        },

        filesSelected(uploads) {
            console.info("Files selected to upload:",uploads);

            if (uploads == null || uploads.length == 0) {
                console.info("No uploads");
                this.uploadProgresses = null;
                this.currentUpload = -1;                
                /*
                if (this.uploadAbortController != null) {
                    console.log("Aborting running upload in folder [",this.folder.displayName,"].");
                    this.uploadAbortController.abort();
                }
                else {
                    this.uploadProgresses = null;
                    this.currentUpload = -1;
                    this.uploadAbortController = null;
                    } */
                
            } else {
                this.startUpload(uploads);
            }            
        },

        startUpload(files) {
            /*
            const maxSize = this.folder.individualMB*1024*1024;

            let uploadSize = 0;
            for (let i=0;i<files.length;++i) {
                if (files[i].size > maxSize) {
                    console.error(this.$t('infex.uploadedFileTooLarge',
                                      {size:this.formatSize(files[i].size),maxSize:this.formatSize(maxSize),file:files[i].name,folder:this.folder.displayName}));
                    this.uploadProgresses = null;
                    this.dragActive=2;
                    return;
                }

                uploadSize += files[i].size;
            }*/

            //const formattedFiles = this.formatFileNames(files);
            /*
            const remaining = this.folder.totalMB*1024*1024 - this.totalSize;
            
            if (uploadSize > remaining) {
                log.error(this.$tc('infex.uploadedFilesTooLarge',files.length,
                                   {size:this.formatSize(uploadSize),remaining:this.formatSize(remaining),files:formattedFiles,folder:this.folder.displayName}));
                this.uploadProgresses = null;
                this.dragActive=2;
                return;
            }*/
            /*
            if (this.currentUpload < 0) {
                this.uploadProgresses = [];
            }
            
            for (let i=0;i<files.length;++i) {
                this.uploadProgresses.push({
                    id:++this.lastUploadProgressId,
                    file:files[i],
                    filename:files[i].name,
                    progress:0,
                    state:"pending"
                }); 
            }
            if (this.currentUpload < 0) {
                this.initiateUpload();
            }*/
        },

        startNextUpload(file) {
            this.file = file;
            this.$emit("fileUploaded");
            this.temporaryDocumentVersionId = null;
            this.seekLabelResult = null;
            if (this.file == null || this.planNumber === loadingPlaceholder || this.unreleasedDocumentId === loadingPlaceholder) {
                // No file provided, or not yet initialized
                return;
            }

            /*
            if (this.uploadProgresses == null || this.currentUpload < 0 || this.currentUpload >= this.uploadProgresses.length) {
                return;
            }

            const uploadProgress = this.uploadProgresses[this.currentUpload];*/


            const newIdString = randomUuid();

            let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
            let planNumber : string = this.planNumber as string;
            let unreleasedDocumentId : number = this.unreleasedDocumentId as number;

            const formData = new FormData();
            formData.append("fileName", file.name);
            formData.append("id", newIdString);
            if (planNumber != null) {
                formData.append("planNumber", planNumber);
            }
            if (uploadInfo.versionPartA != null) {
                formData.append("versionPartA", uploadInfo.versionPartA);
            }
            if (uploadInfo.versionPartB != null) {
                formData.append("versionPartB", uploadInfo.versionPartB);
            }
            if (unreleasedDocumentId != null) {
                formData.append("unreleasedDocumentVersion", unreleasedDocumentId?.toString());
            }
            if (this.taskId != null) {                
                formData.append("taskId", this.taskId?.toString());
            }
            formData.append("organisationPersonId", this.ctx.activeOrganisationPersonId?.toString());
            
            formData.append("file", file, file.name);

            let req = new Request('/cdes/svc/uploadTemporaryVersion',{method:"POST",body:formData});

            //this.uploadAbortController = new AbortController();
            //uploadProgress.progress = 1;

            //log.info(this.$t('infex.uploadingFile',
            //{file:uploadProgress.filename,folder:this.folder.displayName}));

            //uploadProgress.state = "uploading";

            fetch(req,{}).then(
//            fetch(req,{signal:this.uploadAbortController.signal}).then(
                (resp) => {
                    resp.text().then(
                        (txt) => {
                            if (txt == "<html><body>OK</body></html>") {
//                                uploadProgress.progress = 100;
//                                uploadProgress.state = "finished";
//                                log.ok(this.$t('infex.successfullyUploadedFile',
//                                               {file:uploadProgress.filename,folder:this.folder.displayName}));
//                                console.log("Successfully uploaded file [",uploadProgress.filename,"] to folder [",this.folder.displayName,"].");
                                console.info("Successfully uploaded file");

                                if (this.prevTemporaryDocumentVersionId != null) {
                                    this.ctx.documentService.deleteTemporaryDocumentVersion(this.prevTemporaryDocumentVersionId);
                                }
                                this.seekLabel(newIdString);                                

                                /*
                                ++this.currentUpload;
                                if (this.currentUpload >= this.uploadProgresses.length) {
                                    this.uploadAbortController = null;
                                    this.currentUpload = -1;
                                }
                                this.$nextTick(this.loadFiles);*/
                            } else {
                                /*
                                this.uploadAbortController = null;
                                this.currentUpload = -1;
                                uploadProgress.state = "failed";
                                console.log("Upload to folder [",this.folder.displayName,"] failed by server error [",txt,"].");
                                log.error(this.$t('infex.uploadingFileFailed',
                                {file:uploadProgress.filename,folder:this.folder.displayName}));
                                */
                                this.showRetryButton = true;
                                console.error("Upload failed.");
                            }
                        }, (err) => {
                            /*
                            this.uploadAbortController = null;
                            this.currentUpload = -1;
                            uploadProgress.state = "failed";
                            
                            console.log("Upload to folder [",this.folder.displayName,"] failed by transport error:",err);
                            log.error(this.$t('infex.uploadingFileFailed',
                            {file:uploadProgress.filename,folder:this.folder.displayName}));
                            */
                            this.showRetryButton = true;
                            console.error("Upload failed");
                        }
                    );
                    
                },
                (err) => {
                    /*
                    this.uploadAbortController = null;
                    this.currentUpload = -1;
                    uploadProgress.state = "failed";
                    console.log("Upload to folder [",this.folder.displayName,"] failed:",err);
                    log.error(this.$t('infex.uploadingFileFailed',
                    {file:uploadProgress.filename,folder:this.folder.displayName}));
                    */
                    this.showRetryButton = true;
                    console.error("Upload failed");
                }
            );
            //setTimeout(this.queryProgress.bind(this),1000);
        },

        processUploadedFile(buffer) {
            const newIdString = randomUuid();

            let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
            let planNumber : string = this.planNumber as string;
            let unreleasedDocumentId : number = this.unreleasedDocumentId as number;
            this.ctx.documentService.uploadTemporayDocumentVersion(newIdString, this.ctx.activeOrganisationPersonId,
                                                                   this.file.name, planNumber,
                                                                   uploadInfo.versionPartA, uploadInfo.versionPartB,
                                                                   unreleasedDocumentId, this.taskId, new Uint8Array(buffer))
                .then(() => {
                    if (this.prevTemporaryDocumentVersionId != null) {
                        this.ctx.documentService.deleteTemporaryDocumentVersion(this.prevTemporaryDocumentVersionId);
                    }
                    this.seekLabel(newIdString);
                }, (err) => {
                    this.showRetryButton = true;
                    console.error("uploadTemporaryDocumentVersion Promise failed");
                });
        },

        seekLabel(temporaryDocumentVersionId : string) {
            this.ctx.documentService.seekLabel(temporaryDocumentVersionId, null)
                .then((seekLabelResult : SeekLabelResult) => {
                    if (seekLabelResult.labelStatus == LabelStatus.PENDING) {
                        // CDES-2937: Timeouts are detected at server side in
                        // at.cdes.controller.actionhandler.LabelSeekerImpl.seekLabel(Long, String, String, ManualNotFoundResolution)
                        // In this case, LabelStatus.PENDING is delivered to the client,
                        // and we need to try again.
                        if (this.seekLabelCallCount < this.seekLabelMaxCallCount) {
                            this.seekLabelCallCount++;
                            this.seekLabel(temporaryDocumentVersionId);
                        }
                    } else {
                        this.prevTemporaryDocumentVersionId = temporaryDocumentVersionId;
                        this.temporaryDocumentVersionId = temporaryDocumentVersionId;
                        console.info("Setting temporaryDocumentVersionId [" + this.temporaryDocumentVersionId + "]");
                        this.$emit("update:temporaryDocumentVersionId", this.temporaryDocumentVersionId);
                        
                        this.processUnresolvedSeekLabelResult(seekLabelResult);
                    }
                }, (err) => {
                    this.showRetryButton = true;
                    console.error("seekLabel without manualNotFoundResolution Promise failed");
                });
        },

        processUnresolvedSeekLabelResult(seekLabelResult : SeekLabelResult) {
            let labelStatus = seekLabelResult.labelStatus;
			let dialogsTitle = this.$t("review.upload.preview.notFoundButObligatoryTitle");
            let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
            this.$emit("update:labelStatus", labelStatus);

            this.seekLabelResult = seekLabelResult;
            let options = [];
            if ([LabelStatus.GOOD, LabelStatus.PERFECT, LabelStatus.DOUBTFUL, LabelStatus.BAD].includes(labelStatus)) {
                this.showRetryButton = false;
                if (uploadInfo.labelOutsidePossible && 
						(uploadInfo.labelOutsideMandatory || [LabelStatus.DOUBTFUL, LabelStatus.BAD].includes(labelStatus))) {
					dialogsTitle = this.$t("review.upload.preview.foundTitle");
                    options.push([ManualNotFoundResolution.RENDER_LABEL_AS_IS, "RENDER_LABEL_AS_IS"]);
                    options.push([ManualNotFoundResolution.RENDER_LABEL_OUTSIDE_OF_PLAN, "RENDER_LABEL_OUTSIDE_OF_PLAN"]);
                } else
					return;
            } else {
                if ([LabelStatus.UNKNOWN, LabelStatus.NOT_FOUND].includes(labelStatus)) {
                    if (uploadInfo.labelDisablingPossible) {
						dialogsTitle = this.$t("review.upload.preview.notFoundTitle");
                        options.push([ManualNotFoundResolution.LABEL_IGNORED, "LABEL_IGNORED"]);
                    }
                    if (uploadInfo.labelOutsidePossible) {
						dialogsTitle = this.$t("review.upload.preview.notFoundTitle");
                        options.push([ManualNotFoundResolution.RENDER_LABEL_OUTSIDE_OF_PLAN, "RENDER_LABEL_OUTSIDE_OF_PLAN"]);
                    }
                } else if ([LabelStatus.OUTSIDE].includes(labelStatus)) {
                    let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
					dialogsTitle = this.$t("review.upload.preview.notFoundTitle");
                    options.push([ManualNotFoundResolution.RENDER_LABEL_OUTSIDE_OF_PLAN, "RENDER_LABEL_OUTSIDE_OF_PLAN"]);
                }
            }
            let notFoundDialog : InstanceType<typeof NotFoundResolutionDialog> = this.$refs.notFoundDialog as InstanceType<typeof NotFoundResolutionDialog>;
            notFoundDialog.show({ options : options, labelStatus : labelStatus, title : dialogsTitle }).then((selectedOption : ManualNotFoundResolution) => {
                this.processNotFoundResult(selectedOption);
            }, (err) => {
                console.error("notFoundDialog Promise failed");
            });
        },

        processNotFoundResult(selectedOption : ManualNotFoundResolution) {
			console.info("processNotFoundResult: [" + selectedOption + "]");
			if (!selectedOption){ //cancel
				this.file = null;
				this.seekLabelResult = null;
				return;
			} else if (selectedOption == ManualNotFoundResolution.RENDER_LABEL_AS_IS)
				return;
				
            this.ctx.documentService.seekLabel(this.temporaryDocumentVersionId, selectedOption)
                .then((seekLabelResult : SeekLabelResult) => {
                    if (seekLabelResult.labelStatus == LabelStatus.PENDING) {
                        // CDES-2937: Timeouts are detected at server side in
                        // at.cdes.controller.actionhandler.LabelSeekerImpl.seekLabel(Long, String, String, ManualNotFoundResolution)
                        // In this case, LabelStatus.PENDING is delivered to the client,
                        // and we need to try again.
                        if (this.seekLabelCallCount < this.seekLabelMaxCallCount) {
                            this.seekLabelCallCount++;
                            this.processNotFoundResult(selectedOption);
                        }
                    } else {
                        this.showRetryButton = false;
                        this.seekLabelResult = seekLabelResult;
						let labelStatus = seekLabelResult.labelStatus;
						this.$emit("update:labelStatus", labelStatus);
                    }
                }, (err) => {
                    this.showRetryButton = true;
                    console.error("seekLabel with manualNotFoundResolution Promise failed");
                });
        },

        openPreviewWindow(): void {
            if (this.seekLabelResult == null) {
                return;
            }
            if (this.uploadInfo === loadingPlaceholder || this.uploadInfo == null) {
                return;
            }
            /*
            if (this.destinationId === loadingPlaceholder || this.destinationId == null) {
                return;
            }*/
            if (this.temporaryDocumentVersionId == null) {
                return;
            }

            window.open(this.ctx.getTapestryRequestUrl("external/UploadPngPreview", [
                "SuploadDocument",
                "S" + this.temporaryDocumentVersionId,
                "l" + (this.destinationId != loadingPlaceholder && this.destinationId != null ? this.destinationId : "-1"),
                "" + this.seekLabelResult.previewRotation,
            ]), undefined, "popup");
        }
    },
});
