import { defineComponent, getCurrentInstance } from "vue";

import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import ComboBox from "cdes-vue/util/form/ComboBox.vue";
import Select from "cdes-vue/util/form/Select.vue";
import FormGrid from "cdes-vue/util/form/FormGrid.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import Label from "cdes-vue/util/form/Label.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { ProjectPageSearchInfo } from "cdes-api/dto/project/ProjectPageSearchInfo";
import { ProjectPageSearchModel } from "cdes-api/dto/project/ProjectPageSearchModel";
import { Project } from "cdes-api/dto/Project";
import { Person } from "cdes-api/dto/Person";
import { Network } from "cdes-api/dto/Network";
import { ProjectStatus } from "cdes-vue/voc/project/ProjectStatus";
import { SubProject } from "cdes-api/dto/SubProject";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ProjectSearchJoin } from "cdes-api/joinDto/ProjectSearchJoin";

export default defineComponent({
    components: {
        CustomSelect,
        ComboBox,
        Select,
        Label,
        FormGrid,
        FormGridColumn,
        FormGridMember,
    },

    props: {
        disabled : {
            type : Boolean
        }
    },

    data() {
        console.log(this.getDefaultNetwork());
        return {
            searchInfo : null,
            networkOptions : [],
            selectedNetworkId : null,
            selectedProjectId : null,
            selectedSubProjectId : null,
            selectedProjectLeaderId : null,
            selectedProjectStatus : null,
            ProjectStatus : ProjectStatus,
            initialized : false
        };
    },

    emits: ["search"],    

    mounted() {
        this.selectedNetworkId = this.getDefaultNetwork()?.id;

        this.ctx.projectService.getProjectPageSearchInfo(this.ctx.activeOrganisationPersonId).then((searchInfo : ProjectPageSearchInfo) => {
            this.searchInfo = searchInfo;

            let networks : Network[] = this.searchInfo.networks.sort((n1,n2) =>
                n1.name != null && n2.name != null ? n1.name.localeCompare(n2.name) : 0);
            this.networkOptions = [];
            for (let network of networks) {
                let option : SelectOption = {
                    label : network.name,
                    value : network.id
                };
                this.networkOptions.push(option);
            }

            this.populateFromLocalStorage();

            this.$emit("search", this.searchModel);

            window.setTimeout(() => {
                this.initialized = true;
            }, 0);
        }, (err) => {
            ErrorHelper.processError(this.$t, this.$d, err);
        });
    },

    computed : {
        projectOptions() : SelectOption[] {
            if (this.searchInfo != null) {
                let projects : Project[] = [];
                if (this.selectedNetworkId != null) {
                    projects = this.searchInfo.projectsByNetwork.get(this.selectedNetworkId);
                } else {
                    projects = [];
                    for (let networkId of this.searchInfo.projectsByNetwork.keys()) {
                        projects = projects.concat(this.searchInfo.projectsByNetwork.get(networkId));
                    }
                }
                
                projects = projects.sort((p1,p2) => p1.name != null && p2.name != null ? p1.code.localeCompare(p2.code) : 0);
                
                let options : SelectOption[] = [];
                for (let project of projects) {
                    options.push({
                        label : "(" + project.code + ") " + project.name,
                        value : project.id
                    });
                }
                return options;
            } else {
                return [];
            }
        },
        subProjectOptions() : SelectOption[] {
            if (this.searchInfo != null) {
                let subProjects : SubProject[] = [];
                if (this.selectedProjectId != null) {
                    subProjects = this.searchInfo.subProjectsByProject.get(this.selectedProjectId);
                } else {
                    subProjects = [];
                    if (this.selectedNetworkId != null) {
                        let projects : Project[] = this.searchInfo.projectsByNetwork.get(this.selectedNetworkId);
                        for (let project of projects) {
                            let currSubProjects = this.searchInfo.subProjectsByProject.get(project.id);
                            if (currSubProjects != null) {
                                subProjects = subProjects.concat(currSubProjects);
                            }
                        }
                    } else {
                        for (let networkId of this.searchInfo.projectsByNetwork.keys()) {
                            let projects : Project[] = this.searchInfo.projectsByNetwork.get(networkId);
                            if (projects != null) {
                                for (let project of projects) {
                                    let currSubProjects = this.searchInfo.subProjectsByProject.get(project.id);
                                    if (currSubProjects != null) {
                                        subProjects = subProjects.concat(currSubProjects);
                                    }
                                }
                            }
                        }
                    }
                }
                
                let keyToSubProject : Map<string, SubProject> = new Map<string, SubProject>();
                for (let subProject of subProjects) {
                    let key = (subProject.code ?? '') + (subProject.number ?? '') + (subProject.name ?? '');
                    keyToSubProject.set(key, subProject);
                }
                subProjects = [];
                for (let subProject of keyToSubProject.values()) {
                    subProjects.push(subProject);
                }

                subProjects = subProjects.sort((sp1,sp2) =>
                    ((sp1.code ?? '') + (sp1.number ?? '') + (sp1.name ?? '')).localeCompare((sp2.code ?? '') + (sp2.number ?? '') + (sp2.name ?? '')));

                let options : SelectOption[] = [];
                for (let subProject of subProjects) {
                    options.push({
                        label : this.getSubProjectLabel(subProject),
                        value : subProject.id
                    });
                }
                return options;                
            } else {
                return [];
            }
            /*
            if (typeof this.selectedProjectId == "string") {
                return [];
            } else if (this.selectedProjectId != null && this.searchInfo != null) {
                let subProjects : SubProject[] = this.searchInfo.subProjectsByProject.get(this.selectedProjectId);
                subProjects = subProjects.sort((sp1,sp2) =>
                    ((sp1.code ?? '') + (sp1.number ?? '') + (sp1.name ?? '')).localeCompare((sp2.code ?? '') + (sp2.number ?? '') + (sp2.name ?? '')));

                let options : SelectOption[] = [];
                for (let subProject of subProjects) {
                    options.push({
                        label : "(" + subProject.code + (subProject.number ?? "") + ") " + subProject.name,
                        value : subProject.id
                    });
                }
                return options;
            } else {
                return [];
            }*/
        },
        projectLeaderOptions() : SelectOption[] {
            if (this.searchInfo != null) {
                let projectLeaders : ProjectSearchJoin[];
                if (this.selectedNetworkId != null && this.searchInfo.projectLeadersByNetwork.size > 0) {
                    projectLeaders = this.searchInfo.projectLeadersByNetwork.get(this.selectedNetworkId);
                } else {
                    projectLeaders = [];
                    for (let networkId of this.searchInfo.projectLeadersByNetwork.keys()) {
                        projectLeaders = projectLeaders.concat(this.searchInfo.projectLeadersByNetwork.get(networkId));
                    }
                }

                // Eliminate duplicates
                let personIdToProjectLeader : Map<number, ProjectSearchJoin> = new Map<number, ProjectSearchJoin>();
                for (let projectLeader of projectLeaders) {
                    personIdToProjectLeader.set(projectLeader.projectLeaderId, projectLeader);
                }

                projectLeaders = [];
                for (let personId of personIdToProjectLeader.keys()) {
                    projectLeaders.push(personIdToProjectLeader.get(personId));
                }

                projectLeaders = projectLeaders.sort((p1,p2) => {
                    let g1 = p1.projectLeaderGivenName ?? '';
                    let g2 = p2.projectLeaderGivenName ?? '';
                    let s1 = p1.projectLeaderSurName ?? '';
                    let s2 = p2.projectLeaderSurName ?? '';
                    let n1 = g1 + s1;
                    let n2 = g2 + s2;
                    //return n1.localeCompare(n2);
                    return s1.localeCompare(s2);
                });

                let options : SelectOption[] = [];
                for (let projectLeader of projectLeaders) {
                    options.push({
                        label : projectLeader.projectLeaderGivenName + " " + projectLeader.projectLeaderSurName
                            + " (" + projectLeader.organisationName + ")",
                        value : projectLeader.projectLeaderId
                    });
                }
                return options;
            } else {
                return [];
            }
        },
        projectStatusOptions() : SelectOption[] {
            return [
                { label : this.$t("general.projectStatus.ACTIVE"), value : ProjectStatus.ACTIVE },
                { label : this.$t("general.projectStatus.INACTIVE"), value : ProjectStatus.INACTIVE },
            ];
        },

        searchModel(): ProjectPageSearchModel {
            let status: ProjectStatus[];

            if (this.selectedProjectStatus in ProjectStatus) {
                status = [this.selectedProjectStatus as ProjectStatus];
            } else if (typeof this.selectedProjectStatus === "string") {
                status = Object.values(ProjectStatus)
                .filter(state => {
                    const i18nStateString = this.$t("general.projectStatus." + state).toLowerCase();
                    const selectedStateString = (this.selectedProjectStatus as string).toLowerCase();
                    console.log("includes", i18nStateString, selectedStateString);
                    return i18nStateString.includes(selectedStateString);
                }) as ProjectStatus[];
            }

            let subProjectId = null;
            let subProjectString = null;

            if (typeof this.selectedSubProjectId == "number") {
                if (this.selectedProjectId != null) {
                    subProjectId = this.selectedSubProjectId;
                } else {
                    for (let subProjects of this.searchInfo.subProjectsByProject.values()) {
                        for (let subProject of subProjects) {
                            if (this.selectedSubProjectId == subProject.id) {
                                subProjectString = this.getSubProjectLabel(subProject);
                            }
                        }
                    }
                }
            } else if (typeof this.selectedSubProjectId == "string") {
                subProjectString = this.selectedSubProjectId;
            }

            return {
                networkId : typeof this.selectedNetworkId == "number" ? this.selectedNetworkId : null,
                networkString : typeof this.selectedNetworkId == "string" ? this.selectedNetworkId : null,
                projectId : typeof this.selectedProjectId == "number" ? this.selectedProjectId : null,
                projectString : typeof this.selectedProjectId == "string" ? this.selectedProjectId : null,
                subProjectId : subProjectId,
                subProjectString : subProjectString,
                status : status,
                projectLeaderPersonId : typeof this.selectedProjectLeaderId == "number" ? this.selectedProjectLeaderId : null,
                projectLeaderString : typeof this.selectedProjectLeaderId == "string" ? this.selectedProjectLeaderId : null
                /*
                networkId: typeof this.selectedNetworkId === "number" && this.networkValue != null ? this.networkValue.id : undefined,
                networkString: typeof this.networkValue === "string" ? this.networkValue : undefined,
                projectId: typeof this.projectValue === "object" && this.projectValue != null ? this.projectValue.id : undefined,
                projectString: typeof this.projectValue === "string" ? this.projectValue : undefined,
                //                subProjectId: typeof this.subProjectValue === "object" && this.subProjectValue != null ? this.subProjectValue.id : undefined,
                subProjectString : typeof this.subProjectValue === "object" && this.subProjectValue != null
                    ? this.subProjectValue.name : undefined,
                //subProjectString: typeof this.subProjectValue === "string" ? this.subProjectValue : undefined,
                status,
                projectLeaderPersonId: typeof this.projectLeaderValue === "object" && this.projectLeaderValue != null ? this.projectLeaderValue.id : undefined,
                projectLeaderString: typeof this.projectLeaderValue === "string" ? this.projectLeaderValue : undefined,
                */
            };
        }        
    },

    methods : {
        getDefaultNetwork(): Network {
            return this.ctx.getNetworksForOrganisationPersonId(this.ctx.activeOrganisationPersonId)
                .find(network => network.id == this.ctx.activeNetworkId);
        },
        clearSearchFields() : void {
            this.selectedProjectId = null;
            this.selectedSubProjectId = null;
            this.selectedProjectStatus = null;
            this.selectedProjectLeaderId = null;
        },
        populateFromLocalStorage() : void {
            let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
            let searchModel = LocalStorageHelper.getFromLocalStorage([ opString ], "/project/search");

            if (searchModel == null) {
                return;
            }

            console.info("Loaded searchmodel, will now populate fields");
            console.info(searchModel);
            
            this.selectedNetworkId = searchModel.networkId;
            this.selectedProjectId = searchModel.projectId;
            this.selectedSubProjectId = searchModel.subProjectId ?? searchModel.subProjectString;
            this.selectedProjectLeaderId = searchModel.projectLeaderPersonId;
            this.selectedProjectStatus = searchModel.status != null && searchModel.status.length > 0
                ? searchModel.status[0] : null;
        },
        getSubProjectLabel(subProject : SubProject) : string {
            return "(" + subProject.code + (subProject.number ?? "") + ") " + subProject.name;
        }
    },

    watch : {
        selectedNetworkId(newNetworkId : number, oldNetworkId : number) : void {
            if (newNetworkId != oldNetworkId && this.initialized) {
                this.selectedProjectId = null;
                this.selectedSubProjectId = null;
                this.selectedProjectLeaderId = null;
            }
        },
        selectedProjectId(newProjectId : number, oldProjectId : number) : void {
            if (newProjectId != oldProjectId && this.initialized) {
                this.selectedSubProjectId = null;
            }
        }
    }
});
