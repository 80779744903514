/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
// INTERNAL: Should only be imported by TreeView.
import { defineComponent, PropType, InjectionKey, ComponentPublicInstance, Ref, computed, getCurrentInstance, ComputedRef, inject, reactive, watchEffect, toRefs, EffectScope, effectScope, markRaw, watch, unref, onScopeDispose } from "vue";
import { LoadingPlaceholder, loadingPlaceholder, eagerComputed } from "cdes-vue/util/Prop";
import TreeItem from "./TreeItem.vue";
import ClassType from "../Class";

export type TreeModel<T, K = T> = {
    getChildren(parent?: T): Ref<T[] | LoadingPlaceholder> | T[] | LoadingPlaceholder;
    isExpanded?(value: T): boolean;
    isDisabled?(value: T): boolean;
    isLeaf(value: T): boolean;
    getKey?(value: T): K;
    keyEquals?(a: K, b: K): boolean;
    keyHash?(value: K): unknown;
};

export enum State {
    GROUP_NOTHING,
    GROUP_LOADING,
    GROUP_LOADED,
    LEAF,
    LOADING,
}

type BasicGroupTreeItemData<T> = ({
    state: State.GROUP_NOTHING,
} | {
    state: State.GROUP_LOADING,
} | {
    state: State.GROUP_LOADED,
}) & {
    expanded: boolean,
    data: T,
    children: TreeItemData<T>[],
};

type BasicTreeItemData<T> = ({
    state: State.LOADING,
} | {
    state: State.LEAF,
    data: T,
} | BasicGroupTreeItemData<T>);

export type TreeItemData<T> = BasicTreeItemData<T> & {
    ref: InstanceType<typeof TreeItem> | null,
    path: TreePath,
    disabled: boolean,
    scope: EffectScope,
};

export function then<T, R>(value: T | Promise<T>, callback: (val: T) => (R | Promise<R>)): R | Promise<R> {
    if (value instanceof Promise) {
        return value.then(callback);
    } else {
        return callback(value);
    }
}

export function getLoadingChildren<T>(path: TreePath): TreeItemData<T>[] {
    return new Array(4).fill(null).map((_, index) => ({
        state: State.LOADING,
        ref: null,
        path: [...path, index],
        disabled: true,
        scope: null,
    }));
}

export function initializeTreeItem<T, K>(common: CommonOptions<T, K>, data: T, path: TreePath): TreeItemData<T> {
    // We want all all of the reactivity stuff related to an item to be destroyed together with the component that will render it.
    // But we don't want to wait for that component to be created in order to guarantee that if the model loads data synchronously we can load it all at once.
    const scope = markRaw(effectScope());
    const newItem = scope.run(() => {
        const key = computed(() => common.model.getKey != null ? common.model.getKey(data) : data);
        const disabled = computed(() => common.model.isDisabled?.(data) ?? false);
        const isLeaf = computed(() => common.model.isLeaf(data));
        const expanded = computed(() => isLeaf.value ? undefined : (common.model.isExpanded?.(data) ?? false));

        const newItem = reactive({
            data,
            path,
            ref: null,
            disabled,
            scope,
        }) as unknown as TreeItemData<T>;

        // It would be nicer to use a computed for this but that would result in having one more level of Ref that would be hard to deal with.
        watch(
            isLeaf,
            (isLeaf) => {
                if (isLeaf) {
                    newItem.state = State.LEAF;
                    // @ts-ignore
                    delete newItem.children;
                    // @ts-ignore
                    delete newItem.expanded;
                } else {
                    newItem.state = State.GROUP_NOTHING;
                    // @ts-ignore
                    newItem.children = getLoadingChildren(path);
                    // @ts-ignore
                    newItem.expanded = expanded.value;
                }
            },
            {
                immediate: true,
            },
        );

        let loadingScope = null;
        watchEffect(() => {
            if (loadingScope != null && (newItem.state === State.LEAF || newItem.state === State.GROUP_NOTHING)) {
                loadingScope.stop();
                loadingScope = null;
            }
            if (newItem.state === State.GROUP_NOTHING && newItem.expanded) {
                Object.assign(newItem, {
                    state: State.GROUP_LOADING,
                });
                loadingScope = effectScope();
                loadingScope.run(() => {
                    startLoadingItem(common, newItem);
                });
            }
        });

        common.allNodes.add(newItem);
        onScopeDispose(() => {
            common.allNodes.delete(newItem);
        });

        watch(
            key,
            (newKey, oldKey, onInvalidate) => {
                onInvalidate(() => {
                    common.allNodesByKey.delete(newKey as K);
                });

                common.allNodesByKey.set(newKey as K, newItem);
            },
            {
                immediate: true,
            },
        );

        return newItem;
    });

    return newItem;
}

export function startLoading<T, K>(common: CommonOptions<T, K>, path: TreePath, data?: T): ComputedRef<TreeItemData<T>[] | LoadingPlaceholder> {
    const isRoot = arguments.length <= 2;
    const children = eagerComputed((onInvalidated) => {
        const scope = effectScope();
        onInvalidated(() => scope.stop());
        return scope.run(() => {
            if (isRoot) {
                return common.model.getChildren();
            } else {
                return common.model.getChildren(data);
            }
        });
    });

    const itemChildren = computed(() => {
        const childrenValue = unref(children.value);
        if (childrenValue === loadingPlaceholder) {
            return loadingPlaceholder;
        } else {
            return childrenValue?.map((data, index) => {
                const item = initializeTreeItem(common, data as T, [...path, index]);

                return item;
            }) ?? [];
        }
    });

    return itemChildren;
}

export function startLoadingItem<T, K>(common: CommonOptions<T, K>, item: TreeItemData<T>): void {
    if (item.state === State.LOADING) {
        throw new Error("Tried to start loading children even though we are still loading ourselves.");
    }

    const itemChildren = startLoading(common, item.path, item.data);

    watch(
        itemChildren,
        (itemChildren) => {
            if (itemChildren !== loadingPlaceholder && item.state === State.GROUP_LOADING) {
                Object.assign(item, {
                    state: State.GROUP_LOADED,
                    children: itemChildren,
                });
            } else if (itemChildren === loadingPlaceholder && item.state === State.GROUP_LOADED) {
                Object.assign(item, {
                    state: State.GROUP_LOADING,
                    children: getLoadingChildren(item.path),
                });
            } else {
                throw new Error("Illegal state after loading.");
            }
        },
        {
            immediate: true,
        },
    );
}

export type TreePath = number[];

export interface TreeParent<T = unknown> {
    focusNext(selectedChild: TreeItemData<T>): void,
    focusPrev(selectedChild: TreeItemData<T>): void,
    expandChildren(): void,
    focusThis?(): void,
}

export function useCommon<T, K>({
    commonOptions,
    getChildren,
    getExpanded,
    parent,
    getItem,
}: {
    commonOptions: CommonOptions<T, K>,
    getChildren: () => TreeItemData<T>[],
    getExpanded?: () => boolean,
    parent: ComponentPublicInstance & TreeParent<T>,
    getItem?: (() => TreeItemData<T>) | undefined,
}): {
    focusNext: (selectedChild: TreeItemData<T>) => void,
    focusPrev: (selectedChild: TreeItemData<T>) => void,
    focusFirst: () => void,
    focusLast: () => void,
    expandChildren: () => void,
} {
    const focusThis = () => {
        const item = getItem?.();
        if (item != null) {
            commonOptions.focusedData = item;
        }
    };

    const focusNext = (focusedChild: TreeItemData<T>) => {
        const children = getChildren();
        const focusedIndex = children.indexOf(focusedChild);
        if (focusedIndex < (children.length - 1)) {
            children[focusedIndex + 1].ref.focusThis();
        } else if (parent != null) {
            parent.focusNext(getItem());
        }
    };
    const focusPrev = (focusedChild: TreeItemData<T>) => {
        const children = getChildren();
        const focusedIndex = children.indexOf(focusedChild);
        if (focusedIndex > 0) {
            children[focusedIndex - 1].ref.focusLast();
        } else {
            focusThis();
        }
    };

    const focusLast = () => {
        const children = getChildren();
        if ((getExpanded != null && !getExpanded())
            || children.length <= 0) {
            focusThis();
        } else {
            children[children.length - 1].ref.focusLast();
        }
    };

    const focusFirst = () => {
        const children = getChildren();
        if ((getExpanded != null && !getExpanded())
            || children.length <= 0) {
            focusThis();
        } else {
            children[0].ref.focusThis();
        }
    };

    const expandChildren = () => {
        const children = getChildren();
        for (const child of children) {
            child.ref.expand();
        }
    };

    return {
        focusNext,
        focusPrev,
        focusFirst,
        focusLast,
        expandChildren,
    };
}

type BasicRenderInfo<T> = {
    loading: true,
    leaf: false,
    group: false,
    data: LoadingPlaceholder,
} | {
    loading: false,
    leaf: true,
    group: false,
    data: T,
} | {
    loading: false,
    leaf: false,
    group: true,
    data: T,
    childrenLoading: boolean,
    expanded: boolean,
    toggle: () => void,
};


export type RenderInfo<T> = BasicRenderInfo<T> & {
    selected: boolean,
    disabled: boolean,
    focused: boolean,
    focus: () => void,
};

export type CommonOptions<T, K> = {
    model: TreeModel<T, K>,
    focusable: boolean,
    customItemClass: ((info: RenderInfo<unknown>) => ClassType) | ClassType,
    customItemLabelClass: ((info: RenderInfo<unknown>) => ClassType) | ClassType,
    allNodesByKey: Map<K, TreeItemData<T>>,
    allNodes: Set<TreeItemData<T>>,
    action: (value: T) => void | null | undefined,
    selectedData: TreeItemData<T> | null | undefined,
    focusedData: TreeItemData<T> | null | undefined,
    selectionFollowsFocus: boolean,
};

export const commonOptionsKey: InjectionKey<CommonOptions<unknown, unknown>> = Symbol("commonOptions");

export default defineComponent({
    components: {
        TreeItem,
    },

    props: {
        item: {
            type: [Object, Symbol] as PropType<TreeItemData<unknown>>,
        },
        level: {
            type: Number as PropType<number>,
            default: () => 0,
        },
    },


    computed: {
        renderInfo(): RenderInfo<unknown> {
            const basic: BasicRenderInfo<unknown> = (() => {
                if (this.item.state === State.LOADING) {
                    return {
                        data: loadingPlaceholder,
                        leaf: false,
                        loading: true as const,
                        group: false,
                    };
                } else if (this.item.state === State.LEAF) {
                    return {
                        data: this.item.data,
                        leaf: true,
                        loading: false as const,
                        group: false,
                    };
                } else {
                    return {
                        data: this.item.data,
                        leaf: false,
                        loading: false as const,
                        group: true,
                        expanded: this.expanded,
                        childrenLoading: this.childrenLoading,
                        toggle: this.toggle.bind(this),
                    };
                }
            })();

            return {
                ...basic,
                selected: this.selected,
                focused: this.focused,
                disabled: this.item.disabled,
                focus: this.focusThis.bind(this),
            };
        },
        additionalClasses(): ClassType {
            if (typeof this.customItemClass === "function") {
                return this.customItemClass(this.renderInfo);
            } else {
                return this.customItemClass;
            }
        },
        additionalLabelClasses(): ClassType {
            if (typeof this.customItemLabelClass === "function") {
                return this.customItemLabelClass(this.renderInfo);
            } else {
                return this.customItemLabelClass;
            }
        },
        expanded(): boolean {
            const { item } = this;
            if (item.state === State.LOADING
                || item.state === State.LEAF) {
                return false;
            } else if (item.state === State.GROUP_LOADING
                       || item.state === State.GROUP_LOADED
                       || item.state === State.GROUP_NOTHING) {
                return item.expanded;
            } else {
                throw new Error("Illegal state in expanded");
            }
        },
        itemLoading(): boolean {
            return this.item.state === State.LOADING;
        },
        childrenLoading(): boolean {
            const { item } = this;
            if (item.state === State.LOADING) {
                return true;
            } else if (item.state == State.GROUP_LOADED) {
                return false;
            } else if (item.state == State.GROUP_LOADING) {
                return true;
            } else if (item.state == State.GROUP_NOTHING) {
                return true;
            } else if (item.state == State.LEAF) {
                return false;
            } else {
                throw new Error("Illegal state in expanded");
            }
        },
        leaf(): boolean {
            return this.item.state === State.LEAF;
        },
        leafOrLoading(): boolean {
            return this.leaf || this.itemLoading;
        },
        group(): boolean {
            return !this.leafOrLoading;
        },
        selected(): boolean {
            return this.selectedData === this.item;
        },
        focused(): boolean {
            return this.focusedData === this.item;
        },
        tabindex(): string {
            return this.selected ? "0" : "-1";
        },
    },

    setup(props, { emit }) {
        const children = computed<TreeItemData<unknown>[]>(() => {
            const { item } = props;
            if (item.state === State.LEAF
                || item.state === State.LOADING) {
                return [];
            } else if (item.state === State.GROUP_LOADED
                       || item.state === State.GROUP_LOADING
                       || item.state === State.GROUP_NOTHING
            ) {
                return item.children;
            } else {
                throw new Error("Illegal state in children.");
            }
        });

        const inst = getCurrentInstance().proxy;

        const commonOptions = inject(commonOptionsKey);

        return {
            ...useCommon({
                commonOptions,
                getItem: () => props.item,
                getChildren: () => children.value,
                // @ts-ignore
                getExpanded: () => inst.expanded,
                parent: getCurrentInstance().parent.proxy as (ComponentPublicInstance & TreeParent<unknown>),
            }),
            ...toRefs(commonOptions),
            commonOptions,
            children,
            loadingPlaceholder,
            console,
        };
    },

    data() {
        return {
            id: this.$id("item"),
        };
    },

    watch: {
        focused(focused: boolean): void {
            if (focused) {
                const el = (this.$refs.focus as HTMLElement);
                el.focus();
                el.scrollIntoView({
                    block: "nearest",
                    inline: "nearest",
                });
            }
        },
        "item.scope"(newScope: EffectScope, oldScope: EffectScope, onInvalidate: (fn: () => void) => void): void {
            if (newScope != null) {
                onInvalidate(() => newScope.stop());
            }
        },
    },

    methods: {
        toggle(): void {
            const { item } = this;
            if (item.state === State.LOADING
                || item.state === State.LEAF) {
                // IGNORE
            } else if (item.state === State.GROUP_LOADING
                       || item.state === State.GROUP_LOADED
                       || item.state === State.GROUP_NOTHING) {
                item.expanded = !item.expanded;
            } else {
                throw new Error("Illegal state for toggle.");
            }
        },
        expand(): void {
            if (!this.expanded) {
                this.toggle();
            }
        },
        next(): void {
            if (this.expanded
            && this.children.length > 0) {
                this.focusFirst();
            } else {
                (this.$parent as unknown as TreeParent).focusNext(this.item);
            }
        },
        prev(): void {
            (this.$parent as unknown as TreeParent).focusPrev(this.item);
        },
        up(): void {
            if (this.expanded) {
                this.toggle();
            } else {
                (this.$parent as unknown as TreeParent).focusThis?.();
            }
        },
        down(): void {
            if (this.item.state === State.LOADING) {
                return;
            }
            if (!this.expanded) {
                this.toggle();
            } else if (this.children.length > 0) {
                this.children[0].ref.focusThis();
            }
        },
        focusThis(): void {
            this.commonOptions.focusedData = this.item;
        },
        activate(): void {
            if (this.item.disabled) {
                if (this.group) {
                    this.toggle();
                }
                return;
            }
            if (this.item.state === State.LOADING) {
                return;
            }

            if (this.action) {
                this.action(this.item.data);
            } else if (!this.selectionFollowsFocus) {
                this.selectedData = this.item;
            } else if (this.group) {
                this.toggle();
            }
        },
        expandSiblings(): void {
            (this.$parent as unknown as TreeParent).expandChildren();
        }
    },
});
