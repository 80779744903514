/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { Ref, watch } from "vue";

export function observeBoundingClientRect(element: Ref<HTMLElement | null>, callback: (rect: DOMRect) => void): void {
    const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
            callback(entry.target.getBoundingClientRect());
        }
    });

    watch(
        element,
        (element, _, onInvalidate) => {
            if (element != null) {
                onInvalidate(() => observer.unobserve(element));
                observer.observe(element);
            }
        },
        {
            immediate: true,
            flush: "post",
        },
    );
}

export function useElementBreakpoints(element: Ref<HTMLElement | null>, breakpoints: Record<string, number>, prefix: string): void {
    let prevClasses: Set<string> | null = null;

    observeBoundingClientRect(element, (rect) => {
        const downBreakpoints: string[] = [];
        const upBreakpoints: string[] = [];
        let currentBreakpoint: [string, number] | null = null;
        for (const [k, v] of Object.entries(breakpoints)) {
            if (rect.width >= v) {
                if (currentBreakpoint == null || currentBreakpoint[1] < v) {
                    currentBreakpoint = [k, v];
                }

                upBreakpoints.push(k);
            } else {
                downBreakpoints.push(k);
            }
        }

        const currentClasses = new Set<string>();

        if (currentBreakpoint != null) {
            currentClasses.add(`${prefix}-${currentBreakpoint[0]}`);
        }

        for (const upBreakpoint of upBreakpoints) {
            currentClasses.add(`${prefix}-${upBreakpoint}-up`);
        }

        for (const downBreakpoint of downBreakpoints) {
            currentClasses.add(`${prefix}-${downBreakpoint}-down`);
        }

        const classList = element.value.classList;

        let classesToRemove = []

        if (prevClasses != null) {
            for (const prevClass of prevClasses) {
                if (!currentClasses.has(prevClass)) {
                    classesToRemove.push(prevClass);
                }
            }
        }
        classList.remove(...classesToRemove);

        classList.add(...currentClasses);

        prevClasses = currentClasses;
    });
}
