import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }
const _hoisted_7 = ["data-bs-dismiss", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Transform = _resolveComponent("Transform")!

  return (!_ctx.lazy || this.data != null)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", _mergeProps({
          id: "dialogRoot",
          ref: "dialogRoot",
          class: "modal",
          tabindex: "-1"
        }, _toHandlers({
            'hide.bs.modal': [_ctx.onHide, _ctx.getReEmitter('hide')],
            'hidden.bs.modal': _ctx.getReEmitter('hidden'),
            'show.bs.modal': _ctx.getReEmitter('show'),
            'shown.bs.modal': [_ctx.onShown, _ctx.getReEmitter('shown')],
            'hidePrevented.bs.modal': _ctx.getReEmitter('hidePrevented'),
            }), {
          "aria-labelledby": _ctx.$id('title'),
          "aria-hidden": "true"
        }, _ctx.$attrs, {
          onVnodeMounted: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDialogMounted && _ctx.onDialogMounted(...args))),
          onVnodeUnmounted: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDialogUnmounted && _ctx.onDialogUnmounted(...args)))
        }), [
          _createVNode(_component_Transform, {
            transformer: (vnode) => _ctx.$slots.dialogWrapper?.({ vnode, submit: _ctx.submit, abort: _ctx.abort }) ?? vnode
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.form ? 'form' : 'div'), {
                class: _normalizeClass(this.cssClass)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("h5", {
                        class: "modal-title",
                        id: _ctx.$id('title')
                      }, _toDisplayString(this.title ?? ""), 9, _hoisted_4),
                      (this.maximize)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "maximizeButton",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.toggleMaximized && this.toggleMaximized(...args)))
                          }, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["bi", (this.maximized ? 'bi-fullscreen-exit' : 'bi-fullscreen')])
                            }, null, 2)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _renderSlot(_ctx.$slots, "default", {
                        submit: _ctx.submit,
                        abort: _ctx.abort,
                        data: _ctx.data?.userData
                      })
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "buttons", {
                        submit: _ctx.submit,
                        abort: _ctx.abort,
                        data: _ctx.data?.userData
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.buttons, (button) => {
                        return (_openBlock(), _createElementBlock("button", {
                          key: button.getKey(),
                          type: "button",
                          class: _normalizeClass(button.getClass()),
                          "data-bs-dismiss": button.isCloseButton() ? 'modal' : '',
                          onClick: ($event: any) => (_ctx.handle(button))
                        }, _toDisplayString(button.getLabel(_ctx.$t)), 11, _hoisted_7))
                      }), 128))
                    ])
                  ])
                ]),
                _: 3
              }, 8, ["class"]))
            ]),
            _: 3
          }, 8, ["transformer"])
        ], 16, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}