import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        for: {
            type: [String, Object] as PropType<undefined | string | HTMLElement>,
        },
        id: {
            type: String as PropType<undefined | string>,
        },
    },

    computed: {
        trueId(): string {
            return this.id ?? this.$id("label");
        },
    },

    methods: {
        getForElement(): HTMLElement | undefined {
            if (this.for == null) {
                return undefined;
            } else if (typeof this.for === "string") {
                return document.getElementById(this.for);
            } else {
                return this.for;
            }
        },
        click(e: MouseEvent): void {
            this.getForElement()?.focus?.();
            e.preventDefault();
        },
    },

    mounted() {
        let reference = this.getForElement();
        if (reference != null) {
            const prev = reference.getAttribute("aria-labelledby");
            reference.setAttribute("aria-labelledby", prev != null ? `${prev} ${this.trueId}` : this.trueId);
        }
    },
});
