/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, SetupContext } from "vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import ReviewNodeResultList from "./ReviewNodeResultList.vue";
import ReviewProtocolContext from "./ReviewProtocolContext";
import ReviewFileWidget from "./ReviewFileWidget.vue";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        ReviewNodeResultList,
        ReviewFileWidget,
    },

    props: {
        showAll: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        selectedVersionId: {
            type: Number as PropType<number | null>,
        },
        disabled : {
            type : Boolean
        }
    },

    emits: ["update:showAll", "showNav"],

/*    setup(props, context) {
        return {
            //            ourShowAll: makePropWithValue(props, context as SetupContext<"update:showAll"[]>, "showAll"),
            ourShowAll : false,
            loadingPlaceholder,
        };
    },*/
    data() {
        return {
            ourShowAll : false,
            loadingPlaceholder : loadingPlaceholder
        };
    },
    
    computed: {
        selectedVersions(): ReviewProtocolVersionJoin[] | LoadingPlaceholder[] {
            if (this.context === loadingPlaceholder) {
                return new Array(this.ourShowAll ? 4 : 1).fill(loadingPlaceholder);
            } else {
                const allVersions = this.context.versionJoins;

                return this.ourShowAll ? allVersions : allVersions.filter(version => version.documentVersionId === this.selectedVersionId);
            }
        }
    },
    watch : {
        ourShowAll(v : boolean) {
            LocalStorageHelper.storeInLocalStorage([ this.ctx.activeOrganisationPersonId + "" ],
                                                   "/review/showAll", v ? "true" : "false");
        }
    },
    mounted() {
        let storedShowAll : string = LocalStorageHelper.getFromLocalStorage([ this.ctx.activeOrganisationPersonId + "" ],
                                                                            "/review/showAll");
        if (storedShowAll != null) {
            if (storedShowAll == "true") {
                this.ourShowAll = true;
            } else {
                this.ourShowAll = false;
            }
        }
    }
});
