import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { OriginalDocumentOrderInfo } from "cdes-api/dto/original/OriginalDocumentOrderInfo";


export default defineComponent({
  components : {
    ParempiGrid,
  },

  props: {
    infos: {
      type: Array as PropType<OriginalDocumentOrderInfo[]>,
      default: () => [],
    }
  },

  computed: {
    columns() : ParempiColumn<OriginalDocumentOrderInfo>[] {
      return [
        ParempiColumn.asRowSpanSlot("planNumber", this.$t("general.planNumber"), "planNumber"),
        ParempiColumn.asRowSpanSlot("requestedBy", this.$t("general.requestedBy"), "requestedBy"),
        ParempiColumn.asRowSpanSlot("requestedFor", this.$t("general.requestedFor"), "requestedFor"),
        ParempiColumn.asRowSpanSlot("date", this.$t("general.date"), "date"),
        ParempiColumn.asRowSpanSlot("request", this.$t("general.request"), "request"),
      ]
    }
  },
})
