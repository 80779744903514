/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { LabelContentDocTypeInfo } from "cdes-api/dto/review/LabelContentDocTypeInfo";
import { LabelContent } from "cdes-api/dto/LabelContent";
import { MasterDataSet } from "generated/cdes-api/dto/MasterDataSet";
import { LabelContentDocTypeJoin } from "cdes-api/joinDto/LabelContentDocTypeJoin";
import { defineComponent } from "vue";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import LabelContentDocumentTypeListWidget from "cdes-vue/review/label/LabelContentDocumentTypeListWidget.vue";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { LabelContentDocTypeEntry } from "cdes-api/dto/review/LabelContentDocTypeEntry";
import { Project } from "cdes-api/dto/Project";
import SimpleProjectListWidget from "cdes-vue/project/project/SimpleProjectListWidget.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components : {
        LabelContentDocumentTypeListWidget,
        Dialog,
        SimpleProjectListWidget,
        Select,
        TextInput
    },
    
    props: {
        labelContentId : {
            type: Number
        }
    },

    computed : {
        caption() : string {
            return this.$t("review.labelContent.documentType.pageCaption", {
                labelContentName : this.labelContent.name
            });
        },
        masterDataSetOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            let masterDataSets : MasterDataSet[] = this.masterDataSets as MasterDataSet[];
            for (let masterDataSet of masterDataSets) {
                options.push({
                    value : masterDataSet.id,
                    label : masterDataSet.name
                });
            }
            return options;
        },
        selectedEntries() : LabelContentDocTypeEntry[] {
            let joins : LabelContentDocTypeJoin[] = this.masterDataSetIdToJoins.get(this.selectedMasterDataSetId);
            let entries : LabelContentDocTypeEntry[] = [];
            if (joins != null) {
                for (let join of joins) {
                    entries.push({
                        documentTypeId : join.documentTypeId,
                        code : join.documentTypeCode,
                        name : join.documentTypeName,
                        description : join.documentTypeDescription,
                        chosen : join.documentTypeLabelContentLabelContentId != null
                    });
                }
            }

            return entries;
        },
        selectedMasterDataSetDescription() : string {
            let masterDataSets : MasterDataSet[] = this.masterDataSets as MasterDataSet[];
            for (let masterDataSet of masterDataSets) {
                if (masterDataSet.id == this.selectedMasterDataSetId) {
                    return masterDataSet.description;
                }
            }
            return "";
        }
    },

    data() {
        return {
            labelContent : new LabelContent(),
            masterDataSets : [],
            masterDataSetIdToJoins : new Map<number, LabelContentDocTypeJoin[]>(),
            selectedMasterDataSetId : null,
            projects : []
        };
    },

    mounted() {
        let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
        let networkId : number = this.ctx.activeNetworkId;

        this.ctx.reviewService.getLabelContentDocTypeJoins(organisationPersonId, networkId, this.labelContentId)
            .then((labelContentDocTypeInfo : LabelContentDocTypeInfo) => {
                let masterDataSets : MasterDataSet[] = labelContentDocTypeInfo.masterDataSets;
                
                this.labelContent = labelContentDocTypeInfo.labelContent;
                this.masterDataSets = masterDataSets;
                this.masterDataSetIdToJoins = labelContentDocTypeInfo.masterDataSetIdToJoins;
                this.selectedMasterDataSetId = masterDataSets.length > 0 ? masterDataSets[0].id : null;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
    },

    methods : {
        save() : void {
            // TODO: The following code only considers the CheckBoxes corresponding to the currently
            // selected MasterDataSet (according to the dropdown).
            // As in practice, there is just one MasterDataSet, this should be no harm...
            let documentTypeIds : number[] = [];
            for (let entry of this.selectedEntries) {
                if (entry.chosen) {
                    documentTypeIds.push(entry.documentTypeId);
                }
            }
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            this.ctx.reviewService.saveLabelContentDocTypes(organisationPersonId, this.labelContentId, documentTypeIds)
                .then(() => {
                    this.$router.back();
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },
        abort() : void {
            this.$router.back();
        },
        openRelatedProjects() : void {
            let organisationPersonId = this.ctx.activeOrganisationPersonId;
            this.ctx.projectService.getByMasterDataSet(organisationPersonId, this.selectedMasterDataSetId)
                .then((projects : Project[]) => {
                    this.projects = projects;
                    (this.$refs.projectsDialog as InstanceType<typeof Dialog>).show();                    
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        }       
    }
});
