import { DtoHelper } from "dmgen-cli/util/DtoHelper";

export class PositionResultAttachmentMap {

    // Properties: (placed here as documentation)
    //
    // resultId : number; --> ReviewCyclePositionResult
    // attachmentId : number; --> Attachment

    private _resultId : number;
    get resultId() : number {
        this.__checkAttributeDefinition(2, "resultId");
        return this._resultId;
    }
    set resultId(resultId : number) {
        this._resultId = resultId;
    }

    private _attachmentId : number;
    get attachmentId() : number {
        this.__checkAttributeDefinition(3, "attachmentId");
        return this._attachmentId;
    }
    set attachmentId(attachmentId : number) {
        this._attachmentId = attachmentId;
    }


    private __partialInstance : boolean;
    protected __setPartialInstance(__partialInstance : boolean) : void {
        this.__partialInstance = __partialInstance;
    }
    protected __isPartialInstance() : boolean {
        return this.__partialInstance;
    }

    public static constructPartialInstance() : PositionResultAttachmentMap {
        let instance = new PositionResultAttachmentMap();
        instance.__setPartialInstance(true);
        return instance;
    }

    private __definedAttributes : boolean[];
    protected __setDefinedAttributes(__definedAttributes : boolean[]) : void {
        this.__definedAttributes = __definedAttributes;
    }
    protected __getDefinedAttributes() : boolean[] {
        return this.__definedAttributes;
    }
    protected __checkAttributeDefinition(attributeIndex : number, attributeCodeName : string) : void {
        if (this.__definedAttributes != null && !this.__definedAttributes[attributeIndex]) {
            throw new Error("Attribute [" + attributeCodeName + "] is not defined in this dto.  Most probably, you want to check the JoinDto this Dto was extracted from in datamodel.");
        }
    }
    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : PositionResultAttachmentMap {
        let instance = new PositionResultAttachmentMap();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : PositionResultAttachmentMap) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && DtoHelper.areMembersEqual(this.resultId, other.resultId, "Long")
            && DtoHelper.areMembersEqual(this.attachmentId, other.attachmentId, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            __partialInstance: this.__isPartialInstance(),
            __definedAttributes: this.__getDefinedAttributes(),
            resultId: this._resultId,
            attachmentId: this._attachmentId,
        };
    }
}
