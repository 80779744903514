/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { LabelContent } from 'cdes-api/dto/LabelContent';
import CheckBox from 'cdes-vue/util/form/CheckBox.vue';
import { LabelInfo } from "cdes-api/dto/review/LabelInfo";
import { LabelContentInfo } from "cdes-api/dto/review/LabelContentInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components: {
        ParempiGrid,
        CheckBox
    },
    
    emits: ["deleteLabelContent"],

    computed : {
        //
    },

    data() {
        return {
            columns : this.constructColumns(),
        }
    },

        props: {
            labelInfo : Object as PropType<LabelInfo>,
            items: {
                type: Array as PropType<LabelContent[]>,
                default: () => [],
            },
            menuSection : {
                type : String
            }
    },

    methods: {
        openPreview(labelContent : LabelContent) : void {
            let labelPdfLink : string = this.getLabelPdfLink(labelContent);
            window.open(labelPdfLink);
        },
        download(labelContent : LabelContent) : void {
            let labelLink : string = this.getLabelLink(labelContent);
            window.open(labelLink);
        },
        configureDocumentTypes(labelContent : LabelContent) : void {
            let targetName : string = (this.menuSection == "plan" ? "labelContentDocumentTypePage"
                : "labelContentDocumentTypePageAdmin");
            this.$router.push({
                name: targetName,
                query: {
                    labelContentId : labelContent.id
                }
            })
        },
        edit(labelContent : LabelContent) : void {
            let targetName : string = (this.menuSection == "plan" ? "labelContentEdit" : "labelContentEditAdmin");
            this.$router.push({
                name: targetName,
                query: {
                    labelContentId : labelContent.id
                }
            })
        },
        constructColumns() : ParempiColumn<LabelContent>[] {
            let columns : ParempiColumn<LabelContent>[] = [
                ParempiColumn.asRowSpanSlot("name", this.$t("general.name"), "name"),
                ParempiColumn.asRowSpanSlot("isRenderableOutside", this.$t("review.label.list.renderableOutsideCaption"),
                                            "isRenderableOutside"),
                ParempiColumn.asRowSpanSlot("isDefault", this.$t("general.default"), "isDefault"),
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions")
            ]

            return columns;
        },
        /*
        setLabelContentLoneStandard(standardLabelContentId : number) {
            this.items.map((lc : LabelContent) => {
                if (lc.id !== standardLabelContentId) {
                    lc.isDefault = false;
                }
            })
        },*/ 
        preventUnticking(event) {
            const newValue = event.target.checked;
            if (newValue === false)
                event.preventDefault();
        },
        deleteLabelContent(labelContent : LabelContent) {
            if (this.getLabelContent(labelContent.id).isDefault) {
                window.alert(this.$t("review.labelContent.list.defaultNotDeletableMessage"));
                return;
            }
            if (window.confirm(this.$t("review.labelContent.list.reallyDeleteLabelContent", { name : labelContent.name }))) {
                this.ctx.reviewService.deleteLabelContent(this.ctx.activeOrganisationPersonId,
                                                          labelContent.id)
                    .then(() => {
                        this.$emit("deleteLabelContent", labelContent.id)
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        },
        getLabelContent(id : number) {
            return this.items.find((lc) => lc.id === id);
        },

        getLabelLink(labelContent : LabelContent) : string {
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());

            let name : string = labelContent.name ?? "label";
            let filetype : string = labelContent.origFiletype ?? "";
            urlSearchParams.append("filename", name + (filetype.length > 0 ? "." + filetype : ""));
            return "/cdes/svc/label?" + urlSearchParams.toString();
        },

        getLabelPdfLink(labelContent : LabelContent) : string {
            let labelInfo : LabelInfo = this.labelInfo as LabelInfo;
            
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());
            urlSearchParams.append("sp", "X");            
            return "/cdes/svc/labelPdf?" + urlSearchParams.toString();
        }        
    },
});
