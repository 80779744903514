import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class SubProject extends Persistent {

    // Properties: (placed here as documentation)
    //
    // projectId : number; --> Project
    // subProjectTypeId : number; --> SubProjectType
    // name : (string | null | undefined);
    // comment : (string | null | undefined);
    // code : string;
    // objectListId : (number | null | undefined); --> ObjectList
    // documentListId : (number | null | undefined); --> DocumentList
    // number : (number | null | undefined);
    // invalidated : boolean;
    // withAdditionalRoleTypes : boolean;
    // asBuiltReference : (number | null | undefined); --> SubProject
    // readOnly : boolean;

    private _projectId : number;
    get projectId() : number {
        this.__checkAttributeDefinition(13, "projectId");
        return this._projectId;
    }
    set projectId(projectId : number) {
        this._projectId = projectId;
    }

    private _subProjectTypeId : number;
    get subProjectTypeId() : number {
        this.__checkAttributeDefinition(14, "subProjectTypeId");
        return this._subProjectTypeId;
    }
    set subProjectTypeId(subProjectTypeId : number) {
        this._subProjectTypeId = subProjectTypeId;
    }

    private _name : (string | null | undefined);
    get name() : (string | null | undefined) {
        this.__checkAttributeDefinition(15, "name");
        return this._name;
    }
    set name(name : (string | null | undefined)) {
        this._name = name;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(16, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _code : string;
    get code() : string {
        this.__checkAttributeDefinition(17, "code");
        return this._code;
    }
    set code(code : string) {
        this._code = code;
    }

    private _objectListId : (number | null | undefined);
    get objectListId() : (number | null | undefined) {
        this.__checkAttributeDefinition(18, "objectListId");
        return this._objectListId;
    }
    set objectListId(objectListId : (number | null | undefined)) {
        this._objectListId = objectListId;
    }

    private _documentListId : (number | null | undefined);
    get documentListId() : (number | null | undefined) {
        this.__checkAttributeDefinition(19, "documentListId");
        return this._documentListId;
    }
    set documentListId(documentListId : (number | null | undefined)) {
        this._documentListId = documentListId;
    }

    private _number : (number | null | undefined);
    get number() : (number | null | undefined) {
        this.__checkAttributeDefinition(20, "number");
        return this._number;
    }
    set number(number : (number | null | undefined)) {
        this._number = number;
    }

    private _invalidated : boolean;
    get invalidated() : boolean {
        this.__checkAttributeDefinition(21, "invalidated");
        return this._invalidated;
    }
    set invalidated(invalidated : boolean) {
        this._invalidated = invalidated;
    }

    private _withAdditionalRoleTypes : boolean;
    get withAdditionalRoleTypes() : boolean {
        this.__checkAttributeDefinition(22, "withAdditionalRoleTypes");
        return this._withAdditionalRoleTypes;
    }
    set withAdditionalRoleTypes(withAdditionalRoleTypes : boolean) {
        this._withAdditionalRoleTypes = withAdditionalRoleTypes;
    }

    private _asBuiltReference : (number | null | undefined);
    get asBuiltReference() : (number | null | undefined) {
        this.__checkAttributeDefinition(23, "asBuiltReference");
        return this._asBuiltReference;
    }
    set asBuiltReference(asBuiltReference : (number | null | undefined)) {
        this._asBuiltReference = asBuiltReference;
    }

    private _readOnly : boolean;
    get readOnly() : boolean {
        this.__checkAttributeDefinition(24, "readOnly");
        return this._readOnly;
    }
    set readOnly(readOnly : boolean) {
        this._readOnly = readOnly;
    }


    public static constructPartialInstance() : SubProject {
        let instance = new SubProject();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : SubProject {
        let instance = new SubProject();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : SubProject) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.projectId, other.projectId, "Long")
            && DtoHelper.areMembersEqual(this.subProjectTypeId, other.subProjectTypeId, "Long")
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.code, other.code, "String")
            && DtoHelper.areMembersEqual(this.objectListId, other.objectListId, "Long")
            && DtoHelper.areMembersEqual(this.documentListId, other.documentListId, "Long")
            && DtoHelper.areMembersEqual(this.number, other.number, "Integer")
            && DtoHelper.areMembersEqual(this.invalidated, other.invalidated, "Boolean")
            && DtoHelper.areMembersEqual(this.withAdditionalRoleTypes, other.withAdditionalRoleTypes, "Boolean")
            && DtoHelper.areMembersEqual(this.asBuiltReference, other.asBuiltReference, "Long")
            && DtoHelper.areMembersEqual(this.readOnly, other.readOnly, "Boolean")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            projectId: this._projectId,
            subProjectTypeId: this._subProjectTypeId,
            name: this._name,
            comment: this._comment,
            code: this._code,
            objectListId: this._objectListId,
            documentListId: this._documentListId,
            number: this._number,
            invalidated: this._invalidated,
            withAdditionalRoleTypes: this._withAdditionalRoleTypes,
            asBuiltReference: this._asBuiltReference,
            readOnly: this._readOnly,
        };
    }
}
