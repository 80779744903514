import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "inline-overlapping-grid justify-items-start align-items-baseline h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.isDownloading || this.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.start()))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass({ 'invisible': !_ctx.isDownloading })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["spinner-border spinner-border-sm", { 'no-animation': !_ctx.isDownloading }])
        }, null, 2)
      ], 2),
      _createElementVNode("i", {
        class: _normalizeClass(["bi bi-exclamation-circle jobDownloadErrorIcon", { 'invisible': _ctx.error == null }])
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass({ 'invisible': _ctx.isDownloading || _ctx.error != null })
      }, [
        _renderSlot(_ctx.$slots, "icon")
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}