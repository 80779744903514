/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

function stop(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
}

const dragHandlerSymbol: unique symbol = Symbol("ondrag");

export default {
    mounted(el: HTMLElement, { value }: { value: (e: MouseEvent) => void }) {
        el[dragHandlerSymbol] = value;
        let isDrag = false;

        const mouseMoveHandler = (e: MouseEvent) => {
            if (isDrag) {
                el[dragHandlerSymbol](e);
                stop(e);
            } else {
                removeListeners();
            }
        };

        const mouseUpHandler = (e: MouseEvent) => {
            if (isDrag) {
                el[dragHandlerSymbol](e);
                isDrag = false,
                stop(e);
            }
            removeListeners();
        };

        const addListeners = () => {
            window.addEventListener("mousemove", mouseMoveHandler, { capture: true });
            window.addEventListener("mouseup", mouseUpHandler, { capture: true });
            window.addEventListener("click", stop, { capture: true });
        };

        let removeListenersTimeout: ReturnType<typeof setTimeout> | null = null;

        const removeListeners = () => {
            if (removeListenersTimeout != null) {
                return;
            }
            // We wait just a tiny bit to intercept events that happen after mouseup e.g. click.
            // click and mouseup will always be very close together in time.
            removeListenersTimeout = setTimeout(() => {
                window.removeEventListener("mousemove", mouseMoveHandler, { capture: true });
                window.removeEventListener("mouseup", mouseUpHandler, { capture: true });
                window.removeEventListener("click", stop, { capture: true });
                removeListenersTimeout = null;
            }, 10);
        };

        el.addEventListener("mousedown", (e: MouseEvent) => {
            if (e.button === 0) {
                isDrag = true;
                el[dragHandlerSymbol](e);
                stop(e);
                addListeners();
            }
        });
    },

    updated(el: HTMLElement, { value }: { value: (e: MouseEvent) => void }) {
        el[dragHandlerSymbol] = value;
    },
};
