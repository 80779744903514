<template>
<!-- Note: Removed       @blur="hide()" since it closes the dropdown on click into the input field inside the dropdown. -->
  <div role="combobox"
      :class="this.cssClass"
      :tabindex="disabled ? null : '0'"
      @keydown="keydown"
      :id="selectId"
      :aria-expanded="expanded ? 'true' : 'false'"
      :aria-owns="$id('dropdown')"
      aria-autocomplete="none"
      ref="select"
       @click="toggle()"
       @blur="hide"       
      v-bind="$attrs"
      :aria-disabled="disabled ? 'true' : 'false'"
      :aria-labelledby="labelId">
    <div class="customSelectWrapper" role="presentation">
      <!-- The purpose of this zero width space is to ensure that this widget is at least one line high -->
      <span aria-hidden="true">&#8203;</span>
      {{ (this.modelValue != null ? this.getOptionLabel(this.modelValue) : '') }}
    </div>      
  </div>
  <div
    :id="$id('dropdown')"
    class="dropdown-menu start-0 customSelectDropdown"
    ref="dropdown"
    role="listbox"
    tabindex="-1"
    @mousedown.prevent="(evt) => this.onMouseDown(evt)">
    <div>
      <div class="d-flex flex-row align-items-center">
        <TextInput class="ms-3 deputyAddFilter" v-model="mainParticipantFilter" mode="flex" ref="mainParticipantFilterTextInput"
                   :label="this.$t('general.filter')" @textInputBlur="hide"/>
        <CheckBox class="ms-4 me-2" :disabled="this.disabled" mode="flex"
                  v-model="offerParticipationsOfAllOrganisations"
                  :label="this.$t('project.participation.list.deputy.offerParticipationsOfAllOrganisations')"/>
      </div>
      <div class="optionDiv">
      <template v-for="(option, optionIndex) in this.options" :key="optionIndex">
        <button
          type="button" tabindex="-1"
          class="dropdown-item"
          :class="{
                  'active': selectedIndex === optionIndex,
                  }"
          role="option"
          :aria-selected="selectedIndex === optionIndex ? 'true' : 'false'"
          :id="$id('item' + optionIndex)"
          @click="itemClick(optionIndex, option)">
          <span aria-hidden="true">&#8203;</span>
          {{ option.label }}
        </button>
      </template>
      </div>
    </div>
  </div>
</template>

<style lang="css">
  .optionDiv {
  overflow : auto;
  max-height : 15em;
  }
</style>

<script src="./MainParticipantDropDown.ts"/>
