import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoleConflictListWidget = _resolveComponent("RoleConflictListWidget")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("project.participation.edit.roleConflict.subCaption")), 1)
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t("project.participation.edit.roleConflict.explanation")), 1),
    _createVNode(_component_RoleConflictListWidget, {
      items: this.roleConflictInfo.roleConflictJoins
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t("project.participation.edit.roleConflict.consequences")), 1)
    ])
  ]))
}