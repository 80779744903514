/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import { LabelInfo } from 'cdes-api/dto/review/LabelInfo';
import { LabelContent } from 'cdes-api/dto/LabelContent';
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { LabelPageInfo } from "cdes-api/dto/review/LabelPageInfo";

export default defineComponent({
    components: {
        ParempiGrid,
    },

    emits : ["removeLabel", "doReload"],

    data() {
        return {
            columns : this.constructColumns(),
        }
    },

    props: {
        items: {
            type: Array as PropType<LabelInfo[]>,
            default: () => [],
        },
        hideInactive: {
            type: Boolean,
            default: false
        },
        menuSection : {
            type : String
        },
        labelPageInfo : {
            type : Object as PropType<LabelPageInfo>
        }
    },

    computed: {
        filteredItems() {
            if (!this.hideInactive) {
                return this.items;
            } else {
                return this.items.filter((info) => !!info.label.active);
            }
        },
        mayEditLabel() {
            let labelPageInfo : LabelPageInfo = this.labelPageInfo as LabelPageInfo;
            return labelPageInfo != null && labelPageInfo.mayEditLabel;
        }
    },

    methods: {
        constructColumns() : ParempiColumn<LabelInfo>[] {
            let columns : ParempiColumn<LabelInfo>[] = [
                ParempiColumn.asRowSpanSlot("name", this.$t("review.label.label"), "name", this.rowSpanFct),
                ParempiColumn.asRowSpanSlot("reviewCycle", this.$t("review.label.assignedReviewCycle"), "reviewCycle", this.rowSpanFct),
                ParempiColumn.asRowSpanSlot("labelContents", this.$t("review.label.labelContents"), "labelContents"),
                ParempiColumn.asRowSpanSlot("active", this.$t("review.label.active"), "active", this.rowSpanFct),
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions", this.rowSpanFct),
            ]

            return columns;
        },

        rowSpanFct(item: LabelInfo, pos: ParempiRowPos): number {
            if (pos.getSubRow(0) !== 0) {
                return null;
            } else {
                return item.labelContents.length;
            }
        },
        rowPosFactory(item: LabelInfo): ParempiRowPos {
            return new ParempiRowPos(Math.max(item.labelContents.length, 1));
        },
        rowPosIncrementor(item: LabelInfo, rowPos: ParempiRowPos): void {
            rowPos.increment(0);
        },

        getLabelContentForRowPos(item : LabelInfo, pos : ParempiRowPos) : LabelContent {
            let subRow : number = pos.getSubRow();
            return item.labelContents[subRow];
        },

        edit(item : LabelInfo ) : void {
            let targetName : string = (this.menuSection == "plan" ? "labelEdit" : "labelEditAdmin");
            this.$router.push({
                name: targetName,
                query: {
                    labelId : item.label.id
                }
            })
        },
        deleteLabel(item : LabelInfo) : void {
            if (window.confirm(this.$t("review.label.list.reallyDeleteLabel", {name : item.label.name}))) {
                this.ctx.reviewService.deleteLabel(this.ctx.activeOrganisationPersonId,
                                                   item.label.id)
                    .then(() => {
                        this.$emit("removeLabel", item.label.id)
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }            
        },
        openPreview(labelInfo : LabelInfo, labelContent : LabelContent) : void {
            let labelPdfLink : string = this.getLabelPdfLink(labelInfo, labelContent);
            window.open(labelPdfLink);
        },
        download(labelContent : LabelContent) : void {
            let labelLink : string = this.getLabelLink(labelContent);
            window.open(labelLink);
        },

        getLabelLink(labelContent : LabelContent) : string {
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", labelContent.id.toString());

            let name : string = labelContent.name ?? "label";
            let filetype : string = labelContent.origFiletype ?? "";
            urlSearchParams.append("filename", name + (filetype.length > 0 ? "." + filetype : ""));
            return "/cdes/svc/label?" + urlSearchParams.toString();
        },

        getLabelPdfLink(labelInfo : LabelInfo, labelContent : LabelContent) : string {
            let urlSearchParams : URLSearchParams = new URLSearchParams();

            urlSearchParams.append("sp", labelContent.id.toString());
            urlSearchParams.append("sp", "X");
            return "/cdes/svc/labelPdf?" + urlSearchParams.toString();
        },

        copyLabel(item : LabelInfo) : void {
            if (window.confirm(this.$t("review.label.list.copyQuestion"))) {
                this.ctx.reviewService.copyLabel(this.ctx.activeOrganisationPersonId, item.label.id)
                    .then((success : boolean) => {
                        if (success) {
                            this.$emit("doReload");
                        } else {
                            window.alert(this.$t("review.label.list.copyFailedMessage"));
                        }
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        }
    },
});
