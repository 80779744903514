import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class Project extends Persistent {

    // Properties: (placed here as documentation)
    //
    // code : string;
    // name : string;
    // comment : (string | null | undefined);
    // routeName : string;
    // stretchName : (string | null | undefined);
    // stretchKmFrom : number;
    // stretchKmTo : number;
    // created : number;
    // createdById : number; --> OrganisationPerson
    // modified : number;
    // modifiedById : number; --> OrganisationPerson
    // numberCount : (number | null | undefined);
    // networkId : number; --> Network
    // localisationId : (number | null | undefined); --> Localisation
    // documentNumberPartGroupId : number; --> DocumentNumberPartGroup
    // status : number;
    // access : number;
    // withParticipantsSubProjectRestriction : boolean;
    // wizardStep : (number | null | undefined);
    // ignorePlotOrderReceivedSteps : boolean;
    // usePDCStartDateForTasks : boolean;
    // allowedResultOptionId : (number | null | undefined); --> ReviewCycleResultOption
    // principalProject : (string | null | undefined);
    // projectConfigId : (number | null | undefined); --> ProjectConfig

    private _code : string;
    get code() : string {
        this.__checkAttributeDefinition(25, "code");
        return this._code;
    }
    set code(code : string) {
        this._code = code;
    }

    private _name : string;
    get name() : string {
        this.__checkAttributeDefinition(26, "name");
        return this._name;
    }
    set name(name : string) {
        this._name = name;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(27, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _routeName : string;
    get routeName() : string {
        this.__checkAttributeDefinition(28, "routeName");
        return this._routeName;
    }
    set routeName(routeName : string) {
        this._routeName = routeName;
    }

    private _stretchName : (string | null | undefined);
    get stretchName() : (string | null | undefined) {
        this.__checkAttributeDefinition(29, "stretchName");
        return this._stretchName;
    }
    set stretchName(stretchName : (string | null | undefined)) {
        this._stretchName = stretchName;
    }

    private _stretchKmFrom : number;
    get stretchKmFrom() : number {
        this.__checkAttributeDefinition(30, "stretchKmFrom");
        return this._stretchKmFrom;
    }
    set stretchKmFrom(stretchKmFrom : number) {
        this._stretchKmFrom = stretchKmFrom;
    }

    private _stretchKmTo : number;
    get stretchKmTo() : number {
        this.__checkAttributeDefinition(31, "stretchKmTo");
        return this._stretchKmTo;
    }
    set stretchKmTo(stretchKmTo : number) {
        this._stretchKmTo = stretchKmTo;
    }

    private _created : number;
    get created() : number {
        this.__checkAttributeDefinition(32, "created");
        return this._created;
    }
    set created(created : number) {
        this._created = created;
    }

    private _createdById : number;
    get createdById() : number {
        this.__checkAttributeDefinition(33, "createdById");
        return this._createdById;
    }
    set createdById(createdById : number) {
        this._createdById = createdById;
    }

    private _modified : number;
    get modified() : number {
        this.__checkAttributeDefinition(34, "modified");
        return this._modified;
    }
    set modified(modified : number) {
        this._modified = modified;
    }

    private _modifiedById : number;
    get modifiedById() : number {
        this.__checkAttributeDefinition(35, "modifiedById");
        return this._modifiedById;
    }
    set modifiedById(modifiedById : number) {
        this._modifiedById = modifiedById;
    }

    private _numberCount : (number | null | undefined);
    get numberCount() : (number | null | undefined) {
        this.__checkAttributeDefinition(36, "numberCount");
        return this._numberCount;
    }
    set numberCount(numberCount : (number | null | undefined)) {
        this._numberCount = numberCount;
    }

    private _networkId : number;
    get networkId() : number {
        this.__checkAttributeDefinition(37, "networkId");
        return this._networkId;
    }
    set networkId(networkId : number) {
        this._networkId = networkId;
    }

    private _localisationId : (number | null | undefined);
    get localisationId() : (number | null | undefined) {
        this.__checkAttributeDefinition(38, "localisationId");
        return this._localisationId;
    }
    set localisationId(localisationId : (number | null | undefined)) {
        this._localisationId = localisationId;
    }

    private _documentNumberPartGroupId : number;
    get documentNumberPartGroupId() : number {
        this.__checkAttributeDefinition(39, "documentNumberPartGroupId");
        return this._documentNumberPartGroupId;
    }
    set documentNumberPartGroupId(documentNumberPartGroupId : number) {
        this._documentNumberPartGroupId = documentNumberPartGroupId;
    }

    private _status : number;
    get status() : number {
        this.__checkAttributeDefinition(40, "status");
        return this._status;
    }
    set status(status : number) {
        this._status = status;
    }

    private _access : number;
    get access() : number {
        this.__checkAttributeDefinition(41, "access");
        return this._access;
    }
    set access(access : number) {
        this._access = access;
    }

    private _withParticipantsSubProjectRestriction : boolean;
    get withParticipantsSubProjectRestriction() : boolean {
        this.__checkAttributeDefinition(42, "withParticipantsSubProjectRestriction");
        return this._withParticipantsSubProjectRestriction;
    }
    set withParticipantsSubProjectRestriction(withParticipantsSubProjectRestriction : boolean) {
        this._withParticipantsSubProjectRestriction = withParticipantsSubProjectRestriction;
    }

    private _wizardStep : (number | null | undefined);
    get wizardStep() : (number | null | undefined) {
        this.__checkAttributeDefinition(43, "wizardStep");
        return this._wizardStep;
    }
    set wizardStep(wizardStep : (number | null | undefined)) {
        this._wizardStep = wizardStep;
    }

    private _ignorePlotOrderReceivedSteps : boolean;
    get ignorePlotOrderReceivedSteps() : boolean {
        this.__checkAttributeDefinition(44, "ignorePlotOrderReceivedSteps");
        return this._ignorePlotOrderReceivedSteps;
    }
    set ignorePlotOrderReceivedSteps(ignorePlotOrderReceivedSteps : boolean) {
        this._ignorePlotOrderReceivedSteps = ignorePlotOrderReceivedSteps;
    }

    private _usePDCStartDateForTasks : boolean;
    get usePDCStartDateForTasks() : boolean {
        this.__checkAttributeDefinition(45, "usePDCStartDateForTasks");
        return this._usePDCStartDateForTasks;
    }
    set usePDCStartDateForTasks(usePDCStartDateForTasks : boolean) {
        this._usePDCStartDateForTasks = usePDCStartDateForTasks;
    }

    private _allowedResultOptionId : (number | null | undefined);
    get allowedResultOptionId() : (number | null | undefined) {
        this.__checkAttributeDefinition(46, "allowedResultOptionId");
        return this._allowedResultOptionId;
    }
    set allowedResultOptionId(allowedResultOptionId : (number | null | undefined)) {
        this._allowedResultOptionId = allowedResultOptionId;
    }

    private _principalProject : (string | null | undefined);
    get principalProject() : (string | null | undefined) {
        this.__checkAttributeDefinition(47, "principalProject");
        return this._principalProject;
    }
    set principalProject(principalProject : (string | null | undefined)) {
        this._principalProject = principalProject;
    }

    private _projectConfigId : (number | null | undefined);
    get projectConfigId() : (number | null | undefined) {
        this.__checkAttributeDefinition(48, "projectConfigId");
        return this._projectConfigId;
    }
    set projectConfigId(projectConfigId : (number | null | undefined)) {
        this._projectConfigId = projectConfigId;
    }


    public static constructPartialInstance() : Project {
        let instance = new Project();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : Project {
        let instance = new Project();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : Project) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.code, other.code, "String")
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.routeName, other.routeName, "String")
            && DtoHelper.areMembersEqual(this.stretchName, other.stretchName, "String")
            && DtoHelper.areMembersEqual(this.stretchKmFrom, other.stretchKmFrom, "Double")
            && DtoHelper.areMembersEqual(this.stretchKmTo, other.stretchKmTo, "Double")
            && DtoHelper.areMembersEqual(this.created, other.created, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.createdById, other.createdById, "Long")
            && DtoHelper.areMembersEqual(this.modified, other.modified, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.modifiedById, other.modifiedById, "Long")
            && DtoHelper.areMembersEqual(this.numberCount, other.numberCount, "Integer")
            && DtoHelper.areMembersEqual(this.networkId, other.networkId, "Long")
            && DtoHelper.areMembersEqual(this.localisationId, other.localisationId, "Long")
            && DtoHelper.areMembersEqual(this.documentNumberPartGroupId, other.documentNumberPartGroupId, "Long")
            && DtoHelper.areMembersEqual(this.status, other.status, "Integer")
            && DtoHelper.areMembersEqual(this.access, other.access, "Integer")
            && DtoHelper.areMembersEqual(this.withParticipantsSubProjectRestriction, other.withParticipantsSubProjectRestriction, "Boolean")
            && DtoHelper.areMembersEqual(this.wizardStep, other.wizardStep, "Integer")
            && DtoHelper.areMembersEqual(this.ignorePlotOrderReceivedSteps, other.ignorePlotOrderReceivedSteps, "Boolean")
            && DtoHelper.areMembersEqual(this.usePDCStartDateForTasks, other.usePDCStartDateForTasks, "Boolean")
            && DtoHelper.areMembersEqual(this.allowedResultOptionId, other.allowedResultOptionId, "Long")
            && DtoHelper.areMembersEqual(this.principalProject, other.principalProject, "String")
            && DtoHelper.areMembersEqual(this.projectConfigId, other.projectConfigId, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            code: this._code,
            name: this._name,
            comment: this._comment,
            routeName: this._routeName,
            stretchName: this._stretchName,
            stretchKmFrom: this._stretchKmFrom,
            stretchKmTo: this._stretchKmTo,
            created: this._created,
            createdById: this._createdById,
            modified: this._modified,
            modifiedById: this._modifiedById,
            numberCount: this._numberCount,
            networkId: this._networkId,
            localisationId: this._localisationId,
            documentNumberPartGroupId: this._documentNumberPartGroupId,
            status: this._status,
            access: this._access,
            withParticipantsSubProjectRestriction: this._withParticipantsSubProjectRestriction,
            wizardStep: this._wizardStep,
            ignorePlotOrderReceivedSteps: this._ignorePlotOrderReceivedSteps,
            usePDCStartDateForTasks: this._usePDCStartDateForTasks,
            allowedResultOptionId: this._allowedResultOptionId,
            principalProject: this._principalProject,
            projectConfigId: this._projectConfigId,
        };
    }
}
