import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pageCaption" }
const _hoisted_2 = { class: "ps-4 pt-4" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormGridColumn = _resolveComponent("FormGridColumn")!
  const _component_FormGridLocal = _resolveComponent("FormGridLocal")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Transform = _resolveComponent("Transform")!

  return (_openBlock(), _createBlock(_component_Transform, { transformer: _ctx.formTransformer }, {
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(this.getCaption()), 1),
        _createVNode(_component_FormGridLocal, {
          columns: 2,
          labelWidth: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({ 'row': true, 'g-3': true, 'px-3': true, 'pt-3': true, 'was-validated': _ctx.wasValidated })
            }, [
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("general.organisation"),
                    required: "",
                    modelValue: this.orderAddress.organisationName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.orderAddress.organisationName) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"]),
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("plot.orderAddress.edit.clientCode"),
                    modelValue: this.orderAddress.clientCode,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.orderAddress.clientCode) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("plot.orderAddress.edit.organisationalUnit"),
                    modelValue: this.orderAddress.organisationalUnitName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.orderAddress.organisationalUnitName) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }),
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('plot.orderAddress.edit.contactPerson')), 1),
        _createVNode(_component_FormGridLocal, {
          columns: 2,
          labelWidth: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({ 'row': true, 'g-3': true, 'px-3': true, 'pt-3': true, 'was-validated': _ctx.wasValidated })
            }, [
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Select, {
                    label: _ctx.$t("general.salutation"),
                    modelValue: this.orderAddress.gender,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.orderAddress.gender) = $event)),
                    disabled: false,
                    options: this.constructSalutationOptions()
                  }, null, 8, ["label", "modelValue", "options"]),
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("general.givenName"),
                    modelValue: this.orderAddress.givenName,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.orderAddress.givenName) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("plot.orderAddress.edit.title"),
                    modelValue: this.orderAddress.title,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.orderAddress.title) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"]),
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("general.surName"),
                    modelValue: this.orderAddress.surName,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.orderAddress.surName) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_FormGridLocal, {
          columns: 1,
          labelWidth: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({ 'row': true, 'g-3': true, 'px-3': true, 'pt-3': true, 'was-validated': _ctx.wasValidated })
            }, [
              _createVNode(_component_TextInput, {
                label: _ctx.$t("general.address"),
                required: "",
                modelValue: this.orderAddress.postalAddress,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.orderAddress.postalAddress) = $event)),
                disabled: false
              }, null, 8, ["label", "modelValue"])
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_FormGridLocal, {
          columns: 3,
          labelWidth: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({ 'row': true, 'g-3': true, 'px-3': true, 'pt-3': true, 'was-validated': _ctx.wasValidated })
            }, [
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("general.postalCode"),
                    required: "",
                    modelValue: this.orderAddress.postalCode,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.orderAddress.postalCode) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TextInput, {
                    label: _ctx.$t("general.localityName"),
                    required: "",
                    modelValue: this.orderAddress.localityName,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.orderAddress.localityName) = $event)),
                    disabled: false
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Select, {
                    label: _ctx.$t("general.country"),
                    selectClass: "form-select",
                    required: "",
                    modelValue: this.orderAddress.countryId,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.orderAddress.countryId) = $event)),
                    disabled: false,
                    options: this.countryOptions
                  }, null, 8, ["label", "modelValue", "options"])
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary",
          disabled: !_ctx.ok
        }, _toDisplayString(_ctx.$t("general.save")), 9, _hoisted_3),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-secondary m-1",
          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
          disabled: _ctx.disabled
        }, _toDisplayString(_ctx.$t('general.cancel')), 9, _hoisted_4)
      ])
    ]),
    _: 1
  }, 8, ["transformer"]))
}