export default {
  "general": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "alternativePlanNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative document name"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "mainParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primarily responsible"])},
    "mainParticipantShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respons."])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "projectLeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project leader"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
    "documentReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached documents"])},
    "documentReferenceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached documents for the document at hand"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "masterDataSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master data set"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["— None —"])},
    "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object"])},
    "objectPlanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object planner"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "projectHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project holidays"])},
    "projectParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project participant"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "realmShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RE"])},
    "redlining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphical review"])},
    "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "reviewCycleInstance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review sheet"])},
    "reviewCycleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node"])},
    "reviewCyclePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
    "roleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role type"])},
    "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "subProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subproject"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "titleContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "button": {
      "searchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "resetSearchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty search fields"])},
      "collapseSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide search fields"])},
      "openSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show search fields"])}
    },
    "column": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
    },
    "notYetSearched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet searched"])},
    "searchRuns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New search runs..."])},
    "searchCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search cancelled"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entries"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "deputies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputies"])},
    "deputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputy"])},
    "contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor"])},
    "contractorNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor Number"])},
    "email": {
      "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send E-Mail"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])}
    },
    "blockedStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block project status"])},
    "allowedSubProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed project statuses"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveAndExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Exit"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["— All —"])},
    "planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "attribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
    "requestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requested by"])},
    "requestedFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requestedFor"])},
    "planNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan number"])},
    "reviewProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Protocol"])},
    "reviewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Details"])},
    "documentReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Review"])},
    "documentUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Upload"])},
    "documentUploadRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Documentrevision"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation"])},
    "salutationMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODOHerr"])},
    "salutationFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODOFrau"])},
    "givenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given Name"])},
    "surName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "localityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "fields": {
      "quickSearchCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick search"])}
    },
    "delayFormat": {
      "upcoming": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["today"]), _normalize(["in 1 day"]), _normalize(["in ", _interpolate(_named("n")), " days"])])},
      "delayed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["today"]), _normalize(["1 day late"]), _normalize([_interpolate(_named("n")), " days late"])])}
    },
    "projectStatus": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "UNFINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished"])}
    },
    "projectAccess": {
      "INVISIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invisible"])},
      "READ_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read only"])},
      "READ_WRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read/write"])},
      "INVISIBLE_FORNONADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invisible except admin"])},
      "READ_ONLY_FORNONADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read only except admin"])}
    },
    "export": {
      "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf export"])},
      "xls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xls export"])}
    },
    "error": {
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some technical error occurred.  Please contact the support, and provide the current timestamp ", _interpolate(_named("timestamp")), "."])},
      "longRunningMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some technical error occurred.  Please contact the support, and provide the current timestamp ", _interpolate(_named("timestamp")), " an.\n\n(Except: If the error was the reaction to browser-reload the page, maybe just previous queries have been aborted)"])},
      "passwordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some error occurred.  Please check that you entered the correct password.  If you think that you indeed entered the correct password, you might contact the support.  When doing so, please provide the current timestamp ", _interpolate(_named("timestamp")), "."])}
    }
  },
  "libs": {
    "clazzesVue": {
      "loginDialog": {
        "dialogCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
      }
    }
  },
  "login": {
    "passwordForgottenLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password forgotten - request a new one here"])}
  },
  "newPassword": {
    "oldPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "newPassword1Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "newPassword2Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
    "questionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security question"])},
    "answerLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer to security question"])},
    "oldPasswordMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your old password."])},
    "oldPasswordWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong old password"])},
    "newPasswordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
    "newPasswordTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The password needs to have at least ", _interpolate(_named("minLength")), " characters."])},
    "newPasswordLackingDiversity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password needs to contain characters from at least three of the following categories: Lowercase, uppercase, special characters, digits."])},
    "newPasswordOldPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old passwords may not be reused."])},
    "newPassword1Missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new password"])},
    "newPassword2Missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat the new password"])},
    "newPassword2NotIdentical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords entered are not identical"])},
    "passwordIllegalCharsWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password contains illegal characters.  Allowed are: Upper- and lowercase letters (including umlauts and ß), digits, usual punctuation marks and special characters (i.e. .-_/!?,:§$%&€) and brackets. In particular spaces are not allowed."])},
    "questionMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a security question."])},
    "answerMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the answer to the security question."])},
    "answerTooShort": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The answer to the security question needs to have at least ", _interpolate(_named("minLength")), " characters."])},
    "answerInvalidCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answer to the security question may only contain lower or uppercase characters a-z, digits, minus sign, the underline character or umlauts.  Other characters are not valid."])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum age of your password is ", _interpolate(_named("maxAge")), " days and will be reached in ", _interpolate(_named("remainingDays")), ". You need to choose a new password, in order to be able to login after that deadline. In the course of this, the security question/answer must also be entered again"])}
  },
  "requestUserPolicy": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user policy exists."])},
    "bodyFirstLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You should have received a mail with the new user policy as attachment.  Please return it signed to the given mail address, before ", _interpolate(_named("deadline")), ". Otherwise access will be blocked."])},
    "bodySingleLink": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named } = ctx;return _normalize([_linked("requestUserPolicy.bodyFirstLine"), "", "\n\nYou can download the user policy with the following link ", _interpolate(_named("link"))])},
    "bodyMultipleLink": (ctx) => {const { normalize: _normalize, linked: _linked, interpolate: _interpolate, named: _named } = ctx;return _normalize([_linked("requestUserPolicy.bodyFirstLine"), "", "\n\nYou can download ", _interpolate(_named("count")), " user policies for your organisation memberships using the following link ", _interpolate(_named("link"))])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download user policy"])}
  },
  "about": {
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c.des® concurrent design - plan review and plan management"])},
    "infoVersionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "infoRevisionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision"])},
    "infoDateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "infoDeveloperLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept und Development"])},
    "infoDeveloper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ev-i Informationstechnologie GmbH, 6020 Innsbruck"])}
  },
  "addressOutput": {
    "personal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for the attention of ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])}
  },
  "app": {
    "infoMessagesDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
    "newPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "requestUserPolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign new user policy"])}
  },
  "contextLine": {
    "contextLineCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context"])},
    "buekContextLineCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BÜK Context"])},
    "missingOrganisationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to specify an organisation."])},
    "missingNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to specify a network."])},
    "missingProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to specify a project."])},
    "missingSubProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to specify a sub project."])}
  },
  "mainMenu": {
    "adminCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "buekCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning Notifications"])},
    "documentReviewCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Review"])},
    "helpCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help/Info"])},
    "buekSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning Notifications"])},
    "documentsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "globalSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "networkSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Networks"])},
    "notificationSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning notifications"])},
    "otherSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "projectSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "aboutCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "caCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA"])},
    "certificateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "consortiumCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consortium"])},
    "documentListCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Documents"])},
    "emailCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mails"])},
    "labelTemplateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Templates"])},
    "infoCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "instanceCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Cycle Instances"])},
    "jobCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "globalRolesCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Roles"])},
    "manualCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuals"])},
    "masterDataCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Data"])},
    "masterDataCaptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Data Old"])},
    "labelCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
    "networkCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Networks"])},
    "networkCaptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Networks Old"])},
    "objectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
    "organisationCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organi­sations"])},
    "originalsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originals"])},
    "personCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
    "planDeliverCatalogueCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Catalog"])},
    "planningNotificationCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning notifications"])},
    "planningNotificationOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "plotOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot Orders"])},
    "plotTemplateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
    "projectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "projectParticipantCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Participants"])},
    "reportingCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
    "reviewCycleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Cycle"])},
    "reviewOptionsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Options"])},
    "reviewStatusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Status"])},
    "roleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "settingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "subProjectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Projects"])},
    "supportCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "statisticCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])},
    "taskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "doneTaskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done Tasks"])},
    "topMenuLoggedInCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged in as"])},
    "topMenuSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "topMenuAccessCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "topMenuPersonalDataCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "topMenuInfoCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Informations"])},
    "topMenuUserCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open user menu"])},
    "topMenuLogoutCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "plot": {
    "fileIcons": {
      "pdfToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf document preview"])},
      "pngToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Png document preview"])},
      "compareToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document comparison to previous version"])},
      "saveToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save file locally"])}
    },
    "orderAddress": {
      "caption": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order Address"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Order Address"])}
      },
      "edit": {
        "clientCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client code"])},
        "organisationalUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department/Projekt"])},
        "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
      }
    },
    "task": {
      "pageCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot order"])},
      "clientCodeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client code: "])},
      "jobNumberLabelN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job number: "])},
      "positionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered positions"])},
      "items": {
        "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
        "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery to"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
        "countContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " items"])},
        "areaContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("area")), " m²"])},
        "typeAutomatic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatically generated by review cycle node ", _interpolate(_named("reviewCycleNodeName"))])},
        "typeManual": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manually generated by ", _interpolate(_named("personGivenName")), " ", _interpolate(_named("personSurName"))])}
      },
      "areaSums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area sums"])},
      "monochromeSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Monochrome ", _interpolate(_named("sum"))])},
      "colorSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Color ", _interpolate(_named("sum"))])},
      "likeTemplateSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As shown ", _interpolate(_named("sum"))])},
      "totalSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("sum"))])},
      "billingAddressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address: see customer"])},
      "deliveryDateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date:"])},
      "kindOfDeliveryLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kind of delivery: ", _interpolate(_named("kindOfDelivery"))])},
      "additionalInfoLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Additional information: ", _interpolate(_named("comment"))])},
      "plotOrderAcceptanceResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of delivery of the positions chosen above"])},
      "reviewCyclePlotResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of order"])},
      "commentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "ok": {
        "plotOrderAcceptanceNotFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept"])},
        "plotOrderAcceptanceFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done ok"])},
        "reviewCyclePlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])}
      },
      "notOk": {
        "plotOrderAcceptanceNotFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deny"])},
        "plotOrderAcceptanceFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done not ok"])},
        "reviewCyclePlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not ok"])}
      },
      "laterButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept / Finish later"])},
      "nowButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept and finish"])},
      "finishButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
      "acceptanceButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm delivery"])},
      "pngDialogTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Document ", _interpolate(_named("documentVersionName"))])},
      "jobNumberLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Job number: ", _interpolate(_named("jobNumber"))])}
    },
    "template": {
      "edit": {
        "caption": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose the kind of template"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template for manual plot order"])},
          "projectParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template for automatic plot orders for project participants"])},
          "reviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template for automatic plot orders for review cycle positions"])}
        },
        "billing": {
          "addToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new billing address"])},
          "editToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit billing address"])}
        },
        "label": {
          "templateResolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "basedOnReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on review cycle"])}
        }
      },
      "item": {
        "list": {
          "paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper"])},
          "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
          "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold"])},
          "mirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mirror"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
          "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
          "addToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new position"])},
          "deleteToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this position"])},
          "projectParticipationTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery to"])},
          "reviewCycleNodeTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To all participants of node"])}
        }
      },
      "list": {
        "itemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery to project members"])},
        "ofNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Knotens"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit plot template"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete plot template"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the plot order template ", _interpolate(_named("name")), "?"])}
      },
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot templates"])}
      },
      "paperQuality": {
        "laser80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser 80g/m2"])},
        "laser90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laser 90g/m2"])},
        "inkjet80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkjet 80g/m2"])},
        "inkjet90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkjet 90g/m2"])}
      },
      "color": {
        "monochrome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monochrome"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
        "likeTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as displayed"])}
      },
      "kindOfDelivery": {
        "deliveryServiceContractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery by service contractor"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "upsParcelService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS"])},
        "veloExpress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velo Express"])},
        "byCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Customer"])}
      },
      "margin": {
        "noFold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fold"])},
        "fold20mmEdge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold with 20mm edge"])},
        "foldWithoutEdge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold without edge"])}
      },
      "scale": {
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["none"])},
        "a0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A0"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A1"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4"])}
      },
      "prop": {
        "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice recipient"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
        "kindOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind of delivery"])},
        "plotter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor"])}
      },
      "resolver": {
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual plot order"])},
        "projectParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic plot order for project participations"])},
        "reviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic plot order for review cycle positions"])}
      }
    }
  },
  "project": {
    "edit": {
      "editHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit project"])},
      "newHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create project"])},
      "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])},
      "missingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a project code."])},
      "duplicateCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project code is already in use."])},
      "missingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a name."])},
      "missingRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a route name."])},
      "missingLocalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a localisation."])},
      "missingdocumentNumberPartGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a document number scheme."])},
      "missingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter from km."])},
      "missingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter to km."])},
      "badInputFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from km must be a number."])},
      "badInputTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to km must be a number."])},
      "principalProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main project"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
      "stretch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from km"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to km"])},
      "localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
      "documentNumberPartGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number scheme"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
      "ignorePlotOrderReceivedSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark plot orders without delivery confirmation as finished"])},
      "withParticipantsSubProjectRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable access restrictions of project participants on subprojects"])},
      "allowedResultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional review options for reviews"])},
      "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving the project has failed.  Please observe, that deleting object planner definitions already in use is not possible."])},
      "projectHolidays": {
        "missingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name."])},
        "missingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the start date"])},
        "missingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the end date."])}
      },
      "objectPlannerDefinitions": {
        "tabCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object planner definitions / notifications"])},
        "positionsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])},
        "notificationColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification options for document state changes"])},
        "notificationColumnSubCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On entry of the conditions selected here, the respective role type will receive an automatic email notification which cannot be suppressed."])},
        "notificateReleasedPositiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
        "notificateReleasedNegativ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval denied"])},
        "notificateInvalidatedVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid - state X"])},
        "notificateInvalidatedAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid - state U"])},
        "notificateDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload revoked"])},
        "notificateRevised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replaced by revision"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this position definition permanently?"])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " positions found"])}
      }
    },
    "list": {
      "subProject": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit subproject."])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete subproject."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subproject."])},
        "read-only-tool-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change access mode from 'Read/write' to 'Read Only'"])},
        "read-write-tool-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change access mode from 'Read Only' to 'Read/write'"])},
        "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete subproject ", _interpolate(_named("subProject")), "?"])},
        "cannotDeletePlotOrders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unfortunately, it is not possible to delete or invalidate subproject ", _interpolate(_named("subProject")), ".  Reason: Existent plot orders, which are either open, or intersect with other subprojects."])},
        "jumpToObjectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object list"])},
        "jumpToReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review sheets"])},
        "jumpToPlanDeliverCatalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan deliver catalogue"])},
        "jumpToDocumentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document list"])},
        "jumpToAsBuildObjList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As-Built Planning Objects"])},
        "jumpToAsBuildDocList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As-Built Planning Documents"])},
        "readOnlyQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to set the project status ", _interpolate(_named("subProject")), " to 'Read Only' mode?"])},
        "readWriteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to set the project status ", _interpolate(_named("subProject")), " to 'Read/Write mode'?"])}
      },
      "noSubProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subproject exists"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this project"])},
      "invisibleAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark project invisible"])},
      "readWriteAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark project visible - read/write"])},
      "readOnlyAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark project visible - only read"])},
      "invisibleAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to make project ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " visible?"])},
      "readWriteAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to make project ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " visible in access mode read/write?"])},
      "readOnlyAccessQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to make project ", _interpolate(_named("code")), " ", _interpolate(_named("name")), " visible in access mode only read?"])},
      "makeProjectAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declare as project administrator"])},
      "reallyMakeProjectAdminQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to declare yourself a project administrator for project ", _interpolate(_named("code")), " ", _interpolate(_named("name")), ", in order to gain permissions at project context level?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete project"])},
      "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete project ", _interpolate(_named("code")), " ", _interpolate(_named("name")), "?"])},
      "cannotDeletePlotOrders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unfortunately, it is not possible to delete or invalidate subproject ", _interpolate(_named("subProject")), ".  eason: Open plot orders exist.  For this reason, the project cannot be deleted or invalidated."])},
      "participationsToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open project participant list"])}
    },
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "addProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new project"])},
      "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " projects found"])}
    },
    "participation": {
      "noMainParticipantError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For role ", _interpolate(_named("role")), " of organisation ", _interpolate(_named("organisation")), ", no responsible person is defined."])},
      "list": {
        "subProjectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed sub projects"])},
        "organisation": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit all project participations"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a project participation"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all roles of organisation"])},
          "showHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show change log for all project participations"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a group e-mail to all project participants of this organisation"])},
          "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete all participations of organisation ", _interpolate(_named("organisation")), "?"])}
        },
        "deputy": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add deputy"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove deputy"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an e-mail to this deputy"])},
          "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To you really want to remove deputy \"", _interpolate(_named("surName")), " ", _interpolate(_named("givenName")), "\"?"])},
          "addSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appoint the current user as deputy"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about this deputy"])},
          "offerParticipationsOfAllOrganisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation overarching"])}
        },
        "mainParticipant": {
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an e-mail to the person primarily responsible"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the person primarily responsible"])},
          "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION: No responsible person defined!"])}
        },
        "allowedStatuses": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit allowed subprojects"])},
          "restrictNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, it is not possible to restrict access to this subproject.  Reason: The project participant has already active assignments in this subproject (e.g. object planner, review cycle, notifications, plot orders)"])}
        },
        "delete": {
          "adminNonBuekMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project participant could not be deleted.  Reason: At least one participant with role 'Project leader'  or 'Project administrator' needs to exist."])},
          "adminBuekMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project participant could not be deleted.  Reason: At least one participant with role 'Buek administrator' needs to exist."])},
          "plotTaskMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participant could not be deleted.  Reason: Open plot orders exist."])},
          "dialogCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project participant in use"])},
          "headerMessageFoundReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project participant could not be deleted, because references have been found."])},
          "headerMessageReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those references need to be deleted, before the project participant can be marked for deletion."])},
          "headerMessageReleasedHasUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to actually delete the project participant, afterwards, all affected object lists need to be released."])},
          "headerMessageNotReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those references need to be deleted, before the project participant can be deleted."])},
          "foundReferencesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following references where found"])},
          "roleLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assigned for an objectPlannerPosition with role ", _interpolate(_named("roleName"))])},
          "objectLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Object ", _interpolate(_named("objectCode")), " ", _interpolate(_named("objectName"))])},
          "objectPlannerLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Object planner ", _interpolate(_named("objectPlannerCode")), " ", _interpolate(_named("objectPlannerArea"))])},
          "subProjectLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sub project ", _interpolate(_named("subProjectCode")), " ", _interpolate(_named("subProjectName"))])},
          "plotOrderTemplateLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PlotOrder template ", _interpolate(_named("plotOrderTemplateName"))])},
          "realmLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assignment to reviews for realm ", _interpolate(_named("realmName"))])},
          "roleShortLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Role ", _interpolate(_named("roleName"))])},
          "cycleLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Review sheet ", _interpolate(_named("reviewCycleInstanceName")), " (", _interpolate(_named("reviewCycleCellInstanceName")), " - ", _interpolate(_named("reviewCycleNodeInstanceName")), ")"])},
          "emailLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned as receiver of email notifications"])},
          "plotLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned as receiver in a plot order template"])},
          "plotCustomerLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned as customer for plot orders"])},
          "plotContractorLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned as contractor for plot orders"])},
          "plotTemplateLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plot order template ", _interpolate(_named("plotOrderTemplateName"))])},
          "attachedReviewLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assigned as optional reviewer for document ", _interpolate(_named("documentVersionName"))])},
          "taskRealmLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Realm ", _interpolate(_named("realmDescription"))])},
          "deleteOptionCaptionReleasedUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all references automatically, and mark for deletion"])},
          "deleteOptionCaptionReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark project participant for deletion"])},
          "deleteOptionCaptionElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all references automatically, and delete project participant"])},
          "deleteOptionDescUnreleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project participant will be removed from all review sheets and object planners automatically."])},
          "deleteOptionDescAttached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All assignments as optional reviewer will be deleted."])},
          "deleteOptionDescNotificationNotPlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All assignments as receiver of notification emails will be removed."])},
          "deleteOptionDescPlotNotNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All assignments as receiver or customer of plot orders will be removed."])},
          "deleteOptionDescNotificationPlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All assignments as receiver of notification emails and all assignments as receiver or customer of plot orders will be removed"])},
          "deleteOptionDescReleasedReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, this project participant will be marked for deletion.  After the object lists of all affected subprojects have been released, this project participant will be deleted permanently."])},
          "deleteOptionDescReleasedReferencesWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING: The deletion of those assignments cannot be reverted!"])},
          "deleteOptionDescReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, this project participant will be deleted."])},
          "deleteOptionDescReferencesWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING: Deletion of this project participant and the corresponding assignments cannot be reverted!"])},
          "deleteOptionDescElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the object lists of all affected subprojects have been released, this project participant will be deleted automatically."])},
          "cancelOptionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do NOT delete"])},
          "cancelOptionDescHasReleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort and do not mark the project participant for deletion"])},
          "cancelOptionDescElse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort and do not delete the project participant"])},
          "subProjectsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following subprojects are affected"])},
          "deleteBuekCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with deletion of project participant"])},
          "deleteBuekDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We try our best to delete the project participant.  If deletion is not possible, the project participant will continue to be part of the list.  No further notification will take place."])}
        },
        "role": {
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an e-mail to all project participants"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this role"])},
          "reallyDeleteQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the project participation with role ", _interpolate(_named("role")), " of organisation ", _interpolate(_named("organisation")), "?"])}
        },
        "participantHistory": {
          "dialogTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Participant History (\"", _interpolate(_named("organisation")), "\" - \"", _interpolate(_named("role")), "\")"])},
          "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
          "changedByOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed by (on)"])},
          "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
          "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Participant History found."])}
        }
      },
      "edit": {
        "roleHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Role ", _interpolate(_named("role"))])},
        "mailFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send plans by (physical) mail"])},
        "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send plans attached to an e-mail"])},
        "blockedStatusesTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participant does not have access to these project statuses"])},
        "allowedSubProjectsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participant only has access to these project statuses"])},
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
        "roleConflict": {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role conflict"])},
          "subCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
          "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have changed the role of at least one project participant.  This participant is already responsible for review."])},
          "consequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you save the changes, the corresponding schedules will be deleted!"])},
          "cellCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell"])}
        },
        "switchOrg": {
          "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to assign "])},
          "oneOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["role ", _interpolate(_named("role")), " to organisation ", _interpolate(_named("organisation"))])},
          "postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])}
        }
      },
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project participants"])},
        "newButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add project participant"])},
        "mailToAllToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email to all project participants"])},
        "mailToAllTargetText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All participants of project ", _interpolate(_named("code")), " ", _interpolate(_named("name"))])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " project participants found"])}
      }
    }
  },
  "subProject": {
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub project"])}
    },
    "edit": {
      "numberOutOfRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please choose a number between ", _interpolate(_named("from")), " and ", _interpolate(_named("to")), "."])},
      "numberInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a valid number"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
    }
  },
  "task": {
    "list": {
      "deputyLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" deputy for ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])},
      "documentCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "doneDateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished on"])},
      "doneResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
      "extendedDeputyLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ownGivenName")), " ", _interpolate(_named("ownSurName")), " (", _interpolate(_named("ownOrganisation")), ")\ndeputy for ", _interpolate(_named("mainGivenName")), " ", _interpolate(_named("mainSurName")), " (", _interpolate(_named("mainOrganisation")), ")"])},
      "extendedDeputyLineDONE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mainGivenName")), " ", _interpolate(_named("mainSurName")), " (", _interpolate(_named("mainOrganisation")), ")\nsigned by: ", _interpolate(_named("ownGivenName")), " ", _interpolate(_named("ownSurName")), " (", _interpolate(_named("ownOrganisation")), ")"])},
      "objectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object"])},
      "projectCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "networkCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
      "responsibleCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])},
      "responsibleDONECaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible / signed by"])},
      "responsibleCaptionOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible [for Organisation]"])},
      "reviewDateCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed on"])},
      "reviewResultCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
      "reviewStatusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review status"])},
      "statusCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My task status"])},
      "taskCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
      "deadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Deadline: ", _interpolate(_named("deadline")), ")"])},
      "contractualDeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Contractual deadline: ", _interpolate(_named("deadline")), ")"])},
      "computedDeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Computed deadline: ", _interpolate(_named("deadline")), ")"])},
      "inSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(in signature folder)"])},
      "toSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To signature folder"])},
      "overrideReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review protocol"])},
      "editOriginalDocumentRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to edit the original document"])},
      "receivePlotOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive plot order"])},
      "plotOrderDeliveryConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot order delivery confirmation"])},
      "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete task"])},
      "reallyDeleteTaskQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete task ", _interpolate(_named("taskName")), " for document ", _interpolate(_named("documentName")), "?"])},
      "taskCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " of ", _interpolate(_named("total")), " tasks found"])},
      "uploadedCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of upload"])},
      "taskRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload of document revision"])}
    },
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "myTasksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
      "myTasksTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to My tasks"])},
      "signatureFolderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature folder"])},
      "signatureFolderTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Signature folder"])},
      "surveillanceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveillance"])},
      "surveillanceTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Surveillance"])},
      "doneTasksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "doneTasksTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to done tasks"])}
    },
    "search": {
      "modeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
      "myTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
      "organisationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Organisation"])},
      "signatureFolderItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature folder"])},
      "doneTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done tasks"])},
      "belatedTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline monitoring"])},
      "openTasksItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tasks"])},
      "dateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "deputyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as"])},
      "deputyOptions": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "deputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputy"])},
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])}
      },
      "toLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "fromInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One month ago"])},
      "fromIntervalInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid start date."])},
      "toInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 3 days"])},
      "toIntervalInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid end date."])},
      "toggleButton": {
        "asDeputy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as deputy"])},
        "asMainParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as main participant"])},
        "inOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for ", _interpolate(_named("organisationName"))])},
        "fromTs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["from ", _interpolate(_named("fromTs"))])},
        "toTs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to ", _interpolate(_named("toTs"))])},
        "showSearchFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show search fields: "])}
      }
    }
  },
  "original": {
    "page": {
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originals"])},
      "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " requests found"])}
    },
    "provideOriginal": {
      "page": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Original Document"])},
        "provideRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide request"])},
        "denyRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny request"])},
        "selectOriginalFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select original file"])},
        "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
        "uploadHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint: File is uploaded upon signing"])}
      }
    },
    "list": {
      "fromMyself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from myself"])},
      "toMyself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to myself"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejected"])},
      "deleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete request"])},
      "askDeleteRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the request and a possibly existing task?"])}
    },
    "search": {
      "fromMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From me"])},
      "toMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To me"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
    }
  },
  "passwordDialog": {
    "missingPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to enter your password."])},
    "assignedReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned review cycle"])},
    "labelContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label contents"])}
  },
  "review": {
    "label": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "labelContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Contents"])},
      "assignedReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Review Cycle"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "list": {
        "pageCaption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Labels of Organisation ", _interpolate(_named("organisation"))])},
        "hideInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide inactive labels"])},
        "addLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this label"])},
        "editLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this label"])},
        "deleteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this label"])},
        "copyToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "copyQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create a copy of this label with the postfix '~' and a number 0-99?"])},
        "copyFailedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copying the label has failed."])},
        "renderableOutsideCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
        "reallyDeleteLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete label ", _interpolate(_named("name")), "?"])}
      },
      "edit": {
        "pageCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Label"])},
        "lastChangedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last changed: ", _interpolate(_named("dateFormatString"))])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie"])},
        "addLabelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Label Content"])},
        "deleteLabelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete label content"])},
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " label contents found"])}
      },
      "page": {
        "countLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " labels found"])}
      }
    },
    "labelContent": {
      "edit": {
        "caption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Planschildinhalt zu Musterplankopf ", _interpolate(_named("label"))])},
        "primaryReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärer Prüflauf"])},
        "alternativeReviewCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativer Prüflauf"])},
        "copySelectedTextFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Textfelder vom rechts gewähltem Prüflauf in aktiven alternativen Prüflauf kopieren"])},
        "rotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation"])},
        "hpgl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hpgl/pdf-Datei"])},
        "original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originaldatei"])},
        "signatureCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur Textfelder"])},
        "documentVersionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentversion Textfelder"])},
        "freeTextCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitext Textfelder"])},
        "renderableOutsideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionales Planschild"])},
        "renderableOutsideToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird beim Hochladen kein Planschild erkannt, wird diese Planschildvorlage am Rand des Plans gerendert."])},
        "defaultLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])}
      },
      "relatedProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugehörige Projekte"])},
      "list": {
        "previewToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterplankopf Vorschau"])},
        "downloadToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planervorlage herunterladen"])},
        "documentTypesToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttyp zuweisen"])},
        "defaultNotDeletableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht möglich, den Standard-Planschildinhalt zu löschen."])},
        "reallyDeleteLabelContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den Planschildinhalt ", _interpolate(_named("name")), " wirklich löschen?"])}
      },
      "documentType": {
        "pageCaption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plantypen zu Planschildinhalt ", _interpolate(_named("labelContentName"))])},
        "codeColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzel"])},
        "nameColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "chooseColumnCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])}
      }
    },
    "labelTextField": {
      "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RE#"])},
      "lowerLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lower left"])},
      "upperRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upper right"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
      "fontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-size"])},
      "fontStyleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font style"])},
      "alignmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment"])},
      "content": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
        "documentVersionPartA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number part A"])},
        "documentVersionPartB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number part B"])},
        "asBuildName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number of source document"])},
        "altName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative document number"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "principalProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main project"])},
        "projectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project code"])},
        "objectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind of project"])},
        "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])},
        "projectRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Route"])},
        "projectStretch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Section"])},
        "subProjectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subproject code"])},
        "subProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subproject name"])},
        "documentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "projectKms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km from - to (Project)"])},
        "objectKms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km from - to (Object)"])},
        "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
        "objectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object code"])},
        "objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object name"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload date"])},
        "uploadSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload signature"])},
        "trackerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker URL"])}
      },
      "fontStyle": {
        "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
        "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
        "boldItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold and italic"])}
      },
      "alignment": {
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
        "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
        "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])}
      }
    },
    "review": {
      "cellHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cellName")), " version ", _interpolate(_named("versionPart"))])},
      "header": {
        "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further information"])},
        "fromTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km from-to"])},
        "pdfPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdf preview"])},
        "pngPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["png preview"])},
        "comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document comparison with previous version"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save file locally"])},
        "reviewProtocolPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review protocol PDF"])}
      },
      "nav": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])}
      },
      "data": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous reviews"])},
        "showNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose other review"])},
        "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show whole review list"])},
        "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
        "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
        "deputyWithOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["deputy for ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " (", _interpolate(_named("organisation")), ")"])},
        "deputyWithoutOrganisation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["deputy for ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName"))])},
        "notReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task accepted ― comment:"])},
        "overridenBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delayed task taken from ", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " ― comment:"])},
        "durationLine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start:", _interpolate(_named("start")), " End:", _interpolate(_named("end"))])},
        "commentHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("node")), " - Comments about former reviews"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment for review"])},
        "reviewCycleCommentOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
        "noEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entries"])},
        "stateXCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document was declared invalid"])}
      },
      "entry": {
        "heading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["My reviews – ", _interpolate(_named("node"))])},
        "overridingHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["My reviews – ", _interpolate(_named("node")), " — Take delayed review"])},
        "noResultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to enter a result."])},
        "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign now"])},
        "signPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The review could not be signed.  Did you enter the correct password?"])},
        "signChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign chosen tasks"])},
        "signError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some error has occurred.  The tasks where not signed.  Did you enter the correct password?"])},
        "saveToSignatureFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and move to signature folder"])},
        "validFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid for version"])},
        "commentNodeHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version ", _interpolate(_named("version")), " – ", _interpolate(_named("node"))])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments about previous reviews"])},
        "signTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign review"])},
        "attachmentAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("givenName")), " ", _interpolate(_named("surName")), " at ", _interpolate(_named("date")), ")"])},
        "breakWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By this decision, you interrupt the current review cycle.  In order to continue, the planner needs to upload a new version."])}
      }
    },
    "upload": {
      "page": {
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
        "noDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a destination."])},
        "noDestinationFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No destination cell was found. Please check whether a reviwe sheet is assigned to this plan and if so, contact the project administration."])},
        "reviewMatrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Review assignments ― Node ", _interpolate(_named("node"))])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document upload"])},
        "reuploadBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previously"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks on the upload / change notes"])},
        "statements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement about this upload"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment previous reviews"])},
        "showAttachedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach additional reviewers"])},
        "hideAttachedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachment of additional reviewers"])},
        "attachedReviewsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review - additional assignment"])},
        "attachedReviewsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally to the reviewers defined by means of the review sheept, optionally additional reviewers can be assigned for this specific document."])},
        "documentReferenceDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the document attachment?"])},
        "notYetUploadedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all data has been transferred yet.  Please wait for a short while, and then try again."])}
      },
      "preview": {
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
        "localName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename locally"])},
        "rotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
        "alternativeDocumentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative plannumber"])},
        "changeDocumentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter versionsnumber"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of document interpretation"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtiger Hinweis"])},
        "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please always carry out a visual check of the upload using the preview, even if the status of the plan interpretation is positive. If, for example, the plan is rotated or the signature fields are not shown in the intended positions, please carry out the upload anyway and then contact c.des support, stating the following information: network, project, project status and plan number. The developers then adapt the display of the plan directly in c.des."])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "noDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a destination."])},
        "uploadError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some error occurred on uploading the document. ", _interpolate(_named("reload"))])},
        "retryUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
        "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a file"])},
        "passwordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign upload"])},
        "foundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document label was successfully found"])},
        "notFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document label was not found"])},
        "notFoundButObligatoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document label was not found, but it is mandatory. Please check your plan!"])},
        "notFoundLabelIgnored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore label"])},
        "notFoundLabelRenderLabelOutsidePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add standard label as an additional sheet"])},
        "notFoundLabelAsIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Render label as is"])},
        "notFoundLabelTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "label": {
          "outsideMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document label will be rendered outside of the document, based on a corresponding setting."])},
          "outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No label found, the document label will be rendered outside of the document."])},
          "labelIgnored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching the document label will be skipped, since it was deactivated."])},
          "noLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No label was attached to this review sheet"])},
          "noTypeSupportWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For files of type \"", _interpolate(_named("type")), "\", searching the document label is not supported."])},
          "noTypeSupportWithoutLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For files of type \"", _interpolate(_named("type")), "\", calculating the area is not supported."])},
          "finishedWithLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished searching the document label"])},
          "finishedWithoutLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully calculated the area."])},
          "BAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very bad match"])},
          "DOUBTFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad match"])},
          "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found - good match"])},
          "PERFECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found - very good match"])},
          "NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])}
        },
        "pngPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open detailed planpreview"])}
      },
      "matrix": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When loading the review assignments, some error occurred."])},
        "attachedHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint: Additional reviewers can be assigned.  They are located at the lower end of the matrix."])},
        "noNodeWithPositionsButAttachedPostions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No node with valid reviewer assignments could be found.  If possible, you may assign additional reviewers for this review node.  Alternatively, you might contact the project administrator."])},
        "noNodeWithPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No node with valid reviewer assignments could be found. Please contact the project administrator."])}
      },
      "reference": {
        "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach file"])},
        "referenceDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference document"])},
        "selectDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose document to be attached"])},
        "missingReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to fill this field."])}
      },
      "logTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log"])},
      "statements": {
        "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement about"])}
      }
    }
  },
  "roleConflictInfo": {
    "dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role conflict detected"])}
  }
}