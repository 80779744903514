/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, getCurrentInstance } from "vue";

import { makePropWithValue } from "cdes-vue/util/Prop";
import { useIdGenerator } from "cdes-vue/util/UniqueId";
import Transform from "cdes-vue/util/Transform";
import DateInput from "cdes-vue/util/form/DateInput.vue";
import { useValidationForm } from "cdes-vue/util/directives/ValidationForm";
import { DocumentReference } from "cdes-api/dto/DocumentReference";
import { LoadingPlaceholder, loadingPlaceholder, mapLoading } from "cdes-vue/util/Promise";
import DocumentReferenceEditRow, { TemporaryDocumentReference, LoadedDocumentReference } from "./DocumentReferenceEditRow.vue";
import DocumentReferenceEditWidget from "./DocumentReferenceEditWidget.vue";

export default defineComponent({
    components: {
        Transform,
        DateInput,
        DocumentReferenceEditRow,
        DocumentReferenceEditWidget
    },

    emits: ["update:documentReferences", "doDelete"],

    props: {

        persistentDocumentReferences : {
            type: Array as PropType<DocumentReference[]>
        },

        documentReferences: {
            type: Array as PropType<DocumentReference[]>,
            default: () => [],
        },
        wasValidated: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        temporaryDocumentVersionId: {
            type: [String, Symbol] as PropType<string | LoadingPlaceholder>,
        },

        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            temporary: new TemporaryDocumentReference(),
            temporaryId: -1,
        };
    },

    setup(props, context) {
        const inst = getCurrentInstance().proxy;
        const id = useIdGenerator();

        const {
            transformer: temporaryTransformer,
            wasValidated: wasTemporaryValidated,
        } = useValidationForm({
            // @ts-ignore
            onSubmit: (event) => inst.addReference(),
            tag: id("temporary"),
        });

        return {
            SubmitEvent,
            id,
            temporaryTransformer,
            wasTemporaryValidated,
            ourDocumentReferences: makePropWithValue(
                props as Record<"documentReferences", (DocumentReference | LoadingPlaceholder)[]>,
                context as any,
                "documentReferences",
                documentReferences => documentReferences
                    .map(reference => mapLoading(reference => Object.assign(new DocumentReference(), reference), reference)),
                {
                    deep: true,
                },
            ),
            documentReferenceIdsToDelete : new Set<number>()
        };
    },

    methods: {
        addReference() {
            this.temporary.id = this.temporaryId--;
            this.temporary.created = Date.now() / 1000;
            // @ts-ignore
            this.ourDocumentReferences.push(this.temporary);
            this.temporary = new TemporaryDocumentReference();
        },
        doDelete(documentReference : DocumentReference) : void {
            console.info("doDelete called for documentReference [" + documentReference.id + "]");

            //this.documentReferenceIdsToDelete.add(documentReference.id);
            this.$emit("doDelete", documentReference.id);
        },
        showDocumentReference(documentReference : DocumentReference) : boolean {
            return true;
//            return !this.documentReferenceIdsToDelete.has(documentReferenceId);
        }
    },
});
