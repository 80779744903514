import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ws-pre-line" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('requestUserPolicy.heading')), 1),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_i18n_t, {
        keypath: _ctx.orgPersonIds.length > 1 ? 'requestUserPolicy.bodyMultipleLink' : 'requestUserPolicy.bodySingleLink'
      }, _createSlots({
        deadline: _withCtx(() => [
          _createVNode(_component_LoadedOrPlaceholder, { value: _ctx.info }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.info.userPolicyDeadline), 1)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        link: _withCtx(() => [
          _createElementVNode("a", {
            download: "",
            href: _ctx.link
          }, _toDisplayString(_ctx.$t('requestUserPolicy.link')), 9, _hoisted_2)
        ]),
        _: 2
      }, [
        (_ctx.orgPersonIds.length > 1)
          ? {
              name: "count",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.orgPersonIds.length), 1)
              ])
            }
          : undefined
      ]), 1032, ["keypath"])
    ])
  ], 64))
}