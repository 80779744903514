/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export enum LabelStatus {
    OUTSIDE = "OUTSIDE",
    IGNORED = "IGNORED",
    UNKNOWN = "UNKNOWN",
    PERFECT = "PERFECT",
    GOOD = "GOOD",
    DOUBTFUL = "DOUBTFUL",
    BAD = "BAD",
    NOT_FOUND= "NOT_FOUND",
    PENDING = "PENDING"
}
