import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import Transform from "cdes-vue/util/Transform";
import { useValidationForm, ValidationResult } from "cdes-vue/util/directives/ValidationForm";
import { defineComponent, getCurrentInstance, PropType } from "vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { OrderAddressEditInfo } from "cdes-api/dto/plot/OrderAddressEditInfo";
import { OrderAddress } from "cdes-api/dto/OrderAddress";
import { PageCall } from "cdes-vue/util/router/PageCall";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    name : "OrderAddressEditWidget",

    components: {
        FormGridColumn,
        FormGridLocal,
        Select,
        TextInput,
        Transform 
    },

    props: {
        orderAddressId : {
            type: Number as PropType<number | null>,
        },
        networkId : {
            type: Number as PropType<number | null>,
        }
    },

    data() {
        const instance = getCurrentInstance().proxy;
        const validationResult : ValidationResult = useValidationForm({
            // @ts-ignore
            validationFunction : form => instance.validate(form),
            // @ts-ignore
            onSubmit : () => instance.saveOrderAddress(),
        });

        return {
            formTransformer : validationResult.transformer,
            wasValidated : validationResult.wasValidated,
            orderAddressEditInfo : new OrderAddressEditInfo(),
            orderAddress : new OrderAddress()
        };
    },

    computed : {
        countryOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            for (let country of this.orderAddressEditInfo.countries) {
                options.push({ value : country.id, label : country.descDe });
            }
            return options;
        },
        ok() : boolean {
            let orderAddress : OrderAddress = this.orderAddress as OrderAddress;
            return orderAddress != null && orderAddress.countryId != null;
        },
        countryCss() : string {
            let orderAddress : OrderAddress = this.orderAddress as OrderAddress;
            let css = (orderAddress == null || orderAddress.countryId == null) ? "mustField" : "";
            return css;
        }
    },

    async created() : Promise<void> {
        let networkId : number = this.orderAddressId != null ? null : this.ctx.activeNetworkId;
        this.orderAddressEditInfo = await this.ctx.plotService.getOrderAddressEditInfo(this.ctx.activeOrganisationPersonId,
                                                                                       this.orderAddressId, networkId);
        this.orderAddress = this.orderAddressEditInfo.orderAddress;
    },

    mounted() {
        // empty
    },

    methods: {
        validate(form: HTMLFormElement): boolean {
            for (const element of form.elements as Iterable<HTMLInputElement>) {
                if (!element.checkValidity()) {
                    return false;
                }
            }
            return true;
        },

        getCaption() : string {
            if (this.orderAddressId != null) {
                return this.$t("plot.orderAddress.caption.edit");
            } else if (this.networkId != null) {
                return this.$t("plot.orderAddress.caption.new");
            } else {
                throw new Error("Unsupported case");
            }
        },

        constructSalutationOptions() : SelectOption[] {
            return [
                { label : this.$t("general.salutationFemale"), value : "f" },
                { label : this.$t("general.salutationMale"), value : "m" }
            ];
        },

        saveOrderAddress() : void {
            if (this.orderAddress.id == null) {
                this.orderAddress.type = 1;
            }
            this.ctx.plotService.saveOrUpdateOrderAddress(this.ctx.activeOrganisationPersonId,
                                                          this.orderAddress as OrderAddress)
                .then((orderAddress : OrderAddress) => this.processSavedOrderAddress(orderAddress), err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        processSavedOrderAddress(orderAddress : OrderAddress) : void {
            let pageCall : PageCall = new PageCall("OrderAddressEditWidget");
            pageCall.setParam("orderAddress", orderAddress);
            this.ctx.setLastPageCall(pageCall);

            this.$router.back();
//            this.$router.go(-1);
        },
        cancel(): void {
            this.$router.back();
        }
    }
});

