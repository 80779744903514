import { ParticipationDeleteInfo } from "cdes-api/dto/project/ParticipationDeleteInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { SubProject } from "cdes-api/dto/SubProject";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    emits : ["doClose", "removeParticipation"],

    props: {
        deleteInfo : {
            type : Object as PropType<ParticipationDeleteInfo>,
            default : new ParticipationDeleteInfo()
        },
        projectId : Number,
        organisationId : Number,
        participationId : Number
    },
    computed : {
        headerMessage() : string {
            let deleteInfo : ParticipationDeleteInfo = this.deleteInfo as ParticipationDeleteInfo;
            if (deleteInfo == null) {
                return "";
            }
            let msg : string = this.$t("project.participation.list.delete.headerMessageFoundReferences");
            if (!this.buek) {
                if (deleteInfo.deleteJoins.length > 0 && deleteInfo.hasReleasedObjectListAssignments) {
                    msg += this.$t("project.participation.list.delete.headerMessageReleased");
                    if (deleteInfo.hasUnreleasedObjectListAssignments) {
                        msg += this.$t("project.participation.list.delete.headerMessageReleasedHasUnreleased");
                    }
                } else if (deleteInfo.deleteJoins.length > 0 && !deleteInfo.hasReleasedObjectListAssignments) {
                    msg += this.$t("project.participation.list.delete.headerMessageNotReleased");
                }
            }

            return msg;
        },
        deleteCaption() : string {
            let deleteInfo : ParticipationDeleteInfo = this.deleteInfo as ParticipationDeleteInfo;
            if (deleteInfo == null) {
                return "";
            }
            if (this.buek) {
                return this.$t("project.participation.list.delete.deleteBuekCaption");
            } else {
                if (deleteInfo.hasUnreleasedObjectListAssignments && deleteInfo.hasReleasedObjectListAssignments) {
                    return this.$t("project.participation.list.delete.deleteOptionCaptionReleasedUnreleased");
                } else if ((!deleteInfo.hasUnreleasedObjectListAssignments && deleteInfo.hasReleasedObjectListAssignments
                    && !deleteInfo.hasAttachedReviewTasks && !deleteInfo.hasNotificationListenerAssignments
                    && !deleteInfo.hasPlottOrderListenerAssignments) || deleteInfo.deleteJoins.length == 0) {
                    return this.$t("project.participation.list.delete.deleteOptionCaptionReleased");
                } else {
                    return this.$t("project.participation.list.delete.deleteOptionCaptionElse");
                }
            }
        },
        cancelDesc() : string {
            let deleteInfo : ParticipationDeleteInfo = this.deleteInfo as ParticipationDeleteInfo;
            if (deleteInfo == null) {
                return "";
            }
            if (this.buek) {
                return this.$t("project.participation.list.delete.cancelOptionDescElse");
            } else {
                if (deleteInfo.hasReleasedObjectListAssignments) {
                    return this.$t("project.participation.list.delete.cancelOptionDescHasReleased");
                } else {
                    return this.$t("project.participation.list.delete.cancelOptionDescElse");
                }
            }
        },
        buek() : boolean {
            let deleteInfo : ParticipationDeleteInfo = this.deleteInfo as ParticipationDeleteInfo;
            return deleteInfo != null && deleteInfo.buek;
        }
    },
    methods : {
        getSubProjectLabel(subProject : SubProject) : string {
            return subProject.code + " " + subProject.name;
        },
        close() : void {
            this.$emit("doClose");
        },
        doDelete() : void {
            let deleteInfo : ParticipationDeleteInfo = this.deleteInfo as ParticipationDeleteInfo;
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            if (deleteInfo.participationId != null) {
                this.ctx.projectService.deleteParticipationWithRelationsById(organisationPersonId, deleteInfo.participationId)
                    .then(() => {
                        this.$emit("removeParticipation", deleteInfo.organisationId, deleteInfo.participationId);
                        this.$emit("doClose");                        
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            } else {
                this.ctx.projectService.deleteParticipationWithRelationsByOrganisation(organisationPersonId,
                                                                                       deleteInfo.projectId, deleteInfo.organisationId)
                    .then(() => {
                        this.$emit("removeParticipation", deleteInfo.organisationId, null);
                        this.$emit("doClose");                        
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        }
    }
});
