import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultiSelect, {
      id: _ctx.$id(this.participationInfo.projectParticipation.id + 'allowedSubProjects'),
      class: "form-control",
      "data-bs-toggle": "tooltip",
      modelValue: this.allowedSubProjectIds,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((this.allowedSubProjectIds) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (this.saveChange()))
      ],
      itemKey: a => a.id,
      itemValue: a => a.id,
      itemLabel: a => '(' + a.code + (a.number != null ? (a.number + ' ') : '') + ') ' + a.name,
      disabled: !this.editInfo.project.withParticipantsSubProjectRestriction || this.disabled || !this.securityInfo.mayEditParticipation,
      options: _ctx.editInfo.subProjects,
      getRemovalError: this.getRemovalError,
      sort: this.compareSubProjects
    }, null, 8, ["id", "modelValue", "itemKey", "itemValue", "itemLabel", "disabled", "options", "getRemovalError", "sort"])
  ]))
}