import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "name", "value"]
const _hoisted_2 = ["for"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  type: "button",
  class: "btn btn-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_5 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: this.title != null ? this.title : this.$t('review.upload.preview.notFoundTitle'),
    ref: "dialog",
    lazy: ""
  }, {
    default: _withCtx(({ data }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.options, ([resolutionOption, label]) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "form-check",
          key: resolutionOption
        }, [
          _withDirectives(_createElementVNode("input", {
            id: _ctx.$id('choice' + resolutionOption),
            class: "form-check-input",
            type: "radio",
            name: _ctx.$id('notFoundResolution'),
            value: resolutionOption,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resolution) = $event))
          }, null, 8, _hoisted_1), [
            [_vModelRadio, _ctx.resolution]
          ]),
          _createElementVNode("label", {
            class: "form-check-label",
            for: _ctx.$id('choice' + resolutionOption)
          }, _toDisplayString(this.convertLabel(resolutionOption,label)), 9, _hoisted_2)
        ]))
      }), 128))
    ]),
    buttons: _withCtx(({ submit }) => [
      (this.emptyOptions)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-primary",
            onClick: ($event: any) => (submit(this.resolution))
          }, _toDisplayString(_ctx.$t('general.ok')), 9, _hoisted_3))
        : _createCommentVNode("", true),
      (!this.emptyOptions)
        ? (_openBlock(), _createElementBlock("button", _hoisted_4, _toDisplayString(_ctx.$t('general.abort')), 1))
        : _createCommentVNode("", true),
      (!this.emptyOptions)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            disabled: !this.optionChosen,
            type: "button",
            class: "btn btn-primary",
            onClick: ($event: any) => (submit(this.resolution))
          }, _toDisplayString(_ctx.$t('general.continue')), 9, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}