/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

function toHexDigit(index: number): string {
    return "0123456789ABCDEF".charAt(index);
}

function formatUuid(data: Uint8Array): string {
    return [4, 2, 2, 2, 6]
    .reduce(
        ({ start, acc }, length) => ({
            start: start + length,
            acc: [...acc, Array.from(data.subarray(start, start + length))
                .map(a => toHexDigit(a & 0b1111) + toHexDigit(a >> 4))
                .join("")]
        }),
        {
            start: 0,
            acc: [],
    }).acc.join("-");
}

export function randomUuid(): string {
    return formatUuid(crypto.getRandomValues(new Uint8Array(16)));
}
