import { h, VNode } from "vue";
import { ParempiRowPos } from "./ParempiRowPos";
import { Comparator } from "cdes-vue/util/Sort";

type RowSpanFct<T> = (item : T, rowPos : ParempiRowPos) => number;
type RenderFct<T> = (item : T, rowPos? : ParempiRowPos) => VNode | VNode[];

export interface ParempiColumnParams<T> {
    id : string;
    label? : string;
    headerSlotName?: string | undefined;
    headerWrappingSlotName?: string | undefined;
    property? : string;
	slotName?: string | undefined;
    sort?: boolean | Comparator<T> | undefined;
    filter?: boolean | ((val: T) => (string[] | string)) | undefined;
    rowSpanFct? : RowSpanFct<T>;
    renderFct? : RenderFct<T>;
    minWidth?: number /* px */;
    // number will be interpreted as fr.
    defaultWidth?: number | string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ParempiColumn<T> extends ParempiColumnParams<T> { }
export class ParempiColumn<T> {
    constructor(params : ParempiColumnParams<T>) {
		Object.assign(this, params);
    }

    public getRowSpan(item : T, rowPos : ParempiRowPos) : number {
        if (this.rowSpanFct != null && rowPos != null) {
            return this.rowSpanFct(item, rowPos);
        } else {
            return 1;
        }
    }

    public static empty<T>(id : string, label : string) : ParempiColumn<T> {
        return new ParempiColumn<T>({
            id : id,
            label : label
        });
    }

    public static asString<T>(id : string, property : string, label : string) : ParempiColumn<T> {
        return new ParempiColumn<T>({
            id : id,
            property : property,
            label : label
        });
    }

    public static asStrings<T>(id : string, properties : string[], label : string) : ParempiColumn<T> {
        let renderFct : RenderFct<T> = (item : T) => {
            let lines : VNode[] = [];
            for (let property of properties) {
                lines.push(h("div", item[property]));
            }
            return lines;
        };
        return new ParempiColumn<T>({
            id : id,
            label : label,
            renderFct : renderFct
        });
    }

    public static asRenderFct<T>(id : string, label : string, renderFct : RenderFct<T>) : ParempiColumn<T> {
        return new ParempiColumn<T>({
            id : id,
            label : label,
            renderFct : renderFct
        });
    }

    public static asRowSpanRenderFct<T>(id : string, label : string, rowSpanFct : RowSpanFct<T>,
                                        renderFct : RenderFct<T>) : ParempiColumn<T> {
        return new ParempiColumn<T>({
            id : id,
            label : label,
            rowSpanFct : rowSpanFct,
            renderFct : renderFct
        });
    }

    public static asRowSpanSlot<T>(id: string, label: string, slotName?: string, rowSpanFct?: RowSpanFct<T>): ParempiColumn<T> {
		return  new ParempiColumn<T>({
			id,
			label,
			slotName: (slotName ?? id),
			rowSpanFct,
		});
    }

    public static asWidthRowSpanSlot<T>(defaultWidth : number | string, id: string, label: string,
                                        slotName?: string, rowSpanFct?: RowSpanFct<T>): ParempiColumn<T> {
        return  new ParempiColumn<T>({
            defaultWidth,
            id,
            label,
            slotName: (slotName ?? id),
            rowSpanFct,
        });
    }    
}
