import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "loginDialogRoot",
  ref: "loginDialogRoot",
  class: "modal",
  tabindex: "-1",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body d-flex flex-column" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t("libs.clazzesVue.loginDialog.dialogCaption")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("iframe", {
            class: "clazzesLoginFrame",
            src: _ctx.loginUrl,
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFrameLoad && _ctx.onFrameLoad(...args)))
          }, null, 40, _hoisted_7),
          _createElementVNode("a", {
            href: "",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.openPasswordForgottenPage && this.openPasswordForgottenPage(...args)))
          }, _toDisplayString(_ctx.$t("login.passwordForgottenLink")), 1)
        ])
      ])
    ])
  ], 512))
}