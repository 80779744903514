/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { PlotTemplateResolver } from "cdes-vue/voc/plot/PlotTemplateResolver";

export class PlotOrderTemplateHelper {

    public static getTemplateResolverLabel($t, templateResolver : PlotTemplateResolver) : string {
        if (templateResolver == PlotTemplateResolver.MANUAL) {
            return $t("plot.template.resolver.manual");
        } else if (templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION) {
            return $t("plot.template.resolver.projectParticipation");
        } else if (templateResolver == PlotTemplateResolver.REVIEW_CYCLE) {
            return $t("plot.template.resolver.reviewCycle");
        } else {
            throw new Error("Illegal template resolver: [" + templateResolver + "]");
        }
    }
}
