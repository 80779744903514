/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, reactive } from "vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { Dropdown } from "bootstrap";
import { isArray } from "lodash-es";

type FilteredOption<T> = {
    option: T,
    index: number,
};

function logVars(label: string, vars: Record<string, unknown>) {
    console.group(label);
    for (const [k, v] of Object.entries(vars)) {
        console.log(`${k} =`, v);
    }
    console.groupEnd();
}

export default defineComponent({
    props: {
        id : {
            type : String
        },

        defaultValue: {
            type: undefined as PropType<unknown>,
            default: () => null,
        },

        optional: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        optionalLabel: {
            type: String as PropType<string>,
            default: () => "",
        },

        optionLabel: {
            type: Function as PropType<(a: unknown) => string>,
            default(this: void, a: unknown) {
                if (typeof a !== "object" || !("label" in a)) {
                    throw new Error("option does not have a label property, Did you want to specify optionLabel?");
                }
                // @ts-ignore
                return a.label;
            },
        },

        optionValue: {
            type: Function as PropType<(a: unknown) => unknown>,
            default(this: void, a: unknown) {
                if (typeof a !== "object" || !("value" in a)) {
                    throw new Error("option does not have a value property, Did you want to specify optionValue?");
                }
                // @ts-ignore
                return a.value;
            },
        },

        modelValue : {
            type: undefined as PropType<unknown>,
            default(this: void, props: unknown): unknown {
                // vue seems to magically preserve the order of props.
                // @ts-ignore
                return props.defaultValue;
            },
        },

        autocomplete: {
            type: String as PropType<"list" | "both">,
            default: () => "list",
        },

        allowArbitrary: {
            type: Boolean as PropType<boolean>,
            default: () => true,
        },

        filterMode: {
            type: String as PropType<"prefix" | "infix">,
            default: () => "infix",
        },

        options: {
            type: [Array, Symbol] as PropType<unknown[] | typeof loadingPlaceholder>,
            default: () => [],
        },

        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        equals: {
            type: Function as PropType<((valueA: unknown, valueB: unknown) => boolean)>,
            // writing this as an arrow function breaks type inference.
            default(this: void, valueA: unknown, valueB: unknown): boolean {
                return valueA === valueB;
            },
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            expanded: false,
            dropdown: undefined as (undefined | Dropdown),
            resizeObserver: new ResizeObserver(this.resizeCallback.bind(this)),
            form: undefined as (HTMLFormElement | undefined),
            formResetListener: this.reset.bind(this),
            lastUserInputValue: "",
            inlineCompletionActive: false,
            shouldShowOption: false,
        };
    },

    created(): void {
        this.lastUserInputValue = this.getInputValueFor(this.value)
    },

    setup(props, context) {
        return  {
            loadingPlaceholder,
            value: makePropWithValue(props, context, "modelValue"),
        };
    },

    watch: {
        expanded(expanded: boolean): void {
            if (expanded) {
                this.resizeObserver.observe(this.$refs.select as HTMLElement);
                this.dropdown.show();
            } else {
                this.resizeObserver.disconnect();
                this.dropdown.hide();
                this.lastUserInputValue = this.inputValue;
                this.shouldShowOption = false;
            }
        },
        disabled(disabled: boolean): void {
            if (disabled) {
                this.hide();
            }
        },
        form(newForm, oldForm): void {
            if (oldForm) {
                oldForm.removeEventListener("reset", this.formResetListener);
            }
            if (newForm) {
                newForm.addEventListener("reset", this.formResetListener);
            }
        },
        filteredOptions(filteredOptions: FilteredOption<unknown>[] | LoadingPlaceholder, prevFilteredOptions: FilteredOption<unknown>[] | LoadingPlaceholder): void {
            if (this.autocomplete === "both") {
                // if we have inline autocomplete we generally select the first item.
                if (filteredOptions !== loadingPlaceholder && filteredOptions.length > 0) {
                    this.selectedIndex = filteredOptions[0].index;
                }
            } else if (this.autocomplete === "list") {
                if ( this.selectedIndex !== null
                  && filteredOptions !== loadingPlaceholder
                  && prevFilteredOptions !== loadingPlaceholder
                  && filteredOptions.findIndex(a => a.index === this.selectedIndex) < 0) {
                    this.value = this.arbitrarySelection;
                }
            }
        },
        trueOptions(options: unknown | LoadingPlaceholder, prevOptions: unknown | LoadingPlaceholder): void {
            if (options !== loadingPlaceholder) {
                if (this.selectedIndex === undefined && !(this.doesAllowArbitrary && typeof this.value === "string")) {
                    this.reset();
                } else {
                    this.lastUserInputValue = this.getInputValueFor(this.value);
                }
            }
        },

        selectionRange(selectionRange: [number, number] | undefined): void {
            if (selectionRange !== undefined) {
                this.$nextTick(() => {
                    (this.$refs.select as HTMLInputElement).setSelectionRange(...selectionRange);
                });
            }
        },

        modelValue(modelValue: unknown): void {
            if (this.doesAllowArbitrary && typeof modelValue === "string" && modelValue !== this.value) {
                this.lastUserInputValue = this.getInputValueFor(modelValue);
            }
        },
    },

    mounted() {
        this.dropdown = new Dropdown(this.$refs.select, {
            popperConfig: (popperConfig) => {
                popperConfig.modifiers.push({
                    name: 'flip',
                    options: {
                        // don't try to flip between start/end alignments
                        flipVariations: false,
                    },
                });
                return popperConfig;
            },
        });

        let el = this.$refs.select as (HTMLElement | undefined);
        do {
            el = el.parentElement;
        } while (el && !(el instanceof HTMLFormElement));
        this.form = el as (HTMLFormElement | undefined);
    },

    unmounted() {
        this.dropdown.dispose();
        this.dropdown = undefined;
        this.form = undefined;
    },

    deactivated() {
        this.expanded = false;
    },

    computed: {
        trueOptions(): unknown[] | LoadingPlaceholder {
            if (this.options === loadingPlaceholder) {
                return loadingPlaceholder;
            }

            // object/arrays passed in props are not necessarily made reactive themselves.
            // if we don't ensure that they are actually reactive we get problems with object equality.
            if (this.optional) {
                return reactive([null].concat(this.options));
            } else {
                return reactive(this.options);
            }
        },
        isLoading(): boolean {
            return this.trueOptions === loadingPlaceholder;
        },
        trueDisabled(): boolean {
            return this.disabled || this.isLoading;
        },
        doesAllowArbitrary(): boolean {
            return this.autocomplete !== "both" && this.allowArbitrary;
        },
        isExact(): boolean {
            if (this.trueOptions === loadingPlaceholder) {
                return false;
            }
            return this.trueOptions.findIndex(a => this.trueOptionLabel(a) === this.lastUserInputValue) >= 0;
        },
        arbitrarySelection(): string | undefined {
            /*logVars("arbitrarySelection", {
                doesAllowArbitrary: this.doesAllowArbitrary,
                isExact: this.isExact,
                lastUserInputValue: this.lastUserInputValue,
                isLoading: this.isLoading,
            });*/
            if (!this.doesAllowArbitrary) {
                return undefined;
            }

            if (this.isLoading) {
                return undefined;
            }
            if (!this.isExact && this.lastUserInputValue.length > 0) {
                return this.lastUserInputValue;
            } else {
                return undefined;
            }
        },
        isPrefix(): boolean {
            return this.autocomplete === "both" || this.filterMode === "prefix";
        },
        isInfix(): boolean {
            return this.autocomplete !== "both" && this.filterMode === "infix";
        },
        selectId(): string {
            return this.id ?? this.$id("select");
        },
        filter(): string {
            return this.isExact ? "" : this.lastUserInputValue;
        },
        inputValue(): string {
            /*logVars("inputValue", {
                selectedIndex: this.selectedIndex,
                expanded: this.expanded,
                shouldShowOption: this.shouldShowOption,
                arbitrarySelection: this.arbitrarySelection,
                lastUserInputValue: this.lastUserInputValue,
            });*/
            if (this.selectedIndex !== undefined && ((this.inlineCompletionActive && this.autocomplete === "both") || !this.expanded || this.shouldShowOption)) {
                //console.log("inputValue from option");
                return this.trueOptionLabel(this.trueOptions[this.selectedIndex]);
            } else if (this.arbitrarySelection != null && this.selectedIndex === undefined) {
                //console.log("inputValue from arbitrarySelection");
                return this.arbitrarySelection;
            } else {
                //console.log("inputValue from lastUserInputValue");
                return this.lastUserInputValue;
            }
        },
        filteredOptions(): FilteredOption<unknown>[] | LoadingPlaceholder {
            if (this.trueOptions === loadingPlaceholder) {
                return loadingPlaceholder;
            }

            let result = this.trueOptions.map((option, index) => ({option, index}))
                .filter(({ option }) => {
                    return true;
                if (this.isPrefix) {
                    return this.trueOptionLabel(option).toLowerCase().startsWith(this.filter.toLowerCase());
                } else if (this.isInfix) {
                    return this.trueOptionLabel(option).toLowerCase().includes(this.filter.toLowerCase());
                }
                throw new Error("unreachable");
            });

            return result;
        },
        offeredOptions()  {
            if (this.filteredOptions === loadingPlaceholder) {
                return loadingPlaceholder;
            }

            if (this.lastUserInputValue == null) {
                return this.filteredOptions;
            } else {
                let offeredOptions = [];
                for (let n = 0; n < this.filteredOptions.length; n++) {
                    let option = this.filteredOptions[n];
                    // @ts-ignore
                    let label : string = option != null && option.option != null ? option.option.label : null;
                    if (label == null || label.toLocaleLowerCase().includes(this.lastUserInputValue.toLocaleLowerCase())) {
                        offeredOptions.push(option);
                    }
                }
                return offeredOptions;
            }
        },
        selectedIndex: {
            get(): number | undefined {
                /*logVars("get selectedIndex", {
                    trueOptions: this.trueOptions,
                    value: this.value,
                    filter: this.filter,
                });*/
                if (this.trueOptions === loadingPlaceholder) {
                    return undefined;
                }
                const index = this.trueOptions.findIndex(a => this.equals(this.trueOptionValue(a), this.value) || this.trueOptionLabel(a) === this.filter);
                return index === -1 ? undefined : index;
            },
            set(selectedIndex: number | undefined) {
                /*logVars("set selectedIndex", {
                    selectedIndex,
                    trueOptions: this.trueOptions,
                    value: this.value,
                    filter: this.filter,
                });*/
                if (selectedIndex >= 0 && this.trueOptions !== loadingPlaceholder && selectedIndex < this.trueOptions.length) {
                    this.value = selectedIndex == null ? undefined : this.trueOptionValue(this.trueOptions[selectedIndex]);
                }
            },
        },
        selectedFilteredIndex: {
            get(): number | undefined {
                /*logVars("get selectedFilteredIndex", {
                    filteredOptions: this.filteredOptions,
                    value: this.value,
                    filter: this.filter,
                });*/
                if (this.filteredOptions === loadingPlaceholder) {
                    return undefined;
                }
                const index = this.filteredOptions.findIndex(a => this.equals(this.trueOptionValue(a.option), this.value) || this.trueOptionLabel(a.option) === this.filter);
                return index === -1 ? undefined : index;
            },
            set(selectedIndex: number | undefined) {
                /*logVars("set selectedFilteredIndex", {
                    filteredOptions: this.filteredOptions,
                    value: this.value,
                    filter: this.filter,
                });*/
                if (selectedIndex >= 0 && this.filteredOptions !== loadingPlaceholder && selectedIndex < this.filteredOptions.length) {
                    this.value = selectedIndex == null ? undefined : this.trueOptionValue(this.filteredOptions[selectedIndex].option);
                }
            },
        },
        activeDescendant(): string {
            if (this.expanded && (this.selectedIndex !== undefined || this.doesAllowArbitrary)) {
                return this.$id('item' + (this.selectedIndex ?? "arbitrary"));
            } else {
                return "";
            }
        },
        selectionRange(): [number, number] | undefined {
            if (this.autocomplete === "both" && this.inlineCompletionActive) {
                return [this.filter.length, this.inputValue.length];
            } else {
                return [this.inputValue.length, this.inputValue.length];
            }
        },
    },

    methods: {
        toggle(): void {
            if (!this.trueDisabled) {
                this.expanded = !this.expanded;
            }
        },
        hide(): void {
            this.expanded = false;
        },
        show(): void {
            if (!this.trueDisabled) {
                this.expanded = true;
            }
        },
        onInput(e: InputEvent): void {
            this.show();
            this.shouldShowOption = false;
            const newUserInputValue = (e.target as HTMLInputElement).value
            if (newUserInputValue !== this.lastUserInputValue) {
                /*logVars("onInput", {
                    newUserInputValue: newUserInputValue,
                    inputValue: this.inputValue,
                    lastUserInputValue: this.lastUserInputValue,
                });*/
                const isDeleting = newUserInputValue.length < this.inputValue.length && this.inputValue.startsWith(newUserInputValue) && !(newUserInputValue.length > this.lastUserInputValue.length && newUserInputValue.startsWith(this.lastUserInputValue));
                this.lastUserInputValue = newUserInputValue;
                this.inlineCompletionActive = !isDeleting && this.filteredOptions !== loadingPlaceholder && this.filteredOptions.length > 0;
            }
        },
        interruptInlineCompletion(): void {
            this.inlineCompletionActive = false;
        },
        resizeCallback(entries: ResizeObserverEntry[]): void {
            for (const entry of entries) {
                if (entry.target === this.$refs.select) {
                    (this.$refs.dropdown as HTMLElement).style.minWidth =
                        (this.$refs.select as HTMLElement).offsetWidth + "px";
                }
            }
        },
        keydown(event): void {
            if (this.trueDisabled) {
                return;
            }
            if (event.altKey) {
                if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                    this.toggle();
                    event.preventDefault();
                }
            } else if (event.key === "ArrowDown") {
                this.moveDown();
                event.preventDefault();
            } else if (event.key === "ArrowUp") {
                this.moveUp();
                event.preventDefault();
            } else if (event.key === "Enter") {
                this.hide();
                event.preventDefault();
            } else if (event.key === "Escape") {
                if (this.expanded) {
                    this.hide();
                } else {
                    this.reset();
                }
                event.preventDefault();
            }
        },
        moveUp(): void {
            this.interruptInlineCompletion();
            this.shouldShowOption = true;
            const index = this.selectedFilteredIndex;
            if (index === 0) {
                this.value = this.arbitrarySelection;
            } else {
                if (this.filteredOptions === loadingPlaceholder) {
                    throw new Error("moveUp called while options are loading.");
                }
                this.selectedFilteredIndex = index == null ? this.filteredOptions.length - 1 : index - 1;
            }
        },
        moveDown(): void {
            this.interruptInlineCompletion();
            this.shouldShowOption = true;
            const index = this.selectedFilteredIndex;
            this.selectedFilteredIndex = index == null ? 0 : index + 1;
        },
        itemClick(index: number): void {
            /*logVars("itemClick", {
                index,
                value: this.value,
                selectedIndex: this.selectedIndex,
            });*/
            if (index == null) {
                this.value = this.arbitrarySelection;
            } else {
                this.selectedIndex = index;
            }
            this.hide();
        },
        reset(): void {
            this.value = this.defaultValue;
            this.lastUserInputValue = this.getInputValueFor(this.defaultValue);
        },
        getInputValueFor(value: unknown): string {
            const optionIndex = this.findOption(this.value);
            if (optionIndex >= 0) {
                return this.trueOptionLabel(this.trueOptions[optionIndex]);
            } else if (this.doesAllowArbitrary && typeof value === "string") {
                return value;
            } else {
                return "";
            }
        },
        isOption(value: unknown): boolean {
            return this.findOption(value) >= 0;
        },

        findOption(value: unknown): number {
            if (this.trueOptions === loadingPlaceholder) {
                return -1;
            }

            return this.trueOptions.findIndex(option => this.equals(this.trueOptionValue(option), this.value));
        },

        trueOptionLabel(option: unknown): string {
            if (this.optional && option == null) {
                return this.optionalLabel;
            } else {
                return this.optionLabel(option);
            }
        },

        trueOptionValue(option: unknown): unknown {
            if (this.optional && option == null) {
                return option;
            } else {
                return this.optionValue(option);
            }
        },
    },
});
