/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType } from "vue";
import ManualNotFoundResolution from "cdes-api/dto/document/ManualNotFoundResolution";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { NotFoundParams } from "./NotFoundParams";
import { LabelStatus } from "../../../../../cdes-ts-api/src/cdes-api/dto/document/LabelStatus";

export default defineComponent({
    components: {
        Dialog,
    },

    data() {
        return {
            resolution: null as (ManualNotFoundResolution | null),
            title : null,
            labelStatus : null,
            options : null,
        };
    },

    methods: {
        show(params : NotFoundParams): Promise<ManualNotFoundResolution | null> {
            this.title = params.title;
            this.labelStatus = params.labelStatus;
            this.options = params.options;
            return (this.$refs.dialog as InstanceType<typeof Dialog>).show(params) as Promise<ManualNotFoundResolution | null>;
        },
        convertLabel(option : ManualNotFoundResolution, unused : string) : string {
            if (option == ManualNotFoundResolution.LABEL_IGNORED) {
                return this.$t("review.upload.preview.notFoundLabelIgnored");
            } else if (option == ManualNotFoundResolution.RENDER_LABEL_OUTSIDE_OF_PLAN) {
                return this.$t("review.upload.preview.notFoundLabelRenderLabelOutsidePlan");
            } else if (option == ManualNotFoundResolution.RENDER_LABEL_AS_IS) {
                return this.$t("review.upload.preview.notFoundLabelAsIs");
            } else if (option == null) {
                return this.$t("review.upload.preview.notFoundLabelTrue");
            } else {
                return "Unknown, please notify administrator";
            }
        }
    },

    computed : {
        emptyOptions() : boolean {
            return this.options == null || this.options.length == 0;
        },
        optionChosen() : boolean {
            return this.options == null || this.resolution != null;
        }
    }
});
