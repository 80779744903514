/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { Attachment } from "cdes-api/dto/Attachment";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import { ReviewCycleResultOption } from "cdes-api/dto/ReviewCycleResultOption";
import DOMPurify from "dompurify";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        version: {
            type: [Object, Symbol] as PropType<ReviewProtocolVersionJoin | LoadingPlaceholder>,
        },
        nodeResult: {
            type: [Object, Symbol] as PropType<ReviewProtocolVersionJoin | LoadingPlaceholder>,
        }
    },

    emits: ["update:hidden"],

    methods: {
        getRelevantPositionJoins(context: ReviewProtocolContext, version: ReviewProtocolVersionJoin, nodeResult: ReviewProtocolVersionJoin, initialResults: ReviewProtocolVersionJoin[], nodePositionJoins: ReviewProtocolVersionJoin[]) {
            const nodeResultForEndNode = !Array.from(context.idToResultOption.values())
            .some(resultOption => resultOption.reviewCyclePositionTypeId === nodeResult.reviewCycleNodeReviewCyclePositionTypeId);

            if (nodeResultForEndNode) {
                return [];
            } else {
                return nodePositionJoins.filter(nodePosition =>
                    nodePosition.reviewCycleNodeId === nodeResult.reviewCycleNodeId
                 && !initialResults.some(initialResult =>
                     initialResult.reviewCyclePositionResultRealmId === nodePosition.nodePositionRealmId
                  && initialResult.positionParticipantParticipationId === nodePosition.nodePositionProjectParticipationId));
            }
        },
        sanitize: DOMPurify.sanitize,
        getAttachmentLink(attachment: Attachment): string {
            if (attachment.isRedliningDelta) {
                return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionReviewProtocolPdfMimeSource", [""+attachment.documentVersionId]);
            } else {
                return "/cdes-dojo-impl/repositoryDownload/attachments/" + this.ctx.activeOrganisationPersonId + "/" + attachment.id;
            }
        },
    },

    computed: {
        positions(): [ReviewProtocolVersionJoin, ReviewCycleResultOption][] | [LoadingPlaceholder, LoadingPlaceholder][] {
            const { context, version, nodeResult } = this;
            if (context === loadingPlaceholder || version === loadingPlaceholder || nodeResult === loadingPlaceholder) {
                return new Array(4).fill([loadingPlaceholder, loadingPlaceholder]);
            } else {
                const initialResults = Array.from(context.nodeResultIdToPositionResultIdToPositionResult.get(nodeResult.reviewCycleNodeResultId)?.values() ?? [])
                    .filter((positionResult: ReviewProtocolVersionJoin) => {
                        const resultOption = context.idToResultOption.get(positionResult.reviewCyclePositionResultReviewCycleResultOptionId);
                        return positionResult.reviewCyclePositionResultSignatureId != null
                            && resultOption != null
                            && resultOption.value < -8;
                    });
                
                return initialResults.map(position => [position, context.idToResultOption.get(position.reviewCycleNodeResultReviewCycleResultOptionId)]);                    
            }
        },
        hidden(): boolean {
            return this.positions.length <= 0;
        },
    },

    watch: {
        hidden: {
            handler(hidden: boolean): void {
                this.$emit("update:hidden", hidden);
            },
            immediate: true,
        },
    },

    setup() {
        return {
            loadingPlaceholder,
        };
    },
});
