import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table documentReferencesTable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentReferenceEditWidget = _resolveComponent("DocumentReferenceEditWidget")!
  const _component_DocumentReferenceEditRow = _resolveComponent("DocumentReferenceEditRow")!
  const _component_Transform = _resolveComponent("Transform")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.realmShort')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.documentReferenceHeader')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.referenceNumber')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.date')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('general.column.actions')), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.persistentDocumentReferences, (documentReference) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: documentReference.id
          }, [
            (this.showDocumentReference(documentReference))
              ? (_openBlock(), _createBlock(_component_DocumentReferenceEditWidget, {
                  key: 0,
                  disabled: this.disabled,
                  documentReference: documentReference,
                  onDelete: ($event: any) => (this.doDelete(documentReference))
                }, null, 8, ["disabled", "documentReference", "onDelete"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ourDocumentReferences, (reference, index) => {
          return (_openBlock(), _createBlock(_component_DocumentReferenceEditRow, {
            key: reference.id,
            disabled: this.disabled,
            class: _normalizeClass({ 'was-validated': _ctx.wasValidated }),
            reference: reference,
            "onUpdate:reference": ($event: any) => (_ctx.ourDocumentReferences[index] = $event),
            temporaryDocumentVersionId: _ctx.temporaryDocumentVersionId,
            onDelete: ($event: any) => (_ctx.ourDocumentReferences.splice(index, 1))
          }, null, 8, ["disabled", "class", "reference", "onUpdate:reference", "temporaryDocumentVersionId", "onDelete"]))
        }), 128)),
        _createVNode(_component_Transform, { transformer: _ctx.temporaryTransformer }, {
          default: _withCtx(() => [
            _createVNode(_component_DocumentReferenceEditRow, {
              disabled: this.disabled,
              temporary: "",
              class: _normalizeClass({ 'was-validated': _ctx.wasTemporaryValidated }),
              temporaryDocumentVersionId: _ctx.temporaryDocumentVersionId,
              reference: _ctx.temporary,
              "onUpdate:reference": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.temporary) = $event)),
              onAdd: _cache[1] || (_cache[1] = ($event: any) => (this.$refs.newRow.$el.dispatchEvent(new _ctx.SubmitEvent('submit', { submitter: $event.target, cancelable: true })))),
              ref: "newRow",
              onlyValidateFor: _ctx.id('temporary')
            }, null, 8, ["disabled", "class", "temporaryDocumentVersionId", "reference", "onlyValidateFor"])
          ]),
          _: 1
        }, 8, ["transformer"])
      ])
    ])
  ]))
}