/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export default (el: HTMLElement, {value}: { value: string | null | undefined }) => {
    // @ts-ignore
    el.setCustomValidity(value ?? "");

    // If we didn't dispatch an input event we would have no way of detecting that the element just became invalid.
    el.dispatchEvent(new InputEvent("input", {
        bubbles: true,
        cancelable: true,
        composed: true,
    }));
};
