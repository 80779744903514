import { ref, Ref, shallowRef } from "vue";

export const loadingPlaceholder = Symbol("loading…");
export type LoadingPlaceholder = typeof loadingPlaceholder;

// this function converts a promise to a reactive value that is equal to the default "def" while the
// promise is unresolved and after the promise has resolved it is equal to the value the promise resolved with.
export function resolvedOrDefault<T, D>(promise: Promise<T> | T, def: D): Ref<D | T> {
    const ret = shallowRef<T | D>(def);
    if (promise instanceof Promise) {
        promise.then(a => ret.value = a);
    } else {
        ret.value = promise;
    }
    return ret;
}

export function resolvedOrDefaultOrError<T, D, ED>(promise: Promise<T> | T, def: D, errDef: ED): { result: Ref<D | T>, error: Ref<ED | unknown> } {
    const result = shallowRef<T | D>(def);
    const error = shallowRef<ED | unknown>(errDef);
    if (promise instanceof Promise) {
        promise.then(a => result.value = a, e => error.value = e);
    } else {
        result.value = promise;
    }

    return {
        result,
        error,
    };
}

export function mapLoading<T, R>(func: (val: T) => R, val: T | LoadingPlaceholder): R | LoadingPlaceholder {
    if (val === loadingPlaceholder) {
        return loadingPlaceholder;
    } else {
        return func(val);
    }
}

export function loadedOrDefault<T, D>(value: T | LoadingPlaceholder, def: () => D | D): T | D {
    if (value === loadingPlaceholder) {
        return typeof def === "function" ? def() : def;
    } else {
        return value;
    }
}

export function loadingArray<T extends unknown[]>(arr: T | LoadingPlaceholder, defaultLength: number = 4): LoadingPlaceholder[] | T {
    return loadedOrDefault(arr, () => new Array(defaultLength).fill(loadingPlaceholder));
}
