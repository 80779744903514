import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pageCaption ps-2" }
const _hoisted_2 = { class: "text-expanded" }
const _hoisted_3 = { class: "text-collapsed" }
const _hoisted_4 = { class: "countLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OriginalDocumentOrderSearchWidget = _resolveComponent("OriginalDocumentOrderSearchWidget")!
  const _component_Collapse = _resolveComponent("Collapse")!
  const _component_OriginalDocumentOrderListWidget = _resolveComponent("OriginalDocumentOrderListWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("original.page.caption")), 1),
    _createVNode(_component_Collapse, null, {
      label: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('general.button.collapseSearch')), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.button.openSearch')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_OriginalDocumentOrderSearchWidget, {
          disabled: this.disabled,
          ref: "searchWidget",
          class: "mb-3 ms-4 me-3",
          onSearch: _ctx.search
        }, null, 8, ["disabled", "onSearch"])
      ]),
      _: 1
    }),
    _createVNode(_component_OriginalDocumentOrderListWidget, {
      infos: _ctx.infos,
      onRequestOriginal: _ctx.requestOriginal,
      onRemoveOrder: _ctx.removeOrder
    }, null, 8, ["infos", "onRequestOriginal", "onRemoveOrder"]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(this.countLabelString), 1)
  ], 64))
}