/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { PlotTaskInfo } from "cdes-api/dto/plot/PlotTaskInfo";
import { Address } from "cdes-api/dto/util/Address";
import { useCtx } from "cdes-vue/util/Ctx";
import AddressOutput from "cdes-vue/util/output/AddressOutput.vue";
import { AddressHelper } from "cdes-vue/util/output/AddressHelper";
import { asyncEagerComputed, loadingPlaceholder } from "cdes-vue/util/Prop";
import { defineComponent, PropType } from "vue";
import PlotTaskItemListWidget from "cdes-vue/plot/task/PlotTaskItemListWidget.vue";
import { PlotTaskItemInfo } from "cdes-api/dto/plot/PlotTaskItemInfo";
import { LengthCalculator } from "../util/LengthCalculator";
import { LengthUnit } from "../util/LengthUnit";
import { PlotHelper } from "../util/PlotHelper";
import { PlottProperties } from "cdes-api/dto/PlottProperties";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import RadioButton from "cdes-vue/util/form/RadioButton.vue";
import PasswordDialog from "cdes-vue/util/layout/PasswordDialog.vue";
import { JobHelper } from "clazzes-core/util/JobHelper";
import { IJobStatusService } from "clazzes-core/svc/IJobStatusService";
import JobStatusDTO from "cdes-api/dto/job/JobStatusDTO";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ContextJoin } from "cdes-api/joinDto/ContextJoin";

export enum PlotTaskMode {
    PLOT_ORDER_ACCEPTANCE_TASK = "PlotOrderAcceptanceTask",
    REVIEW_CYCLE_PLOT_TASK = "ReviewCyclePlotTask"
}

export default defineComponent({
    components : {
        AddressOutput : AddressOutput,
        RadioButton : RadioButton,
        TextInput : TextInput,
        PasswordDialog : PasswordDialog,
        PlotTaskItemListWidget : PlotTaskItemListWidget
    },

    props: {
        mode : {
            type : String
        },
        taskId : {
            type : Number as PropType<number | null>,
        },
        plotOrderId : {
            type : Number as PropType<number | null>,
        }
    },

    data() {
        return {
            taskInfo : null,
            comment : null,
            result : "accepted",
            password : null,
            loadingPdf : false,
            fetchRunning : false,
            signRunning : false
        };
    },

    watch : {
        result(newValue, oldValue) {
            console.info("result: [" + oldValue + "] --> [" + newValue + "]");
        }
    },

    computed : {
        disabled() : boolean {
            return this.fetchRunning || this.signRunning;
        },

        billingAddress() : Address {
            if (this.taskInfo == null) {
                return new Address();
            } else {
                let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
                return AddressHelper.getAddressFromOrderAddress(taskInfo.billingAddress, taskInfo.billingCountry, false, true);
            }
        },

        plotterAddress() : Address {
            if (this.taskInfo == null) {
                return new Address();
            } else {
                let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
                return AddressHelper.getAddressFromOrganisation(taskInfo.plotterOrganisation, taskInfo.plotterCountry);
            }
        },

        networkString() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return taskInfo != null ? this.$t("general.network") + ": " + taskInfo.network.name : "";
        },

        projectString() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return taskInfo != null ? this.$t("general.project") + ": " + taskInfo.project.code + " " + taskInfo.project.name : "";
        },

        numberOfItemsString() : string {
            let count = this.items.length;
            return count != null ? (count + " " + this.$t("general.entries")) : "";
        },

        createdString() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            let dateString = taskInfo != null ? this.$d(new Date(taskInfo.requestStep.created * 1000), 'long') : "";
            return this.$t("general.date") + ": " + dateString;
        },

        clientCodeString() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return this.$t("plot.task.clientCodeLabel")
                + (taskInfo != null && taskInfo.billingAddress != null
                    && taskInfo.billingAddress.clientCode != null ? taskInfo.billingAddress.clientCode : "");
        },

        jobNumberString() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return this.$t("plot.task.jobNumberLabelN")
                + (taskInfo != null && taskInfo.plotOrder.jobNumber != null
                    ? taskInfo.plotOrder.jobNumber.toString() : "");
        },

        items() : PlotTaskItemInfo[] {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return taskInfo != null && taskInfo.itemInfos != null
                ? taskInfo.itemInfos : [];
        },

        monochromeAreaSum() : number {
            return PlotHelper.getSumForColorValue(this.taskInfo, 0);
        },

        colorAreaSum() : number {
            return PlotHelper.getSumForColorValue(this.taskInfo, 1);
        },

        likeTemplateAreaSum() : number {
            return PlotHelper.getSumForColorValue(this.taskInfo, 2);
        },
        
        totalAreaSum() : number {
            return this.monochromeAreaSum + this.colorAreaSum + this.likeTemplateAreaSum;
        },

        monochromeAreaSumLabel() : string {
            return this.monochromeAreaSum > 0 ? this.$t("plot.task.monochromeSum", { sum : PlotHelper.getPlotAreaString(this.monochromeAreaSum) }) : "";
        },
        colorAreaSumLabel() : string {
            return this.colorAreaSum > 0 ? this.$t("plot.task.colorSum", { sum : PlotHelper.getPlotAreaString(this.colorAreaSum) }) : "";
        },
        likeTemplateAreaSumLabel() : string {
            return this.likeTemplateAreaSum > 0 ? this.$t("plot.task.likeTemplateSum", { sum : PlotHelper.getPlotAreaString(this.likeTemplateAreaSum) }) : "";
        },

        paperQualityAreaSumLabel() : string {
            return PlotHelper.getSumForPaperQualityString(this.taskInfo);
        },

        plotMarginAreaSumLabel() : string {
            return PlotHelper.getSumForPlotMarginString(this.taskInfo);
        },
        
        
        totalAreaSumLabel() : string {
            return this.$t("plot.task.totalSum", { sum : PlotHelper.getPlotAreaString(this.totalAreaSum) });
        },
        kindOfDeliveryLabel() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return this.$t("plot.task.kindOfDeliveryLabel", {
                kindOfDelivery : taskInfo != null ? taskInfo.plotOrder.kindOfDelivery : ""
            });
        },

        commentLabel() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            return this.$t("plot.task.additionalInfoLabel", {
                comment : taskInfo != null ? taskInfo.plotOrder.comment : ""
            });
        },

        resultCaption() : string {
            if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
                return this.$t("plot.task.plotOrderAcceptanceResultCaption");
            } else if (this.mode == PlotTaskMode.REVIEW_CYCLE_PLOT_TASK) {
                return this.$t("plot.task.reviewCyclePlotResultCaption");
            } else {
                throw new Error("Illegal mode [" + this.mode + "]");
            }
        },

        acceptedLabel() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
                if (taskInfo != null) {
                    if (taskInfo.acceptanceStep != null && taskInfo.acceptanceStep.finished != null) {
                        return this.$t("plot.task.ok.plotOrderAcceptanceFinished");
                    } else {
                        return this.$t("plot.task.ok.plotOrderAcceptanceNotFinished");
                    }
                } else {
                    return "";
                }
            } else if (this.mode == PlotTaskMode.REVIEW_CYCLE_PLOT_TASK) {
                return this.$t("plot.task.ok.reviewCyclePlot");
            } else {
                throw new Error("Illegal mode [" + this.mode + "]");
            }
        },

        declinedLabel() : string {
            let taskInfo = this.taskInfo as unknown as PlotTaskInfo;
            if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
                if (taskInfo != null) {
                    if (taskInfo.acceptanceStep != null && taskInfo.acceptanceStep.finished != null) {
                        return this.$t("plot.task.notOk.plotOrderAcceptanceFinished");
                    } else {
                        return this.$t("plot.task.notOk.plotOrderAcceptanceNotFinished");
                    }
                } else {
                    return "";
                }
            } else if (this.mode == PlotTaskMode.REVIEW_CYCLE_PLOT_TASK) {
                return this.$t("plot.task.notOk.reviewCyclePlot");
            } else {
                throw new Error("Illegal mode [" + this.mode + "]");
            }
        },

        signDialogCaption() : string {
            if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
                return this.$t("plot.task.acceptanceButtonCaption");
            } else if (this.mode == PlotTaskMode.REVIEW_CYCLE_PLOT_TASK) {
                return this.$t("plot.task.nowButtonCaption");
            } else {
                throw new Error("Illegal mode [" + this.mode + "]");
            } 
        },

        ok() : boolean {
            return this.result == "accepted";
        }
    },

    beforeMount() {
        this.fetchRunning = true;
        if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
            if (this.taskId != null && !isNaN(this.taskId)) {
                this.ctx.plotService.getPlotOrderAcceptanceTaskInfo(this.ctx.activeOrganisationPersonId, this.taskId)
                    .then((taskInfo : PlotTaskInfo) => {
                        this.taskInfo = taskInfo;
                        this.setContext(taskInfo);
                        this.fetchRunning = false;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                        this.fetchRunning = false;
                    });
            } else {
                this.ctx.plotService.getPlotOrderAcceptanceTaskInfoByPlotOrderId(this.ctx.activeOrganisationPersonId, this.plotOrderId)
                   .then((taskInfo : PlotTaskInfo) => {
                        this.taskInfo = taskInfo;
                        this.setContext(taskInfo);
                        this.fetchRunning = false;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                        this.fetchRunning = false;
                    });                
            }
        } else if (this.mode == PlotTaskMode.REVIEW_CYCLE_PLOT_TASK) {
            this.ctx.plotService.getReviewCyclePlotTaskInfo(this.ctx.activeOrganisationPersonId, this.taskId)
                .then((taskInfo : PlotTaskInfo) => {
                    this.taskInfo = taskInfo;
                    this.setContext(taskInfo);
                    this.fetchRunning = false;                    
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                    this.fetchRunning = false;                    
                });            
        } else {
            throw new Error("Illegal mode [" + this.mode + "]");
        }
    },

    methods : {
        abort() {
            this.ctx.taskAborted = true;
            this.$router.go(-1);
        },

        setContext(plotTaskInfo : PlotTaskInfo) : void {
            let contextJoin : ContextJoin = plotTaskInfo.contextJoin;
            this.ctx.activeNetworkId = contextJoin.networkId;
            this.ctx.activeProjectId = contextJoin.projectId;
            this.ctx.activeSubProjectId = contextJoin.subProjectId;
        },

        onsubmit(evt : SubmitEvent) : void {
            console.info("onsubmit called");
            if (evt.submitter == this.$refs.reviewCyclePlotLaterButton) {
                this.doAcceptReviewCyclePlotTask();
            } else if (evt.submitter == this.$refs.plotOrderAcceptanceAcceptButton) {
                this.doPlotOrderAcceptanceAccept();
            } else if (evt.submitter == this.$refs.reviewCyclePlotNowButton) {
                this.doAcceptAndCompleteReviewCyclePlotTask();
            } else {
                throw new Error("Unknown submitter [" + evt.submitter + "]");
            }
            console.info("end of onsubmit");
            evt.stopPropagation();
            evt.preventDefault();
        },

        doAcceptReviewCyclePlotTask() : void {
            let passwordDialog : (typeof PasswordDialog) = this.$refs.passwordDialog as typeof PasswordDialog;
            passwordDialog.getPassword()
                .then((password: string | null) => {
                    if (password != null) {
                        let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                        this.signRunning = true;
                        this.ctx.plotService.acceptReviewCyclePlotTask(organisationPersonId, this.taskId, this.comment,
                                                                       password, this.ok)
                            .then(() => {
                                console.info("acceptReviewCyclePlotTask completed.");
                                this.signRunning = false;
                                this.ctx.editedTaskId = this.taskId;
                                this.$router.go(-1);                                
                            }, err => {
                                ErrorHelper.processError(this.$t, this.$d, err);
                                this.signRunning = false;                                
                            });
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        doAcceptAndCompleteReviewCyclePlotTask() : void {
            let passwordDialog : (typeof PasswordDialog) = this.$refs.passwordDialog as typeof PasswordDialog;
            passwordDialog.getPassword()
                .then((password: string | null) => {
                    if (password != null) {
                        let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                        this.signRunning = true;
                        this.ctx.plotService.acceptAndCompleteReviewCyclePlotTask(organisationPersonId, this.taskId,
                                                                                  this.comment, password, this.ok)
                            .then(() => {
                                console.info("acceptAndCompleteReviewCyclePlotTask completed.");
                                this.signRunning = false;
                                this.ctx.editedTaskId = this.taskId;
                                this.$router.go(-1);                                                                
                            }, err => {
                                this.signRunning = false;
                                ErrorHelper.processError(this.$t, this.$d, err);
                            });
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        doPlotOrderAcceptanceAccept() : void {
            let passwordDialog : (typeof PasswordDialog) = this.$refs.passwordDialog as typeof PasswordDialog;
            passwordDialog.getPassword()
                .then((password: string | null) => {
                    if (password != null) {
                        let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                        let selectedItemIds : Set<number> = this.getChosenItemIds();
                        this.signRunning = true;
                        this.ctx.plotService.acceptPlotOrderItemTask(organisationPersonId, this.taskId,
                                                                           this.comment, password, this.ok, selectedItemIds)
                            .then(() => {
                                console.info("acceptPlotOrderItemTask completed.");
                                this.signRunning = false;
                                this.ctx.editedTaskId = this.taskId;
                                this.$router.go(-1);                                                                
                            }, err => {
                                this.signRunning = false;
                                ErrorHelper.processError(this.$t, this.$d, err);                                
                            });
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        getChosenItemIds() : Set<number> {
            let selectedItemIds : Set<number> = new Set<number>();
            for (let item of this.items) {
                if (item.chosen) {
                    selectedItemIds.add(item.plotOrderItem.id);
                }
            }
            return selectedItemIds;
        },

        exportToPdf() : void {
            this.loadingPdf = true;
            this.ctx.plotService.triggerExportPlotTaskToPdf(this.ctx.activeOrganisationPersonId, this.taskId,
                                                            this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK)
                .then((jobId : string) => {
                    JobHelper.registerJobStatusQueryForDownload({
                        jobStatusService : this.ctx.jobStatusService as unknown as IJobStatusService,
                        jobId : jobId,
                        doneCallback : (status : JobStatusDTO) => {
                            this.loadingPdf = false;
                        },
                        downloadUrlGetter : (jobId : string) => {
                            let params = new URLSearchParams({
                                jobId : jobId
                            }); 
                            return "/cdes-dojo-impl/download?" + params.toString();
                        }
                    });
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        }
    }
});
