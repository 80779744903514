import { InfoMessage } from "cdes-api/dto/InfoMessage";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props : {
        infoMessage : Object as PropType<InfoMessage>
    },

    computed : {
        recordDateString() {
            return this.ctx.formatUtcSecondsLong(this.infoMessage.recordDate, this.$i18n.locale);
        }
    },

    methods : {
    },

    // See: https://v3.vuejs.org/api/options-data.html#watch
    //      https://v3.vuejs.org/api/instance-methods.html#watch
    watch : {
    },
});
