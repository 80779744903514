/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { DocumentUploadInfo } from "cdes-api/dto/document/DocumentUploadInfo";
import { SeekLabelResult } from "cdes-api/dto/document/SeekLabelResult";
import { loadingPlaceholder } from "cdes-vue/util/Prop";

import { LabelStatus } from "cdes-api/dto/document/LabelStatus";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        uploadInfo: {
            type: Object as PropType<DocumentUploadInfo>,
        },
        seekResult: {
            type: Object as PropType<SeekLabelResult>,
        },
    },

    setup() {
        return {
            LabelStatus,
            loadingPlaceholder
        };
    },

    computed: {
        numericStatus(): number {
            switch (this.seekResult.labelStatus) {
                case LabelStatus.PERFECT:
                    return 4;
                case LabelStatus.GOOD:
                    return 3;
                case LabelStatus.DOUBTFUL:
                   return 2;
                case LabelStatus.BAD:
                    return 1;
                case LabelStatus.NOT_FOUND:
                    return 0;
                default:
                    return null;
            }
        },
    },
});
