import { defineComponent, PropType } from "vue";
import { Dropdown } from "bootstrap";
import { Comparator, defaultCmp } from "cdes-vue/util/Sort";
import HashMap from "clazzes-core/util/HashMap"
import HashSet from "clazzes-core/util/HashSet"
import { ErrorHelper } from "../ErrorHelper";

type PropertySpec = string | string[] | ((obj: unknown) => unknown);
function extractProperty(spec: PropertySpec, obj: unknown): unknown {
    if (typeof spec === "string") {
        return obj[spec];
    } else if (spec instanceof Function) {
        return spec(obj);
    } else if (Array.isArray(spec)) {
        return spec.reduce((acc, prop) => acc[prop], obj);
    } else {
        throw new Error();
    }
}

export default defineComponent({
    props: {
        modelValue: {
            type: Array as PropType<unknown[]>,
            default: () => [],
        },
        itemLabel: {
            type: [String, Array, Function] as PropType<PropertySpec | undefined>,
            default: "label",
        },
        itemValue: {
            type: [String, Array, Function] as PropType<PropertySpec | undefined>,
            default: "value",
        },
        itemKey: {
            type: [String, Array, Function] as PropType<PropertySpec>,
            default: () => undefined,
        },
        valueEquals: {
            type: Function as PropType<(a: unknown, b: unknown) => boolean>,
        },
        valueHash: {
            type: Function as PropType<(value) => unknown>,
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        options: {
            type: Array as PropType<unknown[]>,
            default: () => [],
        },
        sort: {
            type: [Boolean, Function] as PropType<boolean | Comparator<unknown>>,
        },
        getRemovalError : {
            type : Function as PropType<(item : unknown) => Promise<string>>,
            default : null
        }
    },

    data() {
        return {
            selectedItems: this.modelValue as unknown[],
            dropdown: null as Dropdown,
        };
    },

    created() {
        //
    },

    mounted() {
        this.dropdown = new Dropdown(this.$refs.dropdownToggle, {
            reference: this.$refs.root,
            autoClose: true,
        });
    },

    unmounted() {
        this.dropdown.dispose();
        this.dropdown = null;
    },

    emits: ["update:modelValue"],

    computed: {
        sortedOptions(): unknown[] {
            if (this.sort != null && this.sort !== false) {
                return (this.options as unknown[])
                .map(a => a)
                .sort(this.sort === true ? defaultCmp : (this.sort as Comparator<unknown>));
            } else {
                return this.options as unknown[];
            }
        },
        optionsByValue(): HashMap<unknown, unknown> {
            let entries : [unknown, unknown][] = this.options.map((option) => [this.getValue(option), option]);
            return new HashMap(entries, this.valueEquals, this.valueHash);
        },
        trueOptions(): unknown[] {
            const selectedKeys = new HashSet(this.selectedItems, this.valueEquals, this.valueHash);

            return (this.options as unknown[]).filter(option => !selectedKeys.has(this.getValue(option)));
        },
    },

    methods: {
        remove(index: number): void {
            let item : unknown = this.selectedItems[index];
            if (this.getRemovalError == null) {
                this.selectedItems.splice(index, 1);
                this.$emit("update:modelValue", [...this.selectedItems]);
            } else {
                this.getRemovalError(item).then((message : string) => {
                    if (message == null) {
                        this.selectedItems.splice(index, 1);
                        this.$emit("update:modelValue", [...this.selectedItems]);
                    } else {
                        window.alert(message);
                    }
                }, err => {
                    ErrorHelper.processErrorWithoutI18n(err);
                });
            }
        },
        getKey(item: unknown): unknown {
            return extractProperty(this.itemKey, item);
        },
        getValue(item: unknown): unknown {
            return extractProperty(this.itemValue, item);
        },
        getLabel(item: unknown): string {
            if (item != null) {
                return extractProperty(this.itemLabel, item) as string;
            } else {
                return "???";
            }
        },
        add(option: unknown): void {
            this.selectedItems.push(this.getValue(option));
            this.$emit("update:modelValue", [...this.selectedItems]);
        }
    },

    watch: {
        modelValue(value): void {
            this.selectedItems = [...value];
        }
    },
});
