import { ObjectPlannerPositionDefinition } from "cdes-api/dto/ObjectPlannerPositionDefinition";
import { RoleType } from "cdes-api/dto/RoleType";

export class ObjectPlannerPositionDefinitionInfo {
    // Client-only
    id : number;
    
    objectPlannerPositionDefinition : ObjectPlannerPositionDefinition;
    roleType : RoleType;
    
    notificateReleasedPositiv : boolean;
    notificateReleasedNegative : boolean;
    notificateInvalidatedVersion : boolean;
    notificateInvalidatedAll : boolean;
    notificateDeleted : boolean;
    notificateRevised : boolean;
}
