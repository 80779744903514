/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, computed, ref, watch, watchEffect } from "vue";
import { asyncEagerComputed, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import Transform from "cdes-vue/util/Transform";
import { useCtx } from "cdes-vue/util/Ctx";
import { useValidity, useValidationInput } from "cdes-vue/util/directives/ValidationForm";
import ValidationError from "cdes-vue/util/form/ValidationError";
import { PasswordValidationError } from "cdes-api/dto/person/PasswordValidationError";
import ResetPasswordResult from "cdes-api/dto/person/ResetPasswordResult";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";



export default defineComponent({
    components: {
        Transform,
        LoadedOrPlaceholder,
    },

    expose: ["submit"],

    emits: ["update:invalid"],

    setup(_, { emit }) {
        const question = ref<null | string>(null);
        const answer = ref<null | string>(null);

        const lastResult = ref<null | ResetPasswordResult | LoadingPlaceholder>(null);

        const ctx = useCtx();

        const {
            validity: questionValidity,
            transformer: questionTransformer,
        } = useValidity();

        const {
            validity: answerValidity,
            transformer: answerTransformer,
        } = useValidity();

        const info = asyncEagerComputed(() => {
            return ctx.resetPasswordService.getResetPasswordInfo();
        }, loadingPlaceholder);

        const minLength = computed(() => info.value === loadingPlaceholder ? 8 : info.value.passwordMinLength);

        const answerMinLength = computed(() => info.value === loadingPlaceholder ? 8 : info.value.securityAnswerMinLength);

        const {
            validity: oldPasswordValidity,
            transformer: oldPasswordTransformer,
            value: oldPassword,
        } = useValidationInput({
            validate: () => {
                if (lastResult.value === ResetPasswordResult.WRONG_PASSWORD) {
                    return ValidationError.WRONG_PW;
                } else {
                    return null;
                }
            },
        });

        const {
            validity: newPassword1Validity,
            transformer: newPassword1Transformer,
            value: newPassword1,
        } = useValidationInput(({value}) => ({
            errors: asyncEagerComputed(() => {
                if (lastResult.value === ResetPasswordResult.INVALID_PASSWORD) {
                    return ValidationError.INVALID;
                }

                const password = value.value;
                if (password == null || password.length <= 0) {
                    return null;
                }
                if (password.length < minLength.value) {
                    return null;
                }

                const hasLowerCase = /[a-z]/.test(password)?1:0;
                const hasUpperCase = /[A-Z]/.test(password)?1:0;
                const hasDigit = /[0-9]/.test(password)?1:0;
                const hasOther = /[^A-Za-z0-9]/.test(password)?1:0;

                const numCategories = hasLowerCase + hasUpperCase + hasDigit + hasOther;

                if (numCategories < 3) {
                    return ValidationError.LACKING_DIVERSITY;
                }

                return ctx.resetPasswordService.validatePassword(password)
                .then(serverError => {
                    if (serverError == null || serverError === PasswordValidationError.OK) {
                        return null
                    } else if (serverError === PasswordValidationError.TOO_SHORT) {
                        return ValidationError.TOO_SHORT;
                    } else if (serverError === PasswordValidationError.OLD_PW) {
                        return ValidationError.OLD_PW;
                    } else if (serverError === PasswordValidationError.LACKING_DIVERSITY) {
                        return ValidationError.LACKING_DIVERSITY;
                    } else if (serverError === PasswordValidationError.INVALID_CHARS) {
                        return ValidationError.INVALID_CHARS;
                    } else {
                        throw new Error("Invalid validation error.");
                    }
                }, err => {
                    ErrorHelper.processErrorWithoutI18n(err);
                });
            }, null),
        }));

        const {
            validity: newPassword2Validity,
            transformer: newPassword2Transformer,
            value: newPassword2,
        } = useValidationInput({
            validate: () => {
                const password = newPassword2.value;
                if (password == null || password.length <= 0) {
                    return null;
                } else if (password !== newPassword1.value) {
                    return ValidationError.REPETITION_NOT_IDENTICAL;
                } else {
                    return null;
                }
            },
        });

        watch(
            [oldPassword, newPassword1, newPassword2, answer, question],
            () => {
                lastResult.value = null;
            },
        );

        const invalid = computed(() => {
            return !newPassword1Validity.valid || !newPassword2Validity.valid || !oldPasswordValidity.valid || !answerValidity.valid || !questionValidity.valid;
        });

        watchEffect(() => {
            emit("update:invalid", invalid.value);
        });

        const submit = (): Promise<boolean> => {
            if (invalid.value) {
                return Promise.resolve(false);
            }

            lastResult.value = loadingPlaceholder;
            return ctx.resetPasswordService.resetPassword(ctx.activeOrganisationPersonId, oldPassword.value,
                                                          newPassword1.value, question.value, answer.value)
            .then(result => {
                lastResult.value = result;
                return result === ResetPasswordResult.SUCCESS;
            });
        }

        return {
            oldPassword,
            newPassword1,
            newPassword2,
            question,
            answer,
            newPassword1Transformer,
            newPassword2Transformer,
            oldPasswordTransformer,
            newPassword1Validity,
            newPassword2Validity,
            oldPasswordValidity,
            answerValidity,
            answerTransformer,
            questionValidity,
            questionTransformer,
            loadingPlaceholder,
            minLength,
            answerMinLength,
            ValidationError,
            submit,
            info,
        };
    },
});
