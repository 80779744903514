import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class ProjectHoliday extends Persistent {

    // Properties: (placed here as documentation)
    //
    // projectId : number; --> Project
    // startDate : (number | null | undefined);
    // endDate : (number | null | undefined);
    // description : (string | null | undefined);

    private _projectId : number;
    get projectId() : number {
        this.__checkAttributeDefinition(5, "projectId");
        return this._projectId;
    }
    set projectId(projectId : number) {
        this._projectId = projectId;
    }

    private _startDate : (number | null | undefined);
    get startDate() : (number | null | undefined) {
        this.__checkAttributeDefinition(6, "startDate");
        return this._startDate;
    }
    set startDate(startDate : (number | null | undefined)) {
        this._startDate = startDate;
    }

    private _endDate : (number | null | undefined);
    get endDate() : (number | null | undefined) {
        this.__checkAttributeDefinition(7, "endDate");
        return this._endDate;
    }
    set endDate(endDate : (number | null | undefined)) {
        this._endDate = endDate;
    }

    private _description : (string | null | undefined);
    get description() : (string | null | undefined) {
        this.__checkAttributeDefinition(8, "description");
        return this._description;
    }
    set description(description : (string | null | undefined)) {
        this._description = description;
    }


    public static constructPartialInstance() : ProjectHoliday {
        let instance = new ProjectHoliday();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : ProjectHoliday {
        let instance = new ProjectHoliday();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : ProjectHoliday) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.projectId, other.projectId, "Long")
            && DtoHelper.areMembersEqual(this.startDate, other.startDate, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.endDate, other.endDate, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.description, other.description, "String")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            projectId: this._projectId,
            startDate: this._startDate,
            endDate: this._endDate,
            description: this._description,
        };
    }
}
