import { Modal } from "bootstrap";

import { defineComponent, PropType, nextTick, watch, ref } from "vue";
import { DialogButton } from "./DialogButton";
import Transform from "cdes-vue/util/Transform";

interface OnFrameLoadEvent {
    target : OnFrameLoadEventTarget;
}

interface OnFrameLoadEventTarget {
    contentWindow : OnFrameLoadEventTargetContentWindow;
}

interface OnFrameLoadEventTargetContentWindow {
    document : HTMLDocument;
}

interface PromiseFctContainer {
    resolve : (value : string) => void;
    reject : (reason : string) => void;
}

export default defineComponent({
    components: {
        Transform,
    },

    props : {
        title : String,
        buttons : Array as PropType<DialogButton[]>,
		extraClasses : [Array, String, Object] as PropType<string[] | string | Record<string, boolean>>,
        lazy: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        maximize: {
            type: Boolean as PropType<boolean>,
            default: () => true,
        },
        form: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },
    inheritAttrs: false,

    expose: ["show", "submit", "abort"],

    emits: ["hide", "hidden", "shown", "show", "hidePrevented"],

    data() {
        return {
            data: undefined as ({
                userData: unknown,
                resolve: (value: unknown | undefined) => void,
                reject: (value: unknown) => void,
            }),
            maximized : false
        }
    },


    /*mounted()  {
        if (this.$refs.dialogRoot != null) {
            this.dialogModal = new Modal(this.$refs.dialogRoot);
        }
        },*/

    computed : {
        cssClass() {
            let cssClass = [ 'modal-dialog', this.extraClasses, (this.maximized ? "modal-fullscreen" : "") ];
            console.info("cssClass = [" + cssClass + "]");
            return cssClass;
        }
    },

    setup() {
        const dialogRoot = ref<null | HTMLElement>(null);
        const dialogModal = ref<null | Modal>(null);

        return {
            dialogRoot,
            dialogModal,
        };
    },

    methods: {
        toggleMaximized() : void {
            this.maximized = !this.maximized;
        },

        onDialogMounted(): void {
            this.dialogModal?.dispose();
            this.dialogModal = new Modal(this.dialogRoot);
        },
        onDialogUnmounted(): void {
            this.dialogModal?.dispose();
            this.dialogModal = null;
        },
        onHide(): void {
            if (this.data != null) {
                this.abort();
            }
        },
        onShown(): void {
            ((this.dialogRoot as HTMLElement).querySelector(`[data-autofocus=""], [data-autofocus="true"]`) as HTMLElement)?.focus();
        },
        show(data?: unknown) : Promise<unknown | undefined> {
            return new Promise((resolve, reject) => {
                this.data = {
                    userData: data,
                    resolve,
                    reject,
                };

                if (this.lazy) {
                    nextTick(() => {
                        this.dialogModal.show();
                    });
                } else {
                    this.dialogModal.show();
                }
            });
        },
        // WARNING: button modal.hide before this.data = undefined will result in unbounded recursion.
        submit(value: unknown): void {
            this.data.resolve(value);
            this.data = undefined;
            this.dialogModal.hide();
        },
        abort(): void {
            this.data.resolve(undefined);
            this.data = undefined;
            this.dialogModal.hide();
        },

        handle(button : DialogButton) : void {
            if (button.handler != null) {
                button.handler();
            }
        },
        getReEmitter<E>(name: string): (event: E) => void {
            return (event) => this.$emit(name as any, event);
        },
    },

});
