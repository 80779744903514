import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(`p-${_ctx.gutter}`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`row g-${_ctx.gutter}`)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}