/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { ProjectParticipantHistoryPartJoin } from "cdes-api/joinDto/ProjectParticipantHistoryPartJoin";
import { ParempiColumn } from "cdes-vue/util/grid/ParempiColumn";
import ParempiGrid from "cdes-vue/util/grid/ParempiGrid.vue";
import { defineComponent, VNode, h } from 'vue';
import { DateHelper } from 'clazzes-core/dateTime/DateHelper';
import { FancyDate } from 'clazzes-core/dateTime/FancyDate';
import { getCurrentLocaleString } from '../../util/I18n';



export default defineComponent({
    components : {
        ParempiGrid,
    },

    computed : {
    },

    data() {
        return {
            columns : this.constructColumns(),
        };
    },

    methods : {
        constructColumns() : ParempiColumn<ProjectParticipantHistoryPartJoin>[] {
            let columns : ParempiColumn<ProjectParticipantHistoryPartJoin>[] = [
                ParempiColumn.asRowSpanSlot("affectedPerson", this.$t("project.participation.list.participantHistory.participant"), "affectedPerson"),
                ParempiColumn.asRowSpanSlot("projectParticipantHistoryPartStartDate", this.$t("project.participation.list.participantHistory.from"), "projectParticipantHistoryPartStartDate"),
                ParempiColumn.asRowSpanSlot("projectParticipantHistoryPartEndDate", this.$t("project.participation.list.participantHistory.to"), "projectParticipantHistoryPartEndDate"),
                ParempiColumn.asRowSpanSlot("modifyingPersonGivenName", this.$t("project.participation.list.participantHistory.changedByOn"), "modifyingPersonGivenName"),
                ParempiColumn.asRowSpanSlot("projectParticipantInactiveComment", this.$t("project.participation.list.participantHistory.remark"), "projectParticipantInactiveComment"),
            ];

            return columns;
        },
    },

    props : {
        items : {
            type : Array
        }
    }
});
