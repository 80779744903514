import { Ref, computed, unref, ref, reactive, watch, cloneVNode, VNode, ComponentPublicInstance, withDirectives, vModelText } from "vue";
import { Transformer } from "cdes-vue/util/Transform";
import ValidationError from "cdes-vue/util/form/ValidationError";
import CustomValidity from "./CustomValidity";


export function findFormElements(form: HTMLElement): ArrayLike<Element> & Iterable<Element> {
    if (form instanceof HTMLFormElement) {
        return form.elements;
    } else {
        return form.querySelectorAll("input, select");
    }
}

export function checkValidity(form: HTMLElement, tag: string): boolean {
    for (const element of findFormElements(form)) {
        if (element instanceof HTMLFieldSetElement || element instanceof HTMLButtonElement || element instanceof HTMLObjectElement || element instanceof HTMLOutputElement || element instanceof HTMLFormElement) {
            continue;
        }
        if (!(element instanceof HTMLInputElement || element instanceof HTMLSelectElement || element instanceof HTMLTextAreaElement)){
            throw new Error("");
        }
        const onlyValidateFor = element.dataset.onlyValidateFor;
        if (onlyValidateFor == null || (onlyValidateFor.length > 0 && onlyValidateFor === tag)) {
            if (!element.checkValidity()) {
                return false;
            }
        }
    }
    return true;
}

export interface ValidationResult {
    transformer : Transformer;
    wasValidated : Ref<boolean>;
    onSubmit: (event: SubmitEvent) => void,
}

export function useValidationForm({
    validationFunction = checkValidity,
    onSubmit,
    tag,
}: {
    validationFunction?: (form: HTMLFormElement, tag?: string) => boolean
    onSubmit?: (event: SubmitEvent) => void,
    tag?: string,
} = {}) : ValidationResult {
    const wasValidated = ref(false);

    const formElement = ref<null | HTMLElement>(null);

    const ourOnSubmit = (event: SubmitEvent) => {
        try {
        const validationResult = validationFunction(formElement.value as HTMLFormElement, tag);
        if (!validationResult) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        wasValidated.value = !validationResult;
        } catch (e) {
            event.preventDefault();
            throw e;
        }
    };

    const transformer = (vnode: VNode) => cloneVNode(vnode, {
        onSubmit: onSubmit != null ? [ourOnSubmit, onSubmit] : ourOnSubmit,
        novalidate: true,
        ref: ((ref: HTMLElement | ComponentPublicInstance | null) => {
            if (ref == null) {
                formElement.value = ref as null;
            } else if (ref instanceof HTMLElement) {
                formElement.value = ref;
            } else {
                formElement.value = ref.$el;
            }
        }) as any,
    }, true);

    return {
        wasValidated,
        transformer,
        onSubmit: ourOnSubmit,
    };
}

type HTMLElementWithValidity = HTMLElement & { validity: ValidityState, validationMessage: string };
type HTMLElementWithValidityAndValue = HTMLElementWithValidity & { value: string };

const validityProperties = ["valueMissing", "typeMismatch", "tooShort", "tooLong", "stepMismatch", "rangeUnderflow", "rangeOverflow", "patternMismatch", "customError", "badInput", "valid"];

// this hack is necessary because the properties of ValidityState are not actually defined on the object but on the prototype.
// This is hard to spot because the devtools will lie to you.
function assignValidity(a: ValidityState, ...rest: (ValidityState | null)[]) {
    for (const b of rest) {
        for (const prop of validityProperties) {
            a[prop] = b[prop];
        }
    }
}

const defaultValidity: ValidityState = {
    valueMissing: false,
    typeMismatch: false,
    tooShort: false,
    tooLong: false,
    stepMismatch: false,
    rangeUnderflow: false,
    rangeOverflow: false,
    patternMismatch: false,
    customError: false,
    badInput: false,
    valid: true,
};

export type ExtendedValidityState = ValidityState & Record<ValidationError, boolean>;

const defaultExtendedValidity: ExtendedValidityState = {
    ...defaultValidity,
    ...(Object.fromEntries(Object.values(ValidationError).map(customError => [customError, false])) as Record<ValidationError, boolean>),
};

export function useValidity(): {
    validity: ExtendedValidityState,
    transformer: Transformer,
} {
    const validity = reactive({...defaultExtendedValidity});

    const templateRef = ref<null | HTMLElementWithValidity>(null);

    const updateValidity = (e: HTMLElementWithValidity) => {
        const newValidity = {...defaultExtendedValidity};
        assignValidity(newValidity, e.validity);
        if (newValidity.customError) {
        for (const customError of e.validationMessage.split(/\s*,\s*/)) {
            if (customError.length <= 0) {
                continue;
            }

            if (newValidity[customError] != null) {
                newValidity[customError] = true;
                newValidity.valid = false;
            } else if (newValidity[ValidationError[customError]] != null) {
                newValidity[ValidationError[customError]] = true;
                newValidity.valid = false;
            } else {
                console.warn("Unknown custom error", customError);
            }
        }
        }

        Object.assign(validity, newValidity);
    };

    watch(
        templateRef,
        ref => {
            if (ref != null) {
                updateValidity(ref)
            } else {
                Object.assign(validity, defaultValidity);
            }
        },
        {
            flush: "post",
        },
    );

    const onEvent = (event: Event) => {
        updateValidity(event.target as HTMLElementWithValidity);
    };


    const transformer = (vnode: VNode) => cloneVNode(vnode, {
        ref: templateRef,
        onInput: onEvent,
        onInvalid: onEvent,
        onChange: onEvent,
    }, true);

    return {
        validity,
        transformer,
    };
}

type ValidationErrors = ValidationError | ValidationErrors[] | Record<ValidationError, boolean> | null | undefined;

function normalizeErrors(errors: ValidationErrors): ValidationError[] {
    if (errors == null) {
        return [];
    } else if (typeof errors === "string") {
        return [errors];
    } else if (Array.isArray(errors)) {
        return Array.from(errors).flatMap(normalizeErrors);
    } else if (typeof errors === "object") {
        return (Object.entries(errors) as [ValidationError, boolean][])
        .filter(([_, cond]) => cond)
        .map(([error, _]) => error);
    } else {
        console.warn("Unknown kind of errors object", errors);
        return [];
    }
}

interface ValidationInputOptions {
    validate?: () => ValidationErrors,
    errors?: Ref<ValidationErrors>,
}

export function useValidationInput(options: ValidationInputOptions | ((options: { value: Ref<string> }) => ValidationInputOptions)): {
    transformer: Transformer,
    validity: ValidityState & Record<ValidationError, boolean>,
    value: Ref<string>,
} {
    const {
        validity,
        transformer: validityTransformer,
    } = useValidity();

    const value = ref<string>("");

    const { validate, errors } = typeof options === "function" ? options({ value }) : options;

    const customValidity = computed(() => {
        const currentErrors = normalizeErrors([validate?.(), errors?.value]);

        return currentErrors.join(", ");
    });

    const transformer = (vnode: VNode) => withDirectives(cloneVNode(validityTransformer(vnode), {
        onInput: (e: InputEvent) => value.value = (e.target as HTMLElementWithValidityAndValue).value,
        "onUpdate:modelValue": (newValue) => value.value = newValue,
    }), [
        [CustomValidity, customValidity.value],
        [vModelText, value.value],
    ]);

    return {
        validity,
        value,
        transformer,
    };
}

export default {
    created(form) {
        form.addEventListener("submit", event => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                form.classList.add("was-validated");
            } else {
                form.classList.remove("was-validated");
            }
        });
    },
};
