import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = { class: "fieldset mb-4" }
const _hoisted_11 = { class: "fieldset" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = { key: 4 }
const _hoisted_18 = { class: "deleteWarn mt-4" }
const _hoisted_19 = { class: "deleteWarn mt-4" }
const _hoisted_20 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(this.headerMessage), 1),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('project.participation.list.delete.foundReferencesHeader')), 1),
    _createElementVNode("ul", null, [
      (this.deleteInfo != null && !this.buek)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(this.deleteInfo.deleteJoins, (deleteJoin) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: deleteJoin.projectParticipationId
            }, [
              (deleteJoin.unionClause == 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.realmLine', { realmName : _ctx.$s(deleteJoin.realmName) })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.roleShortLine', { roleName : deleteJoin.cdesRoleName })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.cycleLine', {
                reviewCycleInstanceName : _ctx.$s(deleteJoin.reviewCycleInstanceName),
                reviewCycleCellInstanceName : _ctx.$s(deleteJoin.reviewCycleCellName),
                reviewCycleNodeInstanceName : _ctx.$s(deleteJoin.reviewCycleNodeName)
                })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.subProjectLine', {
                subProjectCode : deleteJoin.subProjectCode,
                subProjectName : deleteJoin.subProjectName
                })), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 2)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.emailLine')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.cycleLine', {
                reviewCycleInstanceName : _ctx.$s(deleteJoin.reviewCycleInstanceName),
                reviewCycleCellInstanceName : _ctx.$s(deleteJoin.reviewCycleCellName),
                reviewCycleNodeInstanceName : _ctx.$s(deleteJoin.reviewCycleNodeName)
                })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.subProjectLine', {
                subProjectCode : deleteJoin.subProjectCode,
                subProjectName : deleteJoin.subProjectName
                })), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 3)
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.plotLine')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.cycleLine', {
                reviewCycleInstanceName : _ctx.$s(deleteJoin.reviewCycleInstanceName),
                reviewCycleCellInstanceName : _ctx.$s(deleteJoin.reviewCycleCellName),
                reviewCycleNodeInstanceName : _ctx.$s(deleteJoin.reviewCycleNodeName)
                })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.subProjectLine', {
                subProjectCode : deleteJoin.subProjectCode,
                subProjectName : deleteJoin.subProjectName
                })), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 4 || deleteJoin.unionClause == 5)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.roleLine', { roleName : deleteJoin.cdesRoleName })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.objectLine', {
                objectCode : deleteJoin.objectCode,
                objectName : deleteJoin.objectName
                })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.objectPlannerLine', {
                objectPlannerCode : deleteJoin.objectPlannerCode,
                objectPlannerArea : deleteJoin.objectPlannerArea
                })), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.subProjectLine', {
                subProjectCode : deleteJoin.subProjectCode,
                subProjectName : deleteJoin.subProjectName
                })), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 6)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.plotCustomerLine')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.plotOrderTemplateLine', {
						plotOrderTemplateName : deleteJoin.plottOrderTemplateName
					})), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 7)
                ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.plotContractorLine')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.plotOrderTemplateLine', {
						plotOrderTemplateName : deleteJoin.plottOrderTemplateName
					})), 1)
                  ]))
                : _createCommentVNode("", true),
              (deleteJoin.unionClause == 8)
                ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.attachedReviewLine', {
					documentVersionName : deleteJoin.documentVersionName
				})), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.participation.list.delete.taskRealmLine', {
					realmDescription : deleteJoin.attachedReviewTaskRealmDescription
				})), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    (!this.buek)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('project.participation.list.delete.subProjectsCaption')), 1),
          _createElementVNode("ul", null, [
            (this.deleteInfo != null)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(this.deleteInfo.affectedSubProjects, (subProject) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: subProject.id
                  }, _toDisplayString(this.getSubProjectLabel(subProject)), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        class: "btn btn-primary mb-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.close && this.close(...args)))
      }, _toDisplayString(_ctx.$t('project.participation.list.delete.cancelOptionCaption')), 1),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, _toDisplayString(this.cancelDesc), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        class: "btn btn-danger mb-2",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.doDelete && _ctx.doDelete(...args)))
      }, _toDisplayString(this.deleteCaption), 1),
      _createElementVNode("ul", null, [
        (this.buek)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteBuekDesc')), 1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (this.deleteInfo?.hasUnreleasedObjectListAssignments)
                ? (_openBlock(), _createElementBlock("li", _hoisted_13, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescUnreleased')), 1))
                : _createCommentVNode("", true),
              (this.deleteInfo?.hasAttachedReviewTasks)
                ? (_openBlock(), _createElementBlock("li", _hoisted_14, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescAttached')), 1))
                : _createCommentVNode("", true),
              (this.deleteInfo?.hasNotificationListenerAssignments && !this.deleteInfo?.hasPlottOrderListenerAssignments)
                ? (_openBlock(), _createElementBlock("li", _hoisted_15, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescNotificationNotPlot')), 1))
                : _createCommentVNode("", true),
              (!this.deleteInfo?.hasNotificationListenerAssignments && this.deleteInfo?.hasPlottOrderListenerAssignments)
                ? (_openBlock(), _createElementBlock("li", _hoisted_16, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescPlotNotNotification')), 1))
                : _createCommentVNode("", true),
              (this.deleteInfo?.hasNotificationListenerAssignments && this.deleteInfo?.hasPlottOrderListenerAssignments)
                ? (_openBlock(), _createElementBlock("li", _hoisted_17, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescNotificationPlot')), 1))
                : _createCommentVNode("", true),
              (this.deleteInfo?.hasReleasedObjectListAssignments && this.deleteInfo != null && this.deleteInfo.deleteJoins.length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescReleasedReferences')), 1),
                    _createElementVNode("li", _hoisted_18, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescReleasedReferencesWarn')), 1)
                  ], 64))
                : (this.deleteInfo != null && this.deleteInfo.deleteJoins.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                      _createElementVNode("li", null, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescReferences')), 1),
                      _createElementVNode("li", _hoisted_19, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescReferencesWarn')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock("li", _hoisted_20, _toDisplayString(_ctx.$t('project.participation.list.delete.deleteOptionDescElse')), 1))
            ], 64))
      ])
    ])
  ]))
}