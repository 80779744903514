import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mx-3" }
const _hoisted_2 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_3 = { class: "pageCaption" }
const _hoisted_4 = { class: "d-flex ml-auto p-2 gap-2" }
const _hoisted_5 = { class: "mt-2 d-flex flex-column col-7 gap-1" }
const _hoisted_6 = { class: "d-flex flex-row gap-2 align-items-center p-1 border rounded bg-light" }
const _hoisted_7 = { class: "col-md" }
const _hoisted_8 = { class: "d-flex flex-row gap-2 align-items-center p-1 border rounded bg-light" }
const _hoisted_9 = { class: "col-md" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "d-flex flex-row gap-2 align-items-center p-1 border rounded bg-light" }
const _hoisted_12 = { class: "col-md" }
const _hoisted_13 = { class: "d-flex flex-row align-items-center" }
const _hoisted_14 = {
  key: 0,
  class: "mx-1"
}
const _hoisted_15 = { class: "d-flex flex-column mt-2" }
const _hoisted_16 = { class: "d-flex mt-3 mb-1 flex-row justify-content-between align-items-center" }
const _hoisted_17 = {
  key: 0,
  class: "countLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_LabelContentListWidget = _resolveComponent("LabelContentListWidget")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('review.label.label')) + " " + _toDisplayString(_ctx.label?.name ?? ""), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.saveAndExit()), ["prevent"]))
        }, _toDisplayString(_ctx.$t('general.save')), 1),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => {this.$router.back();})
        }, _toDisplayString(_ctx.$t('general.cancel')), 1)
      ])
    ]),
    _createElementVNode("fieldset", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Label, {
          for: _ctx.labelNameInput,
          class: "col-md-12-em col-form-label p-2 bg-white border rounded m-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("general.name")), 1)
          ]),
          _: 1
        }, 8, ["for"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_TextInput, {
            id: _ctx.labelNameInput,
            mode: 'flex',
            required: "",
            modelValue: this.labelInfo.label.name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.labelInfo.label.name) = $event)),
            maxlength: "255"
          }, null, 8, ["id", "modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Label, {
          for: _ctx.reviewCycleInput,
          class: "col-md-12-em col-form-label p-2 bg-white border rounded m-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("review.label.assignedReviewCycle")), 1)
          ]),
          _: 1
        }, 8, ["for"]),
        _createElementVNode("div", _hoisted_9, [
          (!this.labelId && !this.labelInfo.label.id)
            ? (_openBlock(), _createBlock(_component_Select, {
                key: 0,
                options: this.reviewCycleOptions,
                modelValue: this.labelInfo.reviewCycle,
                "onUpdate:modelValue": [
                  _cache[3] || (_cache[3] = ($event: any) => ((this.labelInfo.reviewCycle) = $event)),
                  _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateLabelReviewCycleId()))
                ],
                mode: 'flex',
                required: ""
              }, null, 8, ["options", "modelValue"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$s(this?.labelInfo?.reviewCycle?.name) ?? ""), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_Label, {
          for: _ctx.activeInput,
          class: "col-md-12-em col-form-label p-2 bg-white border rounded m-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("review.label.active")), 1)
          ]),
          _: 1
        }, 8, ["for"]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_CheckBox, {
              mode: 'flex',
              id: _ctx.activeInput,
              modelValue: this.labelInfo.label.active,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.labelInfo.label.active) = $event))
            }, null, 8, ["id", "modelValue"]),
            _createElementVNode("i", null, _toDisplayString(_ctx.$t('review.label.edit.lastChangedOn', {dateFormatString: this.activeChangedDateFormatted} )), 1),
            (!this.activeChangedDateFormatted)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('review.label.edit.never')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('review.label.labelContents')), 1),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addLabelContent()))
        }, _toDisplayString(_ctx.$t('review.label.edit.addLabelContent')), 1)
      ]),
      _createVNode(_component_LabelContentListWidget, {
        items: this.labelInfo.labelContents,
        labelInfo: this.labelInfo,
        menuSection: this.menuSection,
        onDeleteLabelContent: _ctx.deleteLabelContent
      }, null, 8, ["items", "labelInfo", "menuSection", "onDeleteLabelContent"]),
      (this.labelInfo.labelContents != null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('review.label.edit.countLabel', this.labelInfo.labelContents.length)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}