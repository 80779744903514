import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewAttachmentRow = _resolveComponent("ReviewAttachmentRow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        disabled: this.disabled,
        type: "file",
        onChange: _cache[0] || (_cache[0] = ($event: any) => {_ctx.addFile($event.target.files[0]); $event.target.value = '';}),
        class: "form-control"
      }, null, 40, _hoisted_1)
    ]),
    (_ctx.attachments.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ol", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment, index) => {
              return (_openBlock(), _createBlock(_component_ReviewAttachmentRow, {
                disabled: this.disabled,
                key: attachment.id ?? attachment.attachmentId,
                modelValue: _ctx.attachments[index],
                "onUpdate:modelValue": ($event: any) => ((_ctx.attachments[index]) = $event),
                onDelete: ($event: any) => (_ctx.removeAttachment(index))
              }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "onDelete"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}