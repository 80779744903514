import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CdesMainUi = _resolveComponent("CdesMainUi")!
  const _component_LoginDialog = _resolveComponent("LoginDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ctx.initialized)
      ? (_openBlock(), _createBlock(_component_CdesMainUi, {
          key: 0,
          class: "flex-grow-1"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_LoginDialog, { ref: "loginDialog" }, null, 512)
  ]))
}