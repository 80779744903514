import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class Label extends Persistent {

    // Properties: (placed here as documentation)
    //
    // organisationId : number; --> Organisation
    // reviewCycleId : (number | null | undefined); --> ReviewCycle
    // name : (string | null | undefined);
    // comment : (string | null | undefined);
    // active : (boolean | null | undefined);
    // activeModified : (number | null | undefined);

    private _organisationId : number;
    get organisationId() : number {
        this.__checkAttributeDefinition(7, "organisationId");
        return this._organisationId;
    }
    set organisationId(organisationId : number) {
        this._organisationId = organisationId;
    }

    private _reviewCycleId : (number | null | undefined);
    get reviewCycleId() : (number | null | undefined) {
        this.__checkAttributeDefinition(8, "reviewCycleId");
        return this._reviewCycleId;
    }
    set reviewCycleId(reviewCycleId : (number | null | undefined)) {
        this._reviewCycleId = reviewCycleId;
    }

    private _name : (string | null | undefined);
    get name() : (string | null | undefined) {
        this.__checkAttributeDefinition(9, "name");
        return this._name;
    }
    set name(name : (string | null | undefined)) {
        this._name = name;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(10, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _active : (boolean | null | undefined);
    get active() : (boolean | null | undefined) {
        this.__checkAttributeDefinition(11, "active");
        return this._active;
    }
    set active(active : (boolean | null | undefined)) {
        this._active = active;
    }

    private _activeModified : (number | null | undefined);
    get activeModified() : (number | null | undefined) {
        this.__checkAttributeDefinition(12, "activeModified");
        return this._activeModified;
    }
    set activeModified(activeModified : (number | null | undefined)) {
        this._activeModified = activeModified;
    }


    public static constructPartialInstance() : Label {
        let instance = new Label();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : Label {
        let instance = new Label();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : Label) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.organisationId, other.organisationId, "Long")
            && DtoHelper.areMembersEqual(this.reviewCycleId, other.reviewCycleId, "Long")
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.active, other.active, "Boolean")
            && DtoHelper.areMembersEqual(this.activeModified, other.activeModified, "UtcSeconds")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            organisationId: this._organisationId,
            reviewCycleId: this._reviewCycleId,
            name: this._name,
            comment: this._comment,
            active: this._active,
            activeModified: this._activeModified,
        };
    }
}
