/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { CdesRole } from "cdes-api/dto/CdesRole";
import { Organisation } from "cdes-api/dto/Organisation";
import { Project } from "cdes-api/dto/Project";
import { SubProject } from "cdes-api/dto/SubProject";
import { ConsortiumPersonInfo } from "../person/ConsortiumPersonInfo";
import { OrganisationPersonInfo } from "../person/OrganisationPersonInfo";

export class ParticipationPageEditInfo {
    organisations : Organisation[];
    project : Project;
    cdesRoles : CdesRole[];
    subProjects : SubProject[];
    personInfos : Map<number, ConsortiumPersonInfo[]>;
    consortiumIdToOrganisations : Map<number, Organisation[]>;
}
