import { Tooltip } from "bootstrap";

const tooltipSymbol: unique symbol = Symbol("tooltip");

export default {
	mounted(el) {
		if (!el[tooltipSymbol]) {
			el[tooltipSymbol] = new Tooltip(el);
		}
	},
	unmounted(el) {
		if (el[tooltipSymbol]) {
			el[tooltipSymbol].dispose();
			delete el[tooltipSymbol];
		}
	},
};
