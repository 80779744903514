/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { ComputedRef, defineComponent, getCurrentInstance, PropType, reactive, ref } from "vue";
import { useCtx } from "cdes-vue/util/Ctx";
import { useValidationForm, useValidity, ValidationResult } from "cdes-vue/util/directives/ValidationForm";
import { asyncEagerComputed, loadingPlaceholder } from "cdes-vue/util/Prop";
import { PlotOrderTemplateInfo } from "cdes-api/dto/plot/PlotOrderTemplateInfo";
import { format } from "clazzes-core/dateTime/CldrPatternParser";
import { PlotTemplateResolver } from "cdes-vue/voc/plot/PlotTemplateResolver";
import Transform from "cdes-vue/util/Transform";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import PlotOrderItemTemplateListWidget from "cdes-vue/plot/template/PlotOrderItemTemplateListWidget.vue";
import { PlotOrderItemTemplateInfo } from "cdes-api/dto/plot/PlotOrderItemTemplateInfo";
import { PlotHelper } from "../util/PlotHelper";
import { SelectOption } from "cdes-vue/util/form/Select";
import { PlotOrderTemplateEditInfo } from "cdes-api/dto/plot/PlotOrderTemplateEditInfo";
import { OrderAddress } from "cdes-api/dto/OrderAddress";
import { PlottOrderTemplate } from "cdes-api/dto/PlottOrderTemplate";
import { PlottOrderItemTemplate } from "cdes-api/dto/PlottOrderItemTemplate";
import { PlottProperties } from "cdes-api/dto/PlottProperties";
import { PageCall } from "cdes-vue/util/router/PageCall";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { PlotPaperQuality } from "cdes-vue/voc/plot/PlotPaperQuality";
import { PlotColor } from "cdes-vue/voc/plot/PlotColor";
import { PlotMargin } from "cdes-vue/voc/plot/PlotMargin";
import { PlotScale } from "cdes-vue/voc/plot/PlotScale";

export default defineComponent({
    name: "PlotOrderTemplateEditWidget",

    components: {
        Transform,
        Select,
        TextInput,
        FormGridColumn,
        FormGridLocal,
        PlotOrderItemTemplateListWidget
    },

    props: {
        projectId : {
            type: Number as PropType<number | null>,
        },
        plotOrderTemplateId : {
            type: Number as PropType<number | null>,
        }
    },

/*
    setup(props) {
        const ctx = useCtx();
        const instance = getCurrentInstance().proxy;

        const validationResult : ValidationResult = useValidationForm({
            // @ts-ignore
            validationFunction : form => instance.validate(form),
            // @ts-ignore
            onSubmit : () => instance.saveProject(),
        });


//        let plotOrderTemplateInfo : PlotOrderTemplateInfo;
//        if (props.plotOrderTemplateId != null) {
//            plotOrderTemplateInfo = await ctx.plotService.getPlotOrderTemplateInfoById(ctx.activeOrganisationPersonId,
//                                                                                       props.plotOrderTemplateId);
//        } else {
//            plotOrderTemplateInfo = {
//                id : null,
//                name : null,
//                comment : null,
//                description : null,
//                templateResolver : null,
//                kindOfDelivery : null,
//                plotterProjectParticipationId : null,
//                plotterOrganisationAddress : null,
//                billingAddressId : null,
//                billingAddress : null,
//                items : []
//            };
//        }        

        const plotOrderTemplateInfo = asyncEagerComputed(() => {
            if (props.plotOrderTemplateId != null) {
                return ctx.plotService.getPlotOrderTemplateInfoById(ctx.activeOrganisationPersonId, props.plotOrderTemplateId);
            } else {
                return reactive({
                    id : null,
                    name : null,
                    description : null,
                    templateResolver : null,
                    plotterOrganisationAddress : null,
                    billingAddress : null,
                    items : []
                });
            }
        }, new PlotOrderTemplateInfo());

        return {
            formTransformer : validationResult.transformer,
            wasValidated : validationResult.wasValidated,
            plotOrderTemplateInfo : plotOrderTemplateInfo,
            PlotTemplateResolver : PlotTemplateResolver
        };
    },
*/
    data() {
        const instance = getCurrentInstance().proxy;
        const validationResult : ValidationResult = useValidationForm({
            // @ts-ignore
            validationFunction : form => instance.validate(form),
            // @ts-ignore
            onSubmit : () => instance.saveTemplate(),
        });

        return {
            formTransformer : validationResult.transformer,
            wasValidated : validationResult.wasValidated,
            plotOrderTemplateInfo : new PlotOrderTemplateInfo(),
            plotOrderTemplateEditInfo : new PlotOrderTemplateEditInfo(),
            nextNewItemId : -1,
            saveRunning : false
        };
    },

    computed : {
        billingAddressSelectOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            let orderAddresses = this.plotOrderTemplateEditInfo.orderAddresses;
            if (orderAddresses != null) {
                for (let orderAddress of orderAddresses) {
                    let name : string = orderAddress.organisationName;
                    if (orderAddress.givenName != null || orderAddress.surName != null)
                        name += " (" + (orderAddress.givenName!=null?orderAddress.givenName+' ':'') + (orderAddress.surName!=null?orderAddress.surName:'') + ")";
                    options.push({
                        value : orderAddress.id,
                        label : name
                    });
                }
            }

            options.sort((a : SelectOption, b : SelectOption) => {
                let nameA = a.label;
                let nameB = b.label;
                return nameA.localeCompare(nameB);
            });

            return options;
        },

        reviewCycleSelectOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            let reviewCycles = this.plotOrderTemplateEditInfo.reviewCycleTreeInfo.reviewCycles;
            if (reviewCycles != null) {
                for (let reviewCycle of reviewCycles) {
                    let name : string = this.$s(reviewCycle.name);
                    options.push({
                        value : reviewCycle.id,
                        label : name
                    });
                }
            }
            return options;
        },

        plotterSelectOptions() : SelectOption[] {
            let options : SelectOption[] = [];
            let plotterJoins = this.plotOrderTemplateEditInfo.plotterJoins;
            if (plotterJoins != null) {
                for (let plotterJoin of plotterJoins) {
                    let label : string = plotterJoin.organisationName + " (" + plotterJoin.personSurName
                        + " " + plotterJoin.personGivenName + ")";
                    options.push({
                        value : plotterJoin.projectParticipationId,
                        label : label
                    });
                }
            }
            return options;
        },

        kindOfDeliverySelectOptions() : SelectOption[] {
            return PlotHelper.getKindOfDeliveryOptions(this.$t);
        },

        deliveryAddressId() {
            if (this.plotOrderTemplateInfo != null && this.plotOrderTemplateInfo.items != null
                && this.plotOrderTemplateInfo.items.length > 0) {
                return this.plotOrderTemplateInfo.items[0].deliveryAddressId;
            }
                
            return null;
        },

        templateResolverCss() {
            return this.plotOrderTemplateInfo != null && this.plotOrderTemplateInfo.templateResolver != null ? "" : "mustField";
        },
        nameCss() {
            return this.plotOrderTemplateInfo != null && this.plotOrderTemplateInfo.name != null &&
                this.plotOrderTemplateInfo.name.trim().length > 0 ? "" : "mustField";
        },
        reviewCycleCss() {
            return this.plotOrderTemplateInfo != null
                && (this.plotOrderTemplateInfo.templateResolver != PlotTemplateResolver.REVIEW_CYCLE
                    || this.plotOrderTemplateInfo.reviewCycleId != null) ? "" : "mustField";
        },
        billingAddressCss() {
            return this.plotOrderTemplateInfo != null
                && this.plotOrderTemplateInfo.billingAddressId != null ? "" : "mustField";
        },
        plotterCss() {
            return this.plotOrderTemplateInfo != null
                && this.plotOrderTemplateInfo.plotterProjectParticipationId != null ? "" : "mustField";
        },
        kindOfDeliveryCss() {
            return this.plotOrderTemplateInfo != null
                && this.plotOrderTemplateInfo.kindOfDelivery != null ? "" : "mustField";
        },
        saveDisabled() {
            let itemsDisabled : boolean = false;
            if (this.plotOrderTemplateInfo.templateResolver == PlotTemplateResolver.REVIEW_CYCLE
                || this.plotOrderTemplateInfo.templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION) {
                for (let item of this.plotOrderTemplateInfo.items) {
                    itemsDisabled = itemsDisabled || item.deliveryAddressId == null;
                }
            }

            return this.plotOrderTemplateInfo == null
                || this.plotOrderTemplateInfo.templateResolver == null
                || this.plotOrderTemplateInfo.name == null
                || this.plotOrderTemplateInfo.name.trim().length == 0
                || (this.plotOrderTemplateInfo.templateResolver == PlotTemplateResolver.REVIEW_CYCLE
                    && this.plotOrderTemplateInfo.reviewCycleId == null)
                || this.plotOrderTemplateInfo.billingAddressId == null
                || this.plotOrderTemplateInfo.plotterProjectParticipationId == null
                || this.plotOrderTemplateInfo.kindOfDelivery == null
                || itemsDisabled
                || this.saveRunning;
        }
    },

    mounted() {
        this.plotOrderTemplateInfo = new PlotOrderTemplateInfo();

        let projectId : number = this.plotOrderTemplateId != null ? null : this.ctx.activeProjectId;
        this.ctx.plotService.getPlotOrderTemplateEditInfo(this.ctx.activeOrganisationPersonId,
                                                          this.plotOrderTemplateId, projectId)
            .then((plotOrderTemplateEditInfo : PlotOrderTemplateEditInfo) => {
                this.plotOrderTemplateEditInfo = plotOrderTemplateEditInfo;
                this.plotOrderTemplateInfo = plotOrderTemplateEditInfo.templateInfo;

                if (this.plotOrderTemplateInfo.items.length == 0) {
                    this.addItem(null);
                }

                let lastPageCall : PageCall = this.ctx.getLastPageCall();

                if (lastPageCall != null && lastPageCall.getName() == "OrderAddressEditWidget") {
                    let orderAddress = lastPageCall.getParam("orderAddress") as OrderAddress;
                    let found = false;
                    for (let n = 0; n < this.plotOrderTemplateEditInfo.orderAddresses.length; n++) {
                        let currAddress = this.plotOrderTemplateEditInfo.orderAddresses[n];
                        if (currAddress.id == orderAddress.id) {
                            this.plotOrderTemplateEditInfo.orderAddresses[n] = orderAddress;
                            found = true;
                        }
                    }
                    if (!found) {
                        this.plotOrderTemplateEditInfo.orderAddresses.push(orderAddress);
                    }
                    
                    this.plotOrderTemplateInfo.billingAddressId = orderAddress.id;
                }
            }, (err : any) => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
    },

    methods: {
        validate(form: HTMLFormElement): boolean {
            return true;
        },

        getCaption() : string {
            let plotOrderTemplateInfo = this.plotOrderTemplateInfo as PlotOrderTemplateInfo;
            let templateResolver : PlotTemplateResolver = plotOrderTemplateInfo.templateResolver;
            if (templateResolver == PlotTemplateResolver.MANUAL) {
                return this.$t("plot.template.edit.caption.manual");
            } else if (templateResolver == PlotTemplateResolver.PROJECT_PARTICIPATION) {
                return this.$t("plot.template.edit.caption.projectParticipation");
            } else if (templateResolver == PlotTemplateResolver.REVIEW_CYCLE) {
                return this.$t("plot.template.edit.caption.reviewCycle");
            } else if (templateResolver == null) {
                return this.$t("plot.template.edit.caption.none");
            } else {
                throw new Error("Illegal templateResolver: [" + templateResolver + "]");
            }
        },

        constructTemplateResolverOptions() : SelectOption[] {
            return PlotHelper.getTemplateResolverOptions(this.$t);
        },

        getItemIndex(itemId : number) : number {
            let idx : number = null;
            for (let n = 0; n < this.plotOrderTemplateInfo.items.length; n++) {
                let item = this.plotOrderTemplateInfo.items[n];
                if (item.id == itemId) {
                    idx = n;
                }                    
            }
            return idx;
        },

        addItem(itemId : number) : void {
            let idx : number = itemId != null ? this.getItemIndex(itemId) : null;
            let newItem : PlotOrderItemTemplateInfo = new PlotOrderItemTemplateInfo();
            newItem.id = this.nextNewItemId;
            newItem.plotPropertiesUserUnit = 1.0;
            newItem.invalidated = false;
            newItem.evaluateMailReceivers = false;
            newItem.plotPropertiesPaperQuality = PlotPaperQuality[PlotPaperQuality.LASER_80];
            newItem.plotPropertiesIsInColor = PlotColor.IN_COLOR;
            newItem.plotPropertiesMargin = PlotMargin[PlotMargin.FOLD_20MM_EDGE];
            newItem.plotPropertiesScale = PlotScale.A0;
            newItem.plotPropertiesCount = 1;
            this.nextNewItemId--;

            if (idx == null) {
                this.plotOrderTemplateEditInfo.templateInfo.items.push(newItem);
            } else {
                this.plotOrderTemplateEditInfo.templateInfo.items.splice(idx + 1, 0, newItem);
            }
        },

        removeItem(itemId : number) : void {
            let idx : number = this.getItemIndex(itemId);
            if (idx != null) {
                this.plotOrderTemplateInfo.items.splice(idx, 1);
            }
        },

        saveTemplate() : void {
            /*
            if (this.info === loadingPlaceholder) {
                return;
            }
            if (this.isCodeDuplicate === loadingPlaceholder) {
                return;
            }
            */

            if (this.saveRunning) {
                return;
            }

            this.saveRunning = true;
            this.ctx.plotService.saveOrUpdatePlotOrderTemplate(this.ctx.activeOrganisationPersonId,
                                                               this.plotOrderTemplateInfo as PlotOrderTemplateInfo)
                .then(() => {
                    this.saveRunning = false;
                    this.$router.go(-1);
                }, err => {
                    this.saveRunning = false;
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        addBillingAddress(item : PlotOrderItemTemplateInfo) : void {
            this.$router.push({ name : "orderAddressNew", query : { networkId : this.ctx.activeNetworkId } });
        },

        editBillingAddress(item : PlotOrderItemTemplateInfo) : void {
            this.$router.push({ name : "orderAddressEdit", params : { id : this.plotOrderTemplateInfo.billingAddressId } });
        },
        cancel(): void {
            this.$router.back();
        }        
    }
});

