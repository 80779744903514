import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1 flex-min-height-0 roleConflictListWidget" }
const _hoisted_2 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_3 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_4 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_5 = { class: "d-flex flex-row flex-wrap gap-1" }
const _hoisted_6 = { class: "d-flex flex-row flex-wrap gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParempiGrid = _resolveComponent("ParempiGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.items && this.items.length > 0)
      ? (_openBlock(), _createBlock(_component_ParempiGrid, {
          key: 0,
          class: "flex-grow-1 flex-min-height-1",
          columns: this.columns,
          items: this.items
        }, {
          participant: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(this.getParticipationString(item)), 1)
          ]),
          reviewCycleInstance: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.reviewCycleInstanceName), 1)
          ]),
          reviewCycleCell: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$s(item.reviewCycleCellName)), 1)
          ]),
          subProject: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(this.getSubProjectString(item)), 1)
          ]),
          realm: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(this.getRealmString(item)), 1)
          ]),
          _: 1
        }, 8, ["columns", "items"]))
      : _createCommentVNode("", true)
  ]))
}