import { FormMode } from "cdes-vue/voc/gui/FormMode";
import { defineComponent, inject, PropType, ref } from "vue";
import { formModeKey } from "cdes-vue/util/form/FormGrid.vue";
import { FormHelper } from "./FormHelper";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import Label from "cdes-vue/util/form/Label.vue";

export default defineComponent({
    props : {
        mode : {
            type : String as PropType<FormMode>,
        },

        id : {
            type : String
        },

        step : {
            type : Number,
            default : 1
        },

        wasValidated : {
            type : Boolean
        },

        inputClass : {
            type : String
        },

        label : {
            type : String
        },

        labelClass : {
            type : String
        },

        type : {
            type : String
        },

        title : {
            type : String
        },

        modelValue: {
            type: Number,
        },
    },

    emits: ["update:modelValue"],

    components : {
        Label,
        FormGridMember
    },

    data() {
        return {
            injectedFormMode : ref(inject(formModeKey, undefined))
        }
    },

    computed : {
        effectiveMode() : FormMode {
            if (this.mode != null) {
                return this.mode;
            } else {
                return this.injectedFormMode;
            }
        },

        isFlexMode() : boolean {
            return this.effectiveMode == FormMode.FLEX;
        },

        isFormGridMode() : boolean {
            return this.effectiveMode == FormMode.FORM_GRID;
        },

        computedLabelCss() : string {
            let cssClasses = "text-end textInputLabel ";
            if (this.labelClass != null) {
                cssClasses += this.labelClass;
            }
            return cssClasses;
        },

        computedInputCss() : string {
            let cssClasses = "form-control ";
            if (this.inputClass != null) {
                cssClasses += this.inputClass;
            }
            return cssClasses;
        }
    },

    methods : {
    }
});
