/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { RoleConflictJoin } from "cdes-api/joinDto/RoleConflictJoin";
import { ParempiColumn } from "cdes-vue/util/grid/ParempiColumn";
import ParempiGrid from "cdes-vue/util/grid/ParempiGrid.vue";
import { defineComponent, VNode, h } from 'vue';

export default defineComponent({
    components : {
        ParempiGrid,
    },

    computed : {
    },

    data() {
        return {
            columns : this.constructColumns(),
        };
    },

    methods : {

        constructColumns() : ParempiColumn<RoleConflictJoin>[] {
            let columns : ParempiColumn<RoleConflictJoin>[] = [
                ParempiColumn.asRowSpanSlot("participant", this.$t("general.projectParticipant"), "participant"),
                ParempiColumn.asRowSpanSlot("reviewCycleInstance", this.$t("general.reviewCycleInstance"), "reviewCycleInstance"),
                ParempiColumn.asRowSpanSlot("reviewCycleCell", this.$t("project.participation.edit.roleConflict.cellCaption"),
                                            "reviewCycleCell"),
                ParempiColumn.asRowSpanSlot("subProject", this.$t("general.subProject"), "subProject"),
                ParempiColumn.asRowSpanSlot("realm", this.$t("general.realm"), "realm")
            ];

            return columns;
        },

        getParticipationString(roleConflictJoin : RoleConflictJoin) : string {
            return roleConflictJoin.personGivenName + " " + roleConflictJoin.personSurName
                + " (" + roleConflictJoin.organisationName + ")";
        },

        getSubProjectString(roleConflictJoin : RoleConflictJoin) : string {
            return roleConflictJoin.subProjectCode + " " + roleConflictJoin.subProjectName;
        },

        getRealmString(roleConflictJoin : RoleConflictJoin) : string {
            return roleConflictJoin.realmCode + " " + this.$s(roleConflictJoin.realmName);
        }
    },

    props : {
        items : {
            type : Array
        }
    }
});
