/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { OrganisationParticipationInfo } from "cdes-api/dto/project/OrganisationParticipationInfo";
import { ParticipantInfo } from "cdes-api/dto/project/ParticipantInfo";
import { ParticipationInfo } from "cdes-api/dto/project/ParticipationInfo";
import { ParempiColumn } from "cdes-vue/util/grid/ParempiColumn";
import ParempiGrid from "cdes-vue/util/grid/ParempiGrid.vue";
import { ParempiRowPos } from "cdes-vue/util/grid/ParempiRowPos";
import { Person } from "cdes-api/dto/Person";
import { SubProject } from "cdes-api/dto/SubProject";
import { ProjectParticipationJoin } from "cdes-api/joinDto/ProjectParticipationJoin";
import ProjectParticipationEditWidget from "./ProjectParticipationEditWidget.vue";
import ProjectParticipationRestrictionWidget from "./ProjectParticipationRestrictionWidget.vue";
import ProjectParticipantHistoryPartListWidget from "./ProjectParticipantHistoryPartListWidget.vue";
import ParticipationDeleteWidget from "./ParticipationDeleteWidget.vue";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import MultiSelect from "cdes-vue/util/form/MultiSelect.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import * as DialogButton from "cdes-vue/util/layout/DialogButton";
import { CdesRole } from "cdes-api/dto/CdesRole";
import { defineComponent, h, VNode, PropType } from "vue";
import { OrganisationPerson } from 'cdes-api/dto/OrganisationPerson';
import { ProjectParticipation } from 'cdes-api/dto/ProjectParticipation';
import { ProjectParticipantHistoryPartJoin } from 'cdes-api/joinDto/ProjectParticipantHistoryPartJoin';
import { ParticipationPageEditInfo } from "cdes-api/dto/project/ParticipationPageEditInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { keyToCmp } from "cdes-vue/util/Sort";
import { ParticipationDeleteInfo } from "cdes-api/dto/project/ParticipationDeleteInfo";
import { ParticipationSecurityInfo } from "cdes-api/dto/project/ParticipationSecurityInfo";
import { ConsortiumPersonInfo } from "cdes-api/dto/person/ConsortiumPersonInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import DeputyAddDropdown from "./DeputyAddDropdown.vue";

export default defineComponent({
    components : {
        CheckBox,        
        DeputyAddDropdown,
        Dialog,
        MultiSelect,
        ParempiGrid,
        ProjectParticipationEditWidget,
        ProjectParticipationRestrictionWidget,
        ProjectParticipantHistoryPartListWidget,
        ParticipationDeleteWidget,
        TextInput
    },

    props : {
        organisationParticipationInfos : {
            type : Array
        },
        editInfo : {
            type : Object as PropType<ParticipationPageEditInfo>
        },
        quickFilter: {
            type: String as PropType<string | null>,
        },
        securityInfo : {
            type : Object as PropType<ParticipationSecurityInfo>
        },
        disabled : {
            type : Boolean
        },
        menuSection : {
            type : String
        },
        buek : {
            type : Boolean            
        }
    },

    data() {
        return {
            columns : this.constructColumns(),
            CLOSE: DialogButton.CLOSE,
            history: {
                participation: undefined,
                participationParts: undefined,
                organisation: "",
                role: ""
            },
            deleteInfo : null,
            offerParticipationsOfAllOrganisations : false,
            deputyAddFilter : "",
            commentEditParticipationId : null,
            commentEditRawComment : null,
            commentEditInfo : null
        };
    },

    emits: ["doEdit", "doSearch", "removeParticipation"],

    expose : ["scrollIntoView"],

    watch : {
        organisationParticipationInfos(newOrganisationParticipationInfos : OrganisationParticipationInfo[]) {
            window.setTimeout(() => {
                if (this.ctx.editedParticipationOrganisationId != null) {
                    let grid : typeof ParempiGrid = this.$refs.grid as (typeof ParempiGrid);
                    grid.scrollIntoView(this.ctx.editedParticipationOrganisationId);
                    this.ctx.editedParticipationOrganisationId = null;
                }
            }, 2500);
        }
    },

    computed : {
        personInfos(): ConsortiumPersonInfo[] {
            let personInfos : ConsortiumPersonInfo[] = [];
            let selectedOrganisationPersonIds : Set<number> = new Set<number>();

            // Two steps: First add all personInfos with a set consortiumId, then all without.
            // Reason: We need to suppress duplicates using selectedOrganisationPersonIds,
            //         but on the other hand, in case of consortiums, one copy with and one
            //         copy without consortiumId is around.  Ensure that the copy with
            //         consortiumId is chosen in such cases.
            for (let organisationId of this.editInfo.personInfos.keys()) {
                let currPersonInfos : ConsortiumPersonInfo[] = this.editInfo.personInfos.get(organisationId);
                for (let currPersonInfo of currPersonInfos) {
                    let organisationPersonId : number = currPersonInfo.organisationPerson?.id;
                    let consortiumId : number = currPersonInfo.consortium?.id;
                    if (consortiumId != null) {
                        if (!selectedOrganisationPersonIds.has(organisationPersonId)) {
                            personInfos.push(currPersonInfo);
                            selectedOrganisationPersonIds.add(organisationPersonId);
                        }
                    }
                }
            }

            for (let organisationId of this.editInfo.personInfos.keys()) {
                let currPersonInfos : ConsortiumPersonInfo[] = this.editInfo.personInfos.get(organisationId);
                for (let currPersonInfo of currPersonInfos) {
                    let organisationPersonId : number = currPersonInfo.organisationPerson?.id;
                    let consortiumId : number = currPersonInfo.consortium?.id;
                    if (consortiumId == null) {
                        if (!selectedOrganisationPersonIds.has(organisationPersonId)) {
                            personInfos.push(currPersonInfo);
                            selectedOrganisationPersonIds.add(organisationPersonId);
                        }
                    }
                }
            }
            personInfos = personInfos.sort((a : ConsortiumPersonInfo, b : ConsortiumPersonInfo) => {
                let nameOne = a.person?.surName + a.person?.givenName;
                let nameTwo = b.person?.surName + b.person?.givenName;
                nameOne = nameOne.trim().toLocaleLowerCase();
                nameTwo = nameTwo.trim().toLocaleLowerCase();
                return nameOne.localeCompare(nameTwo);
            });
            
            return personInfos;
            /*
            if (this.editInfo.organisationId != null) {
                return this.selectInfo.value.personInfos.get(this.editInfo.organisationId);
            } else {
                return [];
            }*/
        }
    },

    methods : {
        scrollIntoView() : void {
            if (this.ctx.editedParticipationOrganisationId != null) {
                let grid : typeof ParempiGrid = this.$refs.grid as (typeof ParempiGrid);
                grid.scrollIntoView(this.ctx.editedParticipationOrganisationId);
                this.ctx.editedParticipationOrganisationId = null;
            }
        },

        constructColumns() : ParempiColumn<OrganisationParticipationInfo>[] {
            let columns : ParempiColumn<OrganisationParticipationInfo>[] = [
                ParempiColumn.asRowSpanRenderFct("organisation", this.$t("general.organisation"),
                                                 this.organisationRowSpanFct, this.renderOrganisation),
                ParempiColumn.asRowSpanSlot("roles", this.$t("general.roles"), "roles"),
                ParempiColumn.asRowSpanSlot("mainParticipant", this.$t("general.mainParticipant"), "mainParticipant"),
                new ParempiColumn({
                    id : "deputies",
                    slotName : "deputies",
                    label : this.$t("general.deputies"),
                    filter : this.filterDeputies
                }),
            ];
            if (!this.buek) {
                columns.push(new ParempiColumn({
                    id: "subProjects",
                    slotName: "subProjects",
                    label: this.$t("project.participation.list.subProjectCaption"),
                    headerWrappingSlotName: "subProjectsHeaderWrapper"
                }));
            }

            columns.push(ParempiColumn.asRowSpanSlot("comment", this.$t("general.comment"), "comment"));
            columns.push(ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"),
                                                     "actions", this.organisationRowSpanFct));

            return columns;
        },

        filterDeputies(item : OrganisationParticipationInfo) : string[] {
            let tokens : string[] = [];
            for (let participationInfo of item.participationInfos) {
                for (let deputyInfo of participationInfo.deputyInfos) {
                    let givenName = deputyInfo.person.givenName;
                    let surName = deputyInfo.person.surName;
                    let organisationName = deputyInfo.organisation.name;
                    let token = surName + " " + givenName + " (" + organisationName + ")";
                    tokens.push(token);
                }
            }
            return tokens;
        },

        rowPosFactory(item : OrganisationParticipationInfo) {
            return new ParempiRowPos(item.participationInfos.length);
        },

        rowPosIncrementor(item : OrganisationParticipationInfo, pos : ParempiRowPos) : void {
            pos.increment(0);
        },

        organisationRowSpanFct(item : OrganisationParticipationInfo, pos : ParempiRowPos) : number {
            let subRow : number = pos.getSubRow();
            if (subRow == 0) {
                return item.participationInfos.length;
            } else {
                return null;
            }
        },

        getDeputyInfos(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : ParticipantInfo[] {
            let subRow : number = pos.getSubRow();
            return organisationParticipationInfo.participationInfos[subRow].deputyInfos;
        },

        getDeputyString(participantInfo : ParticipantInfo, baseOrganisationInfo : OrganisationParticipationInfo) : string {
            let baseOrganisationId : number = baseOrganisationInfo.organisation.id;
            let organisationId : number = participantInfo.organisationPerson.organisationId;
            if (organisationId == baseOrganisationId) {
                return participantInfo.person.surName + " " + participantInfo.person.givenName;
            } else {
                return participantInfo.person.surName + " " + participantInfo.person.givenName + " ("
                    + participantInfo.organisation.name + ")";
            }
        },

        getParticipationInfo(organisationParticipationInfo : OrganisationParticipationInfo,
                             pos : ParempiRowPos) : ParticipationInfo {
            let subRow : number = pos.getSubRow();
            return organisationParticipationInfo.participationInfos[subRow];
        },

        getMainParticipantInfo(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : ParticipantInfo {
            let subRow : number = pos.getSubRow();
            return organisationParticipationInfo.participationInfos[subRow].mainParticipantInfo;
        },

        getRole(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : CdesRole {
            let subRow : number = pos.getSubRow();
            return organisationParticipationInfo.participationInfos[subRow].cdesRole;
        },

        getAllowedSubProjects(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : SubProject[] {
            let subRow : number = pos.getSubRow();
            return organisationParticipationInfo.participationInfos[subRow].allowedSubProjects;
        },

        getParticipation(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : ProjectParticipation {
            let subRow : number = pos.getSubRow();
            //let subRow : number = pos.getSubRow()-  1; //TODO: Find a way to pass the correct sub row position
            let part =  organisationParticipationInfo.participationInfos[subRow].projectParticipation;
            return part;
        },
        renderOrganisation(organisationParticipationInfo : OrganisationParticipationInfo) : VNode[] {
            let lines : VNode[] = [];
            lines.push(h("div", organisationParticipationInfo.organisation.name));
            return lines;
        },

        renderRoles(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : VNode[] {
            let subRow : number = pos.getSubRow();

            let lines : VNode[] = [];
            lines.push(h("div", organisationParticipationInfo.participationInfos[subRow].cdesRole.name));
            return lines;
        },

        renderMainParticipant(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : VNode[] {
            let subRow : number = pos.getSubRow();
            const info = organisationParticipationInfo.participationInfos[subRow].mainParticipantInfo;
            if (info != null) {
                let person : Person = organisationParticipationInfo.participationInfos[subRow].mainParticipantInfo.person;
                let nameString : string = person.givenName + " " + person.surName;

                let lines : VNode[] = [];
                lines.push(h("div", nameString));
                return lines;
            } else {
                return [];
            }
        },

        renderDeputies(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : VNode[] {
            let subRow : number = pos.getSubRow();
            let deputyParticipantInfos : ParticipantInfo[] = organisationParticipationInfo.participationInfos[subRow].deputyInfos;

            let lines : VNode[] = [];
            for (let deputyParticipantInfo of deputyParticipantInfos) {
                let person : Person = deputyParticipantInfo.person;
                let nameString : string = person.givenName + " " + person.surName;
                lines.push(h("div", nameString));
            }

            return lines;
        },

        renderSubProjects(organisationParticipationInfo : OrganisationParticipationInfo) : VNode[] {
            let lines : VNode[] = [];
            lines.push(h("div", "TODO"));
/*            let subProjects : SubProject[] = participationInfo.allowedSubProjects;
            for (let subProject of subProjects) {
                lines.push(h("div", subProject.code));
            }
*/
            return lines;
        },

        renderComment(organisationParticipationInfo : OrganisationParticipationInfo, pos : ParempiRowPos) : VNode[] {
            let subRow : number = pos.getSubRow();
            let comment : string = organisationParticipationInfo.participationInfos[subRow].projectParticipation.comment;

            let lines : VNode[] = [];
            lines.push(h("div", comment != null ? comment : ""));
            return lines;
        },

        /**
         * Opens the InstantEmailPage with the given recipientID, which will be resolved to either
         * participation or organisationPerson.
         *
         * @param recipientId
         */
        openEmailPage(recipientIdType : "participation" | "organisationPerson" | "project", recipientIds: number[] | number) : void {
            let targetName : string = (this.menuSection == "plan" ? "sendEmail" : "sendEmailBuek");
            switch (recipientIdType) {
                case "participation": {
                    this.$router.push({name: targetName, query: {
                        participationIds: recipientIds
                    }})

                    break;
                }
                case "organisationPerson": {
                    this.$router.push({name: targetName, query: {
                        organisationPersonId: recipientIds
                    }});

                    break;
                }
                case "project": {
                    this.$router.push({name: targetName, query: {
                        projectId: recipientIds
                    }});
                }
            }
        },
        edit(item: OrganisationParticipationInfo): void {
            this.$emit("doEdit", item);
	},

        getParticipationCommentInputRef(info : ParticipationInfo) : string {
            return "participationCommentInput-" + info.projectParticipation.id;
        },

        editComment(info : ParticipationInfo) : void {
            this.commentEditParticipationId = info.projectParticipation.id;
            this.commentEditRawComment = info.projectParticipation.comment;
            this.commentEditInfo = info;
        },

        saveComment(info : ParticipationInfo) : void {
            let textInput = this.$refs[this.getParticipationCommentInputRef(info)] as InstanceType<typeof TextInput>;
            let comment : string = textInput.getValue();

            this.ctx.projectService.saveParticipationComment(this.ctx.activeOrganisationPersonId, info.projectParticipation.id,
                                                             comment)
                .then(() => {
                    info.projectParticipation.comment = comment;
                    this.commentEditParticipationId = null;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        abortEditComment(info : ParticipationInfo) : void {
            this.commentEditParticipationId = null;
            this.commentEditInfo = null;
        },

        keydown(event): void {
            if (event.key === "Escape") {
                this.commentEditParticipationId = null;                
            } else if (event.key === "Enter") {
                this.saveComment(this.commentEditInfo);
            }
        },

        getDeputyOptions(item : OrganisationParticipationInfo, rowPos : ParempiRowPos) : ConsortiumPersonInfo[] {
            let filterString = this.deputyAddFilter != null ? this.deputyAddFilter.trim() : null;

            console.info("getDeputyOptions, filterSTring [" + filterString + "], timestamp [" + new Date().toISOString() + "]");
            console.info("personInfo count [" + (this.personInfos != null ? this.personInfos.length : 0) + "]");

            let participationInfo : ParticipationInfo = this.getParticipationInfo(item, rowPos);
            let participationOrganisationId : number = participationInfo.projectParticipation.organisationId;
            let mainParticipantInfo : ParticipantInfo = this.getMainParticipantInfo(item, rowPos);
            let existingDeputyInfos : ParticipantInfo[] = this.getDeputyInfos(item, rowPos);
            let existingDeputyOpIds : Set<number> = new Set<number>();
            if (mainParticipantInfo != null) {
                existingDeputyOpIds.add(mainParticipantInfo.organisationPerson.id);
            }
            for (let existingDeputyInfo of existingDeputyInfos) {
                existingDeputyOpIds.add(existingDeputyInfo.organisationPerson.id);
            }
            
            let rawPersonInfos : ConsortiumPersonInfo[] = this.personInfos;
            let personInfos : ConsortiumPersonInfo[] = [];

            for (let rawPersonInfo of rawPersonInfos) {
                let currOrganisationPersonId : number = rawPersonInfo.organisationPerson.id;
                let currOrganisationId : number = rawPersonInfo.organisation.id;
                let currConsortiumId : number = rawPersonInfo.consortium?.id;
                let candidateString = this.getPersonOrganisationString(rawPersonInfo);
                if (!existingDeputyOpIds.has(currOrganisationPersonId)
                    && (this.offerParticipationsOfAllOrganisations
                        || participationOrganisationId == currOrganisationId
                        || participationOrganisationId == currConsortiumId)
                    && (   filterString == null || filterString.length == 0
                        || candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1)) {
//                    && (   this.quickFilter == null || this.quickFilter.length == 0
//                        || candidateString.toLowerCase().indexOf(this.quickFilter.toLowerCase()) != -1)) {                    
                    personInfos.push(rawPersonInfo);
                }
            }
            
            console.info("... finished timestamp [" + new Date().toISOString() + "]");

            return personInfos;
            /*
            // Copied and adjusted from ProjectParticiationEditWidget
            let participationInfo = item.participationInfos[rowPos.getSubRow()];            
            let consortiumPersonInfos : ConsortiumPersonInfo[] = this.personInfos
                ?.filter(a => a.organisationPerson.id !== participationInfo.mainParticipantOrganisationPersonId)
                ?.sort(keyToCmp(a => this.renderPerson(a))) ?? [];
            return consortiumPersonInfos;
            */
        },

        deleteDeputy(deputyInfo : ParticipantInfo) : void {
            if (window.confirm(this.$t('project.participation.list.deputy.confirmDelete', {
                surName: deputyInfo.person.surName,
                givenName: deputyInfo.person.givenName,
            }))) {
                this.ctx.projectService.deleteDeputy(this.ctx.activeOrganisationPersonId, deputyInfo.participant.id).then(() => {
                    let participationInfo = deputyInfo.participationInfo;
                    let deleteIdx : number = null;
                    for (let n = 0; n < participationInfo.deputyInfos.length; n++) {
                        let candidateDeputyInfo = participationInfo.deputyInfos[n];
                        if (candidateDeputyInfo.participant.id == deputyInfo.participant.id) {
                            deleteIdx = n;
                        }
                    }

                    if (deleteIdx != null) {
                        participationInfo.deputyInfos.splice(deleteIdx, 1);
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            }
        },

        openHistoryDialog() {
            let organisationPersonId = this.ctx.activeOrganisationPersonId;
            this.ctx.projectService.getParticipantHistoryPartJoins(organisationPersonId,
                                                                   this.history.participation.id).then((joins) => {
                                                                       this.history.participationParts = joins;
                                                                       (this.$refs.historyDialog as InstanceType<typeof Dialog>).show();
                                                                   }, err => {
                                                                       ErrorHelper.processError(this.$t, this.$d, err);
                                                                   });
        },

        addMeAsDeputy(participationInfo : ParticipationInfo) : void {
            this.ctx.projectService.insertMeAsDeputy(this.ctx.activeOrganisationPersonId, participationInfo.projectParticipation.id)
                .then((participantInfo : ParticipantInfo) => {
                    participantInfo.participationInfo = participationInfo;                    
                    participationInfo.deputyInfos.push(participantInfo);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        hasOwnParticipant(participationInfo : ParticipationInfo) : boolean {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            let found : boolean = false;
            for (let deputyInfo of participationInfo.deputyInfos) {
                found = found || deputyInfo.organisationPerson.id == organisationPersonId;
            }
            if (participationInfo.mainParticipantInfo != null) {
                found = found || participationInfo.mainParticipantInfo.organisationPerson.id == organisationPersonId;
            }
            return found;
        },

        deleteParticipationById(item : OrganisationParticipationInfo, participationInfo : ParticipationInfo) : void {
            let roleName : string = participationInfo.cdesRole.name;
            let organisationName : string = item.organisation.name;
            let organisationId : number = item.organisation.id;
            if (window.confirm(this.$t("project.participation.list.role.reallyDeleteQuestion", {
                role : roleName,
                organisation : organisationName
            }))) {
                let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                let participationId : number = participationInfo.projectParticipation.id;

                this.ctx.projectService.getDeleteParticipationInfoByParticipation(organisationPersonId, participationId)
                    .then((deleteInfo : ParticipationDeleteInfo) => {
                        this.processDeleteInfo(deleteInfo, null, organisationId, participationId);
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });

                /*
                this.ctx.projectService.getDeleteParticipationMessageByParticipation(organisationPersonId, participationId)
                    .then((message) => {
                        if (message != null) {
                            window.alert(message);
                        } else {
                            this.ctx.projectService.deleteParticipationById(organisationPersonId, participationId)
                                .then(() => {
                                    this.$emit("removeParticipation", organisationId, participationId);
                                });
                        }
                    });
                */
            }
        },

        deleteParticipationByOrganisation(item : OrganisationParticipationInfo) : void {
            let organisationName : string = item.organisation.name;
            if (window.confirm(this.$t("project.participation.list.organisation.reallyDeleteQuestion", {
                organisation : organisationName
            }))) {
                let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                let projectId : number = this.ctx.activeProjectId;

                if (projectId != null) {
                    let organisationId : number = item.organisation.id;
                    // TODO
                    // Somewhat dangerous case: Two administrators for the same organisation.
                    // This pre-check against deletion of the last administrator will pass,
                    // because for either administrator, there is another one.
                    // The check should, however, fail at server side, as there, on deletion of the
                    // second administrator, it will see that there is no one left.

                    this.ctx.projectService.getDeleteParticipationInfoByOrganisation(organisationPersonId,
                                                                                     projectId, organisationId)
                        .then((deleteInfo : ParticipationDeleteInfo) => {
                            this.processDeleteInfo(deleteInfo, projectId, organisationId, null);
                        }, err => {
                            ErrorHelper.processError(this.$t, this.$d, err);
                        });
                    /*
                    this.ctx.projectService.getDeleteParticipationMessageByOrganisation(organisationPersonId,
                                                                                        projectId, organisationId)
                        .then((message) => {
                            if (message != null) {
                                window.alert(message);
                            } else {
                                let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                                let organisationId : number = item.organisation.id;
                                this.ctx.projectService.deleteParticipationByOrganisation(organisationPersonId, organisationId,
                                                                                          projectId)
                                    .then(() => {
                                        this.$emit("removeParticipation", organisationId, null);
                                    });
                            }
                        });
                    */
                }
            }
        },

        closeDeleteDialog() : void {
            (this.$refs.deleteDialog as InstanceType<typeof Dialog>).abort();
        },

        removeParticipation(organisationId : number, participationId : number) : void {
            this.$emit("removeParticipation", organisationId, participationId);
        },

        processDeleteInfo(deleteInfo : ParticipationDeleteInfo, projectId : number, organisationId : number,
                          participationId : number) : void {
            if (!this.buek && deleteInfo.isLastAdminNonBuek) {
                window.alert(this.$t("project.participation.list.delete.adminNonBuekMessage"));
            } else if (this.buek && deleteInfo.isLastAdminBuek) {
                window.alert(this.$t("project.participation.list.delete.adminBuekMessage"));
            } else if (deleteInfo.hasOpenTasks) {
                window.alert(this.$t("project.participation.list.delete.plotTaskMessage"));                
            } else {
                deleteInfo.projectId = projectId;
                deleteInfo.organisationId = organisationId;
                deleteInfo.participationId = participationId;
                this.deleteInfo = deleteInfo;

                if (this.deleteInfo.deleteJoins.length > 0
                    || deleteInfo.hasAttachedReviewTasks
                    || deleteInfo.hasNotificationListenerAssignments
                    || deleteInfo.hasOpenTasks
                    || deleteInfo.hasPlottOrderListenerAssignments
                    || deleteInfo.hasReleasedObjectListAssignments
                    || deleteInfo.hasUnreleasedObjectListAssignments) {
                    (this.$refs.deleteDialog as InstanceType<typeof Dialog>).show();
                } else {                    
                    let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                    if (participationId != null) {
                        this.ctx.projectService.deleteParticipationById(organisationPersonId, participationId)
                            .then(() => {
                                this.$emit("removeParticipation", organisationId, participationId);
                            }, err => {
                                ErrorHelper.processError(this.$t, this.$d, err);
                            });
                    } else {
                        this.ctx.projectService.deleteParticipationByOrganisation(organisationPersonId, organisationId,
                                                                                  projectId)
                            .then(() => {
                                this.$emit("removeParticipation", organisationId, null);
                            }, err => {
                                ErrorHelper.processError(this.$t, this.$d, err);
                            });                    
                    }
                }                    
            }
        },

        getPersonOrganisationString(consortiumPersonInfo : ConsortiumPersonInfo) : string {
            return consortiumPersonInfo.person.surName + " " + consortiumPersonInfo.person.givenName
                + " (" + consortiumPersonInfo.organisation.name + ")";
        },

        doesDeputyAddFilterMatch(consortiumPersonInfo : ConsortiumPersonInfo) : boolean {
            let filterString = this.deputyAddFilter != null ? this.deputyAddFilter.trim() : null;
            let candidateString = this.getPersonOrganisationString(consortiumPersonInfo);

            if (filterString == null || filterString.length == 0) {
                return true;
            } else {
                return candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1;
            }
        }
    }
});

/*
ognl:projectParticipation.mainParticipant.organisationPerson.person.inverseCommonName
ognl:projectParticipation.mainParticipant.organisationPerson.organisationalUnitName
ognl:projectParticipation.mainParticipant.organisationPerson.person.id
ognl:projectParticipation.project.id

Alle ProjectParticipants zur ProjectParticipation
ognl:projectParticipant.organisationPerson.organisationalUnitName
ognl:projectParticipant.organisationPerson.organisationalUnitName
ognl:projectParticipant.organisationPerson.organisation.id
ognl:projectParticipant.organisationPerson.organisation.name
ognl:projectParticipant.organisationPerson.person.id

ognl:projectParticipation.comment


organisation.name
role.name
mainParticipant.organisationPerson.person.inverseCommonName

pp.getRole()

ProjectParticipationSubProjectRestrictions

*/
