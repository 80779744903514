import { SubProject } from "cdes-api/dto/SubProject";
import { ParticipationDeleteJoin } from "cdes-api/joinDto/ParticipationDeleteJoin";

export class ParticipationDeleteInfo {
    deleteJoins : ParticipationDeleteJoin[] = [];

    hasReleasedObjectListAssignments : boolean;
    hasUnreleasedObjectListAssignments : boolean;
    hasAttachedReviewTasks : boolean;
    hasNotificationListenerAssignments : boolean;
    hasPlottOrderListenerAssignments : boolean;
    
    isLastAdminNonBuek : boolean;
    isLastAdminBuek : boolean;
    hasOpenTasks : boolean;
    
    affectedSubProjects : SubProject[] = [];

    buek : boolean;

    // Client only
    projectId : number;
    organisationId : number;
    participationId : number;
}
