import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParempiGrid = _resolveComponent("ParempiGrid")!

  return (_openBlock(), _createBlock(_component_ParempiGrid, {
    class: "flex-grow-1 flex-min-height-0",
    columns: this.columns,
    items: this.items
  }, {
    code: _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.code), 1)
    ]),
    name: _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.name), 1)
    ]),
    comment: _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(item.comment), 1)
    ]),
    _: 1
  }, 8, ["columns", "items"]))
}