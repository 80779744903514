<template>
  <div>
    <button
        type="button"
        :id="$id('button')"
        aria-expanded="true"
        class="btn cdes-btn-collapse ps-2"
        data-bs-toggle="collapse"
        :data-bs-target="'#' + $id('collapse')"
        :aria-controls="$id('collapse')"
    >
      <slot name="label"/>
    </button>
    <div
        :id="$id('collapse')"
        class="collapse show"
    >
      <slot/>
    </div>
  </div>
</template>
<script>
 import { defineComponent } from "vue";
 export default defineComponent({});
</script>
