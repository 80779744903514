import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class Person extends Persistent {

    // Properties: (placed here as documentation)
    //
    // commonName : (string | null | undefined);
    // givenName : string;
    // surName : string;
    // title : (string | null | undefined);
    // roleId : number; --> CdesRole
    // ztPermission : boolean;
    // login : (string | null | undefined);
    // password : (string | null | undefined);
    // lastPasswordChangeTs : (number | null | undefined);
    // gender : string;
    // question : (string | null | undefined);
    // answerPassword : (string | null | undefined);
    // status : number;

    private _commonName : (string | null | undefined);
    get commonName() : (string | null | undefined) {
        this.__checkAttributeDefinition(14, "commonName");
        return this._commonName;
    }
    set commonName(commonName : (string | null | undefined)) {
        this._commonName = commonName;
    }

    private _givenName : string;
    get givenName() : string {
        this.__checkAttributeDefinition(15, "givenName");
        return this._givenName;
    }
    set givenName(givenName : string) {
        this._givenName = givenName;
    }

    private _surName : string;
    get surName() : string {
        this.__checkAttributeDefinition(16, "surName");
        return this._surName;
    }
    set surName(surName : string) {
        this._surName = surName;
    }

    private _title : (string | null | undefined);
    get title() : (string | null | undefined) {
        this.__checkAttributeDefinition(17, "title");
        return this._title;
    }
    set title(title : (string | null | undefined)) {
        this._title = title;
    }

    private _roleId : number;
    get roleId() : number {
        this.__checkAttributeDefinition(18, "roleId");
        return this._roleId;
    }
    set roleId(roleId : number) {
        this._roleId = roleId;
    }

    private _ztPermission : boolean;
    get ztPermission() : boolean {
        this.__checkAttributeDefinition(19, "ztPermission");
        return this._ztPermission;
    }
    set ztPermission(ztPermission : boolean) {
        this._ztPermission = ztPermission;
    }

    private _login : (string | null | undefined);
    get login() : (string | null | undefined) {
        this.__checkAttributeDefinition(20, "login");
        return this._login;
    }
    set login(login : (string | null | undefined)) {
        this._login = login;
    }

    private _password : (string | null | undefined);
    get password() : (string | null | undefined) {
        this.__checkAttributeDefinition(21, "password");
        return this._password;
    }
    set password(password : (string | null | undefined)) {
        this._password = password;
    }

    private _lastPasswordChangeTs : (number | null | undefined);
    get lastPasswordChangeTs() : (number | null | undefined) {
        this.__checkAttributeDefinition(22, "lastPasswordChangeTs");
        return this._lastPasswordChangeTs;
    }
    set lastPasswordChangeTs(lastPasswordChangeTs : (number | null | undefined)) {
        this._lastPasswordChangeTs = lastPasswordChangeTs;
    }

    private _gender : string;
    get gender() : string {
        this.__checkAttributeDefinition(23, "gender");
        return this._gender;
    }
    set gender(gender : string) {
        this._gender = gender;
    }

    private _question : (string | null | undefined);
    get question() : (string | null | undefined) {
        this.__checkAttributeDefinition(24, "question");
        return this._question;
    }
    set question(question : (string | null | undefined)) {
        this._question = question;
    }

    private _answerPassword : (string | null | undefined);
    get answerPassword() : (string | null | undefined) {
        this.__checkAttributeDefinition(25, "answerPassword");
        return this._answerPassword;
    }
    set answerPassword(answerPassword : (string | null | undefined)) {
        this._answerPassword = answerPassword;
    }

    private _status : number;
    get status() : number {
        this.__checkAttributeDefinition(26, "status");
        return this._status;
    }
    set status(status : number) {
        this._status = status;
    }


    public static constructPartialInstance() : Person {
        let instance = new Person();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : Person {
        let instance = new Person();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : Person) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.commonName, other.commonName, "String")
            && DtoHelper.areMembersEqual(this.givenName, other.givenName, "String")
            && DtoHelper.areMembersEqual(this.surName, other.surName, "String")
            && DtoHelper.areMembersEqual(this.title, other.title, "String")
            && DtoHelper.areMembersEqual(this.roleId, other.roleId, "Long")
            && DtoHelper.areMembersEqual(this.ztPermission, other.ztPermission, "Boolean")
            && DtoHelper.areMembersEqual(this.login, other.login, "String")
            && DtoHelper.areMembersEqual(this.password, other.password, "String")
            && DtoHelper.areMembersEqual(this.lastPasswordChangeTs, other.lastPasswordChangeTs, "Long")
            && DtoHelper.areMembersEqual(this.gender, other.gender, "String")
            && DtoHelper.areMembersEqual(this.question, other.question, "String")
            && DtoHelper.areMembersEqual(this.answerPassword, other.answerPassword, "String")
            && DtoHelper.areMembersEqual(this.status, other.status, "Integer")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            commonName: this._commonName,
            givenName: this._givenName,
            surName: this._surName,
            title: this._title,
            roleId: this._roleId,
            ztPermission: this._ztPermission,
            login: this._login,
            password: this._password,
            lastPasswordChangeTs: this._lastPasswordChangeTs,
            gender: this._gender,
            question: this._question,
            answerPassword: this._answerPassword,
            status: this._status,
        };
    }
}
