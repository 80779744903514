import { PositionResultAttachmentMap } from "../../cdes-api/dto/PositionResultAttachmentMap";

export class IReviewNodePositionAttachmentJoin {

    // positionResultAttachmentMap : PositionResultAttachmentMap
    positionResultAttachmentMapResultId : number;


    // positionResultAttachmentMap : PositionResultAttachmentMap
    public getPositionResultAttachmentMap() : PositionResultAttachmentMap {
        const positionResultAttachmentMap : PositionResultAttachmentMap = PositionResultAttachmentMap.constructTrackedPartialInstance([true, false]);
        positionResultAttachmentMap.resultId = this.positionResultAttachmentMapResultId;
        return positionResultAttachmentMap;
    }
    public setPositionResultAttachmentMap(positionResultAttachmentMap : PositionResultAttachmentMap) : void {
        this.positionResultAttachmentMapResultId = positionResultAttachmentMap.resultId;
    }

}
