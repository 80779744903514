/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import { PlotOrderTemplateInfo } from "cdes-api/dto/plot/PlotOrderTemplateInfo";
import DeliveryTargetOutput from "cdes-vue/plot/template/DeliveryTargetOutput.vue";
import { PlotOrderTemplateHelper } from "cdes-vue/plot/template/PlotOrderTemplateHelper";
import AddressOutput from "cdes-vue/util/output/AddressOutput.vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { PlotTemplateResolver } from "cdes-vue/voc/plot/PlotTemplateResolver";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components: {
        AddressOutput,
        DeliveryTargetOutput,
        ParempiGrid
    },

    props: {
        plotOrderTemplateInfos: {
            type: Array as PropType<PlotOrderTemplateInfo[]>,
            default: () => [],
        },
        mayEditTemplate : {
            type : Boolean
        }
    },

    emits: [ "removePlotOrderTemplate" ],

    computed: {
        columns() : ParempiColumn<PlotOrderTemplateInfo>[] {
            return [
                ParempiColumn.asRowSpanSlot("name", this.$t("general.name"), "name"),
                ParempiColumn.asRowSpanSlot("templateResolver", this.$t("general.type"), "templateResolver"),
                ParempiColumn.asRowSpanSlot("plotter", this.$t("plot.template.prop.plotter"), "plotter"),
                ParempiColumn.asRowSpanSlot("billingAddress", this.$t("plot.template.prop.billingAddress"), "billingAddress"),
                ParempiColumn.asRowSpanSlot("itemCount", this.$t("plot.template.list.itemCount"), "itemCount"),
                ParempiColumn.asRowSpanSlot("deliveryAddress", this.$t("plot.template.list.deliveryAddress"), "deliveryAddress"),
                ParempiColumn.asRowSpanSlot("description", this.$t("general.description"), "description"),
                ParempiColumn.asRowSpanSlot("actions", this.$t("general.column.actions"), "actions"),
            ];
        }
    },

    methods : {
        getPlotOrderTemplateLabel(item : PlotOrderTemplateInfo) : string {
            return PlotOrderTemplateHelper.getTemplateResolverLabel(this.$t, item.templateResolver);
        },
        editPlotOrderTemplate(item : PlotOrderTemplateInfo): void {
            this.$router.push({ name: "plotOrderTemplateEdit", params: { id : "" + item.id } });
        },

        deletePlotOrderTemplate(item : PlotOrderTemplateInfo) : void {
            let templateName = item.name;
            if (window.confirm(this.$t("plot.template.list.reallyDeleteQuestion", {
                name : templateName
            }))) {
                let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
                let plotOrderTemplateId : number = item.id;
                this.ctx.plotService.mayDeletePlotOrderTemplate(organisationPersonId, plotOrderTemplateId)
                    .then((message) => {
                        if (message != null) {
                            window.alert(message);
                        } else {
                            this.ctx.plotService.invalidatePlotOrderTemplate(organisationPersonId, plotOrderTemplateId)
                                .then(() => {
                                    this.$emit("removePlotOrderTemplate", plotOrderTemplateId);
                                }, err => {
                                    ErrorHelper.processError(this.$t, this.$d, err);
                                });
                        }
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err);
                    });
            }
        }
    }
});
