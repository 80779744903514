/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Task } from "cdes-api/dto/task/Task";
import { TaskSearchModel } from "cdes-api/dto/task/TaskSearchModel";
import ReviewTaskSearchWidgetVue from "cdes-vue/review/task/ReviewTaskSearchWidget.vue";
import ReviewTaskListWidgetVue from "cdes-vue/review/task/ReviewTaskListWidget.vue";
import { TinyLog } from "clazzes-core/log/TinyLog";
import { TaskSearchMode } from "cdes-vue/voc/task/TaskSearchMode";

import { JobHelper } from "clazzes-core/util/JobHelper";
import { IJobStatusService } from "clazzes-core/svc/IJobStatusService";

import { defineComponent, PropType } from "vue";
import JobStatusDTO from "cdes-api/dto/job/JobStatusDTO";
import { TaskCounts } from "cdes-api/dto/task/TaskCounts";
import PasswordDialog from "cdes-vue/util/layout/PasswordDialog.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ReviewTaskPageInfo } from "cdes-api/dto/task/ReviewTaskPageInfo";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";
import ReviewTaskSearchWidget from "./ReviewTaskSearchWidget";

const log = new TinyLog("cdes.review.task.ReviewTaskPage");

export default defineComponent({
    name : "ReviewTaskPage",

    components : {
        PasswordDialog,
        ReviewTaskSearchWidget : ReviewTaskSearchWidgetVue,
        ReviewTaskListWidget : ReviewTaskListWidgetVue
    },

    computed: {
        mayQueryLateReviews() : boolean {
            let taskPageInfo = this.taskPageInfo as ReviewTaskPageInfo;
            return taskPageInfo != null && taskPageInfo.mayQueryLateReviews;
        },

        caption() : string {
            if (this.searchModel?.searchMode == TaskSearchMode.OWN) {
                return this.$t("task.page.myTasksTitle");
            } else if (this.searchModel?.searchMode == TaskSearchMode.BATCH) {
                return this.$t("task.page.signatureFolderTitle");
            } else if (this.searchModel?.searchMode == TaskSearchMode.LATE) {
                return this.$t("task.page.surveillanceTitle");
            } else if (this.searchModel?.searchMode == TaskSearchMode.DONE) {
                return this.$t("mainMenu.doneTaskCaption");
            } else {
                return "";
            }
        },
        mode(): TaskSearchMode {
            return this.activeSearchModel?.searchMode ?? TaskSearchMode.OWN;
        },
        isOwnMode() : boolean {
            return this.mode == TaskSearchMode.OWN;
        },
        isBatchMode() : boolean {
            return this.mode == TaskSearchMode.BATCH;
        },
        isLateMode() : boolean {
            return this.mode == TaskSearchMode.LATE;
        },
        isDoneMode() : boolean {
            return this.mode == TaskSearchMode.DONE;
        },
        asyncRunning() : boolean {
            return this.isGetReviewTasksRunning || this.isSignRunning;
        },
        selectedTasks() : Task[] {
            let selectedTasks : Task[] = [];
            for (let n = 0; n < this.tasks.length; n++) {
                let task : Task = this.tasks[n] as Task;
                if (task.selected) {
                    selectedTasks.push(task);
                }
            }
            return selectedTasks;
        },
        countLabelString() : string {
			let returnString = "";
			if (this.tasks != null && !this.isGetReviewTasksRunning){
				returnString = this.$t('task.list.taskCount', {count: this.tasks.length, total: (this.taskCounts != null && (this.isOwnMode ? 
                  this.taskCounts.ownTaskCount : this.isLateMode ? this.taskCounts.lateTaskCount : this.isBatchMode ? this.taskCounts.batchTaskCount :this.isDoneMode ? this.taskCounts.doneTaskCount:'-'))});
			} else if (this.isGetReviewTasksRunning){
				returnString = this.$t("general.searchRuns");
			}
            return returnString;
        }
    },

    activated() {
        console.info("activated [" + this.ctx.taskAborted + "]");

        if (!this.ctx.taskAborted) {
            this.doSearch(this.searchModel);
            this.ctx.taskService.getReviewTaskPageInfo(this.ctx.activeOrganisationPersonId)
                .then((taskPageInfo : ReviewTaskPageInfo) => {
                    this.taskPageInfo = taskPageInfo;

                    this.ctx.reducedIsolationTaskService.getTaskCounts(this.ctx.activeOrganisationPersonId)
                        .then((taskCounts : TaskCounts) => {
                            this.taskCounts = taskCounts;
                        }, err => {
                            ErrorHelper.processError(this.$t, this.$d, err, "general.error.longRunningMessage");
                        });
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        }

        this.ctx.taskAborted = false;        
    },

    mounted() {
        console.info("mounted called with principal [" + this.ctx.principal + "]");

        let searchWidget : (typeof ReviewTaskSearchWidget) = this.$refs.searchWidget as (typeof ReviewTaskSearchWidget);
        searchWidget.setSearchModelFromLocalStorage();

        this.ctx.taskService.getReviewTaskPageInfo(this.ctx.activeOrganisationPersonId)
            .then((taskPageInfo : ReviewTaskPageInfo) => {
                this.taskPageInfo = taskPageInfo;

                this.ctx.reducedIsolationTaskService.getTaskCounts(this.ctx.activeOrganisationPersonId)
                    .then((taskCounts : TaskCounts) => {
                        this.taskCounts = taskCounts;
                    }, err => {
                        ErrorHelper.processError(this.$t, this.$d, err, "general.error.longRunningMessage");
                    });                
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        this.ctx.taskAborted = false;        
    },

    data() {
        return {
            tasks : [],
            activeSearchModel: undefined as (undefined | TaskSearchModel),
            searchModel: undefined as (undefined | TaskSearchModel),
            quickFilter: null as (null | string),
            isGetReviewTasksRunning : false,
            isSignRunning : false,
            loadingPdf : false,
            loadingXls : false,
            taskCounts : null,
            taskPageInfo : null,
            LocalStorageHelper : LocalStorageHelper,
            initialized : false
        };
    },

    methods : {
        doSearch(searchModel : TaskSearchModel) : void {
            let searchWidget : (typeof ReviewTaskSearchWidget) = this.$refs.searchWidget as (typeof ReviewTaskSearchWidget);
            searchModel.filterByDate = searchWidget.getFilterByDate();
            LocalStorageHelper.storeInLocalStorage([ this.ctx.principal ], "/task/search", searchModel);

            console.info("Stored searchModel with searchMode [" + searchModel.searchMode + "] in local storage, for principal ["
                + this.ctx.principal + "]");

            this.isGetReviewTasksRunning = true;
            this.tasks = [];
            this.ctx.reducedIsolationTaskService.getReviewTasks(this.ctx.activeOrganisationPersonId, searchModel).then((tasks : Task[]) => {
                this.activeSearchModel = searchModel;
                this.tasks = tasks;
                this.isGetReviewTasksRunning = false;

                // Activate watchers in ReviewTaskSearchModel.  Deactivated before, to avoid triggering duplicate
                // executions of doSearch during initialization based on local storage.
                this.initialized = true;
            }, (err) => {
                ErrorHelper.processError(this.$t, this.$d, err, "general.error.longRunningMessage");
                this.isGetReviewTasksRunning = false;                
            });
        },
        reloadTaskCounts() : void {
            this.ctx.reducedIsolationTaskService.getTaskCounts(this.ctx.activeOrganisationPersonId)
                .then((taskCounts : TaskCounts) => {
                    this.taskCounts = taskCounts;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err, "general.error.longRunningMessage");
                });                
        },
        showMyTasks() : void {
            this.searchModel = {
                ...this.searchModel,
                searchMode: TaskSearchMode.OWN,
            };
            console.info("After showMyTasks: ");
            console.info(this.searchModel);
        },
        showSignatureFolder() : void {
            this.searchModel = {
                ...this.searchModel,
                searchMode: TaskSearchMode.BATCH,
            };            
        },
        showBelatedTasks() : void {
            this.searchModel = {
                ...this.searchModel,
                searchMode: TaskSearchMode.LATE,
            };                        
        },
        toSignatureFolder(task: Task): void {
            this.searchModel = {
                ...this.searchModel,
                searchMode: TaskSearchMode.BATCH,
            };

        },
        showDoneTasks() : void {
            this.searchModel = {
                ...this.searchModel,
                searchMode: TaskSearchMode.DONE,
            };                        
        },
        exportToPdf() : void {
            let columnToWidth : Map<string, number> = new Map<string, number>();
            columnToWidth.set("name", 1.0);
            columnToWidth.set("document", 1.0);
            columnToWidth.set("responsible", 1.0);
            columnToWidth.set("object", 1.0);
            columnToWidth.set("project", 1.0);
            columnToWidth.set("network", 1.0);
            columnToWidth.set("uploaded", 1.0);
            columnToWidth.set("taskStatus", 1.0);
            columnToWidth.set("doneDate", 1.0);
            columnToWidth.set("doneResult", 1.0);
            columnToWidth.set("reviewStatus", 1.0);
            columnToWidth.set("reviewDate", 1.0);
            columnToWidth.set("reviewResult", 1.0);

            let listWidget : (typeof ReviewTaskListWidgetVue) = this.$refs.listWidget as (typeof ReviewTaskListWidgetVue);
            let taskIdsAfterQuickFilter : Set<number> = listWidget.getTaskIdsAfterQuickFilter();
            
            this.loadingPdf = true;
            this.ctx.taskService.triggerExportReviewTaskListPdf(this.ctx.activeOrganisationPersonId, this.searchModel,
                                                                columnToWidth, [], taskIdsAfterQuickFilter, this.quickFilter)
                .then((jobId : string) => {
                    JobHelper.registerJobStatusQueryForDownload({
                        jobStatusService : this.ctx.jobStatusService as unknown as IJobStatusService,
                        jobId : jobId,
                        doneCallback : (status : JobStatusDTO) => {
                            this.loadingPdf = false;
                        },
                        downloadUrlGetter : (jobId : string) => {
                            let params = new URLSearchParams({
                                jobId : jobId
                            }); 
                            return "/cdes-dojo-impl/download?" + params.toString();
                        }
                    });
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },
        exportToXls() : void {
            this.loadingXls = true;
            this.ctx.taskService.triggerExportReviewTaskListXls(this.ctx.activeOrganisationPersonId, this.searchModel,
                                                                new Map<string, number>(), [])
                .then((jobId : string) => {
                    JobHelper.registerJobStatusQueryForDownload({
                        jobStatusService : this.ctx.jobStatusService as unknown as IJobStatusService,
                        jobId : jobId,
                        doneCallback : (status : JobStatusDTO) => {
                            this.loadingXls = false;
                        },
                        downloadUrlGetter : (jobId : string) => {
                            let params = new URLSearchParams({
                                jobId : jobId
                            }); 
                            return "/cdes-dojo-impl/download?" + params.toString();
                        }
                    });                
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });            
        },

        removeTask(taskId : number) : void {
            let deleteIdx : number = null;
            for (let n = 0; n < this.tasks.length; n++) {
                let task : Task = this.tasks[n] as Task;
                if (task.taskId == taskId) {
                    deleteIdx = n;
                }
            }
            this.tasks.splice(deleteIdx, 1);
/*
            this.ctx.taskService.getTaskCounts(this.ctx.activeOrganisationPersonId)
                .then((taskCounts : TaskCounts) => {
                    this.taskCounts = taskCounts;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });*/
        },

        signSelectedTasks() : void {
            this.isSignRunning = true;
            (this.$refs.passwordDialog as InstanceType<typeof PasswordDialog>).getPassword()
                .then(password => {
                    this.isSignRunning = false;
                    if (password != null) {
                        let tasks : Task[] = this.tasks as Task[];
						let listWidget : (typeof ReviewTaskListWidgetVue) = this.$refs.listWidget as (typeof ReviewTaskListWidgetVue);
						let taskIdsAfterQuickFilter : Set<number> = listWidget.getTaskIdsAfterQuickFilter();
                        let selectedTaskIds : number[] = [];
                        for (let task of tasks) {
                            if (task.selected && taskIdsAfterQuickFilter.has(task.taskId)) {
                                selectedTaskIds.push(task.taskId);
                            }
                        }

                        return this.ctx.reviewService.signReviews(selectedTaskIds, this.ctx.activeOrganisationPersonId, password)
                            .then(() => {
                                this.doSearch(this.searchModel);
                                listWidget.resetSelectionAll();
                                this.reloadTaskCounts();
                            }, () => {
                                window.confirm(this.$t("review.review.entry.signError"));
                            });
                    } else {
                        return undefined;
                    }
                }, err => {
                    ErrorHelper.processPasswordError(this.$t, this.$d, err);
                    this.isSignRunning = false;
                });
        },

        getTaskSearchModeByString(s : string) : TaskSearchMode {
            if (s == "OWN") {
                return TaskSearchMode.OWN;
            } else if (s == "OPEN") {
                return TaskSearchMode.OPEN;
            } else if (s == "DONE") {
                return TaskSearchMode.DONE;
            } else if (s == "LATE") {
                return TaskSearchMode.LATE;
            } else if (s == "BATCH") {
                return TaskSearchMode.BATCH;
            } else {
                return null;
            }
        }
    },
});
