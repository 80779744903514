/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { PlotColor } from "cdes-api/voc/plot/PlotColor";
import { PlotDeliveryTarget } from "cdes-api/voc/plot/PlotDeliveryTarget";
import { PlotMargin } from "cdes-api/voc/plot/PlotMargin";
import { PlotPaperQuality } from "cdes-api/voc/plot/PlotPaperQuality";
import { PlotScale } from "cdes-api/voc/plot/PlotScale";
import { PlottProperties } from "cdes-api/dto/PlottProperties";

export class PlotOrderItemTemplateInfo {
    id : number;
    deliveryAddressId : number;
    invalidated : boolean;
    evaluateMailReceivers : boolean;
    target : PlotDeliveryTarget;
    organisationName : string;
    personGivenName : string;
    personSurName : string;
    reviewCycleNodeName : string;
    reviewCycleName : string;

    plotPropertiesId : number;
    plotPropertiesCount : number;
    plotPropertiesIsInColor : PlotColor;
    plotPropertiesMargin : PlotMargin;
    plotPropertiesPaperQuality : PlotPaperQuality;
    plotPropertiesMirror : boolean;
    plotPropertiesScale : PlotScale;
    plotPropertiesLlx : number;
    plotPropertiesLly : number;
    plotPropertiesUrx : number;
    plotPropertiesUry : number;
    plotPropertiesUserUnit : number;
}
