/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, provide, InjectionKey, toRefs, toRef } from "vue";
import FormGrid, { Breakpoint, formModeKey, gutterKey, columnsKey, singleColumnBreakpointKey, verticalBreakpointKey, labelWidthKey } from "cdes-vue/util/form/FormGrid.vue";
import { FormMode } from "cdes-vue/voc/gui/FormMode";

export default defineComponent({
    props: {
        columns: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
        singleColumnBreakpoint: {
            type: String as PropType<Breakpoint | null>,
            default: () => null,
        },
        verticalBreakpoint: {
            type: String as PropType<Breakpoint | null>,
            default: () => null,
        },
        gutter: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
        labelWidth: {
            type: Number as PropType<number | null>,
            default: () => null,
        },
    },

    setup(props) {
        const refProps = toRefs(props);
        provide(formModeKey, FormMode.FORM_GRID);
        if (props.gutter != null) {
            provide(gutterKey, refProps.gutter);
        }
        if (props.columns != null) {
            provide(columnsKey, refProps.columns);
        }
        if (props.singleColumnBreakpoint != null) {
            provide(singleColumnBreakpointKey, refProps.singleColumnBreakpoint);
        }
        if (props.verticalBreakpoint != null) {
            provide(verticalBreakpointKey, refProps.verticalBreakpoint);
        }
        if (props.labelWidth != null) {
            provide(labelWidthKey, refProps.labelWidth);
        }
    }
});
