/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

export default {
    mounted(el, { arg, value }: { arg?: string, value: object | ((e: Event) => void) }) {
        if (!(el instanceof HTMLIFrameElement)) {
            throw new Error("");
        }
        if (arg == null) {
            if (typeof value === "function") {
                throw new Error();
            }
            el.addEventListener("load", () => {
                for (const [name, func] of Object.entries(value)) {
                    el.contentDocument.addEventListener(name, e => {
                        func?.(e);
                    });
                }
            });
        } else {
            if (typeof value !== "function") {
                throw new Error();
            }
            el.addEventListener("load", () => {
                el.contentDocument.addEventListener(arg, e => {
                    value?.(e);
                });
            });
        }
    },
};
