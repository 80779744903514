import { defineComponent } from "vue";
import ProvideOriginalPageListWidget from "./ProvideOriginalPageListWidget.vue";
import { OriginalDocumentOrderSearchModel } from 'cdes-api/dto/original/OriginalDocumentOrderSearchModel';
import { OriginalDocumentOrderInfo } from 'cdes-api/dto/original/OriginalDocumentOrderInfo';
import PasswordDialog from "cdes-vue/util/layout/PasswordDialog.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components: {
        ProvideOriginalPageListWidget: ProvideOriginalPageListWidget,
        PasswordDialog : PasswordDialog
    },
    props: {
        originalDocumentOrderId: undefined,
        taskId : null
    },
    data() {
        return {
            infos: [] as OriginalDocumentOrderInfo[],
/*            accepted : false,
            denied : false,*/
            result : null,
            comment: "",
            password: "",
            file : null,
            fetchRunning : false,
            signRunning : false
        };
    },
    watch : {
        accepted(newAccepted : boolean) {
            console.info("accepted: [" + newAccepted + "]");
        }
    },

    computed : {
        acceptedForServer() : boolean {
            if (this.result == "denied") {
                return false;
            } else if (this.result == "accepted") {
                return true;
            } else {
                return null;
            }
        },
        disabled() : boolean {
            return this.fetchRunning || this.signRunning;
        },

        signDisabled() : boolean {
            return this.result == null || (this.file == null && this.result == 'accepted') || this.password == null
                || this.password.trim().length == 0 || this.disabled;
        },

        fileCss() : string {
            return !this.disabled && this.file == null ? "mustField" : "";
        },

        radioCss() : string {
            return !this.disabled && this.result == null ? "mustField" : "";
        },

        passwordCss() : string {
            return !this.disabled && (this.password == null || this.password.trim().length == 0) ? "mustField" : "";
        }
    },
    mounted() {
        let searchModel: OriginalDocumentOrderSearchModel = {
            organisationPersonId: null,
            subProjectId: null,
            originalDocumentOrderId: this.originalDocumentOrderId
        }
        this.fetchRunning = true;
        this.ctx.documentService.getOriginalDocumentOrders(this.ctx.activeOrganisationPersonId, searchModel).then(infos => {
            this.fetchRunning = false;
            this.infos = infos;
        }, err => {
            ErrorHelper.processError(this.$t, this.$d, err);
            this.fetchRunning = false;
        });
    },
    methods: {
        abort() {
            this.ctx.taskAborted = true;
            this.ctx.editedTaskId = this.taskId;            
            this.$router.go(-1);            
        },

        sign() {
            this.signRunning = true;
            if (this.result == 'accepted' && this.file != null){
                this.file.arrayBuffer().then(buffer => {
                    // @ts-ignore
                    return this.ctx.documentService.signProvideOriginalDocumentOrder(this.ctx.activeOrganisationPersonId,
                                                                                        this.password,
                                                                                        this.comment,
                                                                                        this.originalDocumentOrderId,
                                                                                        this.acceptedForServer,
                                                                                        new Uint8Array(buffer),
                                                                                        this.file.name)
                }).then(() => {
                    this.signRunning = false;
                    this.ctx.editedTaskId = this.taskId;            
                    this.$router.go(-1);
                }, err => {
                    ErrorHelper.processPasswordError(this.$t, this.$d, err);
                    this.signRunning = false;                    
                });

            } else if (this.result == 'denied'){
                this.ctx.documentService.signProvideOriginalDocumentOrder(this.ctx.activeOrganisationPersonId,
                                                                                        this.password,
                                                                                        this.comment,
                                                                                        this.originalDocumentOrderId,
                                                                                        this.acceptedForServer,
                                                                                        null,
                                                                                        null)
                .then(() => {
                    this.signRunning = false;
                    this.ctx.editedTaskId = this.taskId;            
                    this.$router.go(-1);
                }, err => {
                    ErrorHelper.processPasswordError(this.$t, this.$d, err);
                    this.signRunning = false;                    
                });

            }
         }
    }
});
