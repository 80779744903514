/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, Ref, ref, getCurrentInstance, computed } from "vue";
import { loadingArray, loadingPlaceholder, LoadingPlaceholder, mapLoading, loadedOrDefault } from "cdes-vue/util/Promise";
import { asyncEagerComputed } from "cdes-vue/util/Prop";
import { ReviewCycleCell } from "cdes-api/dto/ReviewCycleCell";
import { ReviewCycleCellConnection } from "cdes-api/dto/ReviewCycleCellConnection";
import { DestinationConnectionJoin } from "cdes-api/joinDto/DestinationConnectionJoin";
import { useCtx } from "cdes-vue/util/Ctx";
import Transform from "cdes-vue/util/Transform";
import { useValidationForm } from "cdes-vue/util/directives/ValidationForm";
import { ReviewCycleStatementSaveInfo } from "cdes-api/dto/document/ReviewCycleStatementSaveInfo";
import { PlanUploadCommentSaveInfo } from "cdes-api/dto/document/PlanUploadCommentSaveInfo";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import FormGrid from "cdes-vue/util/form/FormGrid.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import UploadWithPreview from "./UploadWithPreview.vue";
import ShowReviewMatrix from "./ShowReviewMatrix.vue";
import EditDocumentReferences from "./EditDocumentReferences.vue";
import PasswordDialog from "cdes-vue/util/layout/PasswordDialog.vue";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import ManualNotFoundResolution from "cdes-api/dto/document/ManualNotFoundResolution";
import EditStatements from "./EditStatements.vue";
import EditCommentListWidget from "./EditCommentListWidget.vue";
import { DocumentReference } from "generated/cdes-api/dto/DocumentReference";
import { DocumentUploadInfo } from "cdes-api/dto/document/DocumentUploadInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ContextJoin } from "cdes-api/joinDto/ContextJoin";
import { LabelStatus } from "../../../../../cdes-ts-api/src/cdes-api/dto/document/LabelStatus";

interface RenderedDestination {
    id: number;
    connectionId: number,
    cellId: number,
    name: string,
    isReupload: boolean,
}

export default defineComponent({
    components: {
        FormGrid,
        FormGridColumn,
        FormGridMember,
        LoadedOrPlaceholder,
        UploadWithPreview,
        ShowReviewMatrix,
        EditDocumentReferences,
        Transform,
        PasswordDialog,
        Dialog,
        EditStatements,
        EditCommentListWidget
    },

    props: {
        documentId: {
            type: Number as PropType<number>,
        },
        documentVersionId: {
            type: Number as PropType<number>,
        },
        taskId: {
            type: Number as PropType<number>,
        },
    },

    data() {
        return {
            reviewMatrixHidden: false,
            statementsHidden: false,
            commentsHidden: false,
            temporaryDocumentVersionId: null as (string | LoadingPlaceholder | null),
            comment: "",
            notFoundResolution: null as (LoadingPlaceholder | ManualNotFoundResolution | null),
            labelStatus : null,
            statements: [] as ReviewCycleStatementSaveInfo[],
            comments: [] as PlanUploadCommentSaveInfo[],
            attachedReviewsExpanded : false,
            documentReferenceIdsToDelete : new Set<number>(),
            signIsRunning : false,
            fileWasUploaded : false,
            selectedRotation : null
        };
    },

    setup(props, context) {
        const ctx = useCtx();
        const selectedDestination: Ref<RenderedDestination | null> = ref(null);
        const uploadWithPreview = ref<null | typeof UploadWithPreview>(null);
        const inst = getCurrentInstance().proxy;

        const {
            transformer: formTransformer,
            wasValidated,
        } = useValidationForm({
            // @ts-ignore
            onSubmit: (event) => {
                // @ts-ignore
                inst.sign();
                event.preventDefault();
            },
        });

        let organisationPersonId : number = ctx.activeOrganisationPersonId;                        

        return {
            formTransformer,
            wasValidated,
            loadingArray,
            loadingPlaceholder,
            mapLoading,
            loadedOrDefault,
            selectedDestination,
            reviewCycleCellId: computed(() => selectedDestination.value?.cellId),
            destinationId: computed(() => selectedDestination.value?.id),
            destinationType: computed(() => {
                const selectedDestinationValue = selectedDestination.value;
                if (selectedDestinationValue == null) {
                    return selectedDestinationValue;
                } else if (selectedDestinationValue.connectionId != null) {
                    return "connection";
                } else {
                    return "cell";
                }
            }),
            uploadWithPreview,
            destinations: asyncEagerComputed<DestinationConnectionJoin[] | ReviewCycleCell[], LoadingPlaceholder>(() => {
                if (props.documentVersionId != null) {
                    return ctx.documentService.getDestinationConnections(organisationPersonId, props.documentVersionId);
                } else {
                    return ctx.documentService.getDestinations(organisationPersonId, props.documentId, ctx.activeSubProjectId);
                }
            }, loadingPlaceholder),
            uploadInfo: asyncEagerComputed(() => {
                if (props.documentVersionId != null) {
                    let result : Promise<DocumentUploadInfo>
                        = ctx.documentService.getUploadInfoForVersion(organisationPersonId, props.documentVersionId,
                                                                      props.taskId, selectedDestination.value?.cellId,
                                                                      selectedDestination.value?.connectionId);
                    result.then((uploadInfo : DocumentUploadInfo) => {
                        let contextJoin : ContextJoin = uploadInfo.contextJoin;
                        ctx.activeNetworkId = contextJoin.networkId;
                        ctx.activeProjectId = contextJoin.projectId;
                        ctx.activeSubProjectId = contextJoin.subProjectId;                        
                    });
                    return result;
                } else {
                    let result : Promise<DocumentUploadInfo>
                        = ctx.documentService.getUploadInfo(organisationPersonId, props.documentId,
                                                            props.taskId, selectedDestination.value?.cellId);
                    result.then((uploadInfo : DocumentUploadInfo) => {
                        let contextJoin : ContextJoin = uploadInfo.contextJoin;
                        ctx.activeNetworkId = contextJoin.networkId;
                        ctx.activeProjectId = contextJoin.projectId;
                        ctx.activeSubProjectId = contextJoin.subProjectId;                                                
                    });
                    return result;
                }
            }, loadingPlaceholder),
        };
    },

    watch : {
        labelStatus(newLabelStatus : LabelStatus) {
            console.info("New labelStatus: [" + newLabelStatus + "]");
        }
    },

    computed: {
        disabled() : boolean {
            return this.signIsRunning /*|| this.uploadInfo == loadingPlaceholder || this.destinations == loadingPlaceholder*/;
        },
        
        labelNotFoundOrUnknown() : boolean {
            return !this.labelStatus || [LabelStatus.UNKNOWN, LabelStatus.NOT_FOUND].includes(this.labelStatus)
        },

        renderedDestinations(): RenderedDestination[] | LoadingPlaceholder[]  {
            return loadingArray(this.destinations)
            .map(destination => {
                if (destination === loadingPlaceholder) {
                    return destination;
                }
                if ("reviewCycleCellConnectionId" in destination) {
                    return {
                        id: destination.reviewCycleCellConnectionId,
                        connectionId: destination.reviewCycleCellConnectionId,
                        cellId: destination.reviewCycleCellId,
                        name: destination.reviewCycleCellName,
                        isReupload: destination.reviewCycleCellConnectionSourceCellId === destination.reviewCycleCellConnectionDestinationCellId,
                    };
                } else {
                    return {
                        id: destination.id,
                        connectionId: null,
                        cellId: destination.id,
                        name: destination.name,
                        isReupload: false,
                    };
                }
            });
        },
    },

    methods: {
		
		checkValueA(event) {
			const value = event.target.value;
			if (value.length>2)
				alert("Versionscode zu lang!");
		},
		checkValueB(event) {
			const value = event.target.value;
			if (isNaN(parseInt(value)))
				alert("Keine gültige Versionsnummer!");
			if (value > 99)
				alert("Versionsnummer kann max. 99 sein!");
		},

        processFileUploaded() {
            this.fileWasUploaded = true;
        },

        abort() {
            this.ctx.taskAborted = true;
            this.ctx.editedTaskId = this.taskId;                                                
            this.$router.go(-1);
        },

        registerDocumentReferenceIdToDelete(documentReferenceId : number) : void {
            let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
            let deleteIdx : number = null;
            for (let n = 0; n < uploadInfo.documentReferences.length; n++) {
                if (uploadInfo.documentReferences[n].id == documentReferenceId) {
                    deleteIdx = n;
                }
            }
            if (deleteIdx != null) {
                uploadInfo.documentReferences.splice(deleteIdx, 1);
                let c = uploadInfo.documentReferences;
                uploadInfo.documentReferences = c;
            }
            
            console.info("registerDocumentReferenceIdToDelete: [" + documentReferenceId + "]");
            let idsToDelete : Set<number> = this.documentReferenceIdsToDelete as Set<number>;
            idsToDelete.add(documentReferenceId);
        },
        save() {
            const temporaryDocumentVersionId = this.temporaryDocumentVersionId;
            // typeof destinationId !== "number" here not relevant? See below.
            if (temporaryDocumentVersionId === loadingPlaceholder) { 
                return;
            }

            this.ctx.reviewService.saveReviewCycleCellChangeComment(this.ctx.activeOrganisationPersonId,
                                                                    this.taskId,
                                                                    this.comments)
                .then(() => {
                    console.info("saveReviewCycleCellChangeComment completed successfully.");
                }, (err) => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },

        sign() {
            let showReviewMatrix : (typeof ShowReviewMatrix) = this.$refs.showReviewMatrix as (typeof ShowReviewMatrix);
            let realmIdToAttachedParticipationIdToSelected : Map<number, Map<number, boolean>>
                = showReviewMatrix != null ? showReviewMatrix.getAttachedMatrix() : new Map<number, Map<number, boolean>>();

            const destinationId = this.destinationId;
            const temporaryDocumentVersionId = this.temporaryDocumentVersionId;
            if (temporaryDocumentVersionId === loadingPlaceholder || typeof destinationId !== "number") {
                return;
            }
			let selectedRotation = this.selectedRotation;
			
			let uploadInfo : DocumentUploadInfo = this.uploadInfo as DocumentUploadInfo;
			let versionPartA = uploadInfo.versionPartA;
			let versionPartB = uploadInfo.versionPartB;
			
            (this.$refs.passwordDialog as typeof PasswordDialog).getPassword()
            .then((password: string | null) => {
                if (password != null) {
                    this.ctx.documentService.isTemporaryDocumentVersionComplete(this.ctx.activeOrganisationPersonId,
                                                                                temporaryDocumentVersionId)
                        .then((isComplete) => {
                            if (isComplete) {
                                this.signIsRunning = true;
                                return this.ctx.reviewService.signUpload(temporaryDocumentVersionId,
                                                                         this.documentReferenceIdsToDelete as Set<number>,
                                                                         this.ctx.activeOrganisationPersonId, destinationId, this.comment,
                                                                         password, selectedRotation, versionPartA, versionPartB, this.statements,
                                                                         this.comments,
                                                                         realmIdToAttachedParticipationIdToSelected)
                                    .then(messages => {
                                        this.signIsRunning = false;
                                        this.ctx.editedTaskId = this.taskId;
                                        this.$router.back();
                                    }, err => {
                                        ErrorHelper.processError(this.$t, this.$d, err);
                                        this.signIsRunning = false;
                                    });
                            } else {
                                window.alert(this.$t("review.upload.page.notYetUploadedWarning"));
                                return null;
                            }
                        }, (err) => {
                            ErrorHelper.processError(this.$t, this.$d, err);                            
                        });
                } else {
                    return null;
                }
            }, err => {
                ErrorHelper.processPasswordError(this.$t, this.$d, err);
            });
        },
        toggleAttachedReviewCollapsed() : void {
            this.attachedReviewsExpanded = !this.attachedReviewsExpanded;
        },
    },
});
