import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id", "aria-expanded", "tabindex", "aria-selected", "aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeItem = _resolveComponent("TreeItem", true)!

  return (_openBlock(), _createElementBlock("li", {
    id: _ctx.id,
    role: "treeitem",
    "aria-expanded": this.leafOrLoading ? undefined : this.expanded,
    class: _normalizeClass([
            'cdesTreeItem',
            {
                'cdesTreeItemGroup': !this.leafOrLoading,
                'cdesTreeItemLeaf': this.leaf,
                'cdesTreeItemLoading': this.itemLoading,
                'cdesTreeItemExpanded': !this.leafOrLoading && _ctx.expanded,
                'cdesTreeItemCollapsed': !this.leafOrLoading && !_ctx.expanded,
            },
            _ctx.additionalClasses,
        ]),
    ref: "focus",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {_ctx.focusThis(); _ctx.activate()}, ["prevent","stop"])),
    onKeydown: [
      _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.next()), ["stop","prevent"]), ["arrow-down"])),
      _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.prev()), ["stop","prevent"]), ["arrow-up"])),
      _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.up()), ["stop","prevent"]), ["arrow-left"])),
      _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.down()), ["stop","prevent"]), ["arrow-right"])),
      _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.activate()), ["stop","prevent"]), ["enter"])),
      _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (_ctx.expandSiblings()), ["stop","prevent"]), ["*"]))
    ],
    tabindex: _ctx.focusable ? _ctx.tabindex : undefined,
    style: _normalizeStyle({ '--level': _ctx.level }),
    "aria-selected": _ctx.selected,
    "aria-disabled": _ctx.item.disabled
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(['cdesTreeItemLabel', _ctx.additionalLabelClasses])
    }, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(_ctx.renderInfo)))
    ], 2),
    (!_ctx.leafOrLoading)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          role: "group",
          class: _normalizeClass(["cdesTreeItemGroupSubtree", { 'd-none': !_ctx.expanded }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child, index) => {
            return (_openBlock(), _createBlock(_component_TreeItem, {
              item: child,
              level: _ctx.level + 1,
              key: index,
              ref: ((ref) => (ref != null && (_ctx.children[index].ref = ref)))
            }, {
              default: _withCtx((props) => [
                _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(props)))
              ]),
              _: 2
            }, 1032, ["item", "level"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 46, _hoisted_1))
}