/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

export enum PlotScale {
    NONE = "NONE",
    A0 = "A0",
    A1 = "A1",
    A2 = "A2",
    A3 = "A3",
    A4 = "A4"
}
