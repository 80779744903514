/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

// Tapestry: SubProjectAdministration

import SubProjectListWidgetVue from "cdes-vue/subProject/SubProjectListWidget.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components : {
        SubProjectListWidget : SubProjectListWidgetVue
    },

    computed : {
    },

    methods : {
    }
});
