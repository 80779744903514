import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row align-items-center"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "title", "disabled"]
const _hoisted_4 = ["id", "title", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (this.label != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          class: _normalizeClass(_ctx.computedLabelCss),
          for: this.id != null ? this.id : _ctx.$id('dateInput')
        }, _toDisplayString(this.label), 11, _hoisted_2),
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(_ctx.computedInputCss),
          type: "date",
          id: this.id != null ? this.id : _ctx.$id('dateInput'),
          title: this.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateString) = $event)),
          disabled: _ctx.disabled,
          ref: "input"
        }, null, 10, _hoisted_3), [
          [_vModelText, _ctx.dateString]
        ])
      ]))
    : _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 1,
        class: _normalizeClass(_ctx.computedInputCss),
        type: "date",
        id: this.id != null ? this.id : _ctx.$id('dateInput'),
        title: this.title,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateString) = $event)),
        disabled: _ctx.disabled,
        ref: "input"
      }, null, 10, _hoisted_4)), [
        [_vModelText, _ctx.dateString]
      ])
}