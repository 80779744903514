/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { defineComponent } from "vue";
import Select from "cdes-vue/util/form/Select.vue";
import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import Label from "cdes-vue/util/form/Label.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { Network } from "cdes-api/dto/Network";
import { Project } from "cdes-api/dto/Project";
import { SubProject } from "cdes-api/dto/SubProject";
import { OrganisationPersonJoin } from "cdes-api/joinDto/OrganisationPersonJoin";
import { OrganisationPersonHelper } from "cdes-vue/person/util/OrganisationPersonHelper";
import { ProjectStatusInt } from "cdes-vue/voc/project/ProjectStatusInt";
import { ContextLevel } from "cdes-vue/voc/ctx/ContextLevel";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components : {
        Select,
        CustomSelect,
        Label,
    },

    emits : ["contextChange"],

    data() {
        return {
            projectOptions : [],
            subProjectOptions : []
        };
    },

    computed : {
        // Used for watchers below.
        activeOrganisationPersonId() : number {
            return this.ctx.activeOrganisationPersonId;
        },

        activeNetworkId() : number {
            return this.ctx.activeNetworkId;
        },

        activeProjectId() : number {
            return this.ctx.activeProjectId;
        },

        activeSubProjectId() : number {
            return this.ctx.activeSubProjectId;
        },

        contextLevel() : ContextLevel {
            return this.ctx.contextLevel;
        },

        buekMode() : boolean {
            return this.ctx.buekMode;
        },

        // These options are based on content loaded
        // into the CdesContext right at the beginning.
        // Project and SubProjectOptions need to be filled
        // based on watchers below, as projects and sub projects
        // are loaded asynchronously as needed.
        organisationPersonOptions() : SelectOption[] {
            let organisationPersonJoins : OrganisationPersonJoin[] = this.ctx.getOwnOrganisationPersonJoins();
            return OrganisationPersonHelper.getOrganisationPersonOptionsByJoins(organisationPersonJoins);
        },

        networkOptions() : SelectOption[] {
            let networks : Network[] = this.ctx.getNetworksForOrganisationPersonId(this.ctx.activeOrganisationPersonId);
            networks = networks.sort((a : Network, b : Network) => {
                let networkNameOne : string = a.name;
                let networkNameTwo : string = b.name;
                return networkNameOne == null ? -1 : networkNameOne.localeCompare(networkNameTwo);
            });

            let networkOptions : SelectOption[] = [];
            for (let network of networks) {
                networkOptions.push({
                    label : network.name,
                    value : network.id
                });
            }
            return networkOptions;
        },

        disabledByRoute(): boolean {
            return !!this.$route.meta.fixedContext;
        },

        effectiveContextLevel(): ContextLevel {
            return this.ctx.contextLevel;
        },

        actualContextLevel(): ContextLevel {
            if (this.ctx.activeNetworkId == null) {
                return ContextLevel.NONE;
            } else if (this.ctx.activeProjectId == null) {
                return ContextLevel.NETWORK;
            } else if (this.ctx.activeSubProjectId == null) {
                return ContextLevel.PROJECT;
            } else {
                return ContextLevel.SUB_PROJECT;
            }
        },
    },

    setup() {
        return {
            ContextLevel : ContextLevel
        };
    },

    mounted() : void {
        this.ctx.getInitializePromise().then(() => {
            // Removed, initial setup is supposed to be done from activeNetworkId / activeProjectId handlers below.
            // Setting up these too, may lead to a race where maybe the request for the old activeNetworkId
            // wins erroneously.
//            this.updateProjectOptions();
//            this.updateSubProjectOptions();
        }, err => {
            ErrorHelper.processError(this.$t, this.$d, err);
        });

        this.ctx.registerCachedNetworkHandler(this.updateProjectOptions);
        this.ctx.registerCachedProjectHandler(this.updateSubProjectOptions);
    },

    watch : {
        activeOrganisationPersonId() {
            this.$emit("contextChange");
        },

        activeNetworkId() {
            this.updateProjectOptions();
            this.$emit("contextChange");
        },
        activeProjectId() {
            this.updateSubProjectOptions();
            this.$emit("contextChange");
        },
        activeSubProjectId() {
            this.$emit("contextChange");
        },
        contextLevel(newLevel : ContextLevel, oldLevel : ContextLevel) {
            if (oldLevel <= ContextLevel.NETWORK && newLevel >= ContextLevel.PROJECT) {
                this.updateProjectOptions();
            }
            if (oldLevel <= ContextLevel.PROJECT && newLevel >= ContextLevel.SUB_PROJECT) {
                this.updateSubProjectOptions();
            }
        },
        buekMode() {
            this.updateProjectOptions();
        }
    },

    methods : {
        updateProjectOptions() : void {
            let activeNetworkId : number = this.ctx.activeNetworkId;
            let activeOrganisationPersonId : number = this.ctx.activeOrganisationPersonId;
            this.ctx.getNetworkProjects(activeNetworkId, activeOrganisationPersonId, this.ctx.buekMode).then((projects : Project[]) => {
                projects = projects.sort((a : Project, b : Project) => {
                    let projectNameOne : string = (a.code ?? "") + (a.name ?? "");
                    let projectNameTwo : string = (b.code ?? "") + (b.name ?? "");
                    return projectNameOne == null ? -1 : projectNameOne.localeCompare(projectNameTwo);
                });
                let projectOptions : SelectOption[] = [];
                let buekMode : boolean = this.ctx.buekMode;
                for (let project of projects) {
                    if (   (buekMode && project.status == ProjectStatusInt.PLANNING_NOTIFICATION_PROJECT)
                        || (!buekMode && project.status == ProjectStatusInt.ACTIVE)) {
                        projectOptions.push({
                            label : '(' + project.code + ') ' + project.name,
                            value : project.id
                        });
                    }
                }
                this.projectOptions = projectOptions;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        },

        updateSubProjectOptions() : void {
            let activeProjectId : number = this.ctx.activeProjectId;
            let activeOrganisationPersonId : number = this.ctx.activeOrganisationPersonId;
            this.ctx.getProjectSubProjects(activeProjectId, activeOrganisationPersonId).then((subProjects : SubProject[]) => {
                subProjects = subProjects.sort((a : SubProject, b : SubProject) => {
                    let subProjectNameOne : string = (a.code ?? "") + (a.number ?? "") + (a.name ?? "");
                    let subProjectNameTwo : string = (b.code ?? "") + (b.number ?? "") + (b.name ?? "");
                    return subProjectNameOne == null ? -1 : subProjectNameOne.localeCompare(subProjectNameTwo);
                });
                let subProjectOptions : SelectOption[] = [];
                for (let subProject of subProjects) {
                    subProjectOptions.push({
                        label : '(' + subProject.code + (subProject.number ?? "") + ') ' + subProject.name,
                        value : subProject.id
                    });
                }
                this.subProjectOptions = subProjectOptions;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        }
    }
});
