/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { defineComponent } from "vue";

export default defineComponent({
    components : {
    },

    computed : {
    },

    methods : {
    }
});
