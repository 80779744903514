/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import Label from "cdes-vue/util/form/Label.vue";
import FormGrid from "cdes-vue/util/form/FormGrid.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { asyncEagerComputed, loadingPlaceholder, LoadingPlaceholder, loadedOrDefault, mapLoading } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import { SubProjectType } from "cdes-api/dto/SubProjectType";
import { SubProject } from "cdes-api/dto/SubProject";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { SubProjectEditInfo } from "../../../../../cdes-ts-api/src/cdes-api/dto/project/SubProjectEditInfo";
import Select from "cdes-vue/util/form/Select.vue";
import { SelectOption } from "cdes-vue/util/form/Select";

export default defineComponent({
    components: {
        Select,
        CustomSelect,
        Label,
        FormGrid,
        FormGridMember,
        FormGridColumn,
        LoadedOrPlaceholder,
    },

    props: {
        subProjectId: {
            type: Number as PropType<number | null>,
        },
        projectId: {
            type: Number as PropType<number | null>,
        },
    },

    data() {
        return {
            selectedTypeId: null as (null | number),
            name: null as (null | string),
            number: null as (null | number),
            comment: null as (null | string),
            numberValidity: {} as ValidityState,
            saveRunning : false,
            subProject : new SubProject(),
            existingCodeNumbers : null,
            types : null,
            initialized : false,
            isTypeEditable : false
        };
    },
/*
    setup(props, context) {
        const ctx = useCtx();

        const subProjectEditInfo = asyncEagerComputed(() => {
            return ctx.projectService.getSubProjectEditInfo(ctx.activeOrganisationPersonId,
                                                            props.projectId, props.subProjectId);
        }, loadingPlaceholder);

        const subProject = asyncEagerComputed(() => {
            if (props.subProjectId != null) {
                return ctx.projectService.getSubProject(props.subProjectId);
            } else {
                return Object.assign(new SubProject(), {
                    projectId: props.projectId,
                    invalidated: false,
                    withAdditionalRoleTypes: false,
                    readOnly: false,
                });
            }
        }, loadingPlaceholder);

        const types = asyncEagerComputed(() => {
            if (props.subProjectId != null) {
                return ctx.projectService.getValidSubProjectTypes(props.subProjectId)
            } else {
                return ctx.projectService.getValidSubProjectTypesByProject(props.projectId);
            }
        }, loadingPlaceholder);

        return {
            subProject : null
            existingCodeNumbers : null,
            types : null
        };
    },
*/

    mounted() {
        this.ctx.projectService.getSubProjectEditInfo(this.ctx.activeOrganisationPersonId,
                                                      this.projectId, this.subProjectId)
            .then((subProjectEditInfo : SubProjectEditInfo) => {
                this.subProject = subProjectEditInfo.subProject;
                this.types = subProjectEditInfo.subProjectTypes;
                this.existingCodeNumbers = subProjectEditInfo.existingCodeNumbers;
                this.isTypeEditable = subProjectEditInfo.isTypeEditable;
                window.setTimeout(() => {
                    this.initialized = true;
                }, 0);
            }, (err) => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
    },

    watch: {
        /*
        subProject(subProject : SubProject, prevSubProject: SubProject): void {
            if (subProject != null && prevSubProject == null) {
                this.selectedTypeId = subProject.subProjectTypeId;
                this.name = subProject.name;
                this.comment = subProject.comment;
                this.number = subProject.number;
            }
        },
        selectedTypeId(selectedTypeId : number) : void {
            this.name = "";
            for (let t of this.types) {
                if (t.id == selectedTypeId) {
                    this.name = t.name;
                }
            }
            }*/

        selectedType(newSelectedType : SubProjectType) {
            if (this.initialized) {
                this.subProject.code = newSelectedType.code;
                this.subProject.name = newSelectedType.name;
            }
        }
    },

    computed: {

        selectedType() : SubProjectType {
            if (this.types != null) {
                for (let t of this.types) {
                    if (t.id == this.subProject.subProjectTypeId) {
                        return t;
                    }
                }
            }

            return null;
        },

        code() : string {
            return this.selectedType != null ? this.selectedType.code : null;
        },

        typeOptions() : SelectOption[] {
            if (this.types == null) {
                return [];
            }

            let options : SelectOption[] = [];
            for (let t of this.types) {
                options.push({
                    label : (t.code ?? "") + " " + (t.name ?? ""),
                    value : t.id
                });
            }
            
            return options;
        },

        disabled() : boolean {
            return this.subProject == null || this.types == null || this.saveRunning;
        }        
    },

    methods: {
        save(): void {
			/*in case of number was set to null*/
            let passedNumber : number;
            if (typeof this.subProject.number == "number") {
                passedNumber = this.subProject.number;
            } else if (typeof this.subProject.number == "string") {
                let numberString = this.subProject.number as string;
                passedNumber = (numberString == null || numberString.trim().length == 0 ? null : parseInt(numberString));
            } else {
                passedNumber = null;
            }
			this.subProject.number = passedNumber;
			
            let newCodeNumber : string = (this.subProject.code ?? "") + (this.subProject.number ?? "");
            if (this.existingCodeNumbers.has(newCodeNumber)) {
                window.alert(this.$t("subProject.edit.codeNumberAlreadyExists", {
                    codeNumber : newCodeNumber
                }));
                return;
            }
            
            this.saveRunning = true;
            this.ctx.projectService.saveSubProject(this.ctx.activeOrganisationPersonId, this.subProject as SubProject)
                .then(() => {
                    this.ctx.invalidateCachedProject(this.projectId);
                    
                    this.$router.go(-1);
                    this.saveRunning = false;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                    this.saveRunning = false;
            });
        },
        updateNumberValidity(): void {
            this.numberValidity = (this.$refs.numberInput as HTMLInputElement).validity;
        },
        cancel(): void {
            this.$router.back();
        }
    }
});
