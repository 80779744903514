import { I18n } from "vue-i18n";
import UniqueId from "cdes-vue/external/vue-unique-id";

import { I18nHelper } from "cdes-vue/i18n/I18nHelper";

import de from "./cdes-vue/i18n/de.json";
import en from "./cdes-vue/i18n/en.json";

import { CdesContext } from "cdes-vue/core/CdesContext";
//import { createClazzesVuePlugin, ClazzesVuePlugin } from "clazzes-vue";
import { createApp, reactive } from 'vue';
import { Router } from "vue-router";
import App from 'cdes-vue/layout/main/App.vue';
import { RouterFactory } from "cdes-vue/layout/main/RouterFactory";
import { TinyLog } from "clazzes-core/log/TinyLog";
import Tooltip from "cdes-vue/util/directives/Tooltip";
import Popover from "cdes-vue/util/directives/Popover";
import ValidationForm from "cdes-vue/util/directives/ValidationForm";
import IframeEvent from "cdes-vue/util/directives/IframeEvent";
import OnDrag from "cdes-vue/util/directives/OnDrag";
import CustomValidity from "cdes-vue/util/directives/CustomValidity";
import { getCurrentLocaleString } from "cdes-vue/util/I18n";

import "cdes-vue/assets/css/global.scss";
import 'remixicon/fonts/remixicon.css';

TinyLog.initialize("/cdes/svc/tinylog.json");

//import { Action } from "cdes-vue/dto/Action";

const app = createApp(App);

// Initialize and register globally a context, containing
// things like tabSessionId, service references, etc.
// NOTE: The way we register it here (under globalProperties)
//       attaches it automatically to the this pointer of each
//       component.
let ctx = reactive(new CdesContext()) as CdesContext;

app.config.globalProperties.ctx = ctx;

app.config.globalProperties.$s = getCurrentLocaleString;
// Setup i18n
const i18n : I18n = I18nHelper.setupI18n("de", de, {
    de : de,
    en : en
});

app.use(i18n);

app.use(UniqueId);

app.directive("tooltip", Tooltip);
app.directive("validation-form", ValidationForm);
app.directive("iframe-event", IframeEvent);
app.directive("on-drag", OnDrag);
app.directive("popover", Popover);
app.directive("custom-validity", CustomValidity);

// Setup router, i.e. register under which path which
// of the cdes main pages (Planverzeichnis, Planlieferkatalog,
// etc.) is accessible.
// NOTE: If a path is given as part of the url (e.g.
//       http://localhost:8885/#/about), this method triggers
//       visiting it in an asynchronous manner.  This, however,
//       is only possible after the initialization steps
//       in CdesContext.initialize, triggered by app.mount
//       below and indirectly CdesMainUi.mounted, have been
//       completed.
//       See also the beforeEach callback registered in
//       RouterFactory.constructRouter.
//       As CdesMainUi.mounted is called based on a synchronous
//       call chain from app.mounted, the respective Promise
//       controlling this should be set up on time in a guaranteed
//       manner, i.e. what we do here should be correct.
const router : Router = RouterFactory.constructRouter(ctx);
app.use(router);

/* Would be the way to integrate the library as plugin,
 * if we would build it separately.
 * Currently, however, we import the library source code
 * directly wherever needed, so this is only here as
 * documentation how it might be done.
const clazzesVuePlugin : ClazzesVuePlugin = createClazzesVuePlugin();
app.use(clazzesVuePlugin);
*/

app.mount('#app');
