import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class DocumentReference extends Persistent {

    // Properties: (placed here as documentation)
    //
    // realm : (string | null | undefined);
    // gz : (string | null | undefined);
    // reference : string;
    // url : (string | null | undefined);
    // comment : (string | null | undefined);
    // created : (number | null | undefined);
    // documentId : number; --> Document
    // sinceDocversionId : number; --> DocumentVersion
    // untilDocversionId : (number | null | undefined); --> DocumentVersion
    // referencedVersionId : (number | null | undefined); --> DocumentVersion
    // attachmentFilename : (string | null | undefined);
    // attachmentOriginalFilename : (string | null | undefined);
    // attachmentFiletype : (string | null | undefined);
    // digest : (string | null | undefined);
    // digestCalculatorId : (number | null | undefined); --> DigestCalculator

    private _realm : (string | null | undefined);
    get realm() : (string | null | undefined) {
        this.__checkAttributeDefinition(16, "realm");
        return this._realm;
    }
    set realm(realm : (string | null | undefined)) {
        this._realm = realm;
    }

    private _gz : (string | null | undefined);
    get gz() : (string | null | undefined) {
        this.__checkAttributeDefinition(17, "gz");
        return this._gz;
    }
    set gz(gz : (string | null | undefined)) {
        this._gz = gz;
    }

    private _reference : string;
    get reference() : string {
        this.__checkAttributeDefinition(18, "reference");
        return this._reference;
    }
    set reference(reference : string) {
        this._reference = reference;
    }

    private _url : (string | null | undefined);
    get url() : (string | null | undefined) {
        this.__checkAttributeDefinition(19, "url");
        return this._url;
    }
    set url(url : (string | null | undefined)) {
        this._url = url;
    }

    private _comment : (string | null | undefined);
    get comment() : (string | null | undefined) {
        this.__checkAttributeDefinition(20, "comment");
        return this._comment;
    }
    set comment(comment : (string | null | undefined)) {
        this._comment = comment;
    }

    private _created : (number | null | undefined);
    get created() : (number | null | undefined) {
        this.__checkAttributeDefinition(21, "created");
        return this._created;
    }
    set created(created : (number | null | undefined)) {
        this._created = created;
    }

    private _documentId : number;
    get documentId() : number {
        this.__checkAttributeDefinition(22, "documentId");
        return this._documentId;
    }
    set documentId(documentId : number) {
        this._documentId = documentId;
    }

    private _sinceDocversionId : number;
    get sinceDocversionId() : number {
        this.__checkAttributeDefinition(23, "sinceDocversionId");
        return this._sinceDocversionId;
    }
    set sinceDocversionId(sinceDocversionId : number) {
        this._sinceDocversionId = sinceDocversionId;
    }

    private _untilDocversionId : (number | null | undefined);
    get untilDocversionId() : (number | null | undefined) {
        this.__checkAttributeDefinition(24, "untilDocversionId");
        return this._untilDocversionId;
    }
    set untilDocversionId(untilDocversionId : (number | null | undefined)) {
        this._untilDocversionId = untilDocversionId;
    }

    private _referencedVersionId : (number | null | undefined);
    get referencedVersionId() : (number | null | undefined) {
        this.__checkAttributeDefinition(25, "referencedVersionId");
        return this._referencedVersionId;
    }
    set referencedVersionId(referencedVersionId : (number | null | undefined)) {
        this._referencedVersionId = referencedVersionId;
    }

    private _attachmentFilename : (string | null | undefined);
    get attachmentFilename() : (string | null | undefined) {
        this.__checkAttributeDefinition(26, "attachmentFilename");
        return this._attachmentFilename;
    }
    set attachmentFilename(attachmentFilename : (string | null | undefined)) {
        this._attachmentFilename = attachmentFilename;
    }

    private _attachmentOriginalFilename : (string | null | undefined);
    get attachmentOriginalFilename() : (string | null | undefined) {
        this.__checkAttributeDefinition(27, "attachmentOriginalFilename");
        return this._attachmentOriginalFilename;
    }
    set attachmentOriginalFilename(attachmentOriginalFilename : (string | null | undefined)) {
        this._attachmentOriginalFilename = attachmentOriginalFilename;
    }

    private _attachmentFiletype : (string | null | undefined);
    get attachmentFiletype() : (string | null | undefined) {
        this.__checkAttributeDefinition(28, "attachmentFiletype");
        return this._attachmentFiletype;
    }
    set attachmentFiletype(attachmentFiletype : (string | null | undefined)) {
        this._attachmentFiletype = attachmentFiletype;
    }

    private _digest : (string | null | undefined);
    get digest() : (string | null | undefined) {
        this.__checkAttributeDefinition(29, "digest");
        return this._digest;
    }
    set digest(digest : (string | null | undefined)) {
        this._digest = digest;
    }

    private _digestCalculatorId : (number | null | undefined);
    get digestCalculatorId() : (number | null | undefined) {
        this.__checkAttributeDefinition(30, "digestCalculatorId");
        return this._digestCalculatorId;
    }
    set digestCalculatorId(digestCalculatorId : (number | null | undefined)) {
        this._digestCalculatorId = digestCalculatorId;
    }


    public static constructPartialInstance() : DocumentReference {
        let instance = new DocumentReference();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : DocumentReference {
        let instance = new DocumentReference();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : DocumentReference) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.realm, other.realm, "String")
            && DtoHelper.areMembersEqual(this.gz, other.gz, "String")
            && DtoHelper.areMembersEqual(this.reference, other.reference, "String")
            && DtoHelper.areMembersEqual(this.url, other.url, "String")
            && DtoHelper.areMembersEqual(this.comment, other.comment, "String")
            && DtoHelper.areMembersEqual(this.created, other.created, "UtcSeconds")
            && DtoHelper.areMembersEqual(this.documentId, other.documentId, "Long")
            && DtoHelper.areMembersEqual(this.sinceDocversionId, other.sinceDocversionId, "Long")
            && DtoHelper.areMembersEqual(this.untilDocversionId, other.untilDocversionId, "Long")
            && DtoHelper.areMembersEqual(this.referencedVersionId, other.referencedVersionId, "Long")
            && DtoHelper.areMembersEqual(this.attachmentFilename, other.attachmentFilename, "String")
            && DtoHelper.areMembersEqual(this.attachmentOriginalFilename, other.attachmentOriginalFilename, "String")
            && DtoHelper.areMembersEqual(this.attachmentFiletype, other.attachmentFiletype, "String")
            && DtoHelper.areMembersEqual(this.digest, other.digest, "String")
            && DtoHelper.areMembersEqual(this.digestCalculatorId, other.digestCalculatorId, "Long")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            realm: this._realm,
            gz: this._gz,
            reference: this._reference,
            url: this._url,
            comment: this._comment,
            created: this._created,
            documentId: this._documentId,
            sinceDocversionId: this._sinceDocversionId,
            untilDocversionId: this._untilDocversionId,
            referencedVersionId: this._referencedVersionId,
            attachmentFilename: this._attachmentFilename,
            attachmentOriginalFilename: this._attachmentOriginalFilename,
            attachmentFiletype: this._attachmentFiletype,
            digest: this._digest,
            digestCalculatorId: this._digestCalculatorId,
        };
    }
}
