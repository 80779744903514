/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { ParticipationPageSearchModel } from "cdes-api/dto/project/ParticipationPageSearchModel";
import { defineComponent, Ref } from "vue";
import Select from "cdes-vue/util/form/Select.vue";
import { Organisation } from "cdes-api/dto/Organisation";
import { CdesRole } from "cdes-api/dto/CdesRole";
import { Person } from "cdes-api/dto/Person";
import { SubProject } from "cdes-api/dto/SubProject";
import { PersonSearchModel } from "cdes-api/dto/PersonSearchModel";
import { ParticipationPageSearchInfo } from "cdes-api/dto/project/ParticipationPageSearchInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { resolvedOrDefault } from "cdes-vue/util/Promise";
import Label from "cdes-vue/util/form/Label.vue";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ProjectParticipationSearchModel } from "cdes-api/dto/project/ProjectParticipationSearchModel";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

/* eslint-disable vue/no-async-in-computed-properties */

export default defineComponent({
    components : {
        Select,
        Label,
    },

    emits: ["doSearch"],

    expose : ["fetchSearchInfo", "populateFromLocalStorage"],

    props: {
        projectId: Number,
        disabled : Boolean,
        buek : Boolean
    },

    mounted() {
        this.fetchSearchInfo();
    },

    computed : {
        searchModel(): ParticipationPageSearchModel {
            const ret = new ParticipationPageSearchModel();
            ret.organisationId = this.organisationId;
            ret.mainParticipantPersonId = this.mainParticipantId;
            ret.cdesRoleId = this.roleId;
            ret.deputyParticipantPersonId = this.deputyId;
            ret.subProjectId = this.subProjectId;
            return ret;
        },
        organisationsOptions(): SelectOption[] {
            return this.searchInfo.organisations.map(org => ({
                value: org.id,
                label: org.name,
                organisation: org,
            }));
        },
        rolesOptions(): SelectOption[] {
            return this.searchInfo.cdesRoles.map(role => ({
                value: role.id,
                label: role.name,
                role,
            }));
        },
        mainParticipantsOptions(): SelectOption[] {
            let options : SelectOption[] = [];
            for (let organisationPersonInfo of this.searchInfo.mainParticipantInfos) {
                let organisationId : number = organisationPersonInfo.organisation.id;
                if (this.organisationId == null || this.organisationId == organisationId) {
                    options.push({
                        value: organisationPersonInfo.person.id,
                        label: organisationPersonInfo.person.surName + " " + organisationPersonInfo.person.givenName
                            + " (" + organisationPersonInfo.organisation.name + ")"
//                        orgPerson : organisationPersonInfo        
                    });
                }
            }
            /*
            return this.searchInfo.mainParticipantInfos.map(orgPerson => ({
                value: orgPerson.person.id,
                label: orgPerson.person.surName + " " + orgPerson.person.givenName,
                orgPerson,
            }))            */

            return options;
        },
        deputiesOptions(): SelectOption[] {
            let options : SelectOption[] = [];
            for (let organisationPersonInfo of this.searchInfo.deputyInfos) {
                let organisationId : number = organisationPersonInfo.organisation.id;
                if (this.organisationId == null || this.organisationId == organisationId) {
                    options.push({
                        value: organisationPersonInfo.person.id,
                        label: organisationPersonInfo.person.surName + " " + organisationPersonInfo.person.givenName
                            + " (" + organisationPersonInfo.organisation.name + ")"
//                        orgPerson : organisationPersonInfo        
                    });
                }
            }
            return options;

            /*
            return this.searchInfo.deputyInfos.map(orgPerson => ({
                value: orgPerson.person.id,
                label: orgPerson.person.surName + " " + orgPerson.person.givenName,
                orgPerson,
            }))*/
        },
        subProjectsOptions(): SelectOption[] {
            return this.searchInfo.subProjects.map(subProject => ({
                value: subProject.id,
                label: "(" + subProject.code + (subProject.number ?? "") + ") " +subProject.name,
                subProject,
            }))
        },
        // Async in computed is a bit of a hack but doing it cleaner would require a vue plugin.
        // The more manual way would probably end up either being a lot more messy, or not allowing the projectId to be reactively updated.
        // btw if you try to modify this i would recommend reading up on the specifics of the vue reactivity system.
        // Most of the documentation about it is here: https://v3.vuejs.org/api/reactivity-api.html
        /*
        searchInfo(): Ref<ParticipationPageSearchInfo> {
            return resolvedOrDefault(this.ctx.projectService.getParticipationPageSearchInfo(this.projectId as number), {
                organisations : [],
                cdesRoles : [],
                subProjects : [],
                mainParticipantInfos : [],
                deputyInfos : [],
            });
        }*/
    },

    data() {
        return {
            organisationId: undefined as (number | undefined),
            roleId: undefined as (number | undefined),
            mainParticipantId: undefined as (number | undefined),
            deputyId: undefined as (number | undefined),
            subProjectId: undefined as (number | undefined),
            searchInfo : new ParticipationPageSearchInfo()
        };
    },

    watch: {
        projectId(newProjectId : number) {
            this.fetchSearchInfo();
        }        
    },

    methods : {
        doSearch() : void {
            this.$emit("doSearch", this.searchModel);
        },
        checkAllDropdowns() : void {
            this.organisationId = this.checkOptions(this.organisationId, this.organisationsOptions);
            this.roleId = this.checkOptions(this.roleId, this.rolesOptions);
            this.subProjectId = this.checkOptions(this.subProjectId, this.subProjectsOptions);
            this.mainParticipantId = this.checkOptions(this.mainParticipantId, this.mainParticipantsOptions);
            this.deputyId = this.checkOptions(this.deputyId, this.deputiesOptions);            
        },
        clearSearchFields() : void {
            this.organisationId = null;
            this.mainParticipantId = null;
            this.roleId = null;
            this.deputyId = null;
            this.subProjectId = null;
        },
        fetchSearchInfo() : void {
            this.ctx.projectService.getParticipationPageSearchInfo(this.projectId as number)
                .then(searchInfo => {
                    this.searchInfo = searchInfo
                    this.checkAllDropdowns();                    
                    this.$emit("doSearch", this.searchModel);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        },
        populateFromLocalStorage() : void {
            let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
            let searchModel : ParticipationPageSearchModel
                = LocalStorageHelper.getFromLocalStorage([ opString ], "/participation/search");

            if (searchModel == null) {
                return;
            }

            this.organisationId = searchModel.organisationId;
            this.roleId = searchModel.cdesRoleId;
            this.subProjectId = searchModel.subProjectId;
            this.mainParticipantId = searchModel.mainParticipantPersonId;
            this.deputyId = searchModel.deputyParticipantPersonId;
            /*
            this.organisationId = this.checkOptions(searchModel.organisationId, this.organisationsOptions);
            this.roleId = this.checkOptions(searchModel.cdesRoleId, this.rolesOptions);
            this.subProjectId = this.checkOptions(searchModel.subProjectId, this.subProjectsOptions);
            this.mainParticipantId = this.checkOptions(searchModel.mainParticipantPersonId, this.mainParticipantsOptions);
            this.deputyId = this.checkOptions(searchModel.deputyParticipantPersonId, this.deputiesOptions);
            */
        },

        checkOptions(idFromLocalStorage : number, options : SelectOption[]) : number {
            let found = false;
            for (let option of options) {
                found = found || (option.value == idFromLocalStorage);
            }
            return found ? idFromLocalStorage : null;
        }
    }
});
