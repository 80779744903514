import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-3" }
const _hoisted_2 = { class: "d-flex flex-row gap-3 flex-wrap text-nowrap" }
const _hoisted_3 = { class: "d-flex flex-row gap-3" }
const _hoisted_4 = ["disabled", "onClick"]
const _hoisted_5 = ["disabled", "onClick"]
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = ["disabled", "onClick"]
const _hoisted_8 = { class: "d-flex flex-row gap-3" }
const _hoisted_9 = ["disabled", "onClick"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadedOrPlaceholder = _resolveComponent("LoadedOrPlaceholder")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ReviewEntryComments = _resolveComponent("ReviewEntryComments")!
  const _component_ReviewDocumentReferences = _resolveComponent("ReviewDocumentReferences")!
  const _component_ReviewPositionEditor = _resolveComponent("ReviewPositionEditor")!
  const _component_PasswordDialog = _resolveComponent("PasswordDialog")!
  const _component_Transform = _resolveComponent("Transform")!

  return (_openBlock(), _createBlock(_component_Transform, {
    transformer: _ctx.formTransformer,
    onSubmit: _ctx.onSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: _normalizeClass(["overflow-y-auto d-flex flex-column gap-3", { 'was-validated': _ctx.wasValidated }]),
        "data-action": "implicit",
        ref: "root"
      }, [
        _createElementVNode("h3", null, [
          _createVNode(_component_i18n_t, {
            keypath: (_ctx.reviewData === _ctx.loadingPlaceholder || !_ctx.overriding) ? 'review.review.entry.heading' : 'review.review.entry.overridingHeading'
          }, {
            node: _withCtx(() => [
              _createVNode(_component_LoadedOrPlaceholder, { value: _ctx.reviewData }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$s(_ctx.reviewData.reviewCycleNode.reviewCycleNodeName)), 1)
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }, 8, ["keypath"])
        ]),
        _createElementVNode("p", null, [
          (_ctx.reviewData === _ctx.loadingPlaceholder || _ctx.reviewData.positions.length > 0)
            ? (_openBlock(), _createBlock(_component_LoadedOrPlaceholder, {
                key: 0,
                value: _ctx.reviewData
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.reviewData.positions[0]?.organisationName) + " (" + _toDisplayString(_ctx.reviewData.positions[0]?.personGivenName) + " " + _toDisplayString(_ctx.reviewData.positions[0]?.personSurName) + ") ", 1)
                ]),
                _: 1
              }, 8, ["value"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ReviewEntryComments, {
          reviewData: _ctx.reviewData,
          documentVersionId: _ctx.documentVersionId,
          modelValue: _ctx.saveInfo.comments,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.saveInfo.comments) = $event))
        }, null, 8, ["reviewData", "documentVersionId", "modelValue"]),
        _createVNode(_component_ReviewDocumentReferences, { context: _ctx.context }, null, 8, ["context"]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positions, (position, positionIndex) => {
            return (_openBlock(), _createBlock(_component_ReviewPositionEditor, {
              key: position.reviewCycleNodePositionReleasedId ?? position.attachedReviewCycleNodePositionId ?? positionIndex,
              context: _ctx.context,
              reviewData: _ctx.reviewData,
              position: position,
              documentVersionId: _ctx.documentVersionId,
              modelValue: _ctx.saveInfo.nodes[positionIndex],
              "onUpdate:modelValue": ($event: any) => ((_ctx.saveInfo.nodes[positionIndex]) = $event)
            }, null, 8, ["context", "reviewData", "position", "documentVersionId", "modelValue", "onUpdate:modelValue"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "submit",
              disabled: this.disabled,
              class: "d-none",
              "data-action": "implicit",
              onClick: [_ctx.validationOnSubmit, _ctx.onSubmit]
            }, null, 8, _hoisted_4),
            _createElementVNode("button", {
              type: "submit",
              disabled: this.disabled,
              class: "btn btn-secondary",
              "data-action": "save",
              onClick: [_ctx.validationOnSubmit, _ctx.onSubmit]
            }, _toDisplayString(_ctx.$t('general.save')), 9, _hoisted_5),
            _createElementVNode("button", {
              type: "submit",
              disabled: this.disabled,
              class: "btn btn-secondary",
              "data-action": "saveAndExit",
              onClick: [_ctx.validationOnSubmit, _ctx.onSubmit]
            }, _toDisplayString(_ctx.$t('general.saveAndExit')), 9, _hoisted_6),
            _createElementVNode("button", {
              type: "submit",
              disabled: this.taskStatus == 1 || this.disabled || this.overriding,
              class: "btn btn-outline-primary",
              "data-action": "save-to-signature-folder",
              onClick: [_ctx.validationOnSubmit, _ctx.onSubmit]
            }, _toDisplayString(_ctx.$t('review.review.entry.saveToSignatureFolder')), 9, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "submit",
              disabled: this.disabled,
              class: "btn btn-primary",
              "data-action": "sign",
              onClick: [_ctx.validationOnSubmit, _ctx.onSubmit]
            }, _toDisplayString(_ctx.$t('review.review.entry.sign')), 9, _hoisted_9),
            _createElementVNode("button", {
              type: "button",
              disabled: this.disabled,
              class: "btn btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
            }, _toDisplayString(_ctx.$t('general.cancel')), 9, _hoisted_10)
          ])
        ]),
        _createVNode(_component_PasswordDialog, {
          title: _ctx.$t('review.review.entry.signTitle'),
          submitLabel: _ctx.$t('general.sign'),
          ref: "passwordDialog"
        }, null, 8, ["title", "submitLabel"])
      ], 2)
    ]),
    _: 1
  }, 8, ["transformer", "onSubmit"]))
}