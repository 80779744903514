import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn } from "cdes-vue/util/grid/ParempiGrid.vue";
import { PlotTaskInfo } from "cdes-api/dto/plot/PlotTaskInfo";
import { PlotTaskItemInfo } from "cdes-api/dto/plot/PlotTaskItemInfo";
import { PlotHelper } from "../util/PlotHelper";
import { LengthCalculator } from "../util/LengthCalculator";
import { LengthUnit } from "../util/LengthUnit";
import AddressOutput from "cdes-vue/util/output/AddressOutput.vue";
import { Address } from "cdes-api/dto/util/Address";
import { AddressHelper } from "cdes-vue/util/output/AddressHelper";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import { DocumentHelper } from "cdes-vue/document/util/DocumentHelper";
import Dialog from "cdes-vue/util/layout/Dialog.vue";
import { PlotTaskMode } from "./PlotTaskPage";

export default defineComponent({

    components : {
        AddressOutput,
        CheckBox,
        Dialog,
        ParempiGrid
    },

    props: {
        mode : {
            type : String
        },
        items : {
            type: Array as PropType<PlotTaskItemInfo[]>,
            default: () => [],
        },
        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            pngDialogDocumentVersion : null,
            selectionAll : null,
            pngDialogSrc : null
        }
    },

    computed: {
        columns() : ParempiColumn<PlotTaskItemInfo>[] {
            let columns : ParempiColumn<PlotTaskItemInfo>[]
                = [
                ParempiColumn.asRowSpanSlot("document", this.$t("general.planNumber"), "document"),
                ParempiColumn.asRowSpanSlot("file", this.$t("general.file"), "file"),
                ParempiColumn.asRowSpanSlot("paperQuality", this.$t("plot.template.item.list.paper"), "paperQuality"),
                ParempiColumn.asRowSpanSlot("color", this.$t("plot.template.item.list.color"), "color"),
                ParempiColumn.asRowSpanSlot("margin", this.$t("plot.template.item.list.margin"), "margin"),
                ParempiColumn.asRowSpanSlot("mirror", this.$t("plot.template.item.list.mirror"), "mirror"),
                ParempiColumn.asRowSpanSlot("scale", this.$t("plot.template.item.list.scale"), "scale"),
                ParempiColumn.asRowSpanSlot("count", this.$t("plot.template.item.list.count"), "count"),
                ParempiColumn.asRowSpanSlot("area", this.$t("plot.task.items.area"), "area"),
                ParempiColumn.asRowSpanSlot("pages", this.$t("plot.task.items.pages"), "pages"),
                ParempiColumn.asRowSpanSlot("deliveryAddress", this.$t("plot.task.items.deliveryAddress"), "deliveryAddress")
                ];
            if (this.mode == PlotTaskMode.PLOT_ORDER_ACCEPTANCE_TASK) {
                columns.push(ParempiColumn.asRowSpanSlot("type", this.$t("plot.task.items.type"), "type"));
                 columns.push(
                    new ParempiColumn({
                        id: "choose",
                        slotName: "choose",
                        headerSlotName: "selectionHeader",
                        //label: this.$t("plot.task.items.choose"),
                        sort: false,
                    }));
        }
            return columns;
        }
    },

    methods : {
        selectAllClicked() {
            let selectionAll : boolean = this.selectionAll;
            let items : PlotTaskItemInfo[] = this.items as PlotTaskItemInfo[];
            for (let item of items) {
                item.chosen = selectionAll;
            }
        },
        selectClicked() : void {
            let selectionAll : boolean = (this.items.length > 0);
            let items : PlotTaskItemInfo[] = this.items as PlotTaskItemInfo[];
            for (let item of items) {
                selectionAll = selectionAll && item.chosen;
            }
            if (selectionAll != this.selectionAll) {
                this.selectionAll = selectionAll;
            }
        },
        getDocumentContent(item : PlotTaskItemInfo) : string {
            if (item.documentRelease.id != null) {
                return this.$s(item.documentRelease.content);
            } else {
                return this.$s(item.document.content);
            }
        },

        getPlotColor(item : PlotTaskItemInfo) : string {
            return PlotHelper.getPlotColorLabel(item.plotProperties.isInColor, this.$t);
        },

        getPlotMirror(item : PlotTaskItemInfo) : string {
            return PlotHelper.getPlotMirrorLabel(item.plotProperties.mirror, this.$t);
        },

        getPlotCount(item : PlotTaskItemInfo) : string {
            return this.$t("plot.task.items.countContent", {
                count : item.plotProperties.count
            });
        },

        getPlotArea(item : PlotTaskItemInfo) : string {
            return PlotHelper.getPlotAreaString(PlotHelper.getPlotPropertiesArea(item.plotProperties));
        },

        getDeliveryAddress(item : PlotTaskItemInfo) : Address {
            return AddressHelper.getAddressFromOrderAddress(item.deliveryAddress, item.deliveryCountry,
                                                            true, true);
        },

        getTypeLabel(item : PlotTaskItemInfo) : string {
            let initiatorParticipantId : number = item.initiatorParticipant.id;
            let reviewCycleNodeInstanceReleasedId : number = item.reviewCycleNodeInstanceReleased.id;
            if (initiatorParticipantId != null && reviewCycleNodeInstanceReleasedId == null) {
                return this.$t("plot.task.items.typeManual", {
                    personGivenName : item.initiatorPerson.givenName,
                    personSurName : item.initiatorPerson.surName
                });
            } else if (initiatorParticipantId == null && reviewCycleNodeInstanceReleasedId != null) {
                return this.$t("plot.task.items.typeAutomatic", {
                    reviewCycleNodeName : this.$s(item.reviewCycleNode.name)
                });
            } else {
                return "---";
            }
        },

        showPdfButton(item : PlotTaskItemInfo) : boolean {
            let fileType : string = item.documentVersion.filetype;
            return DocumentHelper.isFileTypeSupportedForPreview(fileType);
        },

        showPngButton(item : PlotTaskItemInfo) : boolean {
            let fileType : string = item.documentVersion.filetype;
            return DocumentHelper.isFileTypeSupportedForPreview(fileType);
        },

        showCompareButton(item : PlotTaskItemInfo) : boolean {
            return item.previewSupportDocumentVersion.id != null;
        },

        showSaveButton(item : PlotTaskItemInfo) : boolean {
            return true;
        },

        exportPdf(item : PlotTaskItemInfo) : void {
            let documentVersionId : number = item.documentVersion.id;
            let tabSessionId : string = this.ctx.getTabSessionId();

            let params : Record<string, string> = {
                service : "CDESMimeService/6/documentVersionPdfMimeSource",
                sp : documentVersionId.toString(),
                rand : Math.random().toString(),
                ts : tabSessionId
            };

            let queryParams : URLSearchParams = new URLSearchParams(params);
            let url : string = "/cdes/app?" + queryParams.toString();            
            
            //var url = "/cdes/app?service=CDESMimeService/6/documentVersionPdfMimeSource&sp=" + documentVersionId
                //+ "&rand=" + Math.random() + "&ts=" + tabSessionId;
            window.open(url);
        },

        exportPng(item : PlotTaskItemInfo) : void {
            this.pngDialogDocumentVersion = item.documentVersion.name;

            let documentVersionId : number = item.documentVersion.id;
            let tabSessionId : string = this.ctx.getTabSessionId();

            let params : Record<string, string> = {
                service : "CDESMimeService/4/documentVersionPngMimeSource",
                rand : Math.random().toString(),
                ts : tabSessionId
            };

            let queryParams : URLSearchParams = new URLSearchParams(params);
            queryParams.append("sp", documentVersionId.toString());
            queryParams.append("sp", "320");
            queryParams.append("sp", "T");

            this.pngDialogSrc = "/cdes/app?" + queryParams.toString();     
            (this.$refs.pngDialog as InstanceType<typeof Dialog>).show();
        },

        compareDocumentVersion(item : PlotTaskItemInfo) : void {
            let documentVersionId : number = item.documentVersion.id;
            let tabSessionId : string = this.ctx.getTabSessionId();
            let queryParamObject : Record<string, string> = {
                ts : tabSessionId
            };
            let queryParams : URLSearchParams = new URLSearchParams(queryParamObject);

            let hashParamObject : Record<string, string> = {
                page : "documentComparePage",
                documentVersionId : documentVersionId.toString()
            };
            let hashParams : URLSearchParams = new URLSearchParams(hashParamObject);

            let url : string = "/cdes/frame.html?" + queryParams + "#" + hashParams;

            //window.open("frame.html?ts=" + dojoConfig.tabSessionId + "#page=documentComparePage&documentVersionId=" + documentListJoin.documentVersionId, "_blank", "toolbar=no, menubar=no,scrollbars=no,resizable=yes");

            window.open(url, "_blank", "toolbar=no, menubar=no,scrollbars=no,resizable=yes");            
        },

        downloadDocumentVersion(item : PlotTaskItemInfo) : void {
            let documentVersionId = item.documentVersion.id;
            let tabSessionId : string = this.ctx.getTabSessionId();
            let params : Record<string, string> = {
                service : "CDESMimeService/6/documentVersionMimeSource",
                sp : documentVersionId.toString(),
                rand : Math.random().toString(),
                ts : tabSessionId
            };

            let queryParams : URLSearchParams = new URLSearchParams(params);
            let url : string = "/cdes/app?" + queryParams.toString();                        

            //var url = "/cdes/app?service=CDESMimeService/6/documentVersionMimeSource&sp=" + documentVersionId
            // + "&rand=" + Math.random() + "&ts=" + dojoConfig.tabSessionId;
            window.open(url);
        }
    }
});
