import { Modal } from "bootstrap";
import { TaskSearchModel } from "cdes-api/dto/task/TaskSearchModel";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";
import { DateHelper } from "clazzes-core/dateTime/DateHelper";
import { TinyLog } from "clazzes-core/log/TinyLog";
import { SubProject } from "cdes-api/dto/SubProject";


import { defineComponent } from "vue";

const log = new TinyLog("cdes.service.LoginDialog");

interface OnFrameLoadEvent {
    target : OnFrameLoadEventTarget;
}

interface OnFrameLoadEventTarget {
    contentWindow : OnFrameLoadEventTargetContentWindow;
}

interface OnFrameLoadEventTargetContentWindow {
    document : HTMLDocument;
}

interface PromiseFctContainer {
    resolve : (value : string) => void;
    reject : (reason : string) => void;
}

export default defineComponent({
//    name : 'LoginDialog',

    data() {
        return {
            loginDialogModal : null as Modal,
            loginPromises : [] as PromiseFctContainer[],
            loginUrl : "" as string
        }
    },

    mounted()  {
        this.loginDialogModal = new Modal(this.$refs.loginDialogRoot);
    },

    methods: {
        onFrameLoad(event : OnFrameLoadEvent) : void {
            try {
                const doc = event.target.contentWindow.document;

                let form : HTMLFormElement = doc.getElementById('loginResultForm') as HTMLFormElement;

                if (form != null) {
                    const status : number = Number.parseInt(form.status.value);
                    if (status == 200) {

                        this.ctx.principal = form.principal.value;

                        for (let p in this.loginPromises) {
                            try {
                                this.loginPromises[p].resolve(form.principal.value);
                            } catch(err) {
                                log.warn("Error resolving login promise [",p,"]:", err);
                            }
                        }
                        this.loginPromises = [];
                        this.loginUrl = '';
                        this.loginDialogModal.hide();
                        /* Checking for buek mode at this point is not possible.
                         * Reason: We have not yet loaded the personVariables at this point,
                         *         this cannot happen before login. 
                         */
                        /*
                        if (this.ctx.buekMode) {
                            this.$router.push({ name : "planningNotificationTaskList" });
                        } else {
                            this.$router.push({ name : "planningNotificationTaskList" });

                            }*/
                        let currentUtcMillis = DateHelper.getCurrentTimeMillis();
                        if (this.ctx.lastSuccessfulLoginTist == null || currentUtcMillis > this.ctx.lastSuccessfulLoginTist + 2000) {
                            this.ctx.personService.openBuekTasksByDefault().then((openBuek : boolean) => {
                                if (openBuek) {
                                    LocalStorageHelper.storeInLocalStorage([ this.ctx.principal ], "/buek/task/search", { });
                                    LocalStorageHelper.storeInLocalStorage([ this.ctx.principal ], "/task/quickSearch", "");

                                    this.$router.push({ name : "buekAufgaben" });
                                } else {
                                    this.cleanTaskLocalStorage();
                                    this.$router.push({ name : "plan/aufgaben" });
                                }
                            });                            
                        } else {
                            this.cleanTaskLocalStorage();
                            
                            this.$router.push({ name : "plan/aufgaben" });
                        }
                        this.ctx.lastSuccessfulLoginTist = currentUtcMillis;
                    } else if (status == 406) {
                        for (let p in this.loginPromises) {
                            try {
                                this.loginPromises[p].reject("Login failed with status [" + status + "].");
                            } catch(err) {
                                log.warn("Error rejecting login promise [",p,"]:", err);
                            }
                        }
                        this.loginPromises = [];
                        this.loginUrl = '';
                        this.loginDialogModal.hide();
                    }
                    // maybe we need focus fiddlery here...
                }
            } catch (err) {
                log.info("Error fetching login form status:",err);
            }
        },

        cleanTaskLocalStorage() : void {
            let searchModel : TaskSearchModel = LocalStorageHelper.getFromLocalStorage([ this.ctx.principal ], "/task/search");
            if (searchModel != null) {
                searchModel.deputyMode = null;
                searchModel.fromTs = null;
                searchModel.toTs = null;
                searchModel.searchMode = null;
                searchModel.filterByDate = null;
                LocalStorageHelper.storeInLocalStorage([ this.ctx.principal ], "/task/search", searchModel );
            }
            LocalStorageHelper.storeInLocalStorage([ this.ctx.principal ], "/task/quickSearch", "");
        },

        reloginHandler(loginUrl : string) : Promise<string> {

            console.info("loginUrl: [" + loginUrl + "]");

            this.loginDialogModal.show();

            if (this.loginPromises.length == 0) {
                this.loginUrl = loginUrl + "?locale=" + this.$i18n.locale;
            }

            return new Promise((resolve,reject) => {
                this.loginPromises.push({resolve,reject});
            });
        },

        openPasswordForgottenPage() : void {
            window.open("/cdes/app?service=page/PasswordForgotten");
        }
    }
});
