import { DocumentReference } from "cdes-api/dto/DocumentReference";
import { defineComponent, PropType } from "vue";
import DateInput from "cdes-vue/util/form/DateInput.vue";

export default defineComponent({
    components: {
        DateInput
    },

    emits : ["delete"],

    props: {
        documentReference : {
            type : Object as PropType<DocumentReference>
        },
        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            deleted : false
        };
    },

    methods : {
        openFile() {
            let link : string = this.getDocumentRefsLink();
            window.open(link);
        },

        getDocumentRefsLink() : string {
            let urlSearchParams : URLSearchParams = new URLSearchParams();
            urlSearchParams.append("sp", "" + this.documentReference.id);
            return "/cdes/svc/documentRefs?" + urlSearchParams.toString();
        },
        doDelete() : void {
            if (window.confirm(this.$t("review.upload.page.documentReferenceDeleteWarning"))) {
                this.deleted = true;
                this.$emit('delete');
            }
        }
    }
});
