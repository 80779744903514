import { defineComponent, h, VNode, VNodeArrayChildren, Comment, PropType, toRaw, markRaw, getCurrentInstance, isVNode, Component, ref, withDirectives, watchEffect, SetupContext, cloneVNode, computed, Fragment } from "vue";

import { ParempiColumn } from "cdes-vue/util/grid/ParempiColumn";
import { ParempiRowPos, RowPosFactory, RowPosIncrementor } from "./ParempiRowPos";
import { makePropWithValue, eagerComputed, usePersistentValue } from "cdes-vue/util/Prop";
import { Comparator, keyToCmp, defaultCmp, invertCmp } from "cdes-vue/util/Sort";
import OnDrag from "cdes-vue/util/directives/OnDrag";

type NodeLike = string | VNode | VNode[];

export { ParempiColumn, ParempiRowPos };
export type { RowPosFactory, RowPosIncrementor };

export interface ColumnGroup<T> {
    label: string;
    children: ParempiColumn<T>[];
}

export enum SortMode {
    ASC,
    DESC,
}

export enum ColumnResizeMode {
    FIXED = "FIXED",
    DYNAMIC = "DYNAMIC",
}

function parsePx(str: string): number | null {
    const match = /^\s*([+-]?\d*(\.\d+)?)px\s*$/.exec(str);
    if (match == null || match[0] == null) {
        return null;
    } else {
        return Number.parseFloat(match[0]);
    }
}

export default defineComponent({
    components : {
    },

    expose : ["scrollIntoView", "trueItems"],

    methods : {
		renderColumn<T>(rowPos: ParempiRowPos ,item: T, column: ParempiColumn<T>): NodeLike {
			if (column.slotName) {
				return this.$slots[column.slotName]({
					item,
					column,
					rowPos,
				});
			} else if (column.renderFct) {
                return column.renderFct(item, rowPos);
            } else if (column.property) {
				return item[column.property];
            } else {
				return "";
            }
		},
        onHeaderClicked(e: MouseEvent, column: ParempiColumn<any>, columnIndex: number): void {
            if (this.getColumnSorter(column, columnIndex) === undefined) {
                return;
            }

            if (this.ourCurrentlySortedColumn === column.id) {
                if (this.ourSortMode === SortMode.ASC) {
                    this.ourSortMode = SortMode.DESC;
                } else if (this.ourSortMode === SortMode.DESC) {
                    this.ourSortMode = SortMode.ASC;
                }
            } else {
                this.ourCurrentlySortedColumn = column.id;
            }
            e.preventDefault();
        },
        renderItems(items: unknown[]): {
            vnodes: VNode[],
            numberOfRows: number,
        } {
            let rowNumber = 0;
            let itemNumber = 0;
            let lines : VNode[] = [];
            let spanningCells: {
                colIndex: number,
                subRowIndex: number,
                remainingRowSpan: number;
            }[] = [];
            this.idToNode = new Map();
            this.idToNextNode = new Map();

            /* Working around the obscure fact, that for the very last line,
             * node.el is null.  In that case, simply try the previous one. 
             */
            this.idToPrevNode = new Map();
            let prevId = null;
            let prevNode = null;
            for (let item of items) {
                let id;
                if (this.idGetter != null) {
                    id = this.idGetter(item);
                } else if (this.idProperty != null) {
                    id = item[this.idProperty];                    
                }

                let rowPosFactory : RowPosFactory<any> = this.rowPosFactory as RowPosFactory<any>;
                let rowPos : ParempiRowPos = (rowPosFactory != null ? rowPosFactory(item) : null);

                let subLines: VNode[] = [];
                let subRowNumber = 0;
                for (;;) {
                    let columnNumber = 0;
                    let lineCells : NodeLike[] = [];
                    for (let column of this.flattenedColumns) {
                        let rowSpan : number = column.getRowSpan(item, rowPos);
                        if (rowSpan != null) {
                            let options: Record<string, unknown> = {
                                role: "cell",
                                class: {
                                    "parempiLastHorizontalCell": columnNumber === (this.flattenedColumns.length - 1),
                                    "parempiFirstHorizontalCell": columnNumber === 0,
                                },
                                style: {
                                    gridRowStart: rowNumber + (this.hasGroups ? 3 : 2),
                                    gridRowEnd: rowNumber + (this.hasGroups ? 3 : 2) + rowSpan,
                                    ...this.getColumn(columnNumber),
                                },
                            };
                            if (rowSpan != 1) {
                                options.rowSpan = rowSpan;
                            }

                            spanningCells.push({
                                colIndex: columnNumber,
                                subRowIndex: subRowNumber,
                                remainingRowSpan: rowSpan,
                            });

                            const lineCell = h("td", options, this.renderColumn(rowPos, item, column));
                            lineCells.push(lineCell);
                        } else {
                            lineCells.push(undefined);
                        }
                        columnNumber += 1;
                    }

                    lineCells.push(h("td", {
                        "aria-hidden": true,
                        "class": "parempiEndCell",
                        style: {
                            gridRowStart: rowNumber + (this.hasGroups ? 3 : 2),
                            gridRowEnd: rowNumber + (this.hasGroups ? 3 : 2) + 1,
                            gridColumn: this.flattenedColumns.length * 2,
                        },
                    }));

                    // @ts-ignore
                    let line = h("tr", { role: "row", class: this.getRowClass(item, rowPos), key: subRowNumber }, lineCells);
                    if (subRowNumber == 0 && id != null) {
                        // For some obscure reason, we need to scroll to some td inside the tr.
                        // Scrolling to the tr itself apparently doesn't work.
                        //                        this.idToNode.set(id, line);
                        this.idToNode.set(id, lineCells[0]);
                        if (prevId != null) {
                            this.idToNextNode.set(prevId, lineCells[0]);
                        }
                        if (prevNode != null) {
                            this.idToPrevNode.set(id, prevNode);
                        }
                        prevNode = lineCells[0];
                        
                        prevId = id;
                    }
                    
                    subLines.push(line);
                    rowNumber += 1;

                    if (rowPos != null) {
                        let rowPosIncrementor : RowPosIncrementor<any> = this.rowPosIncrementor as RowPosIncrementor<any>;
                        rowPosIncrementor(item, rowPos);
                    }

                    subRowNumber++;
                    let finished;
                    if (rowPos == null || rowPos.isFinished()) {
                        if (itemNumber === (items.length - 1) && subLines.length > 0) {
                            for (const spanningCell of spanningCells) {
                                subLines[spanningCell.subRowIndex].children[spanningCell.colIndex] = cloneVNode(subLines[spanningCell.subRowIndex].children[spanningCell.colIndex], {
                                    class: "parempiLastVerticalCell",
                                });
                            }
                        }
                        finished = true;
                    } else {
                        finished = false;
                    }

                    for (let i = 0; i < spanningCells.length; i++) {
                        spanningCells[i].remainingRowSpan--;

                        if (spanningCells[i].remainingRowSpan <= 0) {
                            spanningCells.splice(i, 1);
                            i--;
                        }
                    }

                    if (finished) {
                        break;
                    }
                }
                lines.push(h(Fragment, { key: this.itemKey?.(item) }, subLines));
                itemNumber++;
            }
            /*
            window.setTimeout(() => {
                if (this.idToNode.has(7461875)) {
                    let node = this.idToNode.get(7461875);
                    node.el.scrollIntoView(true);
                }
            }, 1000);
            */
            
            return {
                vnodes: lines,
                numberOfRows: rowNumber,
            };
        },
        extractTextContentArray(array: VNodeArrayChildren): string {
            return array.reduce<string>((acc, child) => {
                let str: string;
                if (Array.isArray(child)) {
                    str = this.extractTextContentArray(child);
                } else if (child === null || child === undefined || typeof child === "boolean") {
                    return acc
                } else if (typeof child === "object") {
                    str = this.extractTextContent(child);
                } else {
                    str = (child as Exclude<typeof child, void>).toString();
                }
                return acc + str;
            }, "");
        },
        extractTextContent(vnode: VNode): string {
            if (vnode == null || vnode.type === Comment) {
                return "";
            }
            if (Array.isArray(vnode.children)) {
                return this.extractTextContentArray(vnode.children);
            } else if (vnode.children == null) {
                return "";
            } else if (typeof vnode.children === "string") {
                return vnode.children;
            } else if (typeof vnode.type === "object" && !isVNode(vnode.type)) {
                console.log("Ignoring component %s for filtering in ParempiGrid, timestamp ["
                    + new Date().toISOString() + "]", (vnode.type as Component).name);
                return "";
            } else {
                throw new Error("Unsupported VNode child type.");
            }
        },
        getColumnSorter<T>(col: ParempiColumn<T>, columnIndex: number): ((arr: [T, number][]) => [T, number][]) | undefined {
            if ((col.sort === true || col.sort === undefined) && col.property === undefined) {
                let cmp = new Intl.Collator(this.$i18n.locale).compare;
                if (this.ourSortMode === SortMode.DESC) {
                    cmp = invertCmp(cmp);
                }

                return arr => {
                    const getRowText = (rowIndex: number) => this.allItemTexts[rowIndex][columnIndex];

                    const sortedIndices = arr.map<[number, number]>(([value, allIndex], index) => [allIndex, index])
                    .sort(keyToCmp(([allIndex]) => getRowText(allIndex), cmp));

                    return arr.map((value, index) => arr[sortedIndices[index][1]]);
                };
            }

            let cmp: Comparator<T> | undefined;
            if (typeof col.sort === "function") {
                cmp = col.sort;
            } else if (col.sort === true || col.sort === undefined) {
                if (col.property !== undefined) {
                    cmp = keyToCmp(a => "" + a[col.property], new Intl.Collator(this.$i18n.locale).compare);
                }
            }
            if (cmp != null && this.ourSortMode === SortMode.DESC) {
                cmp = invertCmp(cmp);
            }
            return cmp != null ? array => array.map(a => a).sort(keyToCmp(([item]) => item, cmp)) : undefined;
        },
        firstHeaderResizeHandler(entries: ResizeObserverEntry[]): void {
            for (const entry of entries) {
                //const borderWidth = parsePx(window.getComputedStyle(entry.target).getPropertyValue("--dgrid-table-border-width"));
                const borderWidth = 0;
                if (borderWidth == null) {
                    continue;
                }
                for (const secondHeaderCell of this.secondHeaderRefs) {
                    if (secondHeaderCell != null) {
                        // @ts-ignore
                        secondHeaderCell.style.top = (entry.target.offsetHeight + borderWidth * 2) + "px";
                    }
                }
            }
        },
        extractColumnsFilterText<T>(strings: string[][], item: T, col: ParempiColumn<T>, colIndex: number, rowIndex: number): string[] {
            if (typeof col.filter !== "function" && col.filter !== false) {
                return [strings[rowIndex][colIndex]];
            } else if (typeof col.filter === "function") {
                const str = col.filter(item);
                return typeof str === "string" ? [str] : str;
            } else {
                return [];
            }
        },
        getComputedColumnWidths(): number[] {
            const ret = window.getComputedStyle(this.tableRef).gridTemplateColumns
            .split(/\s+/)
            // remove empty
            .filter(a => !!a)
            .filter((_, index) => {
                if (index === (this.flattenedColumns.length + 1) || (index % 2) === 1) {
                    return false;
                } else {
                    return true;
                }
            })
            .map(parsePx);

            /* Temporarily commented out due to https://gitlab.intra.iteg.at/cdes/cdes-main/-/issues/377
             * I'm not sure wether outcommenting this completely is correct, or wether we need changes
             * at other places as well.
            if (ret.length !== this.flattenedColumns.length) {
                throw new Error(`Rendered grid has ${ret.length} columns instead of ${this.flattenedColumns.length}`);
            }*/

            return ret;
        },
        withMinMax(width: number | null, column: ParempiColumn<unknown>): string {
            const minWidthExpr = (column.minWidth ?? 50) + "px";
            if (width == null) {
                const defaultWidth = column.defaultWidth ?? 1;
                const defaultWidthExpr = typeof defaultWidth === "number" ? defaultWidth + "fr" : defaultWidth;
                return `minmax(${minWidthExpr}, ${defaultWidthExpr})`;
            } else {
                return `max(${minWidthExpr}, ${width}px)`;
            }
        },
        correctColumnWidths() : void {
            let offsetWidth : number = this.tableRef.offsetWidth;

            if (this.columnWidths.value != null) {
                let realSum = 0;
                let realCount = 0;
                for (let n = 0; n < this.flattenedColumns.length; n++) {
                    let column : ParempiColumn<unknown> = this.flattenedColumns[n];
                    let columnId : string = column.id;
                    if (columnId in this.columnWidths.value) {
                        // Recognize 1px border.
                        realSum += this.columnWidths.value[columnId];
                        realCount++;
                    }
                }
                realCount--;

                // NOTE: 25 is a guessed number, I don't really know how to calculate it.
                let factor : number = (offsetWidth - realCount - 25) / realSum;
                let adjustedGridTemplateColumns : string = "";
                for (let n = 0; n < this.flattenedColumns.length; n++) {
                    let column : ParempiColumn<unknown> = this.flattenedColumns[n];
                    let columnId : string = column.id;
                    if (columnId in this.columnWidths.value) {
                        let adjustedWidth : number = factor * this.columnWidths.value[columnId];
                        if (isNaN(adjustedWidth)) {
                            adjustedWidth = 100;
                        }
                        adjustedGridTemplateColumns += (adjustedWidth + "px");
                        this.columnWidths.value[column.id] = adjustedWidth;

                        if (n < this.flattenedColumns.length - 1) {
                            adjustedGridTemplateColumns += " 1px ";
                        }
                    }
                }
                this.tableRef.style.gridTemplateColumns = adjustedGridTemplateColumns;                
            }
        },
        applyColumnWidth(): void {
            // Place inside a timeout, to get an already calculated offsetWidth.
            window.setTimeout(() => {
                //console.log("Applying columng widths");
                let gridTemplateColumns: string;
                // NOTE: correctColumnWidths above assumes a --parempi-border-width of 1px.
                let joiner = " var(--parempi-border-width) ";
                if (this.columnWidths.value == null) {
                    gridTemplateColumns = this.flattenedColumns.map((column) => this.withMinMax(null, column)).join(joiner) + " 0px";
                } else {
                    let hasUnknown = false;
                    gridTemplateColumns = this.flattenedColumns.map((column) => {
                        if (this.columnWidths.value[column.id] == null) {
                            hasUnknown = true;
                            return this.withMinMax(null, column);
                        } else {
                            return this.withMinMax(this.columnWidths.value[column.id], column);

                        }
                    }).join(joiner);
                    gridTemplateColumns += hasUnknown ? "0px" : "1fr";
                }

                if (this.tableRef != null) {
                    this.tableRef.style.gridTemplateColumns = gridTemplateColumns;
                }

                if (this.columnWidths.value != null) {
                    let computedColumnWidths: number[];

                    let i = 0;
                    for (const column of this.flattenedColumns) {
                        if (this.columnWidths.value[column.id] == null) {
                            computedColumnWidths ??= this.getComputedColumnWidths();

                            this.columnWidths.value[column.id] = computedColumnWidths[i];
                        }
                        i++;
                    }

                    //console.info("computedColumnWidths:");
                    //console.info(computedColumnWidths);
                    /* This call lead to endless recursion at Firefox.  I don't understand its motivation,
                     * nor do I understand by which argument recursion is supposed to end.
                     * TODO: I noticed no immediate bad consequences from removing it, please inspect why it
                     * is needed, and what needs to be done here (if any).
                     if (computedColumnWidths != null) {
                     this.applyColumnWidth();
                     }*/

                    this.correctColumnWidths();
                }
            }, 0);
        },

        setColumnWidth(colId: string, width: number) {
            width = Math.max(100, width);
            if (this.columnWidths.value == null) {
                const computedColumnWidths = this.getComputedColumnWidths();

                let i = 0;
                // because we are dealing with json we dont use an actual map.
                const columnWidths = this.columnWidths.value = Object.create(null);
                for (const column of this.flattenedColumns) {
                    columnWidths[column.id] = computedColumnWidths[i];
                    i++;
                }
            }
            if (this.columnResizeMode === ColumnResizeMode.FIXED) {
                this.columnWidths.value[colId] = width;
            } else {
                const index = this.flattenedColumns.findIndex(column => column.id === colId);
                const minimums = new Map(this.flattenedColumns.map((column, index) => {
                    return [column.id, column.minWidth ?? 150]
                }));
                const beforeColumns =  this.flattenedColumns.slice(0, index);
                const afterColumns = this.flattenedColumns.slice(index + 1);

                width = Math.max(minimums.get(colId), width);

                const setWithMin = (id: string, width: number): number => {
                    const min = minimums.get(id);

                    const actualWidth = Math.max(min, width);

                    this.columnWidths.value[id] = actualWidth;

                    return actualWidth - width;
                };

                const distributeDifference = (columns: ParempiColumn<unknown>[], diff: number): number => {
                    if (diff === 0) {
                        return 0;
                    } else if (columns.length <= 0) {
                        return diff;
                    }

                    const prevWidth = columns.reduce((acc, column) => acc + this.columnWidths.value[column.id],0);

                    /* At Firefox, the columnWidths record contained a lot of NaN values, basically only
                     * the column we touch by DnD seemed to have a non-NaN-value here (or similar).
                     * These NaN values caused endless recursion lateron.  Avoid that by returning here.
                     * TODO: Please inspect how to deal with NaN correctly here, and especially how to
                     *       guarantee properly that we don't run into endless recursion in that code.
                     */
                    if (isNaN(prevWidth)) {
                        console.warn("On calculating prevWidth: columnWidths contain NaN:");
                        for (let columnId in this.columnWidths.value) {
                            console.warn("==> column [" + columnId + "] = [" + this.columnWidths.value[columnId] + "]");
                        }
                        return 0;
                    }

                    const newWidth = prevWidth - diff;

                    const columnsWithRemainingSpace = new Set(columns);

                    let remaining = 0;
                    for (const column of columns) {
                        const columnRemaining = setWithMin(column.id, (this.columnWidths.value[column.id] * newWidth) / prevWidth);

                        if (columnRemaining > 0) {
                            columnsWithRemainingSpace.delete(column);
                        }
                        remaining += columnRemaining;
                    }

                    if (columnsWithRemainingSpace.size > 0) {
                        if (columnsWithRemainingSpace.size < columns.length) {
                            return remaining;
                            //return distributeDifference(Array.from(columnsWithRemainingSpace), remaining);
                        } else {
                            console.warn("Warning: Returning from function in case columnsWithRemainingSpace = ["
                                + columnsWithRemainingSpace + "]; return remaining = [" + remaining
                                + "] to avoid endless recursion.");
                            return remaining;
                        }
                    } else {
                        return remaining;
                    }
                };

                const diff = width - this.columnWidths.value[colId];

                let remaining;

                remaining = distributeDifference(afterColumns, diff)

                remaining = distributeDifference(beforeColumns, remaining);

                if (remaining > 0) {
                    width -= remaining;
                }

                this.columnWidths.value[colId] = width;

            }
            this.applyColumnWidth();
        },

        onHeaderDrag(event: MouseEvent, colId: string) {
            const headerElement = this.columnHeaderRefs.get(colId);

            if (event.type === "mousedown") {
                document.body.style.cursor = "col-resize";
                const headerRect = headerElement.getBoundingClientRect();
                const borderWidth = parsePx(window.getComputedStyle(headerElement).borderRightWidth);
                this.initialOffset = (event.clientX - headerRect.left) - headerRect.width + borderWidth;
            } else {
                const headerRect = headerElement.getBoundingClientRect();
                this.setColumnWidth(colId, event.clientX - headerRect.left - this.initialOffset);
            }

            if (event.type === "mouseup") {
                document.body.style.cursor = "";
                this.initialOffset = null;
            }
        },

        getColumn(columnIndex: number, rowSpan: number = 1): {
            gridColumnStart: number,
            gridColumnEnd: number,
        } {
            const endNoBorders = (columnIndex + rowSpan) * 2;
            const isEnd = (columnIndex + rowSpan) === this.flattenedColumns.length;

            return {
                gridColumnStart: (columnIndex * 2) + 1,
                gridColumnEnd: isEnd ? endNoBorders : (endNoBorders + 1),
            };
        },

        scrollIntoView(rawId : unknown) {
            let id = (typeof rawId == "number" ? rawId : parseInt(rawId as string));

            if (this.idToNode.has(id)) {
                let node = this.idToNode.get(id);
                if (node.el != null) {
                    node.el.scrollIntoView(false);
                } else {
                    let prevNode = this.idToPrevNode.get(id);
                    if (prevNode != null && prevNode.el != null) {
                        prevNode.el.scrollIntoView(false);
                    }
                }
            } else if (this.idToNextNode.has(id)) {
                let node = this.idToNextNode.get(id);
                if (node.el != null) {
                    node.el.scrollIntoView(false);
                }
            }
        }
    },

    props : {
        columns : {
            type : Array as PropType<(ParempiColumn<unknown> | ColumnGroup<unknown>)[]>,
        },

        idProperty : {
            type : String
        },

        idGetter : {
            type : Function
        },

        items : {
            type : Array
        },

        rowPosFactory : {
            type : Function
        },

        rowPosIncrementor : {
            type : Function
        },

        getRowClass: {
            type: Function as PropType<(item: any, rowPos: ParempiRowPos) => string>,
            default: () => (() => ""),
        },

        currentlySortedColumn: {
            type: String as PropType<string | undefined>,
        },

        sortMode: {
            type: Number as PropType<SortMode>,
            default: () => SortMode.DESC,
        },

        filterString: {
            type: String as PropType<string | null>,
            default: () => null,
        },

        storageKey: {
            type: String as PropType<string | null | undefined>,
        },

        disablePersistentSort : {
            type : Boolean
        },

        columnResizeMode: {
            type: String as PropType<ColumnResizeMode>,
            default: () => ColumnResizeMode.DYNAMIC,
        },

        andFilter : {
            type : Boolean,
            default : false
        },

        itemKey: {
            type: Function as PropType<(item: unknown) => (number | string | symbol)>,
            default: null,
        },
    },

    emits: ["update:currentlySortedColumn", "update:sortMode"],

    setup(props, context) {
        // non-reactive for performance
        let columnWidths = {
            value: null as (Record<string, number> | null),
        };

        if (props.storageKey != null) {
            usePersistentValue({
                key: props.storageKey + "ColumnWidths",
                set: (newColumnWidths) => columnWidths.value = (newColumnWidths ?? null),
                get: () => columnWidths.value ?? undefined,
            });
        }

        const tableRef = ref<null | HTMLElement>(null);

        let inst = getCurrentInstance().proxy;

        watchEffect(() => {
            if (tableRef.value != null) {
                // @ts-ignore
                inst.applyColumnWidth();
            }
        });

        const ourCurrentlySortedColumn = makePropWithValue(props, context as SetupContext<"update:currentlySortedColumn"[]>, "currentlySortedColumn");
        const ourSortMode = makePropWithValue(props, context as SetupContext<"update:sortMode"[]>, "sortMode");

        if (props.storageKey != null && !props.disablePersistentSort) {
            usePersistentValue<{
                mode: keyof typeof SortMode,
                column: string,
            }>({
                key: props.storageKey + "Sort",
                set: (newValue) => {
                    ourCurrentlySortedColumn.value = newValue?.column ?? null;
                    ourSortMode.value = newValue?.mode != null ? SortMode[newValue.mode] : SortMode.DESC;
                },
                get: ()  => {
                    if (ourCurrentlySortedColumn.value == null) {
                        return undefined;
                    } else {
                        return {
                            mode: SortMode[ourSortMode.value] as (keyof typeof SortMode),
                            column: ourCurrentlySortedColumn.value,
                        };
                    }
                },
            });
        }

        return {
            ourCurrentlySortedColumn,
            ourSortMode,
            columnWidths,
            initialOffset: null,
            tableRef,
            idToNode : new Map(),
            idToNextNode : new Map(),
            idToPrevNode : new Map()
        };
    },

    computed : {
        allItemTexts(): string[][] {
            const vnodes = this.renderItems(this.items).vnodes;
            return vnodes.map((row, rowIndex) => {
                if (!(row.children instanceof Array)) {
                    throw new Error("Invalid vnode structure.");
                }
                const subRows = row.children;

                return this.flattenedColumns.map((column, colIndex) =>
                    this.extractTextContentArray(subRows.map(a =>
                        // @ts-ignore
                        a.children[colIndex])));
            });
        },
        trueItems(): unknown[] {
            const currentlySortedColumn = this.ourCurrentlySortedColumn;

            const indexedItems: [unknown, number][] = this.items.map((item, index) => [item, index]);

            let filteredItems: [unknown, number][];

            if (this.filterString != null) {
                const filterString = this.filterString;
                const strings = this.allItemTexts;

                let filterTokens : string[];
                if (this.andFilter) {
                    filterTokens = filterString.split(" ");
                } else {
                    filterTokens = [filterString];
                }

                filteredItems = indexedItems;
                for (let filterToken of filterTokens) {
                    let effectiveFilterToken = filterToken.trim().toLowerCase();
                    if (effectiveFilterToken != null && effectiveFilterToken.length > 0) {
                        filteredItems = filteredItems.filter(([item, rowIndex]) =>
                            this.flattenedColumns.some((column, colIndex) =>
                                this.extractColumnsFilterText(strings, item, column, colIndex, rowIndex).some(str =>
                                    str.toLowerCase().includes(effectiveFilterToken.toLowerCase()))));
                    }
                }
            } else {
                filteredItems = indexedItems;
            }

            let sortedItems: [unknown, number][];

            if (currentlySortedColumn !== undefined) {
                const sortedColumnIndex = this.flattenedColumns.findIndex(column => column.id === currentlySortedColumn);
                const sortedColumn = sortedColumnIndex != -1 ? this.flattenedColumns[sortedColumnIndex] : null;
                if (sortedColumn != null) {
                    const sorter = this.getColumnSorter(sortedColumn, sortedColumnIndex);
                    if (sorter !== undefined) {
                        sortedItems = sorter(filteredItems);
                    } else {
                        console.warn(`Trying to sort by unsortable column ${currentlySortedColumn}`);
                        sortedItems = filteredItems;
                    }
                } else {
                    sortedItems = filteredItems;
                }
            } else {
                sortedItems = filteredItems;
            }

            return sortedItems.map(([item, index]) => item);
        },
        flattenedColumns(): ParempiColumn<unknown>[] {
            return this.columns.flatMap(column => {
                if (column instanceof ParempiColumn) {
                    return [column];
                } else {
                    return column.children;
                }
            });
        },
        hasGroups(): boolean {
            return !this.columns.every(column => column instanceof ParempiColumn);
        },
    },

    data() {
        return {
            secondHeaderRefs: [],
            firstHeaderRefs: [],
            columnHeaderRefs: new Map<string, HTMLElement | null>(),
            resizeObserver: new ResizeObserver(this.firstHeaderResizeHandler.bind(this)),
        };
    },

    beforeUpdate() {
        this.firstHeaderRefs = [];
        this.secondHeaderRefs = [];
    },

    watch: {
        firstHeaderRefs: {
            handler(firstHeaderRefs: HTMLElement[], prevFirstHeaderRefs: HTMLElement[]): void {
                this.resizeObserver.disconnect();
                if (firstHeaderRefs.length > 0) {
                    this.resizeObserver.observe(firstHeaderRefs[0]);
                }
            },
            deep: true,
        },
        columns(newColumns) {
            this.correctColumnWidths();
        }
    },


    render() {
        // Reason for implementing this as render function:
        // Being able to set up individual cells by column render function.

        let columns : ParempiColumn<any>[] = this.flattenedColumns as ParempiColumn<any>[];
        let items : any[] = this.trueItems as any[];

        if (columns == null) {
            return h("div", null, "Please set columns array.");
        }
        if (items == null) {
            return h("div", null, "Please set items array.");
        }

        const renderChildColumn = (column: ParempiColumn<unknown>, rowStart: number, rowEnd: number, index: number, ref: (el: HTMLElement | null) => void) => {
            const resizeHandle = withDirectives(h("div", {
                class: "parempiResizeHandle",
            }), [[OnDrag, (e) => this.onHeaderDrag(e, column.id)]]);

            const labelElement = column.label == null ? h("div", null, this.$slots[column.headerSlotName]?.()) : column.label;

            const vnode = h(
            // @ts-ignore
                "th",
                {
                    scope: "col",
                    role: "columnheader",
                    "class": [
                        "parempiHeaderCell",
                        "parempiLastVerticalCell",
                        {
                            "parempiLastHorizontalCell": index === (this.flattenedColumns.length - 1),
                            "parempiFirstHorizontalCell": index === 0,
                        },
                    ],
                    onClick: this.getColumnSorter(column, index) == null ? undefined : (e) => this.onHeaderClicked(e, column, index),
                    style: {
                        ...this.getColumn(index),
                        gridRowStart: rowStart,
                        gridRowEnd: rowEnd,
                    },
                    ref: (el: HTMLElement | null) => {
                        ref(el);
                        this.columnHeaderRefs.set(column.id, el);
                    },
                },
                (this.ourCurrentlySortedColumn === column.id ? [
                    labelElement,
                    h("div", { "class": "parempiSortIcon" }, h("i", { "class": ["bi", this.ourSortMode === SortMode.ASC ? "bi-caret-up-fill" : "bi-caret-down-fill"] })),
                    resizeHandle,
                ] : [
                    labelElement,
                    resizeHandle,
                ]),
            );

            if (column.headerWrappingSlotName != null) {
                return this.$slots[column.headerWrappingSlotName]({
                    Component: vnode,
                });
            } else {
                return vnode;
            }
        }

        let headerLines: VNode[] = [];
        let headerGroupsNumber = 0;
        headerLines.push(h("tr", { role: "row" }, this.columns.map(column => {
            const headerGroupsNumber2 = headerGroupsNumber;
            let ret;
            if (column instanceof ParempiColumn) {
                ret = renderChildColumn(column, 1, this.hasGroups ? 3 : 2, headerGroupsNumber2, (el) => {
                    this.firstHeaderRefs[headerGroupsNumber2] = el;
                });
            } else {
                ret = h(
                    "th",
                    {
                        scope: "col",
                        role: "columnheader",
                        "class": [
                            "parempiHeaderCell",
                            {
                                "parempiLastHorizontalCell": (headerGroupsNumber + column.children.length - 1) === (this.flattenedColumns.length - 1),
                                "parempiFirstHorizontalCell": headerGroupsNumber === 0,
                            },
                        ],
                        style: {
                            ...this.getColumn(headerGroupsNumber, column.children.length),
                            gridRowStart: 1,
                            gridRowEnd: 2,
                        },
                        ref: (el) => {
                            this.firstHeaderRefs[headerGroupsNumber2] = el;
                        },
                    },
                    column.label);
            }
            headerGroupsNumber += (!(column instanceof ParempiColumn) ? column.children.length : 1);
            return ret;
        }).concat([h("th", {
            "aria-hidden": true,
            "class": "parempiEndCell",
            style: {
                gridRowStart: 1,
                gridRowEnd: (this.hasGroups ? 3 : 2),
                gridColumn: this.flattenedColumns.length * 2,
            },
        })])));

        if (this.hasGroups) {
            let headerCells : (VNode | VNode[])[] = [];
            let columnHeaderNumber = 0;
            for (let columnOrGroup of this.columns) {
                if (columnOrGroup instanceof ParempiColumn) {
                    columnHeaderNumber++;
                    continue;
                }
                for (const column of columnOrGroup.children) {
                    const columnHeaderNumber2 = columnHeaderNumber;
                    headerCells.push(renderChildColumn(column, this.hasGroups ? 2 : 1, this.hasGroups ? 3 : 2, columnHeaderNumber, (el) => {
                        this.secondHeaderRefs[columnHeaderNumber2] = el;
                    }));
                    columnHeaderNumber++;
                }
            }
            headerLines.push(h("tr", { role: "row" }, headerCells));
        }
        let header : VNode = h("thead", { role: "rowgroup" }, headerLines);

        const {
            vnodes: lines,
            numberOfRows,
        } = this.renderItems(items);
        let body : VNode = h("tbody", lines);

        let table : VNode = h(
            "table",
            {
                class : "parempi-grid dgrid-table",
                style: {
                    gridTemplateRows: `repeat(${(this.hasGroups ? 2 : 1) + numberOfRows}, auto) minmax(0, 1fr)`,
                },
                role: "table",
                ref: (ref) => this.tableRef = ref as HTMLTableElement,
            },
            [ header, body, numberOfRows > 0 ? h("div", {
                "aria-hidden": true,
                class: "parempiEndLine",
            }) : null ]
        );

        return table;
    }
});
