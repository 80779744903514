/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { RoleConflictInfo } from "cdes-api/dto/project/RoleConflictInfo";
import RoleConflictListWidget from "cdes-vue/project/participation/RoleConflictListWidget.vue";
import { defineComponent, VNode, h, PropType } from 'vue';

export default defineComponent({
    components : {
        RoleConflictListWidget
    },

    computed : {
    },
/*
    data() {
        return {
//            columns : this.constructColumns(),
        };
    },
*/
    methods : {
        /*
        constructColumns() : ParempiColumn<RoleConflictJoin>[] {
            let columns : ParempiColumn<RoleConflictJoin>[] = [
                ParempiColumn.asRowSpanSlot("affectedPerson", this.$t("project.participation.list.participantHistory.participant"), "affectedPerson"),
                ParempiColumn.asRowSpanSlot("projectParticipantHistoryPartStartDate", this.$t("project.participation.list.participantHistory.from"), "projectParticipantHistoryPartStartDate"),
                ParempiColumn.asRowSpanSlot("projectParticipantHistoryPartEndDate", this.$t("project.participation.list.participantHistory.to"), "projectParticipantHistoryPartEndDate"),
                ParempiColumn.asRowSpanSlot("modifyingPersonGivenName", this.$t("project.participation.list.participantHistory.changedByOn"), "modifyingPersonGivenName"),
                ParempiColumn.asRowSpanSlot("projectParticipantInactiveComment", this.$t("project.participation.list.participantHistory.remark"), "projectParticipantInactiveComment"),
            ];

            return columns;
        },*/
    },

    props : {
        roleConflictInfo : Object as PropType<RoleConflictInfo>
    }
});
