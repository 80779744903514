/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { Popover } from "bootstrap";

export default {
	mounted(el, { value }: { value: any }) {
        Popover.getOrCreateInstance(el, value);
	},
	unmounted(el) {
        const popover = Popover.getInstance(el);
        if (popover != null) {
            popover.dispose();
        }
	},
};
