/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, watch, ref, PropType } from "vue";
import { usePersistentValue, eagerComputed } from "cdes-vue/util/Prop";
import { useCtx } from "cdes-vue/util/Ctx";
import { LoadingPlaceholder, loadingPlaceholder, mapLoading, asyncEagerComputed, makePropWithValue, debounceWatcher } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
// @ts-ignore
import cssVariables from "./ReviewWidget.scss";
import ReviewHeader from "./ReviewHeader.vue";
import ReviewNavigation from "./ReviewNavigation.vue";
import ReviewData from "./ReviewData.vue";
import ReviewEntry from "./ReviewEntry.vue";
import { ReviewContext } from "cdes-api/dto/review/ReviewContext";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import ReviewProtocolContext from "./ReviewProtocolContext";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ContextJoin } from "cdes-api/joinDto/ContextJoin";

export default defineComponent({
    components: {
        ReviewHeader,
        ReviewNavigation,
        ReviewData,
        FormGridMember,
        ReviewEntry,
    },

    props: {
        documentVersionId: {
            type: Number as PropType<number>,
        },
        taskId: {
            type: Number as PropType<number>,
        },
        overriding: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        taskStatus: {
            type: Number as PropType<number>
        }
    },

    data() {
        return {
            selectedVersionId: null,
            showAll: false,
        };
    },

    watch: {
        selectedVersionId() {
            this.navigationShown = false;
            this.showAll = false;
        },
        data(data: ReviewProtocolData | LoadingPlaceholder): void {
            if (data !== loadingPlaceholder) {
                let versionJoinCount = data.versionJoins != null ? data.versionJoins.length : null;
                let initialVersionJoinIndex = versionJoinCount != null && versionJoinCount > 0 ? versionJoinCount - 1 : null;
                this.selectedVersionId = initialVersionJoinIndex != null
                    ? data.versionJoins[initialVersionJoinIndex]?.documentVersionId : null;
            }
        },
    },

    computed: {
        context(): ReviewProtocolContext | LoadingPlaceholder {
            if (this.data === loadingPlaceholder) {
                return loadingPlaceholder;
            } else {
                return new ReviewProtocolContext(this.data);
            }
        },
        disabled() : boolean {
            return this.data === loadingPlaceholder || this.reviewData === loadingPlaceholder;
        }
    },

    setup(props) {
        let rightWidthValue: number | undefined = undefined;
        const navigationShown = ref(false);

        usePersistentValue<number | undefined>({
            key: "reviewWidgetRightWidth",
            set: (v) => rightWidthValue = v,
            get: () => rightWidthValue,
        });

        const rightRef = ref<null | HTMLElement>(null);
        const separatorRef = ref<null | HTMLElement>(null);
        const gridRef = ref<null | HTMLElement>(null);

        const setGridTemplateColumns = () => {
            const rightWidth = rightWidthValue == null ? "30em" : rightWidthValue + "px";

            const columnsWithoutNav = `1fr ${cssVariables.widgetGap} minmax(min-content, ${rightWidth})`;

            const gridTemplateColumns = navigationShown.value ? `30em ${columnsWithoutNav}` : columnsWithoutNav;
            gridRef.value.style.gridTemplateColumns = gridTemplateColumns;
        }

        watch([rightRef, navigationShown], ([rightRef]) => {
            if (rightRef != null) {
                setGridTemplateColumns();
            }
        }, {
            flush: "post",
        });

        let initialOffset = null as (null | number);

        const onSeparatorDrag = (e: MouseEvent) => {

            if (e.type === "mousedown") {
                const gap = Number.parseInt(/^\s*(\d*)\s*px\s*$/.exec(window.getComputedStyle(gridRef.value).getPropertyValue("column-gap"))?.[1]);
                const separatorRect = separatorRef.value.getBoundingClientRect();
                initialOffset = e.clientX - separatorRect.right - gap - gap;
                document.body.style.cursor = "col-resize";
            } else {
                const rect = rightRef.value.getBoundingClientRect();
                rightWidthValue = Math.max(rect.right - e.clientX + initialOffset, 0);
                setGridTemplateColumns();
            }

            if (e.type === "mouseup") {
                document.body.style.cursor = "";
            }

        }


        const ctx = useCtx();

        const data = asyncEagerComputed(() => {
            return ctx.documentService.getReviewProtocolData(ctx.activeOrganisationPersonId, props.documentVersionId);
        }, loadingPlaceholder);

        const reviewData = asyncEagerComputed(() => {
            return ctx.reviewService.getReviewData(props.taskId, ctx.activeOrganisationPersonId, props.overriding)
                .then(reviewData => {
                    let contextJoin : ContextJoin = reviewData.contextJoin;
                    ctx.activeNetworkId = contextJoin.networkId;
                    ctx.activeProjectId = contextJoin.projectId;
                    ctx.activeSubProjectId = contextJoin.subProjectId;                                            
                    
                    return new ReviewContext(reviewData);
                }, err => {
                    ErrorHelper.processErrorWithoutI18n(err);
                });
        }, loadingPlaceholder);

        return {
            navigationShown,
            data,
            onSeparatorDrag,
            rightRef,
            separatorRef,
            gridRef,
            rightWidthValue,
            reviewData,
        };
    },

    methods : {
        versionClicked() {
            this.navigationShown = false;
            this.showAll = false;
        }
    }
});
