/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ScratchFile from "cdes-api/dto/job/ScratchFile";
import JobId from "cdes-api/dto/job/JobId";
import { ErrorHelper } from "../ErrorHelper";

export default defineComponent({
    props: {
        startDownload: {
            type: Function as PropType<() => Promise<JobId<ScratchFile>>>,
        },
        pollDelay: {
            type: Number as PropType<number>,
            default: () => 1000,
        },
        disabled : {
            type : Boolean
        }
    },

    data() {
        return {
            isDownloading: false,
            error: undefined as (undefined | string),
        };
    },

    methods: {
        poll(jobId: JobId<ScratchFile>): Promise<void> {
            return this.ctx.jobStatusService.waitForJob(jobId, this.pollDelay)
            .then(status => {
                if (status.done) {
                    this.isDownloading = false;
                    if ("exceptionMessage" in status) {
                        this.error = status.exceptionMessage;
                    } else {
                        const link = "/cdes-dojo-impl/download?" + new URLSearchParams({
                                jobId,
                            });

                        if (status.result.disposition.startsWith("inline")) {
                            window.open(link);
                        } else {
                            const anchor = document.createElement("a");
                            anchor.download = "";
                            anchor.href = link;
                            anchor.click();
                        }
                    }
                    return undefined;
                } else {
                    return this.poll(jobId);
                }
            }, err => {
                ErrorHelper.processErrorWithoutI18n(err);
            });
        },
        start() {
            this.isDownloading = true;
            this.error = undefined;
            this.startDownload()
                .then(jobId => this.poll(jobId), err => {
                    ErrorHelper.processErrorWithoutI18n(err);
                });
        },
    },
});
