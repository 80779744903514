/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent } from "vue";
import ProjectSearchWidget from "./ProjectSearchWidget.vue";
import ProjectListWidget from "./ProjectListWidget.vue";
import Collapse from "cdes-vue/util/layout/Collapse.vue";

import { ProjectPageSearchModel } from "cdes-api/dto/project/ProjectPageSearchModel";
import { ProjectInfo } from "cdes-api/dto/project/ProjectInfo";
import { ProjectAccess } from "cdes-vue/voc/project/ProjectAccess";
import { ProjectListPageInfo } from "cdes-api/dto/project/ProjectListPageInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { SubProjectInfo } from "cdes-api/dto/project/SubProjectInfo";
import { LocalStorageHelper } from "cdes-vue/util/LocalStorageHelper";

export default defineComponent({
    components: {
        ProjectListWidget,
        ProjectSearchWidget,
        Collapse,
    },

    computed : {
        networkId() : number {
            return this.ctx.activeNetworkId;
        },

        countLabelString() : string {
			let returnString = "";
			if (this.items != null && !this.searchRunning){
                let count = this.items.length;
				returnString = count != null ? (count + " " + this.$t("general.entries")) : "";
			} else if (this.searchRunning){
				returnString = this.$t("general.searchRuns");
			}
            return returnString;
        },
        
        disabled() : boolean {
            return this.queryPageInfoRunning || this.searchRunning;
        }
    },

    watch : {
        networkId(newNetworkId : number) {
            this.queryProjectListPageInfo(newNetworkId);
        }
    },

    setup(props) {
        console.info(props);
    },

    mounted() {
        this.queryProjectListPageInfo(this.ctx.activeNetworkId);
    },

    data() {
        return {
            items: [] as ProjectInfo[],
            projectListPageInfo : null,
            queryPageInfoRunning : false,
            searchRunning : false,
            menuSection : this.$route.meta.section
        };
    },

    methods: {
        queryProjectListPageInfo(newNetworkId : number) : void {
            this.queryPageInfoRunning = true;
            this.ctx.projectService.getProjectListPageInfo(this.ctx.activeOrganisationPersonId, null)
                .then((projectListPageInfo : ProjectListPageInfo) => {
                    this.projectListPageInfo = projectListPageInfo;
                    this.queryPageInfoRunning = false;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                    this.queryPageInfoRunning = false;
                });
        },
        onSearch(searchModel: ProjectPageSearchModel): void {
            let opString = this.ctx.activeOrganisationPersonId != null ? this.ctx.activeOrganisationPersonId.toString() : "---";
            LocalStorageHelper.storeInLocalStorage([ opString ], "/project/search", searchModel);
            
            this.searchRunning = true;
            this.ctx.projectService.getProjectInfos(this.ctx.activeOrganisationPersonId, searchModel)
            .then(items => {
                this.items = items;
                for (let item of this.items) {
                    item.subProjects = item.subProjects.sort((sp1 : SubProjectInfo, sp2 : SubProjectInfo) => {
                        // @ts-ignore
                        let keyOne = (sp1.code ?? '') + (sp1.number ?? '');
                        // @ts-ignore                        
                        let keyTwo = (sp2.code ?? '') + (sp2.number ?? '');
                        console.info("keyOne: [" + keyOne + "], keyTwo [" + keyTwo + "], compare ["
                            + keyOne.localeCompare(keyTwo) + "]");
                        return keyOne.localeCompare(keyTwo);
                    });
                }
                this.searchRunning = false;
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
                this.searchRunning = false;
            });
        },
        makeProjectAdmin(projectId : number) : void {
            let projectIdx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                if (this.items[n].projectId == projectId) {
                    projectIdx = n;
                }
            }

            if (projectIdx != null) {
                this.items[projectIdx].isProjectAdmin = true;
            }
        },

        changeProjectAccess(projectId : number, targetAccess : ProjectAccess) : void {
            let projectIdx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                if (this.items[n].projectId == projectId) {
                    projectIdx = n;
                }
            }

            if (projectIdx != null) {
                this.items[projectIdx].projectAccess = targetAccess;
            }
        },

        removeProject(projectId : number) : void {
            let projectIdx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                if (this.items[n].projectId == projectId) {
                    projectIdx = n;
                }
            }

            if (projectIdx != null) {
                this.items.splice(projectIdx, 1);
            }
        },

        removeSubProject(projectId : number, subProjectId : number) : void {
            let projectIdx : number = null;
            for (let n = 0; n < this.items.length; n++) {
                if (this.items[n].projectId == projectId) {
                    projectIdx = n;
                }
            }
            let subProjectIdx : number = null;
            if (projectIdx != null) {
                for (let n = 0; n < this.items[projectIdx].subProjects.length; n++) {
                    if (this.items[projectIdx].subProjects[n].id == subProjectId) {
                        subProjectIdx = n;
                    }
                }
            }
            if (subProjectIdx != null) {
                this.items[projectIdx].subProjects.splice(subProjectIdx, 1);
                console.info("Spliced away deleted subProject [" + subProjectId
                    + "]; projectIdx [" + projectIdx + "]; subProjectIdx [" + subProjectIdx + "]");
            } else {
                console.warn("Failed so splice away subProject [" + subProjectId + "]");
            }
        }
    },
});
