/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType, inject, ref, computed } from "vue";
import FormGrid, { Breakpoint, singleColumnBreakpointKey, columnsKey, gutterKey } from "cdes-vue/util/form/FormGrid.vue";

export default defineComponent({
    props: {
        size: {
            type: Number as PropType<undefined | number>,
        },
    },

    setup(props) {
        const columns = inject(columnsKey, ref(1));

        return {
            gutter: inject(gutterKey, ref(3)),
            gridSize: computed(() => props.size ?? 12 / columns.value),
            singleColumnBreakpoint: inject(singleColumnBreakpointKey, ref("lg")),
        };
    },
});
