/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { ComputedRef, defineComponent, getCurrentInstance, PropType, reactive, ref } from "vue";
import { useValidationForm, useValidity, ValidationResult } from "cdes-vue/util/directives/ValidationForm";
import Transform from "cdes-vue/util/Transform";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import { ProjectParticipationJoin } from 'cdes-api/joinDto/ProjectParticipationJoin';
import { PersonSearchModel } from 'cdes-api/dto/PersonSearchModel';
import { OrganisationPersonJoin } from 'cdes-api/joinDto/OrganisationPersonJoin';
import { Project } from "cdes-api/dto/Project";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    name: "InstantEmailPage",

    components: {
        Transform,
        TextInput,
        FormGridColumn,
        FormGridLocal,
    },

    props: {

        participationIds: {
            type: Array as PropType<number[]>
        },
        organisationPersonId: {
            type: Number as PropType<number | null>
        },
        targetProjectId: {
            type: Number
        }
    },

    beforeMount() {
        this.sender = this.ctx.activeOrganisationPerson;

        //TODO: Get recipients from given parameters

        // Get the recipients depending on parameters given

        // If organisationPersonId is given, fetch recipient directly
        if (this.organisationPersonId != null) {
            let organisationPersonIdArray = (Array.isArray(this.organisationPersonId) && this.organisationPersonId.length > 0) ?
                this.organisationPersonId : [this.organisationPersonId];

            this.ctx.personService.getOrganisationPersonJoins(this.sender.organisationPersonId, organisationPersonIdArray).then(
                (recipientPersonJoins : OrganisationPersonJoin[]) => {
                    if (recipientPersonJoins.length !== 1) {
                        throw new Error("Query for a specific OrganisationPerson returned " + recipientPersonJoins.length + "entries.")
                    }
                    this.recipients = recipientPersonJoins.map((r) => {
                        return {
                            organisationPersonId: r.organisationPersonId,
                            organisationPersonEmailAddress: r.organisationPersonEmailAddress,
                            organisationPersonFullName: r.personGivenName + " " + r.personSurName
                        }
                    });
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                }
            )
        } else if (this.participationIds != null) {
            //If any number of participationIds are given, then find the recipient emailAddresses via a join
            let participationIdsArray = (Array.isArray(this.participationIds)) ? this.participationIds : [this.participationIds];

            this.ctx.projectService.getParticipationJoinsByIds(this.ctx.activeOrganisationPersonId, participationIdsArray).then((recipientPPs) => {
                this.recipients = recipientPPs.map((r) => {
                    return {
                        organisationPersonId: r.organisationPersonId,
                        organisationPersonEmailAddress: r.organisationPersonEmailAddress,
                        organisationPersonFullName: r.personGivenName + " " + r.personSurName
                    };
                })
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            })
        } else if (this.targetProjectId != null) {
            this.ctx.projectService.getById(this.targetProjectId)
                .then((project : Project) => {
                    this.targetProject = project;
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        }
    },

    data() {
        //Do not allow a different network or project ID to be used in sendEmail() than the one set on page initialization
        const initNetworkId = this.ctx.activeNetworkId;
        const initProjectId = this.ctx.activeProjectId;

        return {
            sender: undefined,
            recipients: undefined,
            subject : undefined,
            content : undefined,
            networkId : initNetworkId,
            projectId : initProjectId,
            targetProject : null
        };
    },

    computed: {
        recipientAddresses() : string {
            if (this.targetProjectId != null) {
                return this.$t("project.participation.page.mailToAllTargetText", {
                    code : this.targetProject?.code,
                    name : this.targetProject?.name
                });
            } else if (!this.recipients) {
                return "";
            } else {
                return this.recipients.map((r) => `${r.organisationPersonFullName} <${r.organisationPersonEmailAddress}>`).join(", \n");
            }
        }

    },

    methods: {
        sendEmail() : void {
            if (this.targetProjectId != null) {
                this.ctx.emailService.sendEmailsToProject(
                    this.sender.organisationPersonId,
                    this.targetProjectId,
                    this.networkId,
                    this.projectId,
                    this.subject,
                    this.content
                ).then(() => {
                    this.$router.go(-1);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            } else {
                let organisationPersonIds : number[] = [];
                for (let recipient of this.recipients) {
                    organisationPersonIds.push(recipient.organisationPersonId);
                }

                this.ctx.emailService.sendEmails(
                    this.sender.organisationPersonId,
                    organisationPersonIds,
                    this.networkId,
                    this.projectId,
                    this.subject,
                    this.content
                ).then(() => {
                    this.$router.go(-1);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
            }
        }
    }

});
