/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType } from "vue";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { LoadingPlaceholder, loadingPlaceholder } from "cdes-vue/util/Promise";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import { Tooltip } from "bootstrap";
import ReviewProtocolContext from "./ReviewProtocolContext";
import ReviewFileWidget from "./ReviewFileWidget.vue";
import ScratchFile from "cdes-api/dto/job/ScratchFile";
import JobId from "cdes-api/dto/job/JobId";
import JobDownloadButton from "cdes-vue/util/layout/JobDownloadButton.vue";

function escapeHtml(str: string): string {
    const e = document.createElement("p");
    e.textContent = str;
    return e.innerHTML;
}

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
        FormGridMember,
        FormGridLocal,
        ReviewFileWidget,
        JobDownloadButton,
    },

    setup() {
        return {
            Tooltip,
        };
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        documentVersionId: Number as PropType<number>,
        disabled : {
            type : Boolean
        }
    },

    computed: {
        infoPopupContent(): string {
            if (this.context === loadingPlaceholder) {
                return " ";
            }
            return "<dl>" + [
                [this.$t("general.object"), this.context.baseJoin.objectReleaseCode + " " + this.context.baseJoin.objectReleaseName],
                [this.$t("general.objectPlanner"), this.context.baseJoin.objectPlannerReleaseCode + " " + this.context.baseJoin.objectPlannerReleaseArea],
                [this.$t("review.review.header.fromTo"), this.context.baseJoin.objectReleaseStretchKmFrom + " – " + this.context.baseJoin.objectReleaseStretchKmTo],
                [this.$t("general.reviewCycleInstance"), this.context.baseJoin.reviewCycleInstanceReleasedName],
                [this.$t("general.planner"), this.context.baseJoin.plannerOrganisationName + " (" + this.context.baseJoin.plannerPersonGivenName + " " + this.context.baseJoin.plannerPersonSurName + ")"],
            ].map(([key, value]) => `<dt>${escapeHtml(key)}</dt><dd>${escapeHtml(value)}</dd>`).join("") + "</dl>";
        },

        content() : string {
            if (this.context === loadingPlaceholder || this.context == null || this.context.baseJoin == null) {
                return "";
            } else if (this.context.baseJoin.documentReleaseId != null) {
                return this.context.baseJoin.documentReleaseContent;
            } else {
                return this.context.baseJoin.documentContent;
            }
        },

        scale() : string {
            if (this.context === loadingPlaceholder || this.context == null || this.context.baseJoin == null) {
                return "";
            } else if (this.context.baseJoin.documentReleaseId != null) {
                return this.context.baseJoin.documentReleaseScale;
            } else {
                return this.context.baseJoin.documentScale;
            }
        }        
    },

    methods: {
        startReviewProtocolDownload(): Promise<string> {
            return this.ctx.documentService.triggerExportReviewProtocol(this.ctx.activeOrganisationPersonId, this.documentVersionId);
        },
    },
});
