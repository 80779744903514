import { ConsortiumPersonInfo } from "cdes-api/dto/person/ConsortiumPersonInfo";
import { OrganisationParticipationInfo } from "cdes-api/dto/project/OrganisationParticipationInfo";
import { defineComponent, ref, onMounted, onBeforeUnmount, PropType } from "vue";
import CheckBox from "cdes-vue/util/form/CheckBox.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { ParticipationInfo } from "cdes-api/dto/project/ParticipationInfo";
import { ParempiRowPos } from "cdes-vue/util/grid/ParempiRowPos";
import { ParticipantInfo } from "cdes-api/dto/project/ParticipantInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

export default defineComponent({
    components : {
        CheckBox,        
        TextInput
    },

    name: "DropdownAddDropdown",

    props : {
        disabled : Boolean,
        item : Object as PropType<OrganisationParticipationInfo>,
        rowPos : Object as PropType<ParempiRowPos>,
        personInfos : Array as PropType<ConsortiumPersonInfo[]>,
        participationInfo : Object as PropType<ParticipationInfo>
    },    

    data() {
        return {
            active: ref(false),
            offerParticipationsOfAllOrganisations : false,
            deputyAddFilter : ""
        };
    },

    mounted() {
        document.addEventListener('click', this.close);        
    },

    beforeUnmount() {
        document.removeEventListener('click', this.close);
    },

    computed : {
        deputyOptions() : ConsortiumPersonInfo[] {
            let filterString = this.deputyAddFilter != null ? this.deputyAddFilter.trim() : null;

            let participationInfo : ParticipationInfo = this.participationInfo;
            if (participationInfo == null) {
                return [];
            }
            
            let participationOrganisationId : number = participationInfo.projectParticipation.organisationId;
            let mainParticipantInfo : ParticipantInfo = this.mainParticipantInfo;
            let existingDeputyInfos : ParticipantInfo[] = this.deputyInfos;
            let existingDeputyOpIds : Set<number> = new Set<number>();
            if (mainParticipantInfo != null) {
                existingDeputyOpIds.add(mainParticipantInfo.organisationPerson.id);
            }
            for (let existingDeputyInfo of existingDeputyInfos) {
                existingDeputyOpIds.add(existingDeputyInfo.organisationPerson.id);
            }
            
            let rawPersonInfos : ConsortiumPersonInfo[] = this.personInfos;
            let personInfos : ConsortiumPersonInfo[] = [];

            for (let rawPersonInfo of rawPersonInfos) {
                let currOrganisationPersonId : number = rawPersonInfo.organisationPerson.id;
                let currOrganisationId : number = rawPersonInfo.organisation.id;
                let currConsortiumId : number = rawPersonInfo.consortium?.id;
                let candidateString = this.getPersonOrganisationString(rawPersonInfo);
                if (!existingDeputyOpIds.has(currOrganisationPersonId)
                    && (this.offerParticipationsOfAllOrganisations
                        || participationOrganisationId == currOrganisationId
                        || participationOrganisationId == currConsortiumId)
                    && (   filterString == null || filterString.length == 0
                        || candidateString.toLowerCase().indexOf(filterString.toLowerCase()) != -1)) {
                    personInfos.push(rawPersonInfo);
                }
            }
            
            return personInfos;
        },
        mainParticipantInfo() : ParticipantInfo {
            return this.participationInfo.mainParticipantInfo;
        },
        deputyInfos() : ParticipantInfo[] {
            return this.participationInfo.deputyInfos;
        },        
    },

    methods : {
        close() {
            this.active = false
        },
        toggleActive() {
            this.active = !this.active;
        },
        getPersonOrganisationString(consortiumPersonInfo : ConsortiumPersonInfo) : string {
            return consortiumPersonInfo.person.surName + " " + consortiumPersonInfo.person.givenName
                + " (" + consortiumPersonInfo.organisation.name + ")";
        },
        addDeputy(participationInfo : ParticipationInfo, consortiumPersonInfo : ConsortiumPersonInfo) : void {
            let organisationPersonId : number = this.ctx.activeOrganisationPersonId;
            let deputyOrganisationPersonId : number = consortiumPersonInfo.organisationPerson.id;
            let participationId : number = participationInfo.projectParticipation.id;
            this.ctx.projectService.insertDeputy(organisationPersonId, deputyOrganisationPersonId, participationId)
                .then((participantInfo : ParticipantInfo) => {
                    participantInfo.participationInfo = participationInfo;
                    participationInfo.deputyInfos.push(participantInfo);
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        }        
    }
});
