/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import { makePropWithValue, loadingPlaceholder, LoadingPlaceholder } from "cdes-vue/util/Prop";
import { ReviewProtocolData } from "cdes-api/dto/review/ReviewProtocolData";
import { Attachment } from "cdes-api/dto/Attachment";
import { ReviewProtocolVersionJoin } from "cdes-api/joinDto/ReviewProtocolVersionJoin";
import ReviewProtocolContext from "./ReviewProtocolContext";
import { ReviewCycleResultOption } from "cdes-api/dto/ReviewCycleResultOption";
import { keyToCmp } from "cdes-vue/util/Sort";
import { takeWhile } from "cdes-vue/util/Array";
import DOMPurify from "dompurify";

export default defineComponent({
    components: {
        LoadedOrPlaceholder,
    },

    props: {
        context: {
            type: [ReviewProtocolContext, Symbol] as PropType<ReviewProtocolContext | LoadingPlaceholder>,
        },
        version: {
            type: [Object, Symbol] as PropType<ReviewProtocolVersionJoin | LoadingPlaceholder>,
        },
        nodeResult: {
            type: [Object, Symbol] as PropType<ReviewProtocolVersionJoin | LoadingPlaceholder>,
        },
    },

    methods: {
        sanitize: DOMPurify.sanitize,
        getAttachmentLink(attachment: Attachment): string {
            if (attachment.isRedliningDelta) {
                return this.ctx.getTapestryRequestUrl("CDESMimeService/6/documentVersionReviewProtocolPdfMimeSource", [""+attachment.documentVersionId]);
            } else {
                return "/cdes-dojo-impl/repositoryDownload/attachments/" + this.ctx.activeOrganisationPersonId + "/" + attachment.id;
            }
        },
    },

    computed: {
        commentReferences(): [ReviewProtocolVersionJoin, ReviewProtocolVersionJoin, ReviewCycleResultOption, ReviewCycleResultOption][] | [LoadingPlaceholder, LoadingPlaceholder, LoadingPlaceholder, LoadingPlaceholder][] {
            const { context, version, nodeResult } = this;
            if (context === loadingPlaceholder || version === loadingPlaceholder || nodeResult === loadingPlaceholder) {
                return new Array(4).fill([loadingPlaceholder, loadingPlaceholder, loadingPlaceholder, loadingPlaceholder]);
            } else {
                const nodeResults = Array.from(context.cellResultIdToNodeResultIdToNodeResult.get(nodeResult.reviewCycleCellResultId).values())
                .sort(keyToCmp(join => join.reviewCycleNodePosition ?? 0));
                const positionResults = Array.from(context.nodeResultIdToPositionResultIdToPositionResult.get(nodeResult.reviewCycleNodeResultId)?.values() ?? []);


                return takeWhile(nodeResults, join => join.reviewCycleNodeResultId !== nodeResult.reviewCycleNodeResultId)
                .filter(join => join.crossCommentNodeId != null)
                .flatMap(join => {
                    const ourPositionResultIds = new Set(Array.from(context.nodeResultIdToPositionResultIdToPositionResult.get(join.reviewCycleNodeResultId)?.values() ?? []).map(positionResult => positionResult.reviewCyclePositionResultId));
                    const chosenPositionResults = positionResults
                    .filter(positionResultJoin => ourPositionResultIds.has(positionResultJoin.reviewCyclePositionResultCommentReferenceId));

                    const chooseThisNodeResult = chosenPositionResults.some(positionResult => positionResult.reviewCyclePositionResultDigest != null
                                                                                         && (positionResult.reviewCyclePositionResultComment
                                                                                         || positionResult.reviewCyclePositionResultReviewCycleCommentOptionId != null
                                                                                         || context.hasPositionResultAttachments(positionResult.reviewCyclePositionResultId)))

                    if (chooseThisNodeResult) {
                        return chosenPositionResults
                        .map(positionResultJoin => {
                            const commentReference = context.idToPositionResultJoin.get(positionResultJoin.reviewCyclePositionResultCommentReferenceId);
                            return [positionResultJoin,
                                    commentReference,
                                    context.idToResultOption.get(positionResultJoin.reviewCycleNodeResultReviewCycleResultOptionId),
                                    context.idToResultOption.get(commentReference.reviewCycleNodeResultReviewCycleResultOptionId)
                            ] as [ReviewProtocolVersionJoin, ReviewProtocolVersionJoin, ReviewCycleResultOption, ReviewCycleResultOption];
                        })
                        .sort(keyToCmp(([positionResultJoin, commentReferenceJoin]) => commentReferenceJoin.reviewCyclePositionResultResultDate ?? 0));
                    } else {
                        return [];
                    }
                });
            }
        },
        hidden(): boolean {
            return this.commentReferences.length <= 0;
        },
    },

    watch: {
        hidden: {
            handler(hidden: boolean): void {
                this.$emit("update:hidden", hidden);
            },
            immediate: true,
        },
    },

    setup() {
        return {
            loadingPlaceholder,
        };
    },
});
