import { DtoHelper } from "dmgen-cli/util/DtoHelper";
import { Persistent } from "../../cdes-api/dto/Persistent";

export class RoleType extends Persistent {

    // Properties: (placed here as documentation)
    //
    // name : string;
    // description : string;
    // type : number;

    private _name : string;
    get name() : string {
        this.__checkAttributeDefinition(4, "name");
        return this._name;
    }
    set name(name : string) {
        this._name = name;
    }

    private _description : string;
    get description() : string {
        this.__checkAttributeDefinition(5, "description");
        return this._description;
    }
    set description(description : string) {
        this._description = description;
    }

    private _type : number;
    get type() : number {
        this.__checkAttributeDefinition(6, "type");
        return this._type;
    }
    set type(type : number) {
        this._type = type;
    }


    public static constructPartialInstance() : RoleType {
        let instance = new RoleType();
        instance.__setPartialInstance(true);
        return instance;
    }

    public static constructTrackedPartialInstance(__definedAttributes : boolean[]) : RoleType {
        let instance = new RoleType();
        instance.__setPartialInstance(false);
        for (let definedAttribute of __definedAttributes) {
            if (!definedAttribute) {
                instance.__setPartialInstance(true);
            }
        }
        instance.__setDefinedAttributes(__definedAttributes);
        return instance;
    }

    public equals(other : RoleType) : boolean {
        if (this === other) { return true; }
        if (other == null) { return false; }
        return true 
            && super.equals(other)
            && DtoHelper.areMembersEqual(this.name, other.name, "String")
            && DtoHelper.areMembersEqual(this.description, other.description, "String")
            && DtoHelper.areMembersEqual(this.type, other.type, "Integer")            ;
    }

    public toString() : string {
                return "Not yet implemented."
    }

    public toJSON() : Record<string, unknown> {
        return {
            ...super.toJSON(),
            name: this._name,
            description: this._description,
            type: this._type,
        };
    }
}
