/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */
import { defineComponent, PropType, ref } from "vue";
import { makePropWithValue } from "cdes-vue/util/Prop";
import { Dropdown } from "bootstrap";
import ValidationError from "./ValidationError";
import TreeView, { TreeModel } from "../layout/TreeView.vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
export type { TreeModel } from "../layout/TreeView.vue";

export interface TreeSelectModel<T, K> extends TreeModel<T, K> {
    getLabel?(data: T): string;
}

export default defineComponent({
    components: {
        TreeView,
        LoadedOrPlaceholder,
    },

    expose: ["reset"],

    props : {
        id : {
            type : String
        },

        defaultValue: {
            type: undefined as PropType<unknown>,
            default: () => null,
        },

        modelValue : {
            type: undefined as PropType<unknown>,
            default(this: void, props: unknown): unknown {
                // @ts-ignore
                return props.defaultValue;
            },
        },

        model: {
            type: Object as PropType<TreeModel<unknown, unknown>>,
        },

        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        required: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },

        wasValidated: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            selectedId: null as (null | string),
            dropdown: undefined as (undefined | Dropdown),
            resizeObserver: new ResizeObserver(this.resizeCallback.bind(this)),
            form: undefined as (HTMLFormElement | undefined),
            // @ts-ignore
            formResetListener: () => this.reset(),
        };
    },

    setup(props, context) {
        const selectedData = ref<null | unknown>(null);
        const expanded = ref(false);

        const value = makePropWithValue({
            props,
            context,
            name: "modelValue",
        });

        return {
            value,
            expanded,
            ValidationError,
            selectedData,
        };
    },

    watch: {
        expanded(expanded: boolean): void {
            if (expanded) {
                this.resizeObserver.observe(this.$refs.select as HTMLElement);
                this.dropdown.show();
            } else {
                this.resizeObserver.disconnect();
                this.dropdown.hide();
                (this.$refs.dropdown as InstanceType<typeof TreeView>).onFocusOut();
            }
        },
        disabled(disabled: boolean): void {
            if (disabled) {
                this.hide();
            }
        },
        form(newForm, oldForm): void {
            if (oldForm) {
                oldForm.removeEventListener("reset", this.formResetListener);
            }
            if (newForm) {
                newForm.addEventListener("reset", this.formResetListener);
            }
        },
        validationError(validationError: ValidationError): void {
            (this.$refs.validationInput as HTMLInputElement).setCustomValidity(validationError);
        },
    },

    mounted() {
        this.dropdown = new Dropdown(this.$refs.select, {
            popperConfig: (popperConfig) => {
                popperConfig.modifiers.push({
                    name: 'flip',
                    options: {
                        // don't try to flip between start/end alignments
                        flipVariations: false,
                    },
                });
                return popperConfig;
            },
        });

        let el = this.$refs.select as (HTMLElement | undefined);
        do {
            el = el.parentElement;
        } while (el && !(el instanceof HTMLFormElement));
        this.form = el as (HTMLFormElement | undefined);
    },

    unmounted() {
        this.dropdown.dispose();
        this.dropdown = undefined;
        this.form = undefined;
    },

    deactivated() {
        this.hide();
    },

    computed: {
        selectId(): string {
            return this.id ?? this.$id("select");
        },
        validationError(): ValidationError {
            if (this.required && this.value == null) {
                return ValidationError.MISSING;
            } else {
                return ValidationError.NONE;
            }
        },
    },

    methods: {
        toggle(): void {
            if (!this.disabled) {
                if (this.expanded) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        },
        hide(): void {
            this.expanded = false;
        },
        show(): void {
            if (!this.disabled) {
                this.expanded = true;
            }
        },
        resizeCallback(entries: ResizeObserverEntry[]): void {
            for (const entry of entries) {
                if (entry.target === this.$refs.select) {
                    (this.$refs.dropdown as InstanceType<typeof TreeView>).$el.style.minWidth =
                        (this.$refs.select as HTMLElement).offsetWidth + "px";
                }
            }
        },

        reset(): void {
            this.value = this.defaultValue;
        }
    },
});
